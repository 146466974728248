import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MenuComponent } from "../_componentes/menu/menu.component";

import { UsuarioService } from "../_servicio/usuario.service";
import { Session } from "../../global/session";
import { JsonPipe } from "@angular/common";
import { Router } from "@angular/router";
import * as $ from "jquery";
import { strictEqual } from "assert";
import { Alert } from "selenium-webdriver";
import { ComboboxService } from "../_servicioComponentes/combobox.service";
import { utilitarios } from "../../global/utilitarios";
import Swal from "sweetalert2";
import { AngularMultiSelect } from "angular2-multiselect-dropdown";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  persona: string = "";
  public angForm: FormGroup;
  public _MenuComponent: MenuComponent;
  public _Session: Session = new Session();
  public dataValidarUsuario = null;
  constructor(
    public _router: Router,
    public fb: FormBuilder,
    public combobox: ComboboxService,
    public _UsuarioService: UsuarioService,
    public _utilitarios: utilitarios
  ) {
    this._MenuComponent = new MenuComponent(_router);

    if (this._Session.usuario != null) {
      this._router.navigate(["/resumenficha"]);
    } else {
      this.validar();
    }
  }
  ngOnInit() {
    localStorage.clear();
    
    document.getElementById("divroles").style.display = "none";
    this.ocultarMenus();

    if (this._Session.recordar) {
      this.angForm.controls["codigo"].setValue(this._Session.NombreUsuario);
      this.angForm.controls["contrasenia"].setValue(this._Session.Contrasenia);
      $("#rdbRecordame").prop("checked", true);
    }
  }
  cargarCargos(idPersona) {
    var promise = new Promise((resolve, reject) => {
      this.combobox.getJson(
        {
          entidad: "mCargoPorPersona",
          parametro: {
            "@p_idPersona": idPersona,
          },
        },
        (res) => {
          $("#contenedorCargos").html("");
          res.map((element) => {
            let html = `
            <label class="btn btn-rol">
            <input type="radio" name="rol"  value="${element.id}" autocomplete="off">${element.nombre} 
            </label>`;
            $("#contenedorCargos").append(html);
          });
          resolve(res);
        }
      );
    });

    return promise;
  }
  ocultarMenus() {
    if (this._Session.usuario == null) {
      //document.getElementById("collapseTwo").style.display = "block";
      document.getElementById("header").style.display = "none";
      document.getElementById("accordionSidebar").style.display = "none";
    } else {
      //document.getElementById("collapseTwo").style.display = "block";
      document.getElementById("header").style.display = "flex";
      document.getElementById("accordionSidebar").style.display = "block";
    }
  }
  validar() {
    this.angForm = this.fb.group({
      codigo: ["", Validators.required],
      contrasenia: ["", Validators.required],
    });
  }

  validarUsuario(frm) {
    localStorage.clear();
    
    const parametro = {
      codigo: frm.value.codigo,
      contrasenia: frm.value.contrasenia,
    };
    this._UsuarioService.validarUsuario(parametro, (res) => {
      let totalCargos: number = 0;
      let roles: number = 0;
      let listaCargos: any;
      this.dataValidarUsuario = res;
      this.persona = res.persona.nombreApellidos;

      let nombreInicial = res.persona.nombres.toString().substr(0, 1);
      let apellidoInicial = res.persona.apellidos.toString().substr(0, 1);
      
      this._Session.iniciales = nombreInicial + apellidoInicial;
      $("#lblNombreUsuario").html(this.persona);

      $("#lblNombreUsuario")
        .next()
        .children("span")
        .html(this._Session.iniciales);


      if ($("#rdbRecordame").prop("checked")) {
        this._Session.recordar = true;
        this._Session.NombreUsuario = this.angForm.controls["codigo"].value;
        this._Session.Contrasenia = this.angForm.controls["contrasenia"].value;
      } else {
        this._Session.recordar = false;
        this._Session.NombreUsuario = null;
        this._Session.Contrasenia = null;
      }

      this._Session.Opciones = res.opciones;
      this._Session.usuario = this.dataValidarUsuario;

      Promise.all([
        this.cargarCargos(this.dataValidarUsuario.persona.idPersona),
      ]).then((data) => {
        listaCargos = data[0];
        totalCargos = listaCargos.length as number;
 
        roles = res.roles.length;

        if (totalCargos > 1) {
          this._Session.usuario = null;
          document.getElementById("angForm").style.display = "none";
          document.getElementById("forgotPassword").style.display = "none";
          document.getElementById("divroles").style.display = "block";
        } else if (totalCargos == 1) {
          let cargo = listaCargos[0];

          if (!this.validarNoTieneRoles(roles)) {
            let esDocente: boolean = false;
            this.removerRoles(cargo.id);
       
            this._Session.usuario = this.dataValidarUsuario;
            switch (cargo.id) {
              case this._utilitarios.enumCargo.CoordinadorAcademico:
                this.ocultarMenus();
                this._MenuComponent.dibujarMenu();
                this._router.navigateByUrl("/solicitudficha");
                this._Session.CargoElegido = this._utilitarios.enumCargo.CoordinadorAcademico;
                break;
              case this._utilitarios.enumCargo.CoordinadorInvestigacion:
                this.ocultarMenus();
                this._MenuComponent.dibujarMenu();
                this._Session.CargoElegido = this._utilitarios.enumCargo.CoordinadorInvestigacion;
                this._router.navigateByUrl("/resumenficha");
                break;
              case this._utilitarios.enumCargo.Docente:
                this.ocultarMenus();
                this._MenuComponent.dibujarMenu();
                this._Session.CargoElegido = this._utilitarios.enumCargo.Docente;
                this._router.navigateByUrl("/ficha");
                break;
                case this._utilitarios.enumCargo.Revisor:
                  this.ocultarMenus();
                  this._MenuComponent.dibujarMenu();
                  this._Session.CargoElegido = this._utilitarios.enumCargo.Docente;
                  this._router.navigateByUrl("/fichasAsignadas");
                  break;
              default:
                alert("El cargo no se asigno en los enumeradores.");
                break;
            }
          }
          //  window.location.href = "/";
        } else {
          localStorage.clear();
          alert("No tiene cargos asignados correctamente.");
        }
      });
    });
  }
  validarNoTieneRoles(totalRoles) {
    let bit: Boolean = false;

    if (totalRoles == 0) {
      Swal.fire({
        html: '<span style="font-weight: 600">No tiene roles asignados.</span>',
        timer: 1500,
        showConfirmButton: false,
      });
      bit = true;
    }
    return bit;
  }
  seleccionarrol() {
    var remover = null;
    let idRoles: string[] = new Array();
    let cargo: string = "";
    if ($('input[name="rol"]:checked').length == 0) {
      Swal.fire({
        html:
          '<span style="font-weight: 600">No ha seleccionado un rol.</span>',
        timer: 1500,
        showConfirmButton: false,
      });
    } else {
      cargo = $('input[name="rol"]:checked').val() as string;
    }

    if (cargo != "") {
      this.removerRoles(cargo);
      this._Session.usuario = this.dataValidarUsuario;

      switch (cargo) {
        case this._utilitarios.enumCargo.CoordinadorAcademico:
          this.ocultarMenus();
          this._Session.CargoElegido = this._utilitarios.enumCargo.CoordinadorAcademico;
          this._MenuComponent.dibujarMenu();
          this._router.navigateByUrl("/solicitudficha");
          break;
        case this._utilitarios.enumCargo.CoordinadorInvestigacion:
          this.ocultarMenus();
          this._Session.CargoElegido = this._utilitarios.enumCargo.CoordinadorInvestigacion;
          this._MenuComponent.dibujarMenu();
          this._router.navigateByUrl("/resumenficha");
          break;
        case this._utilitarios.enumCargo.Docente:
          $("#persona").html(this._Session.usuario.persona.nombreApellidos);
          this.ocultarMenus();
          this._Session.CargoElegido = this._utilitarios.enumCargo.Docente;
          this._MenuComponent.dibujarMenu();
          this._router.navigateByUrl("/ficha");
          break;
        case this._utilitarios.enumCargo.Revisor:
          $("#persona").html(this._Session.usuario.persona.nombreApellidos);
          this.ocultarMenus();
          this._Session.CargoElegido = this._utilitarios.enumCargo.Revisor;
          this._MenuComponent.dibujarMenu();
          this._router.navigateByUrl("/fichasAsignadas");
          break;
        default:
          break;
      }
    }
  }

  private removerRoles(cargo) {
    if (cargo == this._utilitarios.enumCargo.Docente) {
 
      for (var r = this.dataValidarUsuario.acciones.length - 1; r >= 0; r--) {
        if (
          this._utilitarios.enumRoles.docente !=
          this.dataValidarUsuario.acciones[r].codigoRol
        ) {
          this.dataValidarUsuario.acciones.splice(r, 1);
        }
      }

      for (r = this.dataValidarUsuario.roles.length - 1; r >= 0; r--) {
        if (
          this._utilitarios.enumRoles.docente !=
          this.dataValidarUsuario.roles[r].codigoRol
        ) {
          this.dataValidarUsuario.roles.splice(r, 1);
        }
      }

      for (r = this.dataValidarUsuario.opciones.length - 1; r >= 0; r--) {
        if (
          this._utilitarios.enumRoles.docente !=
          this.dataValidarUsuario.opciones[r].codigoRol
        ) {
          this.dataValidarUsuario.opciones.splice(r, 1);
        }
      }
    } 
    else {
      let idRolesRemover = [];

      for (var r = this.dataValidarUsuario.acciones.length - 1; r >= 0; r--) {
        if (
          this._utilitarios.enumRoles.docente ==
          this.dataValidarUsuario.acciones[r].codigoRol
        ) {
          idRolesRemover.push(this.dataValidarUsuario.acciones[r].codigoRol);
          this.dataValidarUsuario.acciones.splice(r, 1);
        } else {
          if (cargo == this._utilitarios.enumCargo.CoordinadorAcademico) {
            if (
              this._utilitarios.enumRoles.coordinadorInvestigacion ==
              this.dataValidarUsuario.acciones[r].codigoRol
            ) {
              idRolesRemover.push(
                this.dataValidarUsuario.acciones[r].codigoRol
              );

              this.dataValidarUsuario.acciones.splice(r, 1);
            }
          } else if (
            cargo == this._utilitarios.enumCargo.CoordinadorInvestigacion
          ) {
            if (
              this._utilitarios.enumRoles.coordinadorAcademico ==
              this.dataValidarUsuario.acciones[r].codigoRol
            ) {
              idRolesRemover.push(
                this.dataValidarUsuario.acciones[r].codigoRol
              );
              this.dataValidarUsuario.acciones.splice(r, 1);
            }
          }
        }
      }
      console.log("::::::::::::::::::::::::");
      console.log(idRolesRemover);
      for (var r = this.dataValidarUsuario.roles.length - 1; r >= 0; r--) {
        var find = idRolesRemover.find(
          (element) => element == this.dataValidarUsuario.roles[r].codigoRol
        );
        if (typeof find != "undefined") {
          this.dataValidarUsuario.roles.splice(r, 1);
        }
      }

      for (var r = this.dataValidarUsuario.opciones.length - 1; r >= 0; r--) {
        var find = idRolesRemover.find(
          (element) => element == this.dataValidarUsuario.opciones[r].codigoRol
        );
        if (typeof find != "undefined") {
          this.dataValidarUsuario.opciones.splice(r, 1);
        }
      }
    }
  }
}
