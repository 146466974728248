import { Component } from "@angular/core";
import { Session } from "../global/session";
import {
  NavigationCancel,
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from "@angular/router";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import * as $ from "jquery";
import { now } from 'jquery';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  title = "angular7crud";
  private urlBase:string="";
  private version: string = "";
  private _Session = new Session();
  constructor(private _router: Router) {
    this._router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });
  }
  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
    }
  }
  ngOnInit() {
    let today = new Date(),
      h = today.getHours(),
      m = today.getMinutes(),
      s = today.getSeconds();

    this.version = '?v=' + h.toString() + m.toString() + s.toString();

    this.urlBase=document.querySelector('base').getAttribute('href');
    this.cerrarSession();

  }
  ngAfterViewInit() {
    $(document).ready(
      function () {

        if (this._Session.usuario == null) {

          if (this._router.url.indexOf('/login') < 0) {
            window.location.href =   this.urlBase+ "login" + this.version;
          }

        } else {
          $("#lblNombreUsuario").html(
            this._Session.usuario.persona.nombreApellidos

          );

          $("#lblNombreUsuario")
            .next()
            .children("span")
            .html(this._Session.iniciales);
          //document.getElementById("collapseTwo").style.display = "block";
          document.getElementById("header").style.display = "flex";
          document.getElementById("accordionSidebar").style.display = "block";

        }
      }.bind(this)
    );
  }

  cerrarSession() {
    document.getElementById("logoutModal").addEventListener("click", target => {
      localStorage.clear();

      //this._router.navigateByUrl("/login");
      window.location.href = this.urlBase+"login" + this.version;
      //  this._Session.usuario = null;
      //document.getElementById("collapseTwo").style.display = "none";
      //  document.getElementById("header").style.display = "none";
      //  document.getElementById("accordionSidebar").style.display = "none";



    });
  }


}
