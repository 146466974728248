import { Injectable } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { MapRoute } from "../../global/maproute";

@Injectable({
  providedIn: "root"
})
export class PreguntaFichaService {
  private _MapRoute: MapRoute = null;
  private urlUsuario: string = "";
  constructor(private http: HttpClient) {
    this._MapRoute = new MapRoute(http);
    this.urlUsuario = this._MapRoute.tibaURL + "PreguntaFicha";
  }

  ObtenerPreguntasAsociadas() {
    var promise = new Promise((resolve, reject) => {
      this._MapRoute.post(
        `${this.urlUsuario}/ObtenerPreguntasAsociadas`,
        {},
        data => {
          resolve(data);
        }
      );
    });
    return promise;
  }
}
