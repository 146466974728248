import { Component, ViewChild, OnInit } from "@angular/core";
import { JsonPipe, getLocaleDateTimeFormat } from "@angular/common";
import { Router } from "@angular/router";
import * as $ from "jquery";
import Swal from "sweetalert2";

//---------------------------------------------------------------------
//-- Clases Locales
//---------------------------------------------------------------------

import { SolicitudFichaService } from "../../_servicio/solicitudFicha.service";
import { ComboboxService } from "../../_servicioComponentes/combobox.service";
import { Session } from "../../../global/session";
import { utilitarios } from "../../../global/utilitarios";

@Component({
  selector: "app-solicitudficha-listar",
  templateUrl: "./solicitudficha-listar.component.html",
  styleUrls: ["./solicitudficha-listar.component.scss"]
})
export class SolicitudfichaListarComponent implements OnInit {
  pendiente: number = 0;
  resumen: any = {
    solicitadas: 0,
    asignadas: 0,
    pendientes: 0,
    aprobado: 0
  };

  model: any = {
    idPeriodo: ""
  };
  constructor(
    private combobox: ComboboxService,
    private _SolicitudFicha: SolicitudFichaService,
    private _Session: Session,
    private _utilitarios: utilitarios
  ) {

    $(document).ready(() => {

      $('[class="container-fluid pb-5"]').removeAttr('style')
    })

  }

  ngOnInit() {
    this.onInitCombobox();
    this.onClickListar();
  }

  onInitCombobox() {
    this.cargarCombobox();
  }

  onClickListar() {
    this._SolicitudFicha.Listar(
      {
        idPersona: this._Session.usuario.persona.idPersona,
        idPeriodo: $("#cboPeriodo").val() == "" ? 0 : $("#cboPeriodo").val(),
        idPersonaSolicitada:
          $("#cboDocente").val() == "" ? 0 : $("#cboDocente").val(),
        idCampusFacultadCarrera:
          $("#cboCarrera").val() == "" ? 0 : $("#cboCarrera").val()
      },
      data => {
        var table = $("#tblListarSolicitudFicha");
        this._utilitarios.Datatable(table, {
          columns: [
            { data: "periodo", class: "small_col" },
            { data: "campus", class: "small_col" },
            { data: "carrera" },
            { data: "nombreApellidos" },
            { data: "solicitadas", class: "text-center small_col text--black" },
            { data: "aprobado", class: "text-center small_col text--green" },
            { data: "pendientes", class: "text-center small_col text--orange" },
            {
              data: "nombreEstado",
              class: "small_col text-center",
              render: function(index, display, rowdata) {
                return (
                  '<span class="ui-badge-' +
                  rowdata.nombreEstado +
                  '">' +
                  rowdata.nombreEstado +
                  "</span>"
                );
              }
            },
            {
              data: null,
              class: "text-center small_col",
              render: function() {
                var html = `<div class="ui-icon-edit data-edit">
                          <i class="material-icons" >
                          <a href="javascript:void(0)" data-edit="">edit</a>
                          </i>
                        </div>
                        <div class="ui-icon-delete data-delete">
                          <i class="material-icons" >
                            <a href="javascript:void(0)" data-edit="">delete</a>
                          </i>
                        </div>`;
                return html;
              }
            }
          ],
          data: data,
          fnDrawCallback: draw => {
            this.obtenerResumen({
              callback: null
            });
          }
        });
      }
    );
  }

  onChangePeriodo(event) {}
  //---------------------------------------------------------------------
  //-- Privates
  //---------------------------------------------------------------------
  private cargarCombobox() {
    this.combobox.init(
      {
        id: "cboPeriodo",
        entidad: "mPeriodo",
        html: "todos"
      },
      respuesta => {
        $("#cboPeriodo")
          .find("option")
          .removeAttr("selected");
        var element = $("#cboPeriodo").children("option:nth-child(0n+2)");
        element.attr("selected", "selected");
        this.model.idPeriodo = element.val();
        this.obtenerResumen({});
      }
    );
    this.combobox.init(
      {
        id: "cboDocente",
        entidad: "DocentesPorCordinadorAcademico",
        html: "todos",
        parametro: {
          "@p_idCoordinadorAcademico": this._Session.usuario.persona.idPersona
        }
      },
      null
    );

    this.combobox.init(
      {
        id: "cboCarrera",
        entidad: "mCampusFacultadCarreraCoordinadorAcademico",
        html: "todos",
        parametro: {
          "@p_idPersona": this._Session.usuario.persona.idPersona
        }
      },
      null
    );
  }

  obtenerResumen(parametro) {
    if ($("#cboPeriodo").val() == "") {
      this.resumen = {
        solicitadas: 0,
        asignadas: 0,
        pendientes: 0,
        aprobado: 0
      };
    } else {
      this._SolicitudFicha.ResumenSolicitudFicha(
        {
          idPersona: this._Session.usuario.persona.idPersona,
          idPeriodo: $("#cboPeriodo").val(),
          idCampusFacultadCarrera:
            $("#cboCarrera").val() == "" ? 0 : $("#cboCarrera").val()
        },
        data => {
          if (parametro.callback != null) {
            parametro.callback(data);
          }

          this.pendiente = data.pendientes;
          this.resumen = {
            solicitadas: data.solicitadas,
            asignadas: data.asignadas,
            pendientes: data.pendientes,
            aprobado: data.aprobado
          };
        }
      );
    }
  }
}
