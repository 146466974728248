import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { MapRoute } from "../../global/maproute";
import { CompetenciaBE } from '../model/CompetenciaModel';


@Injectable({
  providedIn: 'root'
})
export class CompetenciaService {
  private _MapRoute: MapRoute = null;
  private url: string = "";

  constructor(private http: HttpClient) {
    this._MapRoute = new MapRoute(http);
    this.url = this._MapRoute.tibaURL + "Competencia";
   }

   RegistrarCompetencia(parametro: CompetenciaBE, callback) {
    this._MapRoute.post(
      `${this.url}/InsertarCompetencia`,
      parametro,
      callback
    );
  }

  ActualizarCompetencia(parametro: CompetenciaBE, callback) {
    this._MapRoute.post(
      `${this.url}/ActualizarCompetencia`,
      parametro,
      callback
    );
  }

  Listar(parametro, callback) {
    this._MapRoute.post(
      `${this.url}/ListarCompetencia`,
      parametro,
      callback
    );
  }

  ListarEstado(parametro, callback) {
    this._MapRoute.post(
      `${this.url}/ListarEstadoMantenimiento`,
      parametro,
      callback
    );
  }
}
