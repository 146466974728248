import { Injectable } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { MapRoute } from "../../global/maproute";

@Injectable({
  providedIn: "root"
})
export class SeccionService {
  private _MapRoute: MapRoute = null;
  private urlUsuario: string = "";
  constructor(private http: HttpClient) {
    this._MapRoute = new MapRoute(http);
    this.urlUsuario = this._MapRoute.tibaURL + "seccion";
  }
  ListarMovimientoFichas(parametro, callback) {
    this._MapRoute.post(
      `${this.urlUsuario}/ListarMovimientoFichas`,
      parametro,
      callback
    );
  }

  ListarFichaAsociadas(parametro, callback) {
    this._MapRoute.post(
      `${this.urlUsuario}/ListarFichaAsociadas`,
      parametro,
      callback
    );
  }
}
