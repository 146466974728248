import { Injectable } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { MapRoute } from "../../global/maproute";

@Injectable({
  providedIn: "root"
})
export class SolicitudFichaService {
  private _MapRoute: MapRoute = null;
  private url: string = "";
  constructor(private http: HttpClient) {
    this._MapRoute = new MapRoute(http);
    this.url = this._MapRoute.tibaURL + "SolicitudFicha";
  }

  Listar(parametro, callback) {
    this._MapRoute.post(`${this.url}/Listar`, parametro, callback);
  }
  ListarFichasAsignadasPorDocente(parametro, callback) {
    return this._MapRoute.post(
      `${this.url}/ListarFichasAsignadasPorDocente`,
      parametro,
      callback
    );
  }
  ListarFichasDocentesPorTitulo(parametro, callback) {
    return this._MapRoute.post(
      `${this.url}/ListarFichasDocentesPorTitulo`,
      parametro,
      callback
    );
  }
  RegistrarActualizar(parametro, callback) {
    this._MapRoute.post(`${this.url}/RegistrarActualizar`, parametro, callback);
  }
  ObtenerPorId(parametro, callback) {
    this._MapRoute.post(`${this.url}/ObtenerPorId`, parametro, callback);
  }
  ObtenerSolicitudFichaPorId(parametro, callback) {
    var model = {
      idSolicitudFicha: parametro.idSolicitudFicha
    };
    this._MapRoute.post(
      `${this.url}/ObtenerSolicitudFichaPorId`,
      model,
      callback
    );
  }
  ResumenSolicitudFicha(parametro, callback) {
    var model = {
      idPersona: parametro.idPersona,
      idPeriodo: parametro.idPeriodo,
      idCampusFacultadCarrera:parametro.idCampusFacultadCarrera
    };
    this._MapRoute.post(`${this.url}/ResumenSolicitudFicha`, model, callback);
  }

  DesactivarSolicitudFicha(parametro, callback) {
    var model = {
      idSolicitudFicha: parametro.idSolicitudFicha
    };
    this._MapRoute.post(
      `${this.url}/DesactivarSolicitudFicha`,
      model,
      callback
    );
  }
}
