import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class asignarFichaModel {
  constructor() {}

  //Formulario
  txtComentarioDesasociacion: AbstractControl;

  //Variables
  _indexFicha:number=0;
  _indexAl:number=0;
  _idBanco:number=0;
 
  _tipoCurso:string='';
  _codigoAlumno: string;
  _periodo: number = 0;
  _idAlumno: number = 0;
  _idPeriodo: number = 0;
  _idPersonaDocente: number = 0;
  _idSeccion: string;
  _seccion: string;
  _fichasDisponibles: number = 0;
  _fichasAsignadas: number = 0;
  _AlumnosAsignados: number = 0;
  _AlumnosSinAsignar: number = 0;
  _FichasAsociadasModel: Array<any>;
  _AlumnosSinAsignarModel: Array<any>;

  

  private _cboPeriodo: AbstractControl;
  get cboPeriodo() {
    return this._cboPeriodo;
  }
  set cboPeriodo(value: AbstractControl) {
    this._cboPeriodo = value;
  }
}
