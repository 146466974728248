<div class="row justify-content-between">

    <div class="col-md-6">
        <!-- Title section -->
        <h3>Mantenimiento de competencia</h3>
        <i class="material-icons" id="btnAgregar" (click)="ngClick_btnAgregar($event)">add_circle</i>
        <!-- End Title section -->
    </div>
</div>

<div class="card">
    <!-- Filters -->
    <h6>Filtros</h6>
    <div class="row justify-content-between m-0">
        <div class="col-md-10">
            <div class="row">
          
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="cboCarrera_filtro">Carrera</label>
                        <select class="form-control" id="cboCarrera_filtro" data-html="todos">
                        </select>
                    </div>
                </div>

                
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="cboEstado_filtro">Estado</label>
                        <select class="form-control" id="cboEstado_filtro">
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="txtCompetencia_filtro">competencia</label>
                        <input class="form-control" id="txtCompetencia_filtro">
                    </div>
                </div>
                
            </div>
        </div>
        <div class="col-md-2">
            <button class="btn btn-buscar" type="button" id="btnBuscarSolicitud" (click)="ngClick_btnBuscar()">Buscar<i
                    class="material-icons">search</i></button>
        </div>
    </div>
    <!-- End Filters -->
    <!-- Table -->
    <div class="mx-3">
        <table class="table" id="tblGestionarCompetencia">
            <thead>
                <tr>

                    <!--<th scope="col">Codigo</th>-->
                    <th scope="col">Carrera</th>
                    <th scope="col">competencia</th>
                    <!--<th scope="col">Cargo</th>
                    <th scope="col">Carrera</th>-->

                    <!--<th scope="col">Fecha registro</th>-->
                    <th scope="col">Estado</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
    <!-- End Table -->
</div>

<div class="modal fade" id="modalAgregarCompetencia" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Competencia 
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <form name="frmCompetencia" class="card form-signin p-4" onkeydown="return event.key != 'Enter';"
                [formGroup]="_FORMFICHA" novalidate id="_FORMFICHA">
                <div class="row">

                    <div class="col-md-12 mb-2">
                        <b>{{mensajeInformativo}}</b>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group" >
                            <label for="cboCarrera">Carrera</label>
                            <select class="form-control" data-html="seleccione" id="cboCarrera"    
                                formControlName="cboCarrera" #cboCarrera ></select>
                            <div *ngIf="_FORMFICHA.controls['cboCarrera'].invalid && (_FORMFICHA.controls['cboCarrera'].dirty || _FORMFICHA.controls['cboCarrera'].touched )"
                                class="alert alert-danger">
                                <div *ngIf=" _FORMFICHA.controls['cboCarrera'].errors.required">Seleccione una carrera
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <input type="hidden" formControlName="txtIdCompetencia" />

                    <div class="col-md-8">
                        <div class="form-group">
                            <label for="txtCompetencia">Competencia</label>
                            <textarea rows="4" class="form-control" formControlName="txtCompetencia" #txtCompetencia id="txtCompetencia"
                                 
                                 ></textarea>
                            <div *ngIf="_FORMFICHA.controls['txtCompetencia'].invalid && (_FORMFICHA.controls['txtCompetencia'].dirty || _FORMFICHA.controls['txtCompetencia'].touched )"
                                class="alert alert-danger">
                                <div *ngIf=" _FORMFICHA.controls['txtCompetencia'].errors.required">Ingrese una competencia</div>
                            </div>
                        </div>
                    </div>
                    
                </div>


                <div class="row">
                    <div class="col-md-4">
                        
                    </div>
                    <!-- <div class="col-md-4">
                        <div class="form-group">
                            <label for="cboRol">Rol</label>
                            <select class="form-control" formControlName="cboRol" #cboRol id="cboRol"></select>
                            <div *ngIf="_FORMFICHA.controls['cboRol'].invalid && (_FORMFICHA.controls['cboRol'].dirty || _FORMFICHA.controls['cboRol'].touched )"
                                class="alert alert-danger">
                                <div *ngIf=" _FORMFICHA.controls['cboRol'].errors.required">Seleccione un rol
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="col-md-4">
                        
                    </div>

                    <div class="col-md-3" >
                        <div class="form-group">
                            <label for="cboEstado">Estado</label>
                            <select class="form-control" data-html="seleccione" id="cboEstado"  
                                formControlName="cboEstado" #cboEstado ></select>
                            <div *ngIf="_FORMFICHA.controls['cboEstado'].invalid && (_FORMFICHA.controls['cboEstado'].dirty || _FORMFICHA.controls['cboEstado'].touched )"
                                class="alert alert-danger">
                                <div *ngIf=" _FORMFICHA.controls['cboEstado'].errors.required">Seleccione un Estado
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary btn-save col-md-4"
                        (click)="ngClick_btnConfirmar($event)">Confirmar<i class="material-icons">save</i></button>

                </div>
            </form>

        </div>
    </div>
</div>


