import {
  Component,
  ViewChild,
  OnInit,
  Output,
  EventEmitter
} from "@angular/core";
import { NgbDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { JsonPipe, getLocaleDateTimeFormat } from "@angular/common";
import { Router } from "@angular/router";
import * as $ from "jquery";
//---------------------------------------------------------------------
//-- Components
//---------------------------------------------------------------------

//---------------------------------------------------------------------
//-- Clases Locales
//---------------------------------------------------------------------

import { ResumenFichaService } from "../../_servicio/resumenFicha.service";
import { ComboboxService } from "../../_servicioComponentes/combobox.service";
import { Session } from "../../../global/session";
import { utilitarios } from "../../../global/utilitarios";

@Component({
  selector: "app-resumenficha-listar",
  templateUrl: "./resumenficha-listar.component.html",
  styleUrls: ["./resumenficha-listar.component.scss"]
})
export class ResumenfichaListarComponent implements OnInit {
  bodyText: string;
  message: string;
  parameter: string = "..";
  model: any = {
    idPeriodo: "",
    fechaRegistro: {
      year: 0,
      month: 0,
      day: 0
    }
  };
  private _onInitEventoGrilla = null;
  public tableWidget: any;
  @ViewChild("d") private datePicker: NgbDatepicker;

  constructor(
    private _ResumenFichaService: ResumenFichaService,
    private combobox: ComboboxService,
    private _Session: Session,
    private _utilitarios: utilitarios,
    private _Router: Router
  ) { }

  ngOnInit() {
    this.bodyText = "This text can be updated in modal 1";
    this.initCombobox();
    this.onListarResumenFichas();
  }
  ngAfterViewInit() { }
  onListarResumenFichas() {
    var fechaRegistro = null;
    if ($("#fechaRegistro").val() != "") {
      fechaRegistro = this._utilitarios.ConvertJsonDate(
        this.model.fechaRegistro.day.toString() +
        "/" +
        this.model.fechaRegistro.month.toString() +
        "/" +
        this.model.fechaRegistro.year.toString()
      );
    }

    this._ResumenFichaService.Listar(
      {
        idPersonaRegistra: this._Session.usuario.persona.idPersona,
        idPeriodo: $("#cboPeriodo").val() == "" ? 0 : $("#cboPeriodo").val(),
        fechaRegistro: fechaRegistro == "" ? null : fechaRegistro,
        IdPersonaCordinadorAcademico: $('#cboAcademico').val() == '' ? 0 : $('#cboAcademico').val(),
        idCarrera: $("#cboCarrera").val() == "" ? 0 : $("#cboCarrera").val()
      },
      res => {
        let exampleId: any = $("#tblListar");
        this._utilitarios.Datatable(exampleId, {
          columns: [
            { data: "periodo", class: "small_col" },
            { data: "campus", class: "small_col" },
            { data: "carrera" },
            { data: "cordinador" },
            { data: "fechaRegistro", class: "fecha small_col" },
            { data: "solicitadas", class: "text-center small_col text--black" },
            { data: "fechaLimite", class: "text-center fecha small_col" },
            { data: "aprobadoOrdinario", class: "text-center small_col text--green" },
            { data: "aprobadoExtraordinario", class: "text-center small_col text--green" },
            { data: "nombreTipoRegistro" },
            {
              data: null,
              class: "text-center small_col",
              render: function (index, row, data) {
                var html = `<div class="ui-icon-edit data-edit">
                            <i class="material-icons" >
                            <a href="javascript:void(0)" data-edit="" >edit</a>
                            </i>
                          </div>
                          <div class="ui-icon-delete data-delete">
                            <i class="material-icons" >
                              <a href="javascript:void(0)" data-edit="">delete</a>
                            </i>
                          </div>`;
                if (data.idTipoProcesoFicha == 2) {
                  html = '';

                }
                return html;
              }
            },
            { data: "idTipoProcesoFicha", visible: false }
          ],
          data: res,
          fnDrawCallback: event => { }
        });
      }
    );
  }
  onInitEventoGrilla(callback) {
    this._onInitEventoGrilla = callback;
  }

  receiveMessage($event) {
    this.message = $event;
  }
  initCombobox() {
    this.combobox.init(
      {
        id: "cboPeriodo",
        entidad: "mPeriodo",
        html: "todos"
      },
      resultado => {
        $("#cboPeriodo")
          .find("option")
          .removeAttr("selected");
        var element = $("#cboPeriodo").children("option:nth-child(0n+2)");
        element.attr("selected", "selected");
        this.model.idPeriodo = element.val();
      }
    );
    this.combobox.init(
      {
        id: "cboAcademico",
        entidad: "mAcademicosFichasPorCoordInvestigacion",
        html: "todos",
        parametro: {
          "@p_idPersona": this._Session.usuario.persona.idPersona
        }
      },
      null
    );


    this.combobox.init(
      {
        id: "cboCarrera",
        entidad: "mCarreraPorPersonaCampus",
        html: "todos",
        parametro: {
          "@p_idPersona": 0
        }
      },
      null
    );

  }

  onClickCalendarUTP(event) {
    this.model.fechaRegistro = { year: 0, month: 0, day: 0 };
  }
  ngChange_coordinadorAcademico(event) {

    if ($('#cboAcademico').val() == '') {

      this.combobox.init(
        {
          id: "cboCarrera",
          entidad: "mCarreraPorPersonaCampus",
          html: "todos",
          parametro: {
            "@p_idPersona": 0
          }
        },
        null
      );
    } else {

      this.combobox.init(
        {
          id: "cboCarrera",
          entidad: "mCarreraPorPersona",
          html: "todos",
          parametro: {
            "@p_idPersona": $('#cboAcademico').val()
          }
        },
        null
      );
    }

  }
  //#region privates

  //#endregion privates
}
