import { Injectable } from "@angular/core";
import {  HttpClient } from "@angular/common/http";
import { MapRoute } from "../../global/maproute";
import * as $ from "jquery"; 
import { Session } from "../../global/session";
import Swal from "sweetalert2";
@Injectable({ providedIn: "root" })
export class ComboboxService {
  private urlUsuario: string = "";
  private _MapRoute: MapRoute = null;
  constructor(private http: HttpClient, private _Session: Session) {
    this._MapRoute = new MapRoute(http);
    this.urlUsuario = this._MapRoute.tibaURL + "AyudaLista";
  }

  init(parametro, callback) {
    return this.ajaxPost(parametro, data => {
      var data = data;
      var element = $("#" + parametro.id);
       
      var selectElement = element;
      selectElement.find("option:first-child").html(parametro.html);
      selectElement.find("option:not(:first-child)").remove();
      for (var i = 0; i < data.length; i++) {
        var option = $("<option></option>");
        option.attr("value", data[i].id);
        option.html(data[i].nombre);
        selectElement.append(option);
      }
      selectElement.find("option:first-child").prop("selected", false);
      if (parametro.valorDefault != null) {
        selectElement.val(parametro.valorDefault);
      } else {
        selectElement.find("option:first-child").prop("selected", true);
      }
      if (callback != null) {
        callback(selectElement);
      }
    });
  }
  getJson(parametro, callback) {
    return this.ajaxPost(parametro, data => {
      callback(data);
    });
  }

  Clear(id) {
    var element = $("#" + id);
    element.find("option:not(:first-child)").remove();
  }

  //#region privates

  private ajaxPost(parametro, callback) {
    let that = this;
    var conf: any = {
      html: "seleccione",
      id: null,
      entidad: null,
      parametro: null,
      valorDefault: null
    };

    parametro = $.extend(true, conf, parametro);
    return $.ajax({
      beforeSend: function(request) {
        // setting a timeout
        if (that._Session.token != null) {
          request.setRequestHeader(
            "Authorization",
            "Bearer " + that._Session.token
          );
        }
      },
      data: JSON.stringify(parametro),
      type: "POST",
      url: `${this.urlUsuario}/Combobox`,
      async: false,
      contentType: "application/json;charset=utf-8",
      dataType: "json",
      success: function(response) {
        var jsonResponse = response;
        if (jsonResponse.idError > 0) {
          alert(jsonResponse.mensaje);
        } else {
          if (jsonResponse.mensaje != null && jsonResponse.mensaje != "") {
            alert(jsonResponse.mensaje);
          }
          callback(jsonResponse.data);
        }
      },
      error: function(request, status, error) {
        setTimeout(() => {
          var re = /^https?:\/\/[^/]+/i;
          $("#previewUTP").css("display", "none");
          if (request.status == 401) {
            let urlBase = document.querySelector("base").getAttribute("href");

            Swal.fire({
              html:
                '<span style="font-weight: 600">' +
                "La sesión ha caducado.<br/> <span style='font-size:12px;'>Vuelva a iniciar sessión <a class='closeLogin'  href='" +
                 '/login'+
                "'>Aqui</a>.</span> " +
                "</span>",
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: "Cerrar",
              allowOutsideClick: false
            });

            $(".closeLogin").click(() => {
              localStorage.clear();
              sessionStorage.clear();
           });
          }
        }, 500);
      }
    });
  }
  //#endregion privates
}
