import { Component, OnInit, Input, ElementRef } from "@angular/core";
import * as $ from "jquery";
@Component({
  selector: "select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"]
})
export class SelectComponent implements OnInit {
  id: any = null;

  constructor(private elementRef: ElementRef) { }

  ngOnInit() { }
  ngAfterViewInit() {
    var select = this.elementRef.nativeElement;
    select.classList.add("form-control");
    this.id = this.elementRef.nativeElement.getAttribute("id");
    let html: string = this.elementRef.nativeElement.getAttribute("data-html");
    let cancel: string = this.elementRef.nativeElement.getAttribute("data-cancel");


    $("#" + this.id)
      .find("option:first-child")
      .attr("selected", "selected");
    if (html != null) {
      $("#" + this.id)
        .find("option:first-child")
        .html(html);
    }


    /*
    var select=this.elementRef.nativeElement.children[0];
    this.id = this.elementRef.nativeElement.getAttribute('id'); 
    select.setAttribute('id',this.id);
    select.setAttribute('formControlName',this.id);
    select.setAttribute(''+this.id,'');
    
    this.elementRef.nativeElement.removeAttribute('id'); 
    */
  }
}
