//---------------------------------------------------------------------------------------------------------------
// Cargo
//---------------------------------------------------------------------------------------------------------------
export class enumCargo {
  private static _instance: enumCargo;

  constructor() {
    //...
  }

  public static get Instance() {
    // Do you need arguments? Make it a regular static method instead.
    return this._instance || (this._instance = new this());
  }

  CoordinadorInvestigacion: string = "COIN";
  CoordinadorAcademico: string = "COAC";
  Docente: string = "DOET";
  Revisor: string = "REVS";
}
//---------------------------------------------------------------------------------------------------------------
// Estado
//---------------------------------------------------------------------------------------------------------------
export class enumEstado {
  private static _instance: enumEstado;

  constructor() {
    //...
  }

  public static get Instance() {
    // Do you need arguments? Make it a regular static method instead.
    return this._instance || (this._instance = new this());
  }
  Anulado: string = "ANOD";
  Guardado: string = "GUOD";
  Inactivo: string = "INOV";
  Activo: string = "ACOV";
  Enviado: string = "ENOD";
  Aprobadas: string = "APSA";
  Rechazado: string = "REOD";
  Observado: string = "OBOD";
  RechazadoDocente: string = "REPODO";
  ObservacionAprobadaDocente: string = "OBAPPODO";
  Reservado: string = "RSRV";
  Asignado: string = "ASIGN";
  AsignadoCursoTaller: string = "ASCRT";  
  SinAsignar: string = "SNASI";
}
//---------------------------------------------------------------------------------------------------------------
// Estado
//---------------------------------------------------------------------------------------------------------------
export class enumOpciones {
  private static _instance: enumOpciones;

  constructor() {
    //...
  }

  public static get Instance() {
    // Do you need arguments? Make it a regular static method instead.
    return this._instance || (this._instance = new this());
  }

  CargaProyeccion: string = "CADEPR";
  EliminarFichaAprobada: string = "FCAPEL";
  AsignarFichaSeccion: string = "ASFSC";
}
//---------------------------------------------------------------------------------------------------------------
// Estado Roles
//---------------------------------------------------------------------------------------------------------------
export class enumRoles {
  private static _instance: enumRoles;

  constructor() {
    //...
  }

  public static get Instance() {
    // Do you need arguments? Make it a regular static method instead.
    return this._instance || (this._instance = new this());
  }

  coordinadorAcademico: string = "CORAC";
  coordinadorInvestigacion: string = "CORIN";
  docente: string = "DOCN";
 revisor: string = "REVS";
 administrador: string = "ADMIN";
}
