import { Injectable } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { MapRoute } from "../../global/maproute";
import { PersonaBE, PersonaCampusCarreraBE } from '../model/PersonaModel';



@Injectable({
  providedIn: "root"
})

export class PersonaService {
  private _MapRoute: MapRoute = null;
  private url: string = "";
  constructor(private http: HttpClient) {
    this._MapRoute = new MapRoute(http);
    this.url = this._MapRoute.tibaURL + "Persona";
  }

  ActivarCampusFacultadCarrera(parametro:PersonaCampusCarreraBE,callback){
    this._MapRoute.post(
      `${this.url}/ActivarCampusFacultadCarrera`,
      parametro,
      callback
    );

  }
  DesasociarCampusFacultadCarrera(parametro:PersonaCampusCarreraBE,callback){
    this._MapRoute.post(
      `${this.url}/DesasociarCampusFacultadCarrera`,
      parametro,
      callback
    );

  }
  RegistrarPersona(parametro: PersonaBE, callback) {
    this._MapRoute.post(
      `${this.url}/RegistrarPersona`,
      parametro,
      callback
    );
  }
  ObtenerPersonPorCodigoPeopleSoft(parametro: PersonaBE, callback) {
    this._MapRoute.post(
      `${this.url}/ObtenerPersonPorCodigoPeopleSoft`,
      parametro,
      callback
    );
  }
  ObtenerPorId(parametro: PersonaBE, callback) {
    this._MapRoute.post(
      `${this.url}/ObtenerPorId`,
      parametro,
      callback
    );
  }
  ListarDocentesPorCampus(parametro, callback) {
    this._MapRoute.post(
      `${this.url}/ListarDocentesPorCampus`,
      parametro,
      callback
    );
  }

  ListarAlumnosSinFichas(parametro, callback) {
    this._MapRoute.post(
      `${this.url}/ListarAlumnosSinFichas`,
      parametro,
      callback
    );
  }

  Listar(parametro, callback) {
    this._MapRoute.post(
      `${this.url}/Listar`,
      parametro,
      callback
    );
  }
  ListarRevisores(parametro, callback) {
    this._MapRoute.post(
      `${this.url}/ListarRevisores`,
      parametro,
      callback
    );
  }
  RegistrarActualizarRevisor(parametro, callback) {
    this._MapRoute.post(
      `${this.url}/RegistrarActualizarRevisor`,
      parametro,
      callback
    );
  }

}
