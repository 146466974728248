import { Injectable } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { MapRoute } from "../../global/maproute";

@Injectable({
  providedIn: "root"
})
export class ArchivoService {
  private _MapRoute: MapRoute = null;
  private url: string = "";
  constructor(private http: HttpClient) {
    this._MapRoute = new MapRoute(http);
    this.url = this._MapRoute.tibaURL + "Archivo";
  }
  descargarArchivo(url,callback) {
   return this._MapRoute.post(url, null, callback);
  }

  descargarArchivoExcelProyeccion(parametro,callback){
    return this._MapRoute.post(this.url+'/archivoExcelProyeccion', parametro, callback);
  }
}
