import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { MapRoute } from "../../global/maproute";
import { InvestigacionBE } from '../model/InvestigacionModel';

@Injectable({
  providedIn: 'root'
})
export class InvestigacionService {
  private _MapRoute: MapRoute = null;
  private url: string = "";

  constructor(private http: HttpClient) { 
    this._MapRoute = new MapRoute(http);
    this.url = this._MapRoute.tibaURL + "Lineainvestigacion";
  }

  RegistrarInvestigacion(parametro: InvestigacionBE, callback) {
    this._MapRoute.post(
      `${this.url}/InsertarLineaInvestigacion`,
      parametro,
      callback
    );
  }
  
  ActualizarInvestigacion(parametro: InvestigacionBE, callback) {
    this._MapRoute.post(
      `${this.url}/ActualizarLineaInvestigacion`,
      parametro,
      callback
    );
  }

  Listar(parametro, callback) {
    this._MapRoute.post(
      `${this.url}/ListarLineaInvestigacion`,
      parametro,
      callback
    );
  }
}
