<div class="row justify-content-between">

    <div class="col-md-6">
        <!-- Title section -->
        <h3>Gestión de usuarios</h3>
        <i class="material-icons" id="btnAgregar" (click)="ngClick_btnAgregar($event)">add_circle</i>
        <!-- End Title section -->
    </div>

</div>

<div class="card">
    <!-- Filters -->
    <h6>Filtros</h6>
    <div class="row justify-content-between m-0">
        <div class="col-md-10">
            <div class="row">
          
                <div class="col-md-2">
                    <div class="form-group" data-for="cboCampus_filtro">
                        <label for="cboCampus_filtro">Campus</label>
                        <select id="cboCampus_filtro" class="form-control" class="form-control"></select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="cboCargo_filtro">Cargo</label>
                        <select class="form-control" id="cboCargo_filtro">
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="txtDocente_filtro">Docente</label>
                        <input class="form-control" id="txtDocente_filtro">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="cboCarrera_filtro">Carrera</label>
                        <select class="form-control" id="cboCarrera_filtro" data-html="todos">
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <button class="btn btn-buscar" type="button" id="btnBuscarSolicitud" (click)="ngClick_btnBuscar()">Buscar<i
                    class="material-icons">search</i></button>
        </div>
    </div>
    <!-- End Filters -->
    <!-- Table -->
    <div class="mx-3">
        <table class="table" id="tblGestionarUsuario">
            <thead>
                <tr>

                    <th scope="col">Codigo</th>
                    <th scope="col">Nombre apellidos</th>
                    <th scope="col">Campus</th>
                    <th scope="col">Cargo</th>
                    <th scope="col">Carrera</th>

                    <th scope="col">Fecha registro</th>
                    <th scope="col">Estado</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
    <!-- End Table -->
</div>


<div class="modal fade" id="modalAgregarUsuario" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Usuario
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <form name="frmUsuario" class="card form-signin p-4" onkeydown="return event.key != 'Enter';"
                [formGroup]="_FORMFICHA" novalidate id="_FORMFICHA">
                <div class="row">

                    <div class="col-md-12 mb-2">
                        <b>{{mensajeInformativo}}</b>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="cboCampus">Campus</label>
                            <select class="form-control" data-html="seleccione" id="cboCampus"
                                formControlName="cboCampus" #cboCampus (change)="ngChange_campus($event)"></select>
                            <div *ngIf="_FORMFICHA.controls['cboCampus'].invalid && (_FORMFICHA.controls['cboCampus'].dirty || _FORMFICHA.controls['cboCampus'].touched )"
                                class="alert alert-danger">
                                <div *ngIf=" _FORMFICHA.controls['cboCampus'].errors.required">Seleccione un campus
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="txtCodigo">Codigo</label>
                            <input class="form-control" formControlName="txtCodigo" #txtCodigo id="txtCodigo"
                                [readonly]="esEditar" (change)="ngChange_txtCodigo($event)"
                                (keydown.enter)="ngkeydown_buscarAdministrativo($event)" />
                            <div *ngIf="_FORMFICHA.controls['txtCodigo'].invalid && (_FORMFICHA.controls['txtCodigo'].dirty || _FORMFICHA.controls['txtCodigo'].touched )"
                                class="alert alert-danger">
                                <div *ngIf=" _FORMFICHA.controls['txtCodigo'].errors.required">Ingrese un codigo</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="txtNombreApellido">Nombre y apellidos</label>
                            <input class="form-control" formControlName="txtNombreApellido" #txtNombreApellido readonly
                                id="txtNombreApellido" />
                            <div *ngIf="esErrorBusqueda==1 && _FORMFICHA.controls['txtNombreApellido'].invalid && (_FORMFICHA.controls['txtNombreApellido'].dirty || _FORMFICHA.controls['txtNombreApellido'].touched )"
                                class="alert alert-danger">
                                <div *ngIf=" _FORMFICHA.controls['txtNombreApellido'].errors.required">No se ha
                                    realizado ninguna busqueda</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="cboCargo">Cargo</label>
                            <select class="form-control" formControlName="cboCargo" #cboCargo id="cboCargo"
                                (change)="ngChange_cargo($event)"></select>
                            <div *ngIf="_FORMFICHA.controls['cboCargo'].invalid && (_FORMFICHA.controls['cboCargo'].dirty || _FORMFICHA.controls['cboCargo'].touched )"
                                class="alert alert-danger">
                                <div *ngIf=" _FORMFICHA.controls['cboCargo'].errors.required">Seleccione un cargo
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-4">
                        <div class="form-group">
                            <label for="cboRol">Rol</label>
                            <select class="form-control" formControlName="cboRol" #cboRol id="cboRol"></select>
                            <div *ngIf="_FORMFICHA.controls['cboRol'].invalid && (_FORMFICHA.controls['cboRol'].dirty || _FORMFICHA.controls['cboRol'].touched )"
                                class="alert alert-danger">
                                <div *ngIf=" _FORMFICHA.controls['cboRol'].errors.required">Seleccione un rol
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="cboCarrera">Carrera</label>
                            <select class="form-control" data-html="seleccione" formControlName="cboCarrera" #cboCarrera
                                id="cboCarrera"></select>
                            <div *ngIf="_FORMFICHA.controls['cboCarrera'].invalid && (_FORMFICHA.controls['cboCarrera'].dirty || _FORMFICHA.controls['cboCarrera'].touched )"
                                class="alert alert-danger">
                                <div *ngIf=" _FORMFICHA.controls['cboCarrera'].errors.required">Seleccione una carrera
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="txtEstado">Estado</label>

                            <input class="form-control" type="text" disabled id="txtEstado" #txtEstado
                                formControlName="txtEstado" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary btn-save col-md-4"
                        (click)="ngClick_btnConfirmar($event)">Confirmar<i class="material-icons">save</i></button>

                </div>
            </form>

        </div>
    </div>
</div>


<div class="modal fade" id="modalRevisor" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Asociar revisor a coordinador academico
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="card form-signin p-4">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="revisorCboCampus">Campus</label>
                            <select class="form-control" data-html="seleccione" id="revisorCboCampus" disabled></select>

                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="txtRevisor">Nombre del docente</label>
                            <input class="form-control" #txtRevisor
                                (keydown.enter)="ngkeydown_buscarPersonaPorAsociar($event)" />
                        </div>
                    </div>


                </div>
                <div class="row">
                    <div class="col-md-12 height-400" >
                        <table class="table" id="tblRevisores" >
                            <thead>
                                <tr>

                                    <th scope="col"></th>
                                    <th scope="col">Codigo</th>
                                    <th scope="col">Docente</th>
                                    <th scope="col">Cargo</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary btn-save col-md-4"
                        (click)="ngClick_btnGuardarRevisor($event)">Confirmar<i class="material-icons">save</i></button>

                </div>

            </div>


        </div>
    </div>
</div>