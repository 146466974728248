<div class="modal fade" id="myModal" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{solicitarFichas}} - <span>Al coordinador académico</span>
        </h5>
        <button type="button" class="close" (click)="Limpiar()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form name="form" class="card form-signin p-3" (ngSubmit)="f.form.valid && onEnviarDatos()" #f="ngForm"
        novalidate>

        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="frmAgregar_campus">Campus</label>
              <select id="frmAgregar_campus" name="frmAgregar_campus" [(ngModel)]="model.idCampus" #idCampus="ngModel">
              </select>
              <div *ngIf="
              idCampus.invalid &&
              (idCampus.dirty || idCampus.touched || f.submitted) " class="alert alert-danger">
                <div *ngIf="idCampus.errors.required">Campus es requerido</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="frmAgregar_periodo">Periodo</label>
              <select id="frmAgregar_periodo" class="form-control" name="frmAgregar_periodo"
                [(ngModel)]="model.idPeriodo" #idPeriodo="ngModel" required>
              </select>
              <div *ngIf="
              idPeriodo.invalid &&
              (idPeriodo.dirty || idPeriodo.touched || f.submitted)
            " class="alert alert-danger">
                <div *ngIf="idPeriodo.errors.required">Periodo es requerido</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="frmAgregar_fechalimite">Fecha Limite</label>
              <div class="input-group">
                <input class="form-control dateUtp" type="text" placeholder="Seleccione" id="frmAgregar_fechalimite"
                  name="frmAgregar_fechalimite" [minDate]="{
                    year: this.fechaInicial.year, 
                    month: this.fechaInicial.month,
                    day: this.fechaInicial.day }" 
                    [(ngModel)]="model.fechaLimite" #fechaLimite="ngModel" ngbDatepicker #d="ngbDatepicker" readonly
                  required />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar-utp" (click)="d.toggle()" type="button"><i
                      class="material-icons">event</i></button>
                </div>
              </div>
              <div *ngIf="
              fechaLimite.invalid &&
              (fechaLimite.dirty || fechaLimite.touched || f.submitted)
            " class="alert alert-danger">
                <div *ngIf="fechaLimite.errors.required">
                  Fecha Limite es requerido
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="frmAgregar_cantidad">Cantidad</label>
              <input type="text" class="form-control numero" maxlength="6" placeholder="Cantidad"
                id="frmAgregar_cantidad" name="frmAgregar_cantidad" [(ngModel)]="model.cantidad" #cantidad="ngModel"
                required />
              <div *ngIf="
              cantidad.invalid &&
              (cantidad.dirty || cantidad.touched || f.submitted)
            " class="alert alert-danger">
                <div *ngIf="cantidad.errors.required">Cantidad es requerido</div>
                <div *ngIf="cantidad.errors.number">Cantidad es númerico</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="frmAgregar_carrera">Carrera</label>
              <select id="frmAgregar_carrera" class="form-control" name="frmAgregar_carrera"
                [(ngModel)]="model.idCarrera" #idCarrera="ngModel" required>
              </select>
              <div *ngIf="
              idCarrera.invalid &&
              (idCarrera.dirty || idCarrera.touched || f.submitted)
            " class="alert alert-danger">
                <div *ngIf="idCarrera.errors.required">Carrera es requerido</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="frmAgregar_cordinadorAcademico">Coordinador Academico</label>
              <select id="frmAgregar_cordinadorAcademico" class="form-control" name="frmAgregar_cordinadorAcademico"
                [(ngModel)]="model.idPersonaCoordinadorAcademico" #idPersonaCoordinadorAcademico="ngModel" required>
              </select>
              <div *ngIf="
              idPersonaCoordinadorAcademico.invalid &&
              (idPersonaCoordinadorAcademico.dirty ||
                idPersonaCoordinadorAcademico.touched ||
                f.submitted)
            " class="alert alert-danger">
                <div *ngIf="idPersonaCoordinadorAcademico.errors.required">
                  Carrera es requerido
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="reset" class="btn btn-primary" id="frmAgregar_resetear" style="display:none"
            (click)="f.reset()">resetear</button>
          <button type="submit" class="btn btn-save col-md-4">Guardar<i class="material-icons">save</i></button>
          <!-- this button is hidden, used to close from typescript -->
          <!--<button type="button" id="close-modal" class="btn btn-danger" data-dismiss="modal">
            Close
          </button>-->
        </div>
      </form>
    </div>
  </div>
</div>