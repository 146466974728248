import { Component, OnInit ,ViewChild,ElementRef} from '@angular/core';
import { ComboboxService } from "../_servicioComponentes/combobox.service";
import { Session } from "../../global/session";
import { AyudaListaBE } from '../model/AyudaListaModel';
import { utilitarios } from 'src/global/utilitarios';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { InvestigacionService } from "../_servicio/investigacion.service";

import Swal from "sweetalert2";
import { enumEstado, enumCargo } from 'src/global/enum';
import { ENGINE_METHOD_CIPHERS } from 'constants';
import { JsonpClientBackend } from '@angular/common/http';
import { InvestigacionBE } from '../model/InvestigacionModel';

@Component({
  selector: 'app-mantenimientolineainvestigacion',
  templateUrl: './mantenimientolineainvestigacion.component.html',
  styleUrls: ['./mantenimientolineainvestigacion.component.scss']
})
export class MantenimientolineainvestigacionComponent implements OnInit {

  @ViewChild("txtRevisor") txtRevisor: ElementRef;

  _idPersonaArray = [];
  _Session: Session = new Session();
  _utilitarios: utilitarios = new utilitarios();
  _idPersona: number = 0;

  esErrorBusqueda: number = 0;
  esEditar: boolean = false;
  mensajeInformativo: string = "";
  public _FORMFICHA: FormGroup;

  public _BUILDER: any = {
    //cboCampus: ["", Validators.required],
    txtIdLineainvestigacion: [0,null],
    txtLineainvestigacion: ["", Validators.required],
    //txtNombreApellido: ["", Validators.required],
    //cboCargo: ["", Validators.required],
    // cboRol: ["", Validators.required],
    cboCarrera: ["", Validators.required],
    cboEstado: [1,Validators.required],

  };

  constructor(formBuilder: FormBuilder,
    private _InvestigacionService: InvestigacionService,
   private _clscombobox: ComboboxService) { 
    this._FORMFICHA = formBuilder.group(this._BUILDER);
   }

  ngOnInit(): void {

    this.ngChange_campusFiltro();
    this.dibujarGrilla();
  }

  ngClick_btnAgregar(event) {
    this.prLimpiar();
    this.onChangeCarreraSolicitud();
    this._utilitarios.OpenModal("modalAgregarLineaInvestigacion");
  }

  ngClick_btnBuscar() {

    this.dibujarGrilla();
  }
  ngClick_btnConfirmar(event){
    this._FORMFICHA.controls["cboCarrera"].markAsTouched({ onlySelf: true });
    this._FORMFICHA.controls["cboEstado"].markAsTouched({ onlySelf: true });
    this._FORMFICHA.controls["txtLineainvestigacion"].markAsTouched({ onlySelf: true });

    if (this._FORMFICHA.valid){
      let model: InvestigacionBE = {} as InvestigacionBE;
      model.idLineaInvestigacion = this._FORMFICHA.controls["txtIdLineainvestigacion"].value;
      model.nombreCompetencia = this._FORMFICHA.controls["txtLineainvestigacion"].value;
      model.idCarrera = this._FORMFICHA.controls["cboCarrera"].value;
      model.idEstado = this._FORMFICHA.controls["cboEstado"].value;

      if(model.idLineaInvestigacion == null)
      {
       
        this._InvestigacionService.RegistrarInvestigacion(model, (data) => {
          this._utilitarios.CerrarModal("modalAgregarLineaInvestigacion");
          //$("#txtDocente_filtro").val(this._nombres + ' ' + this._apellidos)
          this.dibujarGrilla();})
      }else
      {

        this._InvestigacionService.ActualizarInvestigacion(model, (data) => {
          //console.log(data);
          let mensaje ="";
          if(data == -1)
          {
             mensaje = "La línea de investigación no se pudo actualizar. Las líneas de investigación que ya han sido utilizadas no pueden ser modificadas.";
          }
          else if (data == 0)
          {
             mensaje = "Ocurrio un problema al Actualizar, consulta al Adminstrador";
          }

          if (mensaje.length > 0)
          {
            Swal.fire("Error", mensaje);
          }
            


          this._utilitarios.CerrarModal("modalAgregarLineaInvestigacion");
          //$("#txtDocente_filtro").val(this._nombres + ' ' + this._apellidos)
          this.dibujarGrilla();})
      }
    }
    
  }

  ngChange_campusFiltro() {

    this._clscombobox.init(
      {
        html: "Todos",
        id: "cboCarrera_filtro",
        entidad: "mCarreraPorPersonaCampus",

      },
      data => { }
    );

    this._clscombobox.init(
      {
        html: "Todos",
        value:2,
        id: "cboEstado_filtro",
        entidad: "SP_UTP_ListarEstadoMantenimiento",

      },
      data => { }
    );
    this._clscombobox.init(
      {
       
        id: "cboEstado",
        entidad: "SP_UTP_ListarEstadoMantenimiento"
        
      },
      data => { }
    );
  }
  
  
  onChangeCarreraSolicitud() {
    this._clscombobox.init(
      {
        id: "cboCarrera",
        entidad: "mCarreraPorPersonaCampus"
        
      },
      data => { }
    );
    this._clscombobox.init(
      {
       
        id: "cboEstado",
        entidad: "SP_UTP_ListarEstadoMantenimiento",
        valorDefault: 1
        
      },
      data => { }
    );
  }

  prLimpiar() {
    this._clscombobox.init(
      {
        id: "cboCarrera",       
       
      },
      response => {                 
        $("#cboCarrera").removeAttr("disabled");                  
      }
    );

    this._clscombobox.init(
      {
        id: "cboEstado",       
       
      },
      response => {                 
        $("#cboEstado").attr("disabled", "disabled");                
      }
    );

    this.esEditar = false;
    this.esErrorBusqueda = 0;
    this._idPersona = 0;
    this.mensajeInformativo = "";
    this._FORMFICHA.reset();
    this._FORMFICHA.controls["txtLineainvestigacion"].setValue("");
    this._FORMFICHA.controls["cboCarrera"].setValue("");
    this._FORMFICHA.controls["cboEstado"].setValue(1);
  }

  dibujarGrilla() {
    var that = this;
    this._InvestigacionService.Listar(
      {
        idCarrera: $("#cboCarrera_filtro").val() == "" ? 0 : $("#cboCarrera_filtro").val(),
        idestado: $("#cboEstado_filtro").val() == "" ? 2 : $("#cboEstado_filtro").val(),
        nombreCompetencia: $("#txtInvestigacion_filtro").val() == "" ? "" : $("#txtInvestigacion_filtro").val(),
        
      },
      data => {
        var table = $("#tblGestionarInvestigacion");
        this._utilitarios.Datatable(table, {

          columns: [

           
            { data: "nombreCarrera" },
            { data: "descripcionLineaInvestigacion" },
            //{ data: "estado", class: "small_col" },
            {
              data: "estado",
              class: "small_col text-center",
              render: function (index, display, rowdata) {
                
                return (
                  '<span class="ui-badge-' +
                  rowdata.estado +
                  '">' +
                  rowdata.estado +
                  "</span>"
                );
              }
            },
            {
              data: null,
              class: "text-center small_col",
              render: (index, display, rowdata) => {


                var html = '';
                html += '<div class="ui-icon-edit" data-add><a href="javascript:void(0)"  class="edit"><i class="material-icons">edit</i></a></div>';
                //html += '<div class="ui-icon-update" data-update><a href="javascript:void(0)" class="update"><i class="material-icons">update</i></a></div>';

                return html;
              }
            },
            
          /*{
            data: null,
            class: "text-center small_col",
            render: (index, display, rowdata) => {


              var html = '';
              html += '<div class="ui-icon-edit" data-add><a href="javascript:void(0)"  class="add"><i class="material-icons">add_circle</i></a></div>';

              if (rowdata.codigoEstadoCampusCarrera == this._utilitarios.enumEstado.Inactivo) {

                html += '<div class="ui-icon-update" data-update><a href="javascript:void(0)" class="update"><i class="material-icons">update</i></a></div>';
              }
              else if (rowdata.codigoEstadoCampusCarrera == this._utilitarios.enumEstado.Activo) {
                if (rowdata.codigoCargo == this._utilitarios.enumCargo.CoordinadorAcademico) {
                  html += '<div class="ui-icon-update" data-asociar><a href="javascript:void(0)" class="attach_file"><i class="material-icons">attach_file</i></a></div>';
                }
                html += '<div class="ui-icon-delete" data-delete><a href="javascript:void(0)" class="delete"><i class="material-icons">delete</i></a></div>';
              }


              return html;
            }
          },*/
            { data: "id", visible: false },
            { data: "idLineaInvestigacion", visible: false },
            { data: "idcarrera", visible: false },
            { data: "idestado", visible: false },
          ],
          data: data,

          
          fnDrawCallback: () => {

            $("#tblGestionarInvestigacion").find('tr td [data-add]').click((event) => {

              this.mensajeInformativo = "Editar Linea Investigacion";
              this.esEditar = true;

              var row = $(event.currentTarget).parents('tr');
              let data = $("#tblGestionarInvestigacion").DataTable().row(row).data() as any;


              this.onChangeCarreraSolicitud();
             
              this._utilitarios.OpenModal("modalAgregarLineaInvestigacion");           

              this._clscombobox.init(
                {
                  id: "cboEstado",
                  entidad: "SP_UTP_ListarEstadoMantenimiento"
                },
                data => {


                }
              ).then(() => {
                $("#cboEstado").removeAttr("disabled");  
                this._FORMFICHA.controls["cboCarrera"].setValue(data.idcarrera);
                this._FORMFICHA.controls["txtIdLineainvestigacion"].setValue(data.idLineaInvestigacion);
                this._FORMFICHA.controls["txtLineainvestigacion"].setValue(data.descripcionLineaInvestigacion);
                this._FORMFICHA.controls["cboEstado"].setValue(data.idestado);
              });


              this._clscombobox.init(
                {
                  id: "cboCarrera", 
                  entidad: "mCarreraPorPersonaCampus",                
                  valorDefault: data.idcarrera
                },
                response => {                 
                  $("#cboCarrera").attr("disabled", "disabled");                  
                }
              );
             


            });
      
            /*$(document).off(
              "click",
              "div.ui-icon-edit"
            );
            $(document).on("click", "div.ui-icon-edit", (element) => {
              this._utilitarios.OpenModal("modalAgregarLineaInvestigacion");
              })*/
            }
        
        });
      }
    );
  }

}
