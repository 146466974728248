import { ANALYZE_FOR_ENTRY_COMPONENTS, Component, OnInit } from '@angular/core';
import { ReporteService } from '../_servicio/reporte.service';
import { ComboboxService } from '../_servicioComponentes/combobox.service';
import { FormBuilder, FormGroup } from '@angular/forms';

import { utilitarios } from 'src/global/utilitarios';
import { ReporteTrabajosInvestigacionTallerFiltroBE } from '../model/reporte/ReporteTrabajosInvestigacionTallerFiltroBE';
import { ReporteTrabajosInvestigacionTallerBE } from '../model/reporte/ReporteTrabajosInvestigacionTallerBE';
import { Session } from 'src/global/session';
import { ArchivoBaseBE } from '../model/reporte/ArchivoBaseBE';
import { ReporteDocumentosOficialesCargadosFiltroBE } from '../model/reporte/ReporteDocumentosOficialesCargadosFiltroBE';
import { DataTablesModule } from 'angular-datatables';
import { element } from 'protractor';
import { BancoService } from '../_servicio/banco.service';
import { JsonPipe } from '@angular/common';
import Swal from 'sweetalert2';
import { BancoArchivoBinarioBE, BancoArchivoTipoDocumentoBE } from '../model/BancoModel';
import { ResumenFichaService } from '../_servicio/resumenFicha.service';
import { FichaService } from '../_servicio/ficha.service';
import { hasClassName } from '@ng-bootstrap/ng-bootstrap/util/util';

@Component({
  selector: 'app-reportedocumentosoficiales',
  templateUrl: './reportedocumentosoficiales.component.html',
  styleUrls: ['./reportedocumentosoficiales.component.scss']
})
export class ReportedocumentosoficialesComponent implements OnInit {

  public _BancoArchivoBinarioBE: BancoArchivoBinarioBE[] = [];

  public _formTipoDocumentoLoad: boolean = true;
  public _tipoDocumento: string = ""
  public _tipoDocumentoArchivo: string = "";
  public _tipoDocumentoArchivoBase64: string = "";
  public _idFicha: number = 0;
  public _idbanco:number= 0;
  public _titulotrabajoinvestigacion: string = "";
  public _nombreperiodo: string = "";

  _utilitarios: utilitarios = new utilitarios();
  _dropdownSettings: any = {};
  _dropdownSettingColumna: any = {};
  _dropdownSettingSinBuscar: any = {};

  _dropdownListPeriodo = [];
  _selectedItemsPeriodo = [];



  _dropdownListFacultad = [];
  _selectedItemsFacultad = [];

  _dropdownListCarrera = [];
  _selectedItemsCarrera = [];



  _dropdownListSeccion = [];
  _selectedItemsSeccion = [];





  _FORMFICHA: FormGroup;

  public _BUILDER: any = {
    _selectedItemsPeriodo: ["", ""],
    _selectedItemsSeccion: ["", ""],
    _selectedItemsFacultad: ["", ""],
    _selectedItemsCarrera: ["", ""],
    _txtCodigoAlumno: ["", ""],
    _txtAlumno: ["", ""],
    _txtrabajoInvestigacion: ["", ""],
    _txtcodigoFicha: ["", ""]


  };
  constructor(
    private _ReporteService: ReporteService,
    private _BancoService: BancoService,
    private _clscombobox: ComboboxService,
    private _FichaService: FichaService,
    private formBuilder: FormBuilder,
    private _Session: Session) {
    this._FORMFICHA = formBuilder.group(this._BUILDER);

  }

  ngOnInit(): void {
    this._BancoArchivoBinarioBE = [];

    this.onClearSelectAll();
    $('[class="container-fluid pb-5"]').removeAttr('style')

    Promise.all([this.comboPeriodo(),

    this.comboFacultad(),
    this.comboCarrera(),
    this.comboSeccion(),
    this.comboTipoDocumentos()
    ]).then(() => {
      this._dropdownSettings = {
        singleSelection: false,
        text: "Todos",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Deseleccionar todos",
        enableSearchFilter: true,
        badgeShowLimit: 14,
        classes: "multipleRepositorio custom-class ",
        searchPlaceholderText: "Buscar",

      };
      this._dropdownSettingColumna = {
        singleSelection: false,
        text: "Seleccione",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Deseleccionar todos",
        enableSearchFilter: false,
        classes: "multipleRepositorio custom-class ",

      };
      this._dropdownSettingSinBuscar = {
        singleSelection: false,
        text: "Seleccione",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Deseleccionar todos",
        enableSearchFilter: false,
        classes: "multipleRepositorio custom-class ",

      };
      // this.dibujarGrilla();

    })

  }
  ngClick_BuscarDatos(event) {

    if ((this._FORMFICHA.controls["_selectedItemsPeriodo"].value != null
      && this._FORMFICHA.controls["_selectedItemsPeriodo"].value.length > 0)) {
      this.dibujarGrilla();
    } else {
      Swal.fire({
        html:
          'Seleccione un periodo.',
        timer: 2500,
        showConfirmButton: false
      });
    }

  }
  ngClick_DescargarArchivo(event) {

    this._ReporteService.DescargarDocumentosOficialesCargados(this.parametrosFiltros(), (data: ArchivoBaseBE) => {
      this._utilitarios.descargarArchivo(data.archivoBase64, data.nombreArchivo, 'omitir')
    })
  }

  onClearSelectAll() {
    setInterval((item) => {
      $(".multipleRepositorio span.clear-all").each((index, element) => {
        var parent = $(element).parents(".multipleRepositorio");
        if ($(element).find(".repositorio").length == 0) {
          parent
            .find("span.clear-all")
            .html('<span class="repositorio">x</span>');
          $(element)
            .find(".repositorio")
            .click((event) => {
              this._FORMFICHA.controls[parent.attr("formcontrolname")].setValue(
                []
              );

            });
        }
      });
    }, 1);
  }


  ngClick_cerrarmodalTipoDocumento(event) {
    $('[data-for="modalTipoDocumento"]').removeClass('background-modal');
    this._idFicha = 0;
    this.dibujarGrilla();
    this._utilitarios.CerrarModal("modalTipoDocumento");

  }

  ngClick_cerrartitulotrabajoinvestigacion(event){
    $('[data-for="modalcambiartitulotrabajoinvestigacion"]').removeClass('background-modal');
    this._idFicha = 0;
    this.dibujarGrilla();
    this._utilitarios.CerrarModal("modalcambiartitulotrabajoinvestigacion");

  }
  reset_formTipoDocumentoLoad() {

    this._formTipoDocumentoLoad = false;
  }

  onChangeArchivoTipoDocumento(event) {

    var file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      event.target.value = "";
      let archivoBase64 = reader.result.toString().split(",")[1];
      let nombreArchivo = file.name;
      this._tipoDocumentoArchivo = nombreArchivo;
      this._tipoDocumentoArchivoBase64 = archivoBase64;
    };
  }

  ngClick_GuardarAdjuntarTipoDocumento(event) {
    this._formTipoDocumentoLoad = false;
    if (this._tipoDocumentoArchivo != ""
      && this._tipoDocumentoArchivoBase64 != ""
      && this._tipoDocumento != "") {
      this._BancoService.AdjuntarDocumentoFicha(
        {
          archivoBase64: this._tipoDocumentoArchivoBase64,
          idFicha: this._idFicha,
          nombreArchivo: this._tipoDocumentoArchivo,
          idDocumentoProceso: 2,
          idTipoDocumento: this._tipoDocumento,
          idPersonaRegistra: this._Session.usuario.persona.idPersona,
        },
        (data) => {

          this.dibujarGrilla();
          $('[data-for="modalTipoDocumento"]').removeClass('background-modal');
          this._utilitarios.CerrarModal("modalTipoDocumento");

        }
      );

    }

  }
  ngClick_GuardarNombreDocumento(event)
  {
    
    const titulodigitado = document.getElementById("txtNombreTitulo") as HTMLInputElement | null;
    //console.log(titulodigitado.value);

    if(  this._idbanco != null && this._idbanco > 0)
    {
      if(titulodigitado.value != "" && titulodigitado.value != null)
      {
        console.log(titulodigitado.value);
        console.log("x fin registra");
        console.log(this._nombreperiodo);
        console.log(this._Session.usuario.persona.idPersona,);
        this._BancoService.ActualizarTitulo(
          {
            idbanco: this._idbanco,
            alias: titulodigitado.value,
            nombreApellidos: this._nombreperiodo,
            idPersonaRegistra: this._Session.usuario.persona.idPersona,
          },
          (data) => {

            this.dibujarGrilla();
            $('[data-for="modalcambiartitulotrabajoinvestigacion"]').removeClass('background-modal');
            this._utilitarios.CerrarModal("modalcambiartitulotrabajoinvestigacion");
  
          }
        )

      }
      else
      {
        let mensaje ="";
        mensaje = "Tiene que tener un Titulo de Trabajo de Investigacion";
        Swal.fire("Error", mensaje);
      }
    }
    else
    {
      let mensaje ="";
      mensaje = "No cuenta con IDBANCO, para la actualizacion";
      Swal.fire("Error", mensaje);
    }


  }
  ngChangeAdjuntarTipoDocumento(event) {
    $("#fldSubirArchivo").trigger("click");
  }

  ngClick_DescargarDocumento(parametro: BancoArchivoBinarioBE) {
    this._BancoService.ObtenerBancoFichaDocumento(
      parametro,
      (data: BancoArchivoBinarioBE) => {
        this._utilitarios.descargarArchivo(
          data.archivoBase64,
          data.nombreArchivo,
          "omitir"
        );
      }
    );
  }
  ngClick_EliminarDocumento(parametro: BancoArchivoBinarioBE, index, esDocumentoFinal: boolean = false) {
    Swal.fire({
      //title
      //text
      html:
        "Al confirmar esta acción, se estará eliminando el documento.</br><b>¿Está seguro en continuar?</b>",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#43b12b",
      confirmButtonText: "Si",
      cancelButtonColor: "red",
      cancelButtonText: "No",
    }).then(
      (confirm) => {
        if (confirm.value) {
          this._BancoService.EliminarDocumentoBancoFicha(parametro, (data) => {
            if (esDocumentoFinal) {
              this._BancoArchivoBinarioBE.splice(index, 1)
            } else {
              this._BancoArchivoBinarioBE.splice(index, 1);
            }
          });
        }
      },
      function () {
        return false;
      }
    );
  }
  //===============================================================================================
  //-- Privado
  //===============================================================================================
  parametrosFiltros() {

    let parametro = {} as ReporteDocumentosOficialesCargadosFiltroBE;

    parametro.idPeriodoArray = "";
    parametro.idSeccionArray = "";
    parametro.idFacultadArray = "";
    parametro.idCarreraArray = "";
    parametro.codigoAlumno = this._FORMFICHA.controls["_txtCodigoAlumno"].value;
    parametro.nombreAlumno = this._FORMFICHA.controls["_txtAlumno"].value;
    parametro.trabajoInvestigacion = this._FORMFICHA.controls["_txtrabajoInvestigacion"].value;
    parametro.codigoFicha = this._FORMFICHA.controls["_txtcodigoFicha"].value;
    if ((this._FORMFICHA.controls["_selectedItemsPeriodo"].value != null
      && this._FORMFICHA.controls["_selectedItemsPeriodo"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsPeriodo"].value.forEach(element => {
        parametro.idPeriodoArray += element.id + ','
      });
    }

    if ((this._FORMFICHA.controls["_selectedItemsSeccion"].value != null
      && this._FORMFICHA.controls["_selectedItemsSeccion"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsSeccion"].value.forEach(element => {
        parametro.idSeccionArray += element.id + ','
      });
    }


    if ((this._FORMFICHA.controls["_selectedItemsFacultad"].value != null
      && this._FORMFICHA.controls["_selectedItemsFacultad"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsFacultad"].value.forEach(element => {
        parametro.idFacultadArray += element.id + ','
      });
    }

    if ((this._FORMFICHA.controls["_selectedItemsCarrera"].value != null
      && this._FORMFICHA.controls["_selectedItemsCarrera"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsCarrera"].value.forEach(element => {
        parametro.idCarreraArray += element.id + ','
      });
    }




    return parametro;
  }
  comboPeriodo() {
    return this._clscombobox.getJson(
      {
        entidad: "mPeriodo"
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListPeriodo.push({
            id: parseInt(item.id),
            itemName: item.nombre,
          });

          //this._selectedItemsPeriodo = this._dropdownListPeriodo;
        });
      }
    );
  }

  comboFacultad() {
    return this._clscombobox.getJson(
      {
        entidad: "ReporteFacultad"
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListFacultad.push({
            id: parseInt(item.id),
            itemName: item.nombre,
          });


        });
      }
    );
  }
  comboCarrera() {

    let entidad = this._Session.rolMarcado == this._utilitarios.enumRoles.coordinadorAcademico ? 'mCarreraPorPersona' : 'mCarreraPorPersonaCampus';
    let parametro = this._Session.rolMarcado == this._utilitarios.enumRoles.coordinadorAcademico ? { "@p_idPersona": this._Session.usuario.persona.idPersona } : null;

    return this._clscombobox.getJson(
      {
        entidad,
        parametro
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListCarrera.push({
            id: parseInt(item.id),
            itemName: item.nombre,
          });
          // this._selectedItemsCarrera = this._dropdownListCarrera;
        });
      }
    );

  }


  comboSeccion() {

    return this._clscombobox.getJson(
      {
        entidad: "ReporteSeccion"
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListSeccion.push({
            id: item.id,
            itemName: item.nombre,
          });
          // this._selectedItemsCampus = this._dropdownListCampus;
        });

      }
    );
  }

  comboTipoDocumentos() {
    return this._clscombobox.init(
      {
        id: "cboTipoDocumento",
        entidad: "tipoDocumentoListar",
        html: "seleccionar",
      },
      () => {


      }
    );
  }

  tituloColumns(idColumna: string) {
    let resultado = idColumna;

    switch (idColumna) {
      case "idBanco":
        resultado = null;
        break;
      case "archivos":
        resultado = null;
        break;
    }

    return resultado;
  }
  dibujarGrilla() {
    var that = this;
var promise=new Promise((resolve,reject)=>{
  this._ReporteService.ListarDocumentosOficialesCargados(
    this.parametrosFiltros(),
    (data) => {

      if (data.length == 0) {
        $("#tblReporte").DataTable().clear().draw();
        return null;

      }
      let keysJson = Object.keys(data[0]);
      let tblContenedor = $("#tblReporte");
      let header = [];
      let tr = $('<tr></tr>')

     

      keysJson.forEach((element, index) => {
        let title = this.tituloColumns(element);
        let render = null;
        if (index >= 10) {
          render = (valorColumna, display, rowdata, meta) => {
            //console.log(valorColumna)
            if (valorColumna != null) {
              return "<span class='circulo-verde' data-col='" + meta.col + "'  data-index='" + meta.row + "' ></span>"
            } else {
              return "<span class='circulo-rojo' ></span>"
            }

          }
        }
 
        const className=(index==8)?'':(index >= 9 ? 'text-center': this.hasClassName(index))
        header.push({ data: element, class:className , "title": title, visible: title??false, render: render })
      });

      header.push({
        data: null,
        class: "",
        "title": 'Subir Documento',
        visible: true,
        render: (valorColumna, display, rowdata, meta) => {
          return '<div class="ui-icon-update adjuntar-documento" data-asociar><a href="javascript:void(0)" title="adjuntar documento" class="attach_file"><i class="material-icons">attach_file</i></a></div>'
        }
        
        
      })

      // AÑADIDO 2022-08-26
      header.push({
        data: null,
        class: "",
        "title": 'Cambiar Nombre Trabajo Investigacion',
        visible: true,
        render: (valorColumna, display, rowdata, meta) => {
          return '<div class="ui-icon-edit actualizar-nombretrabajo" data-asociar><a href="javascript:void(0)" title="titulo documento" class="edit"><i class="material-icons">edit</i></a></div>'
        }
        
        
      })

      // tblContenedor.DataTable({
      //   columns: header,
      //   data: data

      // });
      this._utilitarios.Datatable(tblContenedor, {
        responsive: false,
        columns: header,
        data: data,
        bAutoWidth:false,

        fnDrawCallback: () => {
      
          $(document).off(
            "click",
            "span.circulo-verde"
          );
          $(document).on("click", "span.circulo-verde", (element) => {
            let target = element.target;
            let rowdata = $('#tblReporte').DataTable().row($(target).attr('data-index')).data() as any;

            let column = header[$(target).attr('data-col')].data
            let idBancoArchivoBinario = 0;
            console.log(column)
            let keyValor = column;

            var html = $('<span></span>');
            html.html(`${rowdata.archivos.replace(/(\r\n|\n|\r)/gm, " ")}`)

            var archivos = html.html();
            var array = JSON.parse(archivos);

            array.map((item) => {
              if (item.key.trim().toLocaleLowerCase() == keyValor.trim().toLocaleLowerCase()) {
                idBancoArchivoBinario = item.value
              }

            })
            this._BancoService.ObtenerBancoFichaDocumento({
              idBancoArchivoBinario: idBancoArchivoBinario
            }, (data) => {
              this._utilitarios.descargarArchivo(
                data.archivoBase64,
                data.nombreArchivo,
                null
              );

            })
          })

          $(document).off(
            "click",
            "div.adjuntar-documento"
          );
          $(document).on("click", "div.adjuntar-documento", (element) => {
            let target = element.target;
            let rowdata = $('#tblReporte').DataTable().row($(target).parents('tr')).data() as any;
            this._idFicha = rowdata["codigo Ficha"];
            this._FichaService.ListarDocumentosBanco({
              idBanco: rowdata.idBanco,
              idDocumentoProceso: 2
            }, (data) => {
              this._BancoArchivoBinarioBE = data;

              this._formTipoDocumentoLoad = true;
              this._tipoDocumento = "";
              this._tipoDocumentoArchivo = "";
              this._tipoDocumentoArchivoBase64 = "";
              this._utilitarios.OpenModal('modalTipoDocumento');
              
            })
          })

          $(document).off(
            "click",
            "div.actualizar-nombretrabajo"
          );
          $(document).on("click","div.actualizar-nombretrabajo",(element)=>
          {
            let target = element.target;
            let rowdata = $('#tblReporte').DataTable().row($(target).parents('tr')).data() as any;
            console.log(rowdata);
            this._idbanco = rowdata["idBanco"];
            this._titulotrabajoinvestigacion = rowdata["trabajo de Investigación"];
            this._nombreperiodo = rowdata["ciclo Academico"];
            console.log(this._idbanco);
            console.log(this._titulotrabajoinvestigacion);
            console.log(this._nombreperiodo);
            $("#txtidbanco").val(this._idbanco);
            $("#txtNombreTitulo").val(this._titulotrabajoinvestigacion);
            this._utilitarios.OpenModal('modalcambiartitulotrabajoinvestigacion');
          })

          resolve('');
        }
      })

   

    }
  );
})
  
promise.then(()=>{

 
})
  }

private hasClassName(index):string{

  
    if( index==1 || index==0 ){
      return '  text-center'; 
    }
    if(   index==7){
      return 'small_col text-left';
    }
    

    if(index==2  || index==3 || index==4 || index==5|| index==6){
      return '  text-left';
    }         
 
    return '';
  
}
}

