<div class="row justify-content-between">
    <div class="col-md-6">
        <!-- Title section -->
        <h3>Carga de proyección</h3>
        <i class="material-icons" id="myModalAgregar" (click)="onClickSubirArchivo($event)">add_circle</i>

        <input type="file" id="fileArchivo" name="fileArchivo" style="display:none"
            (change)="onChangeArchivo($event)" />
        <!-- End Title section -->
    </div>
    <div class="col-md-6 text-right">
        <button type="submit" class="btn btn-history col-md-5" id="btnHistorialCarga" data-toggle="modal" data-target="#myModal">Historial de
            carga<i class="material-icons">assignment</i></button>
    </div>
</div>
<!-- Content section -->
<div class="card">
    <!-- Filters -->
    <h6>Filtros</h6>
    <div class="row justify-content-between m-0">
        <div class="col-md-10">
            <div class="row">
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="idPeriodo">Periodo</label>
                        <input type="text" id="idPeriodo" class="form-control" disabled>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group" data-for="cboCampus">
                        <label for="cboCampus">Campus</label>
                        <select id="cboCampus" class="form-control" #cboCampus (change)="onChangeCampus($event)">
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group" data-for="cboCarrera">
                        <label for="cboCarrera">Carrera</label>
                        <select id="cboCarrera" class="form-control" data-html="Todos">
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <button class="btn btn-buscar" type="submit" (click)="onClickBuscar($event)">
                Buscar<i class="material-icons">search</i>
            </button>
        </div>
    </div>
    <!-- End Filters -->
    <!-- Table -->
    <div class="mx-3">
        <table class="table" id="tblListarProyeccion">
            <thead>
                <tr>
                    <th scope="col">Campus</th>
                    <th scope="col">Carrera</th>
                    <th scope="col">Fecha Limite</th>
                    <th scope="col">Proyectados</th>
                    <th scope="col">Factor</th>
                    <th scope="col">F. a solicitar</th>
                    <th scope="col">F. ajustadas(20%)</th>
                    <th scope="col">F. banco</th>
                    <th scope="col">F. Total</th>
                    <th scope="col">Estado</th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
    <!-- End Table -->
    <div id="opcionesInvestigacion" style="display: none" class="text-center mt-3">
        <button type="submit" class="btn btn-save col-md-2" (click)="onClickEnviar($event)">Enviar<i
                class="material-icons">send</i></button>
    </div>

    <div id="opcionesAcademico" style="display: none" class="text-center">
        <button type="submit" class="btn btn-confirmar col-md-2 mx-3" (click)="onClickAprobar($event)">Confirmar<i
                class="material-icons">done</i></button>

        <button type="submit" class="btn btn-rechazar col-md-2 mx-3" (click)="onClickRechazar($event)">Rechazar<i
                class="material-icons">close</i></button>
    </div>
    <!-- Button enviar -->
    <!-- End Button enviar -->
</div>
<!-- End Content section -->


<!-- Modal Previsualización -->
<div class="modal fade" id="modalHistoricoProyeccionDetalle" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Previsualización de carga de proyección
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form name="form" class="card p-3">
                <div class="mx-3">
                    <table class="table" id="tblHistoricoProyeccionDetalle">
                        <thead>
                            <tr>
                                <th scope="col">Campus</th>
                                <th scope="col">Carrera</th>
                                <th scope="col">Alumnos proyectados</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- Modal Historial carga -->
<div class="modal fade" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Historial de carga de proyección
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form name="form" class="card p-3">
                <div class="mx-3">
                    <table class="table" id="tblHistoricoProyeccion">
                        <thead>
                            <tr>
                                <th scope="col">Nombre del archivo</th>
                                <th scope="col">Fecha de subida</th>
                                <th scope="col">Encargado</th>
                                <th scope="col">Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- Modal Ajuste factor -->
<div class="modal fade" id="myModal3" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Ajuste factor - <span id="nombreCarrera">{{nombreCarrera}}</span>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClickCerrarModal($event)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form name="form" class="card p-3">
                <div class="mx-3 text-center">
                    <label class="lblFactor">Factor</label>
                    <div class="btn-group btn-group-toggle col-md-6" data-toggle="buttons">
                        <label class="btn btn-switch active">
                            <input type="radio" name="idFactor" id="idAlum1" autocomplete="off" value="1" checked> 1
                        </label>
                        <label class="btn btn-switch">
                            <input type="radio" name="idFactor" id="idAlum2" autocomplete="off" value="2"> 2
                        </label>
                    </div>
                    <div class="form-group mt-1 mb-0" data-for="chkFactor">
                        <input type="checkbox" id="chkFactor">
                        <label for="chkFactor"> Aplicar a todos los campus</label>
                    </div>

                    <div class="mt-2" (click)="onClickActualizarFactor($event)">
                        <button type="reset" class="btn btn-primary" id="frmActualizarFicha_resetear"
                            style="display:none">resetear</button>
                        <button type="button" class="btn btn-save col-md-4">Actualizar<i
                                class="material-icons" (click)="onClickActualizarFactor($event)">cached</i></button>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>
