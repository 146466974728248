import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ComboboxService } from "../_servicioComponentes/combobox.service";
import { Session } from "../../global/session";
import { AyudaListaBE } from '../model/AyudaListaModel';
import { utilitarios } from 'src/global/utilitarios';
import { PersonaService } from "../_servicio/persona.service";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { PersonaBE, PersonaCampusCarreraBE } from '../model/PersonaModel';

import Swal from "sweetalert2";
import { enumEstado, enumCargo } from 'src/global/enum';
import { ENGINE_METHOD_CIPHERS } from 'constants';
import { JsonpClientBackend } from '@angular/common/http';
@Component({
  selector: 'app-gestionarusuario',
  templateUrl: './gestionarusuario.component.html',
  styleUrls: ['./gestionarusuario.component.scss']
})
export class GestionarusuarioComponent implements OnInit {

  @ViewChild("txtRevisor") txtRevisor: ElementRef;
  //
  _idPersonaArray = [];
  _nombres: string = "";
  _apellidos: string = "";
  _Session: Session = new Session();
  _campus: AyudaListaBE[] = [];
  _utilitarios: utilitarios = new utilitarios();
  _idPersona: number = 0;
  _idCampusFacultadCarreraRevisor: number = 0;
  _idCampusRevisor: number = 0;
  _idPersonaRevisor: number = 0;
  _idCarreraRevisor: number = 0;
  //  
  esErrorBusqueda: number = 0;
  esEditar: boolean = false;
  mensajeInformativo: string = "";
  public _FORMFICHA: FormGroup;

  public _BUILDER: any = {
    cboCampus: ["", Validators.required],
    txtCodigo: ["", Validators.required],
    txtNombreApellido: ["", Validators.required],
    cboCargo: ["", Validators.required],
    // cboRol: ["", Validators.required],
    cboCarrera: ["", Validators.required],
    txtEstado: ["", ""],

  };
  constructor(formBuilder: FormBuilder,
    private _PersonaService: PersonaService,
    private _clscombobox: ComboboxService) {
    this._FORMFICHA = formBuilder.group(this._BUILDER);
  }

  ngOnInit(): void {

    this.prInicializarDatos();
    this.dibujarGrilla();
    this.ngChange_campusFiltro();
  }

  ngClick_btnAgregar(event) {
    this.prLimpiar();
    this._utilitarios.OpenModal("modalAgregarUsuario");
  }

  ngChange_campusFiltro() {

    this._clscombobox.init(
      {
        html: "Todos",
        id: "cboCarrera_filtro",
        entidad: "mCarreraPorPersonaCampus",

      },
      data => { }
    );
  }
  ngChange_cargo(event) {

    let codigoCargo = $('#cboCargo').val();

    this._FORMFICHA.controls["cboCarrera"].disable({ onlySelf: true });
    switch (codigoCargo) {

      case this._utilitarios.enumCargo.CoordinadorInvestigacion:
        this._FORMFICHA.controls["cboCarrera"].setValue("");
        this._FORMFICHA.controls["cboCarrera"].clearValidators();
        this._FORMFICHA.controls["cboCarrera"].setErrors(null);
        this._FORMFICHA.controls["cboCarrera"].reset();
        break;
      case this._utilitarios.enumCargo.CoordinadorAcademico:

        this._FORMFICHA.controls["cboCarrera"].setValidators([
          Validators.required,
        ]);
        this._FORMFICHA.controls["cboCarrera"].setValue("");
        this._FORMFICHA.controls["cboCarrera"].enable({ onlySelf: false });
        break;
      case this._utilitarios.enumCargo.Docente:
        this._FORMFICHA.controls["cboCarrera"].setValue("");
        this._FORMFICHA.controls["cboCarrera"].clearValidators();
        this._FORMFICHA.controls["cboCarrera"].setErrors(null);
        this._FORMFICHA.controls["cboCarrera"].reset();

        break;
    }

  }

  ngChange_campus(event) {

    this._clscombobox.init(
      {
        id: "cboCarrera",
        entidad: "mCarreraPorPersonaCampus",
        parametro: {
          "@p_idPersona": 0,
          "@p_idCampus": $('#cboCampus').val(),
          "@p_idFacultad": 0
        }
      },
      data => { }
    );
  }
  ngChange_txtCodigo(event) {
    if (this._nombres != "") {

      this._nombres = "";
      this._apellidos = "";
      this._FORMFICHA.controls["txtNombreApellido"].setValue("");
      this.esErrorBusqueda = 1;
    }

  }

  ngkeydown_buscarPersonaPorAsociar(event) {
    this.dibujarGrillaRevisor(this._idPersonaRevisor, this._idCampusFacultadCarreraRevisor, this._idCampusRevisor, this._idCarreraRevisor);
  }

  ngkeydown_buscarAdministrativo(event) {

    if (event.currentTarget) {

      let parametro: PersonaBE = {} as PersonaBE;
      parametro.codigo = this._FORMFICHA.controls["txtCodigo"].value;
      this._PersonaService.ObtenerPersonPorCodigoPeopleSoft(parametro, (data) => {
        if (data.nombres == "") {
          this._nombres = "";
          this._apellidos = "";
          this.esErrorBusqueda = 1;
          this._FORMFICHA.controls["txtNombreApellido"].setValue("");
        } else {
          this._nombres = data.nombres;
          this._apellidos = data.apellidos;
          this._FORMFICHA.controls["txtNombreApellido"].setValue(data.nombres + " " + data.apellidos);

        }

      })
    }

  }
  ngClick_btnBuscar() {

    this.dibujarGrilla();
  }
  ngClick_btnConfirmar(event) {


    this._FORMFICHA.controls["txtCodigo"].markAsTouched({ onlySelf: true });
    // this._FORMFICHA.controls["cboRol"].markAsTouched({ onlySelf: true });
    this._FORMFICHA.controls["cboCampus"].markAsTouched({ onlySelf: true });
    this._FORMFICHA.controls["cboCarrera"].markAsTouched({ onlySelf: true });
    this._FORMFICHA.controls["cboCargo"].markAsTouched({ onlySelf: true });

    if (this._nombres == "") {

      this.esErrorBusqueda = 1;
      this._FORMFICHA.controls["txtNombreApellido"].markAsTouched({ onlySelf: true });
    }
    if (this._FORMFICHA.valid) {


      let model: PersonaBE = {} as PersonaBE;
      model.idPersona = this._idPersona;
      model.Nombres = this._nombres;
      model.Apellidos = this._apellidos;
      model.codigo = this._FORMFICHA.controls["txtCodigo"].value;
      // model.roles = [this._FORMFICHA.controls["cboRol"].value];
      model.personaCampusCarreras = [] as Array<PersonaCampusCarreraBE>;
      let itemCampusCarrera: PersonaCampusCarreraBE = {} as PersonaCampusCarreraBE;
      itemCampusCarrera.idCampus = this._FORMFICHA.controls["cboCampus"].value;
      itemCampusCarrera.idCarrera = this._FORMFICHA.controls["cboCarrera"].value;
      itemCampusCarrera.codigoCargo = this._FORMFICHA.controls["cboCargo"].value;
      model.personaCampusCarreras.push(itemCampusCarrera)

      this._PersonaService.RegistrarPersona(model, (data) => {
        this._utilitarios.CerrarModal("modalAgregarUsuario");
        $("#txtDocente_filtro").val(this._nombres + ' ' + this._apellidos)
        this.dibujarGrilla();

      })

    }


  }
  ngClick_btnGuardarRevisor(event) {
    let idPersonaArray = new Array();
    idPersonaArray = this._idPersonaArray;
    /*
    $("#tblRevisores").DataTable().rows().every(function (rowIdx, tableLoop, rowLoop) {
      var data = this.data() as any;
      var row = $("#tblRevisores").DataTable().row(rowIdx).node();
      var input = $(row).find('input.rdbRevisor');
      if (input.prop('checked')) {
        idPersonaArray.push(data.idPersona);
      }
      // ... do something with data(), or this.node(), etc
    });

*/

    if (idPersonaArray.length > 0) {

      let arrayCadena = idPersonaArray.join(',');
      this._PersonaService.RegistrarActualizarRevisor({
        idPersonaArray: arrayCadena,
        idCampusFacultadCarrera: this._idCampusFacultadCarreraRevisor
      }, () => {



      })
    } else {

      Swal.fire({
        html: 'No ha seleccionar un docente para asociarlo como revisor.',
        timer: 1500,
        showConfirmButton: false
      });

    }


  }
  //===============================================================================================
  //-- Privado
  //===============================================================================================

  dibujarGrilla() {
    var that = this;
    this._PersonaService.Listar(
      {
        idCampus: $("#cboCampus_filtro").val() == "" ? 0 : $("#cboCampus_filtro").val(),
        idRol: 0,
        codigoCargo: $("#cboCargo_filtro").val() == "" ? "" : $("#cboCargo_filtro").val(),
        nombreApellidos: $("#txtDocente_filtro").val() == "" ? "" : $("#txtDocente_filtro").val(),
        idCarrera: $("#cboCarrera_filtro").val() == "" ? 0 : $("#cboCarrera_filtro").val(),
      },
      data => {
        var table = $("#tblGestionarUsuario");
        this._utilitarios.Datatable(table, {

          columns: [

            { data: "codigo", class: "small_col text-center" },
            { data: "nombreApellidos" },
            { data: "campus" },
            { data: "nombreCargo", class: "small_col" },
            { data: "nombreCarrera", class: "small_col" },

            { data: "fechaCreacion", class: "fecha" },
            {
              data: "nombreEstadoCampusCarrera",
              class: "small_col text-center",
              render: function (index, display, rowdata) {
                return (
                  '<span class="ui-badge-' +
                  rowdata.nombreEstadoCampusCarrera +
                  '">' +
                  rowdata.nombreEstadoCampusCarrera +
                  "</span>"
                );
              }
            },
            {
              data: null,
              class: "text-center small_col",
              render: (index, display, rowdata) => {


                var html = '';
                html += '<div class="ui-icon-edit" data-add><a href="javascript:void(0)"  class="add"><i class="material-icons">add_circle</i></a></div>';

                if (rowdata.codigoEstadoCampusCarrera == this._utilitarios.enumEstado.Inactivo) {

                  html += '<div class="ui-icon-update" data-update><a href="javascript:void(0)" class="update"><i class="material-icons">update</i></a></div>';
                }
                else if (rowdata.codigoEstadoCampusCarrera == this._utilitarios.enumEstado.Activo) {
                  if (rowdata.codigoCargo == this._utilitarios.enumCargo.CoordinadorAcademico) {
                    html += '<div class="ui-icon-update" data-asociar><a href="javascript:void(0)" class="attach_file"><i class="material-icons">attach_file</i></a></div>';
                  }
                  html += '<div class="ui-icon-delete" data-delete><a href="javascript:void(0)" class="delete"><i class="material-icons">delete</i></a></div>';
                }


                return html;
              }
            },
            { data: "idPersona", visible: false },
            { data: "codigo", visible: false },
            { data: "codigoCargo", visible: false },
            { data: "idCampus", visible: false },
            { data: "idRol", visible: false },
            { data: "idCarrera", visible: false },
            { data: "idCampusFacultadCarrera", visible: false },
          ],
          data: data,
          fnDrawCallback: () => {
            $("#tblGestionarUsuario").find('tr td [data-asociar]').click((event) => {
              var row = $(event.currentTarget).parents('tr');
              this._idPersonaArray = [];
              let data = $("#tblGestionarUsuario").DataTable().row(row).data() as any;
              $('#revisorCboCampus').val(data.idCampus)
              this.txtRevisor.nativeElement.value = "";
              this._utilitarios.OpenModal("modalRevisor");
              this._idPersonaRevisor = data.idPersona;
              this._idCampusFacultadCarreraRevisor = data.idCampusFacultadCarrera;
              this._idCampusRevisor = data.idCampus;
              this._idCarreraRevisor = data.idCarrera;
              this.dibujarGrillaRevisor(data.idPersona, data.idCampusFacultadCarrera, data.idCampus, data.idCarrera);
            })

            $("#tblGestionarUsuario").find('tr td [data-add]').click((event) => {

              var row = $(event.currentTarget).parents('tr');

              let data = $("#tblGestionarUsuario").DataTable().row(row).data() as any;
              this.prLimpiar();
              this._utilitarios.OpenModal("modalAgregarUsuario");
              let parametro: PersonaBE = {} as PersonaBE;
              parametro.idPersona = data.idPersona;

              this._clscombobox.init(
                {
                  html: "Seleccione",
                  id: "cboCargo",
                  entidad: "mCargoSinRevisor",
                  parametro: { "@p_idPersona": data.idPersona }
                },
                data => {


                }
              ).then(() => {
                this._PersonaService.ObtenerPorId(parametro, (data) => {
                  this.esEditar = true;
                  this._nombres = data.nombres;
                  this._apellidos = data.apellidos;
                  this._idPersona = data.idPersona;
                  this.mensajeInformativo = "Ingrese los datos para agregar un nuevo registro";
                  this._FORMFICHA.controls["txtCodigo"].setValue(data.codigo);
                  this._FORMFICHA.controls["txtNombreApellido"].setValue(data.nombres + " " + data.apellidos);
                });

              });

            })

            $("#tblGestionarUsuario").find('tr td [data-update]').click((event) => {
              Swal.fire({
                title: "¿Está seguro en activar el registro?",
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Si",
                cancelButtonText: "No"
              }).then(result => {
                if (result.value) {

                  var row = $(event.currentTarget).parents('tr');
                  var data = $("#tblGestionarUsuario").DataTable().row(row).data() as any;
                  let parametro: PersonaCampusCarreraBE = {} as PersonaCampusCarreraBE;
                  parametro.idPersona = data.idPersona;
                  parametro.idCampus = data.idCampus;
                  parametro.idCarrera = data.idCarrera;
                  parametro.codigoCargo = data.codigoCargo;
                  this._PersonaService.ActivarCampusFacultadCarrera(parametro, () => {
                    $("#txtDocente_filtro").val(data.nombreApellidos);
                    this.dibujarGrilla();
                  });

                }
              });
            })

            $("#tblGestionarUsuario").find('tr td [data-delete]').click((event) => {
              Swal.fire({
                title: "¿Está seguro en desactivar el registro?",
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Si",
                cancelButtonText: "No"
              }).then(result => {
                if (result.value) {

                  var row = $(event.currentTarget).parents('tr');
                  var data = $("#tblGestionarUsuario").DataTable().row(row).data() as any;
                  let parametro: PersonaCampusCarreraBE = {} as PersonaCampusCarreraBE;
                  parametro.idPersona = data.idPersona;
                  parametro.idCampus = data.idCampus;
                  parametro.idCarrera = data.idCarrera;
                  parametro.codigoCargo = data.codigoCargo;
                  this._PersonaService.DesasociarCampusFacultadCarrera(parametro, () => {

                    $("#txtDocente_filtro").val(data.nombreApellidos);

                    this.dibujarGrilla();
                  });

                }
              });

            })
          }
        });
      }
    );
  }

  dibujarGrillaRevisor(idPersona, idCampusFacultadCarrera, idCampus, idCarrera) {
    var that = this;
    this._idCampusFacultadCarreraRevisor = idCampusFacultadCarrera;
    this._PersonaService.ListarRevisores(
      {
        idPersona: idPersona,
        idCampus: idCampus,
        idCarrera: idCarrera,
        nombreApellidos: this.txtRevisor.nativeElement.value
      },
      data => {
        data.map((item, index) => {
          if (item.esRevisor == 1) {
 
            let indexFind = this._idPersonaArray.findIndex(x => x == item.idPersona);
 
            if (indexFind < 0) {
              this._idPersonaArray.push(item.idPersona);
             
            }
          }
        })

        var table = $("#tblRevisores");
        this._utilitarios.Datatable(table, {
          scrollY: "300px",
          columns: [
            {
              data: "",
              render: (index, display, rowdata) => {
         
                let indexArray = this._idPersonaArray.findIndex(x => x == rowdata.idPersona);
                rowdata.esRevisor = indexArray >= 0 ? 1 : rowdata.esRevisor;
                var html = '<input class="rdbRevisor" type="checkbox" name="revisor" ' + (rowdata.esRevisor == 0 ? "" : "checked") + '>';
                return html;
              }
            },
            { data: "codigo", class: "small_col text-center" },
            { data: "nombreApellidos" },
            { data: "nombreCargo", class: "small_col" },
            { data: null, visible: false },
          ],
          data: data,
          fnDrawCallback: () => {


            $('#tblRevisores').find('tbody > tr > td input.rdbRevisor').change((event) => {
              var rowTr = $(event.currentTarget).parents('tr');
              var rowData = $('#tblRevisores').DataTable().row(rowTr).data() as any;
              let index = this._idPersonaArray.findIndex(x => x == rowData.idPersona);

              if (!$(event.currentTarget).prop('checked')) {
                
                if (index >= 0) {
                  this._idPersonaArray.splice(index, 1);
            
                }
              } else { 
                if (index < 0) {
 
                  this._idPersonaArray.push(rowData.idPersona);
                }
              }
            })
          }
        });
      }
    );
  }
  prInicializarDatos() {

    this._FORMFICHA.controls["txtEstado"].setValue("Activo");

    this._clscombobox.init(
      {
        html: "Todos",
        id: "cboCampus_filtro",
        entidad: "mCampusActivo"
        //entidad: "mCampusPorPersona",
        //parametro: { "@p_idPersona": this._Session.usuario.persona.idPersona }
      },
      data => { }
    );
    this._clscombobox.init(
      {
        id: "cboCampus",
        entidad: "mCampusActivo"
        //entidad: "mCampusPorPersona",
        //parametro: { "@p_idPersona": this._Session.usuario.persona.idPersona }
      },
      data => { }
    );
    this._clscombobox.init(
      {
        id: "revisorCboCampus",
        entidad: "mCampusPorPersona",
        parametro: { "@p_idPersona": this._Session.usuario.persona.idPersona }
      },
      data => { }
    );
    this._clscombobox.init(
      {
        html: "Todos",
        id: "cboCargo_filtro",
        entidad: "mCargo"
      },
      data => { }
    );
    this._clscombobox.init(
      {
        html: "Seleccione",
        id: "cboCargo",
        entidad: "mCargoSinRevisor",
        parametro: { "@p_idPersona": this._Session.usuario.persona.idPersona }
      },
      data => {


      }
    )
    // this._clscombobox.init(
    //   {
    //     html: "Seleccione",
    //     id: "cboRol",
    //     entidad: "mRol_ListarRolesSinRevisor"
    //   },
    //   data => { }
    // );



  }

  prLimpiar() {
    this.esEditar = false;
    this.esErrorBusqueda = 0;
    this._idPersona = 0;
    this.mensajeInformativo = "";
    this._FORMFICHA.reset();
    this._FORMFICHA.controls["txtCodigo"].setValue("");
    // this._FORMFICHA.controls["cboRol"].setValue("");
    this._FORMFICHA.controls["cboCampus"].setValue("");
    this._FORMFICHA.controls["cboCarrera"].setValue("");
    this._FORMFICHA.controls["cboCargo"].setValue("");
    this._FORMFICHA.controls["txtNombreApellido"].setValue("");
    this._FORMFICHA.controls["txtEstado"].setValue("Activo");
  }
}
