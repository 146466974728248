import { Component, OnInit } from "@angular/core";
//---------------------------------------------------------------------
//-- Clases Locales
//---------------------------------------------------------------------
import { ProyeccionService } from "../_servicio/proyeccion.service";
import { ResumenFichaService } from "../_servicio/resumenFicha.service";
import { ComboboxService } from "../_servicioComponentes/combobox.service";
import { Session } from "../../global/session";
import { utilitarios } from "../../global/utilitarios";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import * as $ from "jquery";
import { SolicitudFichaService } from "../_servicio/solicitudFicha.service";
@Component({
  selector: "app-fichasasignadas",
  templateUrl: "./fichasasignadas.component.html",
  styleUrls: ["./fichasasignadas.component.scss"]
})
export class FichasasignadasComponent implements OnInit {
  constructor(
    private combobox: ComboboxService,
    private _Session: Session,
    private _ProyeccionService: ProyeccionService,
    private _SolicitudFichaService: SolicitudFichaService,
    private _utilitarios: utilitarios
  ) {}
  ngOnInit() {
    this.combobox.init(
      {
        html: "Todos",
        id: "cboDocentes",
        entidad: "mDocentesFichasPorCoordAcademico",
        parametro: {
          "@p_idPeriodo": 0,
          "@p_idPersona": this._Session.usuario.persona.idPersona
        }
      },
      data => {}
    );

    $.when(
      this.dom.periodoVigente(),
      this.dom.estados(),
      this.dom.ListarFichasAsignadasPorDocente()
    ).then(function(data, textStatus, jqXHR) {});
  }

  onChangePeriodo(event) {
    /*
     this.combobox.init(
      {
        html: "Todos",
        id: "cboDocentes",
        entidad: "mDocentesFichasPorCoordAcademico",
        parametro: {
          "@p_idPeriodo": $(event.target).val(),
          "@p_idPersona": this._Session.usuario.persona.idPersona
        }
      },
      data => {}
    );
    */
   
  }

  onClickBuscar() {
    this.dom.ListarFichasAsignadasPorDocente();
  }
  onClickBuscarFichaTitulo() {
    this.dom.ListarFichasDocentesPorTitulo();
  }
  onClickHistorialFichas() {
    $("#idTitulo").val("");
    if ($.fn.dataTable.isDataTable("#tblBusquedaFichaInv")) {
      $("#tblBusquedaFichaInv")
        .DataTable()
        .clear()
        .draw();
    }
    this._utilitarios.OpenModal("modalBusquedaTitulo");
  }
  //------------------------------------------------------------------------------------
  //-- Metodos privados
  //------------------------------------------------------------------------------------
  dom: any = {
    periodoVigente: x => {
      return this.combobox.init(
        {
          html: "Todos",
          id: "cboPeriodo",
          entidad: "mPeriodo"
        },
        data => {}
      );
    },
    estados: x => {
      return this.combobox.init(
        {
          html: "Todos",
          id: "cboEstado_fichasAsignadas",
          entidad: "EstadoFichasAsignadas"
        },
        data => {}
      );
    },
    ListarFichasAsignadasPorDocente: cl => {
      var _that = this;
      return this._SolicitudFichaService.ListarFichasAsignadasPorDocente(
        {
          idPersona: this._Session.usuario.persona.idPersona,
          idPeriodo: $("#cboPeriodo").val() == "" ? 0 : $("#cboPeriodo").val(),
          idPersonaSolicitada:
            $("#cboDocentes").val() == "" ? 0 : $("#cboDocentes").val(),
          idEstado: $("#cboEstado_fichasAsignadas").val() == "" ? -1 : $("#cboEstado_fichasAsignadas").val()
        },
        data => {
          let exampleId: any = $("#tblListarFichasAsignadas");
          this._utilitarios.Datatable(exampleId, {
            columns: [
              { data: "tituloFicha"},
              { data: "periodo", class: " small_col" },
              { data: "campus" },
              { data: "nombreDocenteRegistra" },
              { data: "fechaRegistro", class: "fecha small_col" },
              {
                data: "nombreEstado",
                class: "small_col",
                render: function(index, display, rowdata) {
                  return (
                    '<span class="ui-badge-' +
                    rowdata.nombreEstado +
                    '">' +
                    rowdata.nombreEstado +
                    "</span>"
                  );
                }
              },
              {
                data: null,
                class: "text-center small_col",
                render: function(index, display, rowdata) {
                  var html = ``;
                  if (
                    rowdata.codigoEstado ==
                      _that._utilitarios.enumEstado.Aprobadas ||
                    rowdata.codigoEstado ==
                      _that._utilitarios.enumEstado.Observado ||
                      rowdata.codigoEstado ==
                      _that._utilitarios.enumEstado.Inactivo ||
                      rowdata.codigoEstado ==
                      _that._utilitarios.enumEstado.Anulado 
                      
                  ) {
                    html = `<div class="ui-icon-visibility" data-view="">
                      <i class="material-icons" >
                      <a href="javascript:void(0)"  >visibility</a>
                      </i>
                    </div>`;
                  } else if (
                    rowdata.codigoEstado ==
                    _that._utilitarios.enumEstado.Enviado
                  ) {
                    html = `<div class="ui-icon-edit" data-toggle="modal" data-edit="">
                  <i class="material-icons">
                    <a href="javascript:void(0)" >edit</a>
                  </i>
                </div>`;
                  }

                  return html;
                }
              },
              { data: "codigoEstado", visible: false },
              { data: "idSolicitudFicha", visible: false },
              { data: "idFicha", visible: false },
              { data: "idCampusFacultadCarrera", visible: false }
            ],
            data: data,
            fnDrawCallback: fndraw => {}
          });
        }
      );
    },
    ListarFichasDocentesPorTitulo: cl => {
      var _that = this;
      return this._SolicitudFichaService.ListarFichasDocentesPorTitulo(
        {
          titulo: $("#idTitulo").val()
        },
        data => {
          let exampleId: any = $("#tblBusquedaFichaInv");
          this._utilitarios.Datatable(exampleId, {
            columns: [
              { data: "periodo", class: " small_col" },
              { data: "tituloFicha"},
              { data: "nombreDocenteRegistra" },
              {
                data: "nombreEstado",
                class: "small_col",
                render: function(index, display, rowdata) {
                  return (
                    '<span class="ui-badge-' +
                    rowdata.nombreEstado +
                    '">' +
                    rowdata.nombreEstado +
                    "</span>"
                  );
                }
              },
              {
                data: null,
                class: "text-center small_col",
                render: function(index, display, rowdata) {
                  var html = ``;
                  html = `<div class="ui-icon-visibility" data-view="">
                      <i class="material-icons" >
                      <a href="javascript:void(0)"  >visibility</a>
                      </i>
                    </div>`;
                  return html;
                }
              },
              { data: "codigoEstado", visible: false },
              { data: "idSolicitudFicha", visible: false },
              { data: "idFicha", visible: false },
              { data: "idCampusFacultadCarrera", visible: false }
            ],
            data: data,
            fnDrawCallback: fndraw => {}
          });
        }
      );
    }
  };
}
