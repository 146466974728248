import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ReactiveFormsModule,
} from "@angular/forms";
import { ClassField } from "@angular/compiler";
import { ComboboxService } from "../_servicioComponentes/combobox.service";
import { utilitarios } from "../../global/utilitarios";
import { SeccionService } from "../_servicio/seccion.service";
import { Session } from "../../global/session";
import { seccionesAsignadasModel } from "../model/seccionesAsignadasModel";

import { enumCargo } from "src/global/enum";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
declare let $global: any;
//#region ajax
class clsAJAX {
  constructor(
    private _clscombobox: ComboboxService,
    private _SeccionService: SeccionService
  ) { }
  getJSON = () => {
    var promise = new Promise((response, reject) => {
      this._clscombobox.getJson(
        {
          entidad: "mPeriodoAperturado",
        },
        (data) => {
          response(data);
        }
      );
    });

    return promise;
  };

  ListarMovimientoFichas = (parametro) => {
    var promise = new Promise((response, reject) => {
      this._SeccionService.ListarMovimientoFichas(
        {
          idPeriodo: parametro.idPeriodo,
          idSeccion: parametro.idSeccion,
          idPersonaDocente: parametro.idPersonaDocente,
          idCampus: parametro.idCampus,
          idCurso: parametro.idCurso,
          codigoRol: parametro.codigoRol
        },
        (data) => {
          response(data);
        }
      );
    });
    return promise;
  };
}
//#endregion ajax

//#region  metodos
class clsMetodos {
  _MODEL: seccionesAsignadasModel = new seccionesAsignadasModel();
  _utilitarios: utilitarios = new utilitarios();
  _Session: Session = new Session();

  private _clsAJAX: clsAJAX;
  private _ROUTER: Router;

  constructor(
    private ComboboxService: ComboboxService,
    SeccionService: SeccionService,
    RouterParam: Router
  ) {
    this._clsAJAX = new clsAJAX(ComboboxService, SeccionService);
    this._ROUTER = RouterParam;
  }

  obtenerPeriodo = () => {
    let promise = new Promise((response, reject) => {
      this._clsAJAX.getJSON().then((data) => {
        let first = data[0];
        this._MODEL._idPeriodo = first.id;
        this._MODEL._Periodo = first.nombre;
        this._MODEL.cboPeriodo.setValue(first.nombre);
        response();
      });
    });
    return promise;
  };

  dibujarGrillaSecciones = () => {
    let promise = new Promise((response, reject) => {
      this._clsAJAX
        .ListarMovimientoFichas({
          idPeriodo: this._MODEL._idPeriodo,
          idPersonaDocente:
            this._Session.CargoElegido ==
              this._utilitarios.enumCargo.CoordinadorInvestigacion
              ? 0
              : this._Session.usuario.persona.idPersona,
          idCampus:
            this._MODEL.cboCampus.value == "" ? 0 : this._MODEL.cboCampus.value,
          idCurso:
            this._MODEL.cboCurso.value == "" ? 0 : this._MODEL.cboCurso.value,
          idSeccion:
            this._MODEL.cboSeccion.value == "" ? 0 : this._MODEL.cboSeccion.value,
          codigoRol: this._Session.rolMarcado
        })
        .then((res) => {
          this._utilitarios.Datatable($("#frmFichaTblListarFicha"), {
            columns: [
              { data: "classNbr", class: "small_col" },
              {
                data: "curso",
              },
              { data: "fichasAsignadas", class: " small_col text-center" },
              { data: "fichasDisponibles", class: " small_col text-center" },
              { data: "alumnosAsignados", class: " small_col text-center" },
              { data: "alumnosSinAsignar", class: " small_col text-center" },
              { data: "estado", class: " small_col" },
              {
                data: null,
                class: "text-center small_col",
                render: function () {
                  var html = `<div class="ui-group_add data-add">
                            <i class="material-icons" >
                            <a href="javascript:void(0)"  data-add >group_add</a>
                            </i>
                          </div>`;
                  return html;
                },
              },
              { data: "observacion", visible: false },
              { data: "idSeccion", visible: false },
              { data: "idPersonaDocente", visible: false },
              { data: "tipoCurso", visible: false },
            ],
            data: res,
            fnDrawCallback: (event) => {
              let jquery: any = $('[data-toggle="tooltip"]');
              jquery.tooltip();

              //Event
              $("#frmFichaTblListarFicha")
                .find("td a[data-add]")
                .click((event) => {
                  if (event.currentTarget == event.target) {
                    let tr = $(event.target).parents("tr");
                    let rowData: any = $("#frmFichaTblListarFicha")
                      .DataTable()
                      .row(tr)
                      .data();
                    sessionStorage.setItem("_seccionId", rowData.idSeccion);
                    sessionStorage.setItem("_seccionNombre", rowData.classNbr);
                    sessionStorage.setItem("_idPersonaDocente", rowData.idPersonaDocente);
                    sessionStorage.setItem("_tipoCurso", rowData.tipoCurso);
                    this._ROUTER.navigateByUrl("/asignarFicha");
                  }
                });

              //
              response();
            },
            fnRowCallback: (nRow, aData, iDisplayIndex, iDisplayIndexFull) => {
              console.log(aData);
              if (aData.observacion != null) {
                let tooltip = `
              <span data-html="true" class="text-left" data-toggle="tooltip" data-placement="top" title="${aData.observacion}">
              <span class="ui-info">
                <i class="material-icons" >
                <span class=" text--orange" >info</span>
                </i>
              </span>
            </span>`;
                $(nRow).addClass("row-observado");
                $(nRow)
                  .find("td:nth-child(0n+7)")
                  .html(aData.estado + tooltip);
              }
            },
          });
        });
    });

    return promise;
  };

  dibujarComboboxInit() {
    $(".campus").hide();
    $(".curso").hide();
    $(".buscar").hide();
    let idPersona=0;
    switch (this._Session.CargoElegido) {
      case this._utilitarios.enumCargo.Docente:
        break;
      case this._utilitarios.enumCargo.CoordinadorInvestigacion:
        $(".campus").show();
        $(".curso").show();
        $(".buscar").show();
          idPersona = this._Session.usuario["persona"].idPersona;
        this.ComboboxService.init(
          {
            id: "cboCampus",
            entidad: "mCampusCoordinadorInvestigacion",
            parametro: { "@p_idPersona": idPersona },
          },
          () => { }
        );
        this.ComboboxService.init(
          {
            id: "cboCurso",
            entidad: "mCursoAperturados",
          },
          () => { }
        );
        break;
        case this._utilitarios.enumCargo.CoordinadorAcademico:
          $(".campus").show();
          $(".curso").show();
          $(".buscar").show();
            idPersona = this._Session.usuario["persona"].idPersona;
            this.ComboboxService.init(
              {
                id: "cboCampus",
                entidad: "mCampusCoordinadorAcademico",
                parametro: { "@p_idPersona": idPersona },
              },
              () => { }
            );
            this.ComboboxService.init(
              {
                id: "cboCurso",
                entidad: "mCursoAperturados",
              },
              () => { }
            );

        break;
      default:
        break;
    }
  }

  dibujarCursos() { }

  dibujarSeccion() {
  if(this._utilitarios.enumCargo.CoordinadorInvestigacion==this._Session.CargoElegido){
    this.ComboboxService.init(
      {
        id: "cboseccion",
        entidad: "Seccion",
        parametro: { "@p_idPeriodo": this._MODEL._idPeriodo },
      },
      () => { }
    );

  }else{
    this.ComboboxService.init(
      {
        id: "cboseccion",
        entidad: "SeccionCampusPersonaCargo",
        parametro: { "@p_idPeriodo": this._MODEL._idPeriodo,
                     "@p_idPersona":this._Session.usuario["persona"].idPersona,
                     "@p_codigoCargo":this._Session.CargoElegido
      },
      },
      () => { }
    );


  }
   

  }
}

//#endregion metodos

//#region DOM
@Component({
  selector: "app-seccionesasignadas",
  templateUrl: "./seccionesasignadas.component.html",
  styleUrls: ["./seccionesasignadas.component.scss"],
})
export class SeccionesasignadasComponent extends clsMetodos implements OnInit {
  public _FORMFICHA: FormGroup;
  _Session: Session = new Session();
  public _BUILDER: any = {
    periodo: ["", null],
    campus: ["", null],
    curso: ["", null],
    seccion: ["", null],
  };

  constructor(
    private formBuilder: FormBuilder,
    private combobox: ComboboxService,
    private SeccionService: SeccionService,
    private ROUTER: Router
  ) {
    super(combobox, SeccionService, ROUTER);
    let valor = this._Session.usuario.persona.idPersona;
    this._FORMFICHA = this.formBuilder.group(this._BUILDER);
    this._MODEL.cboPeriodo = this._FORMFICHA.controls["periodo"];
    this._MODEL.cboCampus = this._FORMFICHA.controls["campus"];
    this._MODEL.cboCurso = this._FORMFICHA.controls["curso"];
    this._MODEL.cboSeccion = this._FORMFICHA.controls["seccion"];

  }

  ngOnInit() {
    this.dibujarComboboxInit();
    this.obtenerPeriodo().then(() => {
      this.dibujarSeccion();
      this.dibujarGrillaSecciones();
    });
  }
  ngChangeCampus(event) {

    this.dibujarCursos();
  }

  ngClickBuscarSecciones() {
    this.dibujarGrillaSecciones();
  }



  ngChangeCurso(event) {

  }
}

//#endregion DOM
