<style>
    div.dataTables_scrollBody thead {
    display: none;
}

td{
word-break: break-all !important;
}
</style>
<div class="row justify-content-between">

    <div class="col-md-6">
        <!-- Title section -->
        <h3>Reporte documentos oficiales cargados en tiba.</h3>
        <!-- End Title section -->
    </div>

</div>

<div class="card">
    <!-- Filters -->

    <form class="card form-signin p-4 col-md-12" onkeydown="return event.key != 'Enter';" [formGroup]="_FORMFICHA"
        novalidate id="_FORMFICHA">
        <div class="row justify-content-between m-0 ">

            <div class="row col-md-12">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="cboCampus_filtro">Periodo</label>
                        <angular2-multiselect [data]="_dropdownListPeriodo" formControlName="_selectedItemsPeriodo"
                            [(ngModel)]="_selectedItemsPeriodo" [settings]="_dropdownSettingSinBuscar">
                        </angular2-multiselect>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" data-for="">
                        <label for="">Seccion</label>
                        <angular2-multiselect [data]="_dropdownListSeccion" formControlName="_selectedItemsSeccion"
                            [(ngModel)]="_selectedItemsSeccion" [settings]="_dropdownSettings">
                        </angular2-multiselect>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group" data-for="">
                        <label for="">Facultad</label>
                        <angular2-multiselect [data]="_dropdownListFacultad" formControlName="_selectedItemsFacultad"
                            [(ngModel)]="_selectedItemsFacultad" [settings]="_dropdownSettings">
                        </angular2-multiselect>
                    </div>
                </div>
            </div>
            <div class="row col-md-12">
                <div class="col-md-4">
                    <div class="form-group" data-for="cboCampus_filtro">
                        <label for="cboCampus_filtro">Carrera</label>
                        <angular2-multiselect [data]="_dropdownListCarrera" formControlName="_selectedItemsCarrera"
                            [(ngModel)]="_selectedItemsCarrera" [settings]="_dropdownSettings">
                        </angular2-multiselect>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Codigo Alumno</label>
                        <input class="form-control" value="" type="text" formControlName="_txtCodigoAlumno" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Alumno</label>
                        <input class="form-control" value="" type="text" formControlName="_txtAlumno" />
                    </div>
                </div>
            </div>
            <div class="row col-md-12">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Trabajo investigacion</label>
                        <input class="form-control" value="" type="text" formControlName="_txtrabajoInvestigacion" />
                    </div>
                </div>


                <div class="col-md-4">
                    <div class="form-group">
                        <label>Codigo ficha</label>
                        <input class="form-control" value="" type="text" formControlName="_txtcodigoFicha" />
                    </div>
                </div>

            </div>






        </div>
        <div class="row  justify-content-between m-0">
            <div class="col-md-3 ">
                <button class="btn btn-buscar" type="button" id="btnBuscarSolicitud"
                    (click)="ngClick_BuscarDatos($event)">Buscar<i class="material-icons">search</i></button>
                <button class="btn btn-buscar" type="button" id="btnBuscarSolicitud"
                    (click)="ngClick_DescargarArchivo($event)">Descargar<i
                        class="material-icons">cloud_download</i></button>

            </div>
        </div>
    </form>

    <!-- End Filters -->
    <!-- Table -->
    <div class="mx-3" id="tblContenedor" style="overflow-x: scroll;">
        <table class="table-report dataTable " id="tblReporte">
            <thead>

            </thead>
            <tbody>

            </tbody>
        </table>
    </div>
    <!-- End Table -->
</div>


<div class="modal fade" id="modalTipoDocumento" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Adjuntar Documento
                </h5>
                <button type="button" class="close" (click)="ngClick_cerrarmodalTipoDocumento($event)"
                    data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="row  offset-md-1 col-md-11 mb-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="lblCampus">Tipo documento</label>
                        <select type="text" (change)="reset_formTipoDocumentoLoad()" class="form-control"
                            id="cboTipoDocumento" [(ngModel)]="_tipoDocumento">
                        </select>
                        <div *ngIf="_tipoDocumento=='' && !_formTipoDocumentoLoad" class="alert alert-danger">
                            <div class="white-space">
                                Tipo documento es requerido</div>
                        </div>
                    </div>
                </div>



                <div class="col-md-12">
                    <div class="form-group">
                        <label for="lblCampus">Adjuntar</label>
                        <div (click)="ngChangeAdjuntarTipoDocumento($event)" class="outline">
                            <input type="text" readonly style="border:none" class="outline-none"
                                [(ngModel)]="_tipoDocumentoArchivo" />
                            <span class="float-right">
                                <a href="javascript:void(0)">
                                    <i class="material-icons">cloud_upload</i>
                                </a>
                            </span>
                        </div>
                        <div *ngIf="_tipoDocumentoArchivo=='' && !_formTipoDocumentoLoad" class="alert alert-danger">
                            <div class="white-space"> Es necesario subir un archivo</div>
                        </div>
                        <input type="file" (change)="onChangeArchivoTipoDocumento($event)" id="fldSubirArchivo"
                            style="display: none;" />
                    </div>
                </div>

                <div class="col-md-12">
                    <hr />
                </div>
                <h5>Documento Final</h5>

                <div class="col-md-12 mt-0 p-0 "
                    *ngFor="let itemDocumento of _BancoArchivoBinarioBE; let indexFicha = index">
                    <div class="col-md-12 mt-1" *ngIf="itemDocumento.nombreTipoDocumento!=null">
                        <div class="titulo">
                            <b> {{itemDocumento.nombreTipoDocumento}}
                            </b>
                        </div>
                        <div class="detalle">Documento subido por
                            <span>{{itemDocumento.nombreApellidos}}</span> el
                            dia
                            <span>{{itemDocumento.fechaEjecucion}}</span>
                        </div>
                        <div class="archivo col-md-12">
                            <a role="button" href="javascript:void(0)"
                                (click)="ngClick_DescargarDocumento(itemDocumento)">
                                {{itemDocumento.nombreArchivo}}
                            </a>
                            <span class="float-right">
                                <a role="button" href="javascript:void(0)" title="Eliminar"
                                    (click)="ngClick_EliminarDocumento(itemDocumento,indexFicha,true)">
                                    <i class="material-icons f-size-15">delete_outline</i>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 offset-md-2  p-4 text-center">
                    <button type="button" class="btn btn-save mt-1"
                        (click)="ngClick_GuardarAdjuntarTipoDocumento($event)">Guardar<i
                            class="material-icons">save</i></button>
                </div>

            </div>
        </div>
    </div>
</div>
<div data-for="modalTipoDocumento">

</div>

<div class="modal fade" id="modalcambiartitulotrabajoinvestigacion" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Actualizar Titulo de  Trabajo de Investigacion
                </h5>
                <button type="button" class="close" (click)="ngClick_cerrartitulotrabajoinvestigacion($event)"
                    data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="row  offset-md-1 col-md-11 mb-2" >

                <div class="col-md-12">
                    <div class="form-group">
                        <label for="lblCampus">Titulo de Trabajo de Investigación</label>
                        <input type="hidden" id="txtidbanco" />
                         <textarea rows="4" class="form-control" 
                         id="txtNombreTitulo">
                        </textarea>
                                
                    
                    </div>
                </div>

                
                
                <div class="col-md-6 offset-md-2  p-4 text-center">
                    <button type="button" class="btn btn-save mt-1"
                        (click)="ngClick_GuardarNombreDocumento($event)">Guardar<i
                            class="material-icons">save</i></button>
                </div>
            </div>
        </div>
    </div>
</div>