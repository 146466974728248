<div class="row justify-content-between">
    <div class="col-md-6">
        <!-- Title section -->
        <h3>Resumen fichas aprobadas</h3>

        <input type="file" id="fileArchivo" name="fileArchivo" style="display:none" />
        <!-- End Title section -->
    </div>
    <div class="col-md-6 text-right">
        <button type="submit" class="btn btn-history col-md-5" id="btnHistorialCarga"
            (click)="onClickHistorialFichas()">Historial de
            fichas<i class="material-icons">assignment</i></button>
    </div>
</div>

<!-- Content section -->
<div class="card">
    <!-- Filters -->
    <h6>Filtros</h6>
    <div class="row justify-content-between m-0">
        <div class="col-md-10">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group" data-for="cboPeriodo">
                        <label for="cboPeriodo">Periodo</label>
                        <select id="cboPeriodo" class="form-control" data-html="Todos">
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group" data-for="cboCoordinador">
                        <label for="cboCoordinador">Coordinador</label>
                        <select id="cboCoordinador" class="form-control" data-html="Todos">
                        </select>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group" data-for="cboCarrera">
                        <label for="cboCarrera">Carrera</label>
                        <select id="cboCarrera" class="form-control" data-html="Todos">
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="cboEstadoBanco">Estado Banco</label>
                        <select id="cboEstadoBanco" data-html="todos"  ></select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <button class="btn btn-buscar" type="submit" (click)="onClickBuscar()">
                Buscar<i class="material-icons">search</i>
            </button>
        </div>
    </div>
    <!-- End Filters -->
    <!-- Table -->
    <div class="mx-3">
        <table class="table" id="tblListaFichaAprobado">
            <thead>
                <tr>
                    <th scope="col">Título de la ficha</th>
                    <th scope="col">Periodo</th>
                    <th scope="col">Coordinador</th>
                    <th scope="col">Docente</th>
                    <th scope="col">Fecha de aprobación</th>
                    <th scope="col">Estado ficha</th>
                    <th scope="col">Estado banco</th>
                    <th scope="col">Opciones</th>
                </tr>
            </thead>
            <tbody>

            </tbody>
        </table>
    </div>
    <!-- End Table -->
</div>

<!-- Modal Historial carga -->
<div class="modal fade" id="modalBuscarTituloFichaAprobada" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Búsqueda de ficha de investigación por título
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form name="form" class="card p-3">
                <!-- Filters -->
                <h6>Filtros</h6>
                <div class="row justify-content-between m-0">
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="idTitulo">Título</label>
                                    <input type="text" id="idTitulo" class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-buscar" type="submit" (click)="onClickBuscarFichaTitulo($event)">
                            Buscar<i class="material-icons">search</i>
                        </button>
                    </div>
                </div>
                <div class="mx-3">
                    <table class="table" id="tblBusquedaFichaInv">
                        <thead>
                            <tr>
                                <th scope="col">Periodo</th>
                                <th scope="col">Título de la ficha</th>
                                <th scope="col">Carrera</th>
                                <th scope="col">Docente</th>
                                <th scope="col">Estado</th>
                                <th scope="col">Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </form>
        </div>
    </div>
</div>


<div class="modal fade" id="modalAsignarFichaSeccion" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Asignar Ficha a la seccion
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="card form-signin p-4">
                <form name="frmUsuario" class="card form-signin p-4" onkeydown="return event.key != 'Enter';"
                    [formGroup]="_FORMFICHA" novalidate id="_FORMFICHA">

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="tituloFicha">Ficha</label>
                                <input class="form-control " [ngModelOptions]="{standalone: true}" [(ngModel)]="tituloFicha" value="" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="asignarCboCampus">Campus</label>
                                <select class="form-control" formControlName="asignarCboCampus" data-html="seleccione"
                                    id="asignarCboCampus"></select>
                                <div *ngIf="_FORMFICHA.controls['asignarCboCampus'].invalid && (_FORMFICHA.controls['asignarCboCampus'].dirty || _FORMFICHA.controls['asignarCboCampus'].touched )"
                                    class="alert alert-danger">
                                    <div *ngIf="_FORMFICHA.controls['asignarCboCampus'].errors.required">Ingrese un
                                        campus</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="txtRevisor">Seccion</label>
                                <input class="form-control" formControlName="txtSeccion" />
                                <div *ngIf="_FORMFICHA.controls['txtSeccion'].invalid && (_FORMFICHA.controls['txtSeccion'].dirty || _FORMFICHA.controls['txtSeccion'].touched )"
                                    class="alert alert-danger">
                                    <div *ngIf="_FORMFICHA.controls['txtSeccion'].errors.required">Ingrese un campus
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-save col-md-4"
                            (click)="ngClick_btnGuardarSeccion($event)">Confirmar<i
                                class="material-icons">save</i></button>

                    </div>
                </form>


            </div>


        </div>
    </div>
</div>
<app-ficha-agregar (dibujarGrilla)="onClickBuscar()" [tipoCarga]="1"></app-ficha-agregar>