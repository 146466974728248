<div class="row justify-content-between">
    <div class="col-md-6">
        <!-- Title section -->

        <br />
        <h3>Asignación de fichas en la sección {{this._MODEL._seccion}}</h3>
        <div class="col-md-3">
            <button type="button" class="btn btn-danger" id="btnlistarretirados" (click)="ngClick_btnListarRetirados()">Alumnos Retirados</button>
        </div>

        <!-- End Title section -->
    </div>
    <!-- Section resume -->
    <div class="col-md-6 row">
        <div class="col-md-3">
            <div>
                <div><span>Fichas asignadas</span></div>
                <div><span>{{this._MODEL._fichasAsignadas}}</span></div>
            </div>
        </div>
        <div class="col-md-3">
            <div>
                <div><span>Fichas sin asignar</span></div>
                <div><span>{{this._MODEL._fichasDisponibles}}</span></div>
            </div>
        </div>
        <div class="col-md-3">
            <div>
                <div><span>Alumnos asignados</span></div>
                <div><span>{{this._MODEL._AlumnosAsignados}}</span></div>
            </div>
        </div>
        <div class="col-md-3">
            <div>
                <div><span>Alumnos sin asignar</span></div>
                <div><span>{{this._MODEL._AlumnosSinAsignar}}</span></div>
            </div>
        </div>
    </div>
    <!-- End Section resume -->
</div>



<div cdkDropListGroup class="row col-md-12 container "
    style="margin: 0px !important;padding: 0px !important;margin-top: 20px !important;">
    <div *ngFor="let itemRoot of _FichasAsociadasModel.column1" class="card panel-no-asig col-md-5">
        <div cdkDropList id="{{itemRoot.idBanco}}" [cdkDropListData]="itemRoot.alumnos"
            (cdkDropListDropped)="dropAlumnoSinAsignar($event,0,0,'')">
            <div class=" panel-no-asig-header ">
                Alumnos no asignados
            </div>
            <div class="panel-no-search">

                <label class="f-size-12">Buscar Alumno:</label>

                <input class="form-control" id="txtBuscarAlumno" (keypress)="ngNumberOnly($event)"
                    placeholder="Presione Enter para realizar la busqueda" (keyup)="ngkeyup_AlumnoSinAsignar($event)" />
            </div>
            <div class="panel-no-asig-body p-3">

                <div *ngIf="_Session.CargoElegido ==_utilitarios.enumCargo.Docente">
                    <div class="row" cdkDrag *ngFor="let item of itemRoot.alumnos" [cdkDragDisabled]="item.observacion == 'RETIRADO'"
                        (cdkDragMoved)="cdkDragMoved ($event)">
                        <div class="col-md-6 m-0 p-1 retirado">{{item.alumno }}
                            <span *ngIf="item.observacion =='RETIRADO'"   class="ui-info" data-html="true" class="text-left" data-toggle="tooltip" data-placement="top" title="RETIRADO">
                                <i class="material-icons" >
                                <span class=" text--orange" >info</span>
                                </i>
                               
                                <a role="button"  href="javascript:void(0)" (click)="ngClick_LimpiarRetirado(item)">
                                    <i class="material-icons f-size-14">close</i>
                                </a>
                              </span>
                            
                        </div>
                        <div class="col-md-6 m-0 p-1 ">{{item.nombreCarrera}}
                            <span *ngIf="_MODEL._tipoCurso=='2'" class="float-right ">
                                <a role="button" href="javascript:void(0)" (click)="ngClick_AsignarProyecto(item,item)">
                                    <i class="material-icons f-size-14">assignment</i>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
                <div *ngIf="_Session.CargoElegido !=_utilitarios.enumCargo.Docente">
                    <div class="row" *ngFor="let item of itemRoot.alumnos">
                        <div class="col-md-6 m-0 p-1 retirado">{{item.alumno }}
 
                                    <span *ngIf="item.observacion =='RETIRADO'"   class="ui-info" data-html="true" class="text-left" data-toggle="tooltip" data-placement="top" title="RETIRADO">
                                      <i class="material-icons" >
                                      <span class=" text--orange" >info</span>
                                      </i>
                                      
                                    </span>
 
                        </div>
                        <div class="col-md-6 m-0 p-1 ">{{item.nombreCarrera}} </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container col-md-7 pr-0">
        <div class="card panel-asig col-md-12">
            <div class=" panel-asig-header ">
                <span *ngIf="this._MODEL._tipoCurso=='1'">Fichas de investigación</span>
                <span *ngIf="this._MODEL._tipoCurso=='2'"> Trabajo de investigación</span>
            </div>
            <div class="panel-asig-search col-md-12">
                <div class="row">
                    <div class="col-md-6 m-0 p-1 ">
                        <label class="f-size-12">Buscar Ficha</label>
                        <input class="form-control" id="txtBuscarFichaTodos"
                            placeholder="Presione Enter para realizar la busqueda" (keypress)="ngNumberOnly($event)"
                            (keyup)="ngkeyup_Fichas($event)" />
                    </div>
                    <div class="col-md-4 m-0 p-1">
                        <label class="f-size-12">Estado</label>

                        <select id="cboEstadoBanco" name="cboEstadoBanco" data-html="Todos" class="form-control"
                            (change)="ngChange_EstadoBanco($event)">
                        </select>
                    </div>
                    <div class="col-md-2 m-0 p-1" style="position:relative;">
                        <div class="pull-right"
                            style="background-color: white;position: absolute;top: 30px;bottom: 0;left: 0;right: 0;height: 20px;margin: auto;">

                            <label><input type="checkbox" (change)="ngChange_Expand($event)" name="chkExtraordinaria"
                                    [(ngModel)]="theCheckbox">Expandir Todo</label>
                        </div>

                    </div>
                </div>
            </div>


            <div class="panel-asig-body ">

                <div *ngFor="let itemFicha of _FichasAsociadasModel.column2; let indexFicha = index">
                    <div class="panel-group card col-md-12 m-0  m-2" id="id_{{itemFicha.idBanco}}" role="tablist"
                        aria-multiselectable="true">
                        <div class="panel panel-default">
                            <div class="panel-heading row" role="tab" id="headingOne">
                                <div class="col-md-4">
                                    <span *ngIf="this._MODEL._tipoCurso=='1'">
                                        <span *ngIf="itemFicha.esFichaExtraordinaria == 1" style="color:#7b7676">
                                            [EXTRAORDINARIA]</span>
                                        {{itemFicha.tituloFicha}}
                                    </span>
                                    <span *ngIf="this._MODEL._tipoCurso=='2'                                                                          
                                    && itemFicha.totalConfirmadosPorSeccion==0">
                                        <span *ngIf="itemFicha.esFichaExtraordinaria == 1" style="color:#7b7676">
                                            [EXTRAORDINARIA]</span>
                                        {{itemFicha.tituloFicha}}
                                    </span>
                                    <span *ngIf="this._MODEL._tipoCurso=='2'                                                                          
                                    && itemFicha.totalConfirmadosPorSeccion>0
                                    && itemFicha.codigoEstado==_utilitarios.enumEstado.Asignado">
                                        <span *ngIf="itemFicha.esFichaExtraordinaria == 1" style="color:#7b7676">
                                            [EXTRAORDINARIA]</span>
                                        {{itemFicha.tituloFicha}}
                                    </span>
                                    <span *ngIf="this._MODEL._tipoCurso=='2' 
                                        && itemFicha.codigoEstado==_utilitarios.enumEstado.AsignadoCursoTaller
                                         && itemFicha.totalConfirmadosPorSeccion>0">
                                        <a href="javascript:void(0)"
                                            (click)="ngClick_MostrarNickName(itemFicha,indexFicha)">
                                            <span *ngIf="itemFicha.esFichaExtraordinaria == 1" style="color:#7b7676">
                                                [EXTRAORDINARIA]</span>
                                            <span *ngIf="itemFicha.alias !=null">
                                                {{itemFicha.alias}}
                                            </span>
                                            <span *ngIf="itemFicha.alias ==null">
                                                {{itemFicha.tituloFicha}}
                                            </span>

                                        </a>
                                    </span>
                                    <div class="d-inline opcion" data-toggle="tooltip" data-placement="top"
                                        title="Visualizar la información de la ficha.">
                                        <a href="javascript:void(0)"
                                            (click)="fichaAgregar.ngVisualizarFicha(itemFicha)">
                                            <i class="material-icons text-left text--green f-size-15">remove_red_eye</i>
                                        </a>
                                    </div>
                                    <div class="d-inline opcion" data-toggle="tooltip" data-placement="top"
                                        title="Al confirmar la ficha, se estará eliminando del alumno." *ngIf="itemFicha.codigoEstado==_utilitarios.enumEstado.Asignado
                                                    && _MODEL._tipoCurso=='2'
                                                    && itemFicha.idFichaPadre==null
                                                    && itemFicha.idTipoFicha==4
                                                    ">
                                        <a href="javascript:void(0)"
                                            (click)="ngClick_DesactivarFicha(indexFicha,itemFicha.idBanco,itemFicha.idFicha)">
                                            <i class="material-icons text-left text--red  f-size-15">delete_forever</i>
                                        </a>

                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <span>{{itemFicha.alumnos.length}}</span><span>/</span><span>2</span>
                                </div>
                                <div class="col-md-4">
                                    {{itemFicha.nombreCarrera}}
                                </div>
                                <div class="col-md-2">
                                    <span class="ui-badge-{{itemFicha.nombreEstadoCSS}} p-0">{{itemFicha.nombreEstado}}
                                        <span *ngIf="itemFicha.observacion != null" data-html="true" style="width: 100%"
                                            class="text-left" data-toggle="tooltip" data-placement="top"
                                            style="text-align: left !important;display: inline-block;height: 33px;line-height: 26px;vertical-align: middle;"
                                            title="{{itemFicha.observacion}}">
                                            <span class="ui-info">
                                                <i class="material-icons">
                                                    <span class="">info</span>
                                                </i>
                                            </span>
                                        </span>
                                    </span>

                                </div>
                                <div class="col-md-1">
                                    <a role="button" data-toggle="collapse"
                                        [attr.data-parent]="'#id_'+itemFicha.idBanco"
                                        href="#collapse_{{itemFicha.idBanco}}"
                                        [attr.aria-controls]="'collapse_'+itemFicha.idBanco">
                                        <i class="material-icons">expand_more</i>
                                    </a>
                                </div>
                            </div>

                            <div id="collapse_{{itemFicha.idBanco}}" class="panel-collapse collapse show"
                                role="tabpanel" aria-labelledby="headingOne">
                                <div class="panel-body">
                                    <!-- style="display: block !important;    background: red;
                                    position: absolute;
                                    z-index: 1;
                                    height: 100px;
                                    width: 502px !important;" -->
                                    <div class="drop">



                                        <div class="row">


                                            <div class="col-md-10 m-0 p-1 datos">
                                                <!-- <div *ngFor="let itemAlumno of itemFicha.alumnos; let indexAl = index">-->

                                                <div *ngFor="let itemAlumno of itemFicha.alumnos; let indexAl = index">

                                                    <div class="col-md-12 m-0 p-1 "
                                                        [ngStyle]="{'background-color': (itemAlumno.observacion !=null) ? '#8080802e' : ''}">
                                                        <!--cdkDrag::< atributo-->

                                                        <span #aVariable="var"
                                                            [var]="itemAlumno.esConfirmadoParaTaller"></span>

                                                        <span style="display: none;"
                                                            class="confirmo_{{itemFicha.idBanco}}">{{itemAlumno.esConfirmadoParaTaller}}</span>


                                                        <span>
                                                            {{itemAlumno.alumno}}
                                                        </span>
                                                        <span
                                                            *ngIf="_Session.CargoElegido ==_utilitarios.enumCargo.Docente">

                                                            <span *ngIf="itemFicha.codigoEstado!=_utilitarios.enumEstado.Asignado
                                                        && itemAlumno.esPropietario==0
                                                        && this._MODEL._tipoCurso=='1'
                                                        && (itemAlumno.observacion == null
                                                            || itemAlumno.observacion == ''
                                                            )" class="float-right close-a">
                                                                <a role="button" href="javascript:void(0)"
                                                                    (click)="ngClick_EliminarAlumnoAsignado(indexFicha,indexAl,itemFicha.idBanco)">
                                                                    <i class="material-icons f-size-14">close</i>
                                                                </a>
                                                            </span>
                                                            <span *ngIf="itemFicha.codigoEstado==_utilitarios.enumEstado.Asignado 
                                                        && itemAlumno.esPropietario==0
                                                        && this._MODEL._tipoCurso=='2'
                                                        &&  (itemAlumno.esConfirmadoParaTaller==0
                                                        || itemAlumno.esConfirmadoParaTaller==null)"
                                                                class="float-right close-b">
                                                                <a role="button" href="javascript:void(0)"
                                                                    (click)="ngClick_EliminarAlumnoAsignado(indexFicha,indexAl,itemFicha.idBanco)">
                                                                    <i class="material-icons f-size-14">close</i>
                                                                </a>
                                                            </span>
                                                            <span *ngIf="itemAlumno.esPropietario>0
                                                            && (itemAlumno.observacion == null
                                                            || itemAlumno.observacion == '')" class="float-right ">

                                                                <a role="button" href="javascript:void(0)"
                                                                    *ngIf="itemFicha.codigoEstado==_utilitarios.enumEstado.Reservado"
                                                                    (click)="ngClick_EliminarAsignacionExtraordinaria(indexFicha,indexAl,itemFicha.idBanco)">
                                                                    <i class="material-icons f-size-14">lock_open</i>
                                                                </a>

                                                                <SPAN style="display: none;">
                                                                    <SPAN>{{itemAlumno.esPropietario}}</SPAN>
                                                                    <span>:::</span>
                                                                    <SPAN>{{itemAlumno.idAlumno}}</SPAN>
                                                                </SPAN>

                                                                <span role="button" *ngIf="
                                                                (itemFicha.codigoEstado!=_utilitarios.enumEstado.Reservado
                                                                &&  _MODEL._tipoCurso=='1'        )
                                                                 ||( 
                                                                (itemFicha.codigoEstado==_utilitarios.enumEstado.AsignadoCursoTaller
                                                                 && itemAlumno.esConfirmadoParaTaller=='1'
                                                                )
                                                                ||( itemFicha.codigoEstado==_utilitarios.enumEstado.Asignado
                                                                && itemAlumno.esPropietario>0)
                                                                 ) ">
                                                                    <i class="material-icons f-size-14">lock</i>
                                                                </span>
                                                            </span>

                                                            <span *ngIf="itemAlumno.observacion!=null"
                                                                class="float-right ">
                                                                <span role="button">

                                                                    <span data-html="true" style="width: 100%"
                                                                        class="text-left" data-toggle="tooltip"
                                                                        data-placement="top"
                                                                        title="{{itemAlumno.observacion}}">
                                                                        <span class="ui-info">
                                                                            <i
                                                                                class="material-icons f-size-14 text--orange">info
                                                                            </i>
                                                                        </span>
                                                                    </span>

                                                                </span>



                                                            </span>

                                                            <span *ngIf="itemFicha.codigoEstado==_utilitarios.enumEstado.AsignadoCursoTaller
                                                        && itemAlumno.esConfirmadoParaTaller!='1'"
                                                                class="float-right ">
                                                                <a role="button" href="javascript:void(0)"
                                                                    (click)="ngClick_AsignarProyecto(itemFicha,itemAlumno)">
                                                                    <i class="material-icons f-size-14">assignment</i>
                                                                </a>
                                                            </span>

                                                        </span>

                                                    </div>


                                                </div>

                                                <div class="col-md-12 m-0 p-1" *ngIf="itemFicha.alumnos.length<2
                                                && (
                                                    (itemFicha.codigoEstado!=_utilitarios.enumEstado.AsignadoCursoTaller
                                                    && _MODEL._tipoCurso=='2')
                                                || (
                                                    itemFicha.codigoEstado==_utilitarios.enumEstado.Asignado
                                                    && _MODEL._tipoCurso=='2'     
                                                )
                                                || (
                                                    itemFicha.codigoEstado!=_utilitarios.enumEstado.Asignado
                                                    && _MODEL._tipoCurso=='1'     
                                                )                                                
                                                )
                                                " cdkDropList id="{{itemFicha.idBanco}}"
                                                    [cdkDropListData]="itemFicha.alumnos"
                                                    cdkDropListOrientation="horizontal"
                                                    (mouseover)="ngOver_SobreFicha(itemFicha.idBanco)"
                                                    (mouseout)="ngOut_SobreFicha()"
                                                    (cdkDropListDropped)="dropAlumnoSinAsignar($event,itemFicha.idBanco,itemFicha.totalConfirmadosPorSeccion,itemFicha.codigoEstado)">
                                                    <span>
                                                    </span>
                                                </div>
                                                <div class="col-md-12 m-0 p-1" *ngIf="itemFicha.alumnos.length==0
                                                && (
                                                    (itemFicha.codigoEstado!=_utilitarios.enumEstado.AsignadoCursoTaller
                                                    && _MODEL._tipoCurso=='2')
                                                || (
                                                    itemFicha.codigoEstado==_utilitarios.enumEstado.Asignado
                                                    && _MODEL._tipoCurso=='2'     
                                                )
                                                || (
                                                    itemFicha.codigoEstado!=_utilitarios.enumEstado.Asignado
                                                    && _MODEL._tipoCurso=='1'     
                                                )  
                                                )" cdkDropList id="{{itemFicha.idBanco}}"
                                                    [cdkDropListData]="itemFicha.alumnos"
                                                    cdkDropListOrientation="horizontal"
                                                    (mouseover)="ngOver_SobreFicha(itemFicha.idBanco)"
                                                    (mouseout)="ngOut_SobreFicha()"
                                                    (cdkDropListDropped)="dropAlumnoSinAsignar($event,itemFicha.idBanco,itemFicha.totalConfirmadosPorSeccion,itemFicha.codigoEstado)">
                                                    <span>
                                                    </span>
                                                </div>

                                            </div>


                                            <span style="display: none;">
                                                <span> {{  _utilitarios.enumEstado.AsignadoCursoTaller}}</span>
                                                <span> {{  itemFicha.codigoEstado}}</span>
                                                <span> {{ _MODEL._tipoCurso}}</span>
                                                <span> {{ itemFicha.cantidadConfirmados}}</span>
                                                <span> {{ itemFicha.totalConfirmadosPorSeccion}}</span>
                                            </span>



                                            <div *ngIf="_Session.CargoElegido ==_utilitarios.enumCargo.Docente">
                                                <div class="col-md-2 m-0 p-1 opcion" *ngIf="itemFicha.codigoEstado==_utilitarios.enumEstado.Asignado
                                                && _MODEL._tipoCurso=='1'                                                   
                                                || (
                                                    itemFicha.codigoEstado==_utilitarios.enumEstado.AsignadoCursoTaller
                                                && _MODEL._tipoCurso=='2'
                                                && itemFicha.cantidadConfirmados>0
                                                &&  itemFicha.totalConfirmadosPorSeccion>0)" data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Al revertir la ficha, se estará cambiando el estado de la ficha a 'Reservado'">
                                                    <a href="javascript:void(0)"
                                                        (click)="ngClick_RevertirFichaAlumnos(indexFicha,itemFicha.idBanco,itemFicha.idFicha)">
                                                        <i class="material-icons  text--red">replay</i>
                                                    </a>
                                                </div>
                                                <div class="col-md-2 m-0 p-1 opcion inline-block" data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Al confirmar la ficha, se estará enviando un email con la información de la ficha."
                                                    *ngIf="itemFicha.codigoEstado==_utilitarios.enumEstado.Reservado
                                                || (itemFicha.codigoEstado==_utilitarios.enumEstado.Asignado
                                                && _MODEL._tipoCurso=='2')">
                                                <!-- ||(itemFicha.codigoEstado==_utilitarios.enumEstado.SinAsignar
                                                && _MODEL._tipoCurso=='1'
                                                && itemFicha.esFichaExtraordinaria == 1) -->
                                                    <a href="javascript:void(0)"
                                                        (click)="ngClick_AsignarFichaAlumnos(indexFicha,itemFicha.idBanco,itemFicha.idFicha)">
                                                        <i class="material-icons text-left text--green">check_circle</i>
                                                    </a>

                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!-- panel-group -->

                </div>


            </div><!-- container -->

        </div>
    </div>
</div>



<app-ficha-agregar #fichaAgregar [tipoCarga]="1"></app-ficha-agregar>



<div class="modal fade" id="modalProyecto" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Nuevo trabajo de investigación
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="card form-signin p-4">
                <div class="row">

                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="txtProyecto">Nombre del Proyecto</label>
                            <input class="form-control" [(ngModel)]="txtNombreProyecto" />
                            <div class="alert alert-danger col-md-12">
                                <div *ngIf="_errorNombreProyecto">Ingrese
                                    un nombre del proyecto.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary btn-save col-md-4"
                        (click)="ngClick_btnGuardarProyecto($event)">Confirmar<i
                            class="material-icons">save</i></button>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalDesasociarFichaExtraordinaria" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Eliminar asignación extraordinaria
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <form name="frmDesasociarFichaExtraordinaria" class="card form-signin p-4"
                onkeydown="return event.key != 'Enter';" [formGroup]="_FORMFICHA" novalidate id="_FORMFICHA">
                <div class="row justify-content-md-center col-md-12">
                    <div *ngIf="_FORMFICHA.controls['txtComentarioDesasociacion'].invalid && (_FORMFICHA.controls['txtComentarioDesasociacion'].dirty || _FORMFICHA.controls['txtComentarioDesasociacion'].touched )"
                        class="alert alert-danger col-md-12">
                        <div *ngIf=" _FORMFICHA.controls['txtComentarioDesasociacion'].errors.required">Ingrese una
                            observación</div>
                    </div>
                    <textarea placeholder="escribe aqui una observación.." class="col-md-12"
                        formControlName="txtComentarioDesasociacion" #txtComentarioDesasociacion></textarea>

                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary btn-save col-md-4"
                        (click)="ngClick_DesasociarFicha($event)">Confirmar<i class="material-icons">save</i></button>
                    <button type="button" data-dismiss="modal" aria-label="Close"
                        class=" btn btn-danger  btn-save  col-md-4" (click)="onCerrarModel()">Cancelar<i
                            class="material-icons">close</i></button>
                </div>
            </form>

        </div>
    </div>
</div>

<div class="modal fade" id="modalEditarTrabajoInvestigacion" style="z-index: 9999;" data-backdrop="static"
    data-keyboard="false">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Editar trabajo de investigación
                </h5>
                <button type="button" class="close" (click)="ngClick_CerrarModal($event)" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="row  offset-md-1 col-md-11 mb-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="lblCampus">Título final del Trabajo de Investigación</label>
                        <input type="text" (blur)="ngBlur_validarombreDelProyecto($event)" class="form-control"
                            [(ngModel)]="_nombreDelProyecto" />
                        <div *ngIf="_nombreDelProyecto=='' && _formEditarTrabajoInvestigacionValidar"
                            class="alert alert-danger">
                            <div class="white-space"> Es necesario ingresar un nombre.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <button type="button"  (click)="ngClick_GuardarAlias($event)"
                        class="btn btn-save mt-1">Actualizar<i class="material-icons">save</i></button>
                </div>


            </div>



        </div>
    </div>
</div>

<!--MODAL PARA RETIRADO-->
<div class="modal fade" id="modalListarRetirados" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Lista de Alumnos Retirados
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div>
                <div class="row">
                    <div class="col-md-12 height-400" >
                        <table class="table" id="tblRetirados" >
                            <thead>
                                <tr>

                                    <th scope="col">#</th>
                                    <th scope="col">Codigo</th>
                                    <th scope="col">Alumno</th>
                                    <th scope="col">Estado</th>
                                    
                                </tr>
                            </thead>
                            <tbody *ngIf="listaAlumnosRetirados != undefined && listaAlumnosRetirados.length > 0;">
                                <tr *ngFor="let row of listaAlumnosRetirados; let i=index">
                                    <td>{{row.n}}</td>
                                    <td>{{row.codigo}}</td>
                                    <td>{{row.alumno}}</td>
                                    <td>{{row.estado}}</td>                                
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="fondo">

</div>