<div class="row justify-content-between">

    <div class="col-md-6">
        <!-- Title section -->
        <h3>Reporte final de trabajos de investigación</h3>
        <!-- End Title section -->
    </div>

</div>

<div class="card">
    <!-- Filters -->

    <form class="card form-signin p-4 col-md-12" onkeydown="return event.key != 'Enter';" [formGroup]="_FORMFICHA"
        novalidate id="_FORMFICHA">
        <div class="row justify-content-between m-0 ">

            <div class="row col-md-12">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="cboCampus_filtro">Periodo</label>
                        <angular2-multiselect [data]="_dropdownListPeriodo" formControlName="_selectedItemsPeriodo"
                            [(ngModel)]="_selectedItemsPeriodo" [settings]="_dropdownSettingSinBuscar">
                        </angular2-multiselect>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Codigo Docente</label>
                        <input class="form-control" value="" type="text" formControlName="_txtCodigoDocente" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Docente</label>
                        <input class="form-control" value="" type="text" formControlName="_txtDocente" />
                    </div>
                </div>

            </div>
            <div class="row col-md-12">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Codigo Alumno</label>
                        <input class="form-control" value="" type="text" formControlName="_txtCodigoAlumno" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Alumno</label>
                        <input class="form-control" value="" type="text" formControlName="_txtAlumno" />
                    </div>
                </div>
                <div class="col-md-4">

                    <div class="form-group" data-for="cboGrado_filtro">
                        <label for="cboGrado_filtro">Grado</label>
                        <angular2-multiselect [data]="_dropdownListGrado" formControlName="_selectedItemsGrado"
                            [(ngModel)]="_selectedItemsGrado" [settings]="_dropdownSettingSinBuscar">
                        </angular2-multiselect>
                    </div>
                </div>
            </div>
            <div class="row col-md-12">
                <div class="col-md-4">
                    <div class="form-group" data-for="">
                        <label for="">Facultad</label>
                        <angular2-multiselect [data]="_dropdownListFacultad" formControlName="_selectedItemsFacultad"
                            [(ngModel)]="_selectedItemsFacultad" [settings]="_dropdownSettings">
                        </angular2-multiselect>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" data-for="cboCampus_filtro">
                        <label for="cboCampus_filtro">Carrera</label>
                        <angular2-multiselect [data]="_dropdownListCarrera" formControlName="_selectedItemsCarrera"
                            [(ngModel)]="_selectedItemsCarrera" [settings]="_dropdownSettings" (ngModelChange)="ngChange_Carrera($event)">
                        </angular2-multiselect>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" data-for="cboCampus_filtro">
                        <label for="cboCampus_filtro">Linea investigación</label>
                        <angular2-multiselect [data]="_dropdownListLineainvestigacion" formControlName="_selectedItemsLineainvestigacion"
                            [(ngModel)]="_selectedItemsLineainvestigacion" [settings]="_dropdownSettings">
                        </angular2-multiselect>
                    </div>
                </div>
            </div>
            <div class="row col-md-12">
                <div class="col-md-4">
                    <div class="form-group" data-for="cboCampus_filtro">
                        <label for="cboCampus_filtro">Campus</label>
                        <angular2-multiselect [data]="_dropdownListCampus" formControlName="_selectedItemsCampus"
                            [(ngModel)]="_selectedItemsCampus" [settings]="_dropdownSettingSinBuscar">
                        </angular2-multiselect>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group" data-for="">
                        <label for="">Seccion</label>
                        <angular2-multiselect [data]="_dropdownListSeccion" formControlName="_selectedItemsSeccion"
                            [(ngModel)]="_selectedItemsSeccion" [settings]="_dropdownSettings">
                        </angular2-multiselect>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" data-for="">
                        <label for="">Curso</label>
                        <angular2-multiselect [data]="_dropdownListCurso" formControlName="_selectedItemsCurso"
                            [(ngModel)]="_selectedItemsCurso" [settings]="_dropdownSettings">
                        </angular2-multiselect>
                    </div>
                </div>


            </div>




        </div>
        <div class="row  justify-content-between m-0">
            <div class="col-md-3 ">
                <button class="btn btn-buscar" type="button" id="btnBuscarSolicitud"
                    (click)="ngClick_BuscarDatos($event)">Buscar<i class="material-icons">search</i></button>
                <button class="btn btn-buscar" type="button" id="btnBuscarSolicitud"
                    (click)="ngClick_DescargarArchivo($event)">Descargar<i
                        class="material-icons">cloud_download</i></button>

            </div>
        </div>
    </form>

    <!-- End Filters -->
    <!-- Table -->
    <br>
    <div class="mx-3" style="overflow-x: scroll;">
        <table class="table" id="tblReporte">
            <thead>
                <tr>

                    <th scope="col small_col">Periodo</th>
                    <th scope="col">Codigo UTP</th>
                    <th scope="col">Alumno</th>
                    <th scope="col">Grado</th>
                    <th scope="col">Nombre cacultad</th>
                    <th scope="col">Nombre carrera </th>
                    <th scope="col">Nombre Campus </th>
                    <th scope="col">Seccion </th>
                    <th scope="col">Curso</th>
                    <th scope="col">Nota </th>
                    <th scope="col">Codigo docente 1 </th>
                    <th scope="col">docente 1</th>
                    <th scope="col">Codigo docente 2 </th>
                    <th scope="col">Docente 2 </th>
                    <th scope="col">Titulo ficha</th>
                    <th scope="col">Nombre trabajo final</th>
                    <th scope="col">Linea investigación</th>

                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
    <!-- End Table -->
</div>