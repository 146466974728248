import { Injectable } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { MapRoute } from "../../global/maproute";
@Injectable({
    providedIn: "root"
})
export class ReporteService {
    private _MapRoute: MapRoute = null;
    private urlUsuario: string = "";
    constructor(private http: HttpClient) {
        this._MapRoute = new MapRoute(http);
        this.urlUsuario = this._MapRoute.tibaURL + "Reporte";
    }
    ListarProduccionFichas(parametro, callback) {


        this._MapRoute.post(`${this.urlUsuario}/ListarProduccionFichas`, parametro, callback);
    }

    ListarSeccionesFichas(parametro, callback) {

        this._MapRoute.post(`${this.urlUsuario}/ListarSeccionesFichas`, parametro, callback);
    }
    ListarTrabajosInvestigacionTaller(parametro, callback) {

        this._MapRoute.post(`${this.urlUsuario}/ListarTrabajosInvestigacionTaller`, parametro, callback);
    }

    ListarDocumentosOficialesCargados(parametro, callback) {

        this._MapRoute.post(`${this.urlUsuario}/ListarDocumentosOficialesCargados`, parametro, callback);
    }

    DescargarArchivoExcelProduccionFichas(parametro,callback) {

        this._MapRoute.post(`${this.urlUsuario}/DescargarArchivoExcelProduccionFichas`, parametro, callback);
    }
    
    DescargarArchivoExcelReporteSecciones(parametro,callback) {

        this._MapRoute.post(`${this.urlUsuario}/DescargarArchivoExcelReporteSecciones`, parametro, callback);
    }
    DescargarArchivoExcelReporteTrabajoInvestigacion(parametro,callback) {

        this._MapRoute.post(`${this.urlUsuario}/DescargarArchivoExcelReporteTrabajoInvestigacion`, parametro, callback);
    }
    DescargarDocumentosOficialesCargados(parametro,callback) {

        this._MapRoute.post(`${this.urlUsuario}/DescargarDocumentosOficialesCargados`, parametro, callback);
    }
}
