
<style>
    table td.text-right{

        text-align: right;
    }
</style>
<div class="row justify-content-between">

    <div class="col-md-6">
        <!-- Title section -->
        <h3>Reporte de secciones</h3>
        <!-- End Title section -->
    </div>

</div>

<div class="card">
    <!-- Filters -->

    <form class="card form-signin p-4 col-md-12" onkeydown="return event.key != 'Enter';" [formGroup]="_FORMFICHA"
        novalidate id="_FORMFICHA">
        <div class="row justify-content-between m-0 ">

            <div class="row col-md-12">

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="cboCampus_filtro">Periodo</label>
                        <angular2-multiselect [data]="_dropdownListPeriodo" formControlName="_selectedItemsPeriodo"
                            [(ngModel)]="_selectedItemsPeriodo" [settings]="_dropdownSettingColumna">
                        </angular2-multiselect>
                    </div>
                </div>
                <div class="col-md-4">

                    <div class="form-group" data-for="cboGrado_filtro">
                        <label for="cboGrado_filtro">Grado</label>
                        <angular2-multiselect [data]="_dropdownListGrado" formControlName="_selectedItemsGrado"
                            [(ngModel)]="_selectedItemsGrado" [settings]="_dropdownSettingSinBuscar">
                        </angular2-multiselect>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" data-for="cboCampus_filtro">
                        <label for="cboCampus_filtro">Campus</label>
                        <angular2-multiselect [data]="_dropdownListCampus" formControlName="_selectedItemsCampus"
                            [(ngModel)]="_selectedItemsCampus" [settings]="_dropdownSettings">
                        </angular2-multiselect>
                    </div>
                </div>
            </div>

            <div class="row col-md-12">

                <div class="col-md-4">
                    <div class="form-group" data-for="">
                        <label for="">Seccion</label>
                        <angular2-multiselect [data]="_dropdownListSeccion" formControlName="_selectedItemsSeccion"
                            [(ngModel)]="_selectedItemsSeccion" [settings]="_dropdownSettings">
                        </angular2-multiselect>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" data-for="">
                        <label for="">Curso</label>
                        <angular2-multiselect [data]="_dropdownListCurso" formControlName="_selectedItemsCurso"
                            [(ngModel)]="_selectedItemsCurso" [settings]="_dropdownSettings">
                        </angular2-multiselect>
                    </div>
                </div>


                <div class="col-md-4">
                    <div class="form-group" data-for="_selectedItemsColor">
                        <label for="_selectedItemsColor">Color</label>
                        <angular2-multiselect [data]="_dropdownListColor" (onSelect)="ngItemSelect_color($event)"
                            (onDeSelect)="ngItemDeSelect_color($event)" (onSelectAll)="ngSelectAll_color($event)"
                            (onDeSelectAll)="ngDeSelectAll_color($event)" formControlName="_selectedItemsColor"
                            [(ngModel)]="_selectedItemsColor" [settings]="_dropdownSettingSinBuscar"
                            (ngModelChange)="ngChange_selectedItemsColor($event)">
                        </angular2-multiselect>
                    </div>
                </div>

            </div>



        </div>
        <div class="row  justify-content-between m-0">
            <div class="col-md-3 ">
                <button class="btn btn-buscar" type="button" id="btnBuscarSolicitud"
                    (click)="ngClick_BuscarDatos($event)">Buscar<i class="material-icons">search</i></button>
                <button class="btn btn-buscar" type="button" id="btnBuscarSolicitud"
                    (click)="ngClick_DescargarArchivo($event)">Descargar<i
                        class="material-icons">cloud_download</i></button>

            </div>
        </div>
    </form>

    <!-- End Filters -->
    <!-- Table -->
    <div class="mx-12" >
        <table  class="table-report dataTable " id="tblReporte">
            <thead>
                <tr>

                    <th scope="col" class="text-center">Periodo</th>
                    <th scope="col" class="text-left">Grado</th>
                    <th scope="col"  class="text-left">Campus</th>
                    <th scope="col" class="">Seccion</th>

                    <th scope="col ">Curso</th>
                    <th scope="col " class="text-left">Cod.Doc.1</th>
                    <th scope="col " class="text-left">DOC.1</th>
                    <th scope="col " class="text-left">Cod.Doc.2</th>
                    <th scope="col " class="text-left">DOC.2 </th>
                    <th scope="col " class="text-left">Carrera</th>
                    <th scope="col text-center">Cant.Alm. </th>
                    <th scope="col  text-right">Fic.requeridas </th>
                    <th scope="col   text-right">Fic.repartidas </th>
                    <th scope="col  text-right">Fic.asignadas </th>
                    <th scope="col  text-right">Fic.reservadas </th>
                    <th scope="col  text-right">Fic.disponibles </th>
                    <th scope="col  text-right">Alum.asignados </th>
                    <th scope="col  text-right">Alum.reservados </th>
                    <th scope="col text-right">Alum.observados </th>
                    <th scope="col ">Estatus de asignacion </th>

                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
    <!-- End Table -->
</div>