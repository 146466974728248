import { Component, OnInit } from "@angular/core";
//---------------------------------------------------------------------
//-- Clases Locales
//---------------------------------------------------------------------
import { FichaService } from "../_servicio/ficha.service";
import { ResumenFichaService } from "../_servicio/resumenFicha.service";
import { BancoService } from "../_servicio/banco.service";
import { SolicitudFichaService } from "../_servicio/solicitudFicha.service";
import { ComboboxService } from "../_servicioComponentes/combobox.service";
import { Session } from "../../global/session";
import { utilitarios } from "../../global/utilitarios";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import Swal from "sweetalert2";
import * as $ from "jquery";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
@Component({
  selector: "app-fichaaprobada",
  templateUrl: "./fichaaprobada.component.html",
  styleUrls: ["./fichaaprobada.component.scss"],
})
export class FichaaprobadaComponent implements OnInit {

  esTransferirFichaSeccion: boolean = false;
  tituloFicha: string = "";
  _idFichaSeleccionado: string = "";

  public _FORMFICHA: FormGroup;

  public _BUILDER: any = {
    asignarCboCampus: ["", Validators.required],
    txtSeccion: ["", Validators.required],


  };

  constructor(
    public formBuilder: FormBuilder,
    private combobox: ComboboxService,
    private _Session: Session,
    private _FichaService: FichaService,
    private _BancoService: BancoService,
    private _ResumenFichaService: ResumenFichaService,
    private _SolicitudFichaService: SolicitudFichaService,
    private _utilitarios: utilitarios
  ) { }
  ngOnInit() {
    this._FORMFICHA = this.formBuilder.group(this._BUILDER);
    this.combobox.init(
      {
        html: "Todos",
        id: "cboCoordinador",
        entidad: "mAcademicosFichasPorCoordInvestigacion",
        parametro: {
          "@p_idPersona": this._Session.usuario.persona.idPersona,
        },
      },
      (data) => { }
    );
    this.combobox.init(
      {
        id: "cboEstadoBanco",
        entidad: "BancoEstados_Listar",
        html: "todos"
      },
      (data) => { }
    );
    this.dom.habilitarOpciones();
    $.when(

      this.dom.periodoVigente(),
      this.dom.estados(),
      this.dom.ListarFichasAsignadasPorAcademico()
    ).then(function (data, textStatus, jqXHR) { });
  }

  onClickBuscar() {
    this.dom.ListarFichasAsignadasPorAcademico();
  }
  onClickBuscarFichaTitulo(event) {
    this.dom.ListarFichasDocentesPorTitulo();
  }
  onClickHistorialFichas() {
    $("#idTitulo").val("");

    if ($.fn.dataTable.isDataTable("#tblBusquedaFichaInv")) {
      $("#tblBusquedaFichaInv").DataTable().clear().draw();
    }
    this._utilitarios.OpenModal("modalBuscarTituloFichaAprobada");
  }

  ngClick_btnGuardarSeccion(event) {

    this._FORMFICHA.controls["txtSeccion"].markAsTouched({ onlySelf: true });;
    this._FORMFICHA.controls["asignarCboCampus"].markAsTouched({ onlySelf: true });;

    if (this._FORMFICHA.valid) {
      this._BancoService.RegistrarActualizar({
        idFicha: this._idFichaSeleccionado,
        ClassNbr: this._FORMFICHA.controls["txtSeccion"].value,
        idCampus: this._FORMFICHA.controls["asignarCboCampus"].value,
      }, () => {
        this._FORMFICHA.reset();
        this._utilitarios.CerrarModal("modalAsignarFichaSeccion");
        this.dom.ListarFichasAsignadasPorAcademico()
      });
    }


  }
  //------------------------------------------------------------------------------------
  //-- Metodos privados
  //------------------------------------------------------------------------------------
  dom: any = {
    habilitarOpciones: () => {
      this._Session.Opciones.forEach(element => {
        if (element.comando == this._utilitarios.enumOpciones.AsignarFichaSeccion) {
          this.esTransferirFichaSeccion = true;
        }
      });
    },

    campusParaAsignarFicha: () => {
      this.combobox.init(
        {
          id: "asignarCboCampus",
          entidad: "mCampusPorPersona",
          parametro: { "@p_idPersona": this._Session.usuario.persona.idPersona }
        },
        data => { }
      );
    },
    periodoVigente: (x) => {
      return this.combobox.init(
        {
          html: "Todos",
          id: "cboPeriodo",
          entidad: "mPeriodo",
        },
        (data) => { }
      );
    },
    estados: (x) => {
      return this.combobox.init(
        {
          html: "Todos",
          id: "cboCarrera",
          entidad: "mCarreraPorPersonaCampus",
          parametro: {
            "@p_idPersona": this._Session.usuario.persona.idPersona,
          },
        },
        (data) => { }
      );
    },
    ListarFichasAsignadasPorAcademico: (cl) => {
      var _that = this;
      return this._ResumenFichaService.ListarFichasAsignadasPorAcademico(
        {
          idPersonaRegistra: this._Session.usuario.persona.idPersona,
          idPeriodo: $("#cboPeriodo").val() == "" ? 0 : $("#cboPeriodo").val(),
          IdPersonaCordinadorAcademico:
            $("#cboCoordinador").val() == "" ? 0 : $("#cboCoordinador").val(),
          idCarrera: $("#cboCarrera").val() == "" ? 0 : $("#cboCarrera").val(),
          codigoEstadoBanco: $("#cboEstadoBanco").val() == "" ? "" : $("#cboEstadoBanco").val(),
        },
        (data) => {
          let exampleId: any = $("#tblListaFichaAprobado");
          this._utilitarios.Datatable(exampleId, {
            columns: [
              { data: "tituloFicha" },
              { data: "periodo", class: " small_col" },
              { data: "coordinadorAcademico" },
              { data: "docente" },
              {
                data: "fechaAprobacion",
                //class: "fecha small_col",
                class: "small_col",
                render: (index, display, rowdata) => {
                  if (rowdata.fechaAprobacion == null) return "";
                  else
                    return this._utilitarios.ConvertFechaServidorToDMYHMS(
                      rowdata.fechaAprobacion
                    );
                },
              },
              {
                data: "nombreEstado",
                class: "small_col",
                render: function (index, display, rowdata) {
                  return (
                    '<span class="ui-badge-' +
                    rowdata.nombreEstado +
                    '">' +
                    rowdata.nombreEstado +
                    "</span>"
                  );
                },
              },
              {
                data: "nombreEstadoBanco",
                class: "small_col",
                render: function (index, display, rowdata) {
                  return (
                    '<span class="ui-badge-' +
                    rowdata.nombreEstadoBanco.replace(/ /g, '') +
                    '">' +
                    rowdata.nombreEstadoBanco +
                    "</span>"
                  );
                },
              },
              {
                data: null,
                class: "text-center small_col",
                render: (index, display, rowdata) => {
                  var esEliminarFichaAprobada: boolean = false;

                  this._Session.Opciones.forEach((element) => {
                    if (
                      element.comando ==
                      this._utilitarios.enumOpciones.EliminarFichaAprobada
                    ) {
                      esEliminarFichaAprobada = true;
                    }
                  });

                  var html = ``;

                  html = `<div class="ui-icon-visibility" data-view="">
                      <i class="material-icons" >
                      <a href="javascript:void(0)"  >visibility</a>
                      </i>
                    </div>`;

                  html += `<div class="ui-icon-print" data-toggle="modal" data-print="">
                            <i class="material-icons">
                              <a href="javascript:void(0)" >local_printshop</a>
                            </i>
                          </div>`;

                  if (esEliminarFichaAprobada) {
                    if (
                      rowdata.codigoEstado !=
                      _that._utilitarios.enumEstado.Anulado
                    ) {
                      html += `<div class="ui-icon-delete" data-toggle="modal" data-delete="">
                      <i class="material-icons">
                        <a href="javascript:void(0)" >delete</a>
                      </i>
                    </div>`;
                    }
                  }

                  if ((rowdata.codigoEstadoBanco == null || rowdata.codigoEstadoBanco == 'SNSC')
                    && this.esTransferirFichaSeccion == true) {


                    html += `<div class="ui-icon-delete" data-toggle="modal" data-trasnferir="">
                      <i class="material-icons">
                        <a href="javascript:void(0)" >published_with_changes</a>
                      </i>
                    </div>`;
                  }
                  return html;
                },
              },
              { data: "codigoEstado", visible: false },
              { data: "idSolicitudFicha", visible: false },
              { data: "idFicha", visible: false },
              { data: "idCampusFacultadCarrera", visible: false },
            ],
            data: data,
            fnDrawCallback: (fndraw) => {
              $("#tblListaFichaAprobado")
                .find("tbody tr  div[data-delete]")
                .unbind("click");
              $("#tblListaFichaAprobado")
                .find("tbody tr  div[data-delete]")
                .click(function () {
                  var tr = $(this).parents("tr");
                  var rowData: any = $("#tblListaFichaAprobado")
                    .DataTable()
                    .row(tr)
                    .data();
                  Swal.fire({
                    title: "¿Está seguro en anular la ficha?",
                    type: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                  }).then((result) => {
                    if (result.value) {
                      _that._FichaService.AnularFicha(
                        {
                          idFicha: rowData.idFicha,
                          codigoRol: _that._Session.rolMarcado,
                        },
                        (dataEstado) => {
                          let row: any = $("#tblListaFichaAprobado")
                            .DataTable()
                            .row(tr);

                          row.remove().draw(false);
                          /*
                           $("#tblListaFichaAprobado")
                            .DataTable()
                            .row(tr)
                            .data(dataEstado);
                          $("#tblListaFichaAprobado")
                            .DataTable()
                            .row(tr)
                            .invalidate();
                          */

                        }
                      );
                    }
                  });
                });


              $("#tblListaFichaAprobado")
                .find("tbody tr  div[data-trasnferir]")
                .unbind("click");
              $("#tblListaFichaAprobado")
                .find("tbody tr  div[data-trasnferir]")
                .click((event) => {
                  var tr = $(event.currentTarget).parents("tr");
                  var rowData: any = $("#tblListaFichaAprobado")
                    .DataTable()
                    .row(tr)
                    .data();
                  this.tituloFicha = rowData.tituloFicha;
                  this._idFichaSeleccionado = rowData.idFicha;
                  this.dom.campusParaAsignarFicha();
                  this._utilitarios.OpenModal("modalAsignarFichaSeccion");
                })
            },
          });
        }
      );
    },
    ListarFichasDocentesPorTitulo: (cl) => {
      var _that = this;
      return this._SolicitudFichaService.ListarFichasDocentesPorTitulo(
        {
          titulo: $("#idTitulo").val(),
        },
        (data) => {
          let exampleId: any = $("#tblBusquedaFichaInv");
          this._utilitarios.Datatable(exampleId, {
            columns: [
              { data: "periodo", class: " small_col" },
              { data: "tituloFicha" },
              { data: "carrera", class: "small_col" },
              { data: "nombreDocenteRegistra" },
              {
                data: "nombreEstado",
                class: "small_col",
                render: function (index, display, rowdata) {
                  return (
                    '<span class="ui-badge-' +
                    rowdata.nombreEstado +
                    '">' +
                    rowdata.nombreEstado +
                    "</span>"
                  );
                },
              },
              {
                data: null,
                class: "text-center small_col",
                render: function (index, display, rowdata) {
                  var html = ``;
                  html = `<div class="ui-icon-visibility" data-view="">
                      <i class="material-icons" >
                      <a href="javascript:void(0)"  >visibility</a>
                      </i>
                    </div>`;
                  return html;
                },
              },
              { data: "codigoEstado", visible: false },
              { data: "idSolicitudFicha", visible: false },
              { data: "idFicha", visible: false },
              { data: "idCampusFacultadCarrera", visible: false },
            ],
            data: data,
            fnDrawCallback: (fndraw) => { },
          });
        }
      );
    },
  };
}
