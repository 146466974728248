import { Injectable } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { MapRoute } from "../../global/maproute";



@Injectable({
  providedIn: "root"
})

export class ResumenFichaService {
  private _MapRoute: MapRoute = null;
  private url: string = "";
  constructor(private http: HttpClient) {
    this._MapRoute = new MapRoute(http);
    this.url = this._MapRoute.tibaURL + "ResumenFicha";
  }
  RegistrarActualizar(parametro, callback) {
    this._MapRoute.post(
      `${this.url}/RegistrarActualizar`,
      parametro,
      callback
    );
  }

  Listar(parametro,callback){
    this._MapRoute.post(
      `${this.url}/Listar`,
      parametro,
      callback
    );
  }

  ListarFichasAsignadasPorAcademico(parametro,callback){
    this._MapRoute.post(
      `${this.url}/ListarFichasAsignadasPorAcademico`,
      parametro,
      callback
    );

  }
  ObtenerPorId(parametro,callback){
    this._MapRoute.post(
      `${this.url}/ObtenerPorId`,
      parametro,
      callback
    );
  }

  
  
  DesactivarSolicitudFicha(parametro,callback){
    var model={
      idResumenFicha:parametro.idResumenFicha
    }
    this._MapRoute.post(
      `${this.url}/DesactivarSolicitudFicha`,
      model,
      callback
    );
  }
}
