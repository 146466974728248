<div class="row justify-content-between">

    <div class="col-md-6">
        <!-- Title section -->
        <h3>Reporte de Producción de fichas</h3>
        <!-- End Title section -->
    </div>

</div>

<div class="card">
    <!-- Filters -->

    <form class="card form-signin p-4 col-md-12" onkeydown="return event.key != 'Enter';" [formGroup]="_FORMFICHA"
        novalidate id="_FORMFICHA">
        <div class="row justify-content-between m-0 ">

            <div class="row col-md-12">

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="cboCampus_filtro">Periodo</label>
                        <angular2-multiselect [data]="_dropdownListPeriodo" formControlName="_selectedItemsPeriodo"
                            [(ngModel)]="_selectedItemsPeriodo" [settings]="_dropdownSettingSinBuscar">
                        </angular2-multiselect>
                    </div>
                </div>
                <div class="col-md-4">

                    <div class="form-group" data-for="cboCampus_filtro">
                        <label for="cboCampus_filtro">Campus</label>
                        <angular2-multiselect [data]="_dropdownListCampus" formControlName="_selectedItemsCampus"
                            [(ngModel)]="_selectedItemsCampus" [settings]="_dropdownSettings">
                        </angular2-multiselect>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" data-for="cboCampus_filtro">
                        <label for="cboCampus_filtro">Carrera</label>
                        <angular2-multiselect [data]="_dropdownListCarrera" formControlName="_selectedItemsCarrera"
                            [(ngModel)]="_selectedItemsCarrera" [settings]="_dropdownSettings">
                        </angular2-multiselect>
                    </div>
                </div>
            </div>


            <div class="row col-md-12">


                <div class="col-md-4">
                    <div class="form-group" data-for="_selectedItemsAcademico">
                        <label for="_selectedItemsAcademico">Coordinador</label>
                        <angular2-multiselect [data]="_dropdownListAcademico" formControlName="_selectedItemsAcademico"
                            [(ngModel)]="_selectedItemsAcademico" [settings]="_dropdownSettings">
                        </angular2-multiselect>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" data-for="_selectedItemsColor">
                        <label for="_selectedItemsColor">Color</label>
                        <angular2-multiselect [data]="_dropdownListColor" (onSelect)="ngItemSelect_color($event)"
                            (onDeSelect)="ngItemDeSelect_color($event)" (onSelectAll)="ngSelectAll_color($event)"
                            (onDeSelectAll)="ngDeSelectAll_color($event)" formControlName="_selectedItemsColor"
                            [(ngModel)]="_selectedItemsColor" [settings]="_dropdownSettingSinBuscar"
                            (ngModelChange)="ngChange_selectedItemsColor($event)">
                        </angular2-multiselect>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group _selectedItemsColumna" style="display: none;">
                        <label for="cboCarrera_filtro">Columnas</label>
                        <angular2-multiselect [data]="_dropdownListColumna" formControlName="_selectedItemsColumna"
                            [(ngModel)]="_selectedItemsColumna" [settings]="_dropdownSettingColumna">
                        </angular2-multiselect>
                        <div *ngIf="_FORMFICHA.controls['_selectedItemsColumna'].invalid && (_FORMFICHA.controls['_selectedItemsColumna'].dirty || _FORMFICHA.controls['_selectedItemsColumna'].touched)"
                            class="alert alert-danger">
                            <div *ngIf="_FORMFICHA.controls['_selectedItemsColumna'].errors.required"
                                class="white-space">*
                                Seleccione una columna</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row  justify-content-between m-0">
            <div class="col-md-3 ">
                <button class="btn btn-buscar" type="button" id="btnBuscarSolicitud"
                    (click)="ngClick_BuscarDatos($event)">Buscar<i class="material-icons">search</i></button>
                <button class="btn btn-buscar" type="button" id="btnBuscarSolicitud"
                    (click)="ngClick_DescargarArchivo($event)">Descargar<i
                        class="material-icons">cloud_download</i></button>
            </div>

        </div>
    </form>

    <!-- End Filters -->
    <!-- Table -->
    <div class="mx-3">
        <table class="table" id="tblReporte">
            <thead>
                <tr>

                    <th scope="col">Periodo Academico</th>
                    <th scope="col">Campus</th>
                    <th scope="col">Carrera</th>
                    <th scope="col">Coordinador Académico</th>

                    <th scope="col">Alumnos matriculados</th>
                    <th scope="col">Fichas proyectadas</th>
                    <th scope="col">Fichas solicitadas</th>
                    <th scope="col">Fichas ord.(Aprob.) </th>
                    <th scope="col">Fichas extr.(Aprob.) </th>
                    <th scope="col">Fichas proy.(Aprob.) </th>
                    <th scope="col">Total carrera </th>

                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
    <!-- End Table -->
</div>