<div class="row justify-content-between">
    <div class="col-md-6">
        <!-- Title section -->
        <h3>Solicitud de fichas</h3>
        <i class="material-icons" id="solicitudficha_btnAgregarSolicitud">add_circle</i>
        <i class="material-icons" data-toggle="modal" data-target="#myModal" style="display:none">add_circle</i>
        <!-- End Title section -->
    </div>
    <!-- Section resume -->
    <div class="col-md-6 row">
        <div class="col-md-3">
            <div>
                <div><span>Solicitadas</span></div>
                <div><span>{{resumen.solicitadas}}</span></div>
            </div>
        </div>
        <div class="col-md-3">
            <div>
                <div><span>Asignadas</span></div>
                <div><span>{{resumen.asignadas}}</span></div>
            </div>
        </div>
        <div class="col-md-3">
            <div>
                <div><span>Aprobadas</span></div>
                <div><span>{{resumen.aprobado}}</span></div>
            </div>
        </div>
        <div class="col-md-3">
            <div>
                <div><span>Pendientes</span></div>
                <div><span>{{resumen.pendientes}}</span></div>
            </div>
        </div>
    </div>
    <!-- End Section resume -->
</div>

<!-- Content section -->
<div class="card">
    <!-- Filters -->
    <h6>Filtros</h6>
    <div class="row justify-content-between m-0">
        <div class="col-md-10">
            <div class="row">
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="cboPeriodo">Periodo</label>
                        <select id="cboPeriodo" class="form-control" [(ngModel)]="model.idPeriodo"
                          ></select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="cboDocente">Docente</label>
                        <select class="form-control" id="cboDocente">>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="cboCarrera">Carrera</label>
                        <select class="form-control" id="cboCarrera">>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <button class="btn btn-buscar" type="button" id="btnBuscarSolicitud" (click)="onClickListar()">Buscar<i
                    class="material-icons">search</i></button>
        </div>
    </div>
    <!-- End Filters -->
    <!-- Table -->
    <div class="mx-3">
        <table class="table" id="tblListarSolicitudFicha">
            <thead>
                <tr>
                    <th scope="col">Periodo</th>
                    <th scope="col">Campus</th>
                    <th scope="col">Carrera</th>
                    <th scope="col">Docente</th>
                    <th scope="col">Solicitadas</th>
                    <th scope="col">Aprobadas</th>
                    <th scope="col">Pendientes</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Opciones</th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
    <!-- End Table -->
</div>
<!-- End Content section -->


<app-solicitudficha-agregar (onClickListar)="onClickListar()" (obtenerResumen)="obtenerResumen($event)">
</app-solicitudficha-agregar>