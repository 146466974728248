import { Component, OnInit } from '@angular/core';
import { ReporteService } from '../_servicio/reporte.service';
import { ReporteProduccionFichasFiltroBE } from '../model/reporte/ReporteProduccionFichasFiltroBE';
import { ReporteListarProduccionFichasBE } from '../model/reporte/ReporteListarProduccionFichasBE';
import { utilitarios } from 'src/global/utilitarios';
import { ComboboxService } from "../_servicioComponentes/combobox.service";
import { Session } from 'src/global/session';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReporteSeccionesFichasFiltroBE } from '../model/reporte/ReporteSeccionesFichasFiltroBE';
import { ReporteSeccionesFichasBE } from '../model/reporte/ReporteSeccionesFichasBE';
import { ArchivoBaseBE } from '../model/reporte/ArchivoBaseBE';

@Component({
  selector: 'app-reporteseccionesfichas',
  templateUrl: './reporteseccionesfichas.component.html',
  styleUrls: ['./reporteseccionesfichas.component.scss']
})
export class reporteseccionesfichasComponent implements OnInit {

  _utilitarios: utilitarios = new utilitarios();
  _dropdownSettings: any = {};
  _dropdownSettingColumna: any = {};
  _dropdownSettingSinBuscar: any = {};

  _dropdownListPeriodo = [];
  _selectedItemsPeriodo = [];

  _dropdownListGrado = [];
  _selectedItemsGrado = [];

  _dropdownListCampus = [];
  _selectedItemsCampus = [];

  _dropdownListSeccion = [];
  _selectedItemsSeccion = [];

  _dropdownListCurso = [];
  _selectedItemsCurso = [];


  _dropdownListColor = [];
  _selectedItemsColor = [];

  _dropdownListColumna = [];
  _selectedItemsColumna = [];


  _FORMFICHA: FormGroup;

  public _BUILDER: any = {
    _selectedItemsPeriodo: ["", ""],
    _selectedItemsGrado: ["", ""],
    _selectedItemsCampus: ["", ""],
    _selectedItemsSeccion: ["", ""],
    _selectedItemsCurso: ["", ""],

    _selectedItemsColor: ["", ""],
    _selectedItemsColumna: ["", Validators.required],
  };
  constructor(
    private _ReporteService: ReporteService,
    private _clscombobox: ComboboxService,
    private formBuilder: FormBuilder,
    private _Session: Session) {
    this._FORMFICHA = formBuilder.group(this._BUILDER);

  }

  ngOnInit(): void {
    this.onClearSelectAll();
    $('[class="container-fluid pb-5"]').removeAttr('style')

    Promise.all([this.comboPeriodo(), this.comboGrado(), this.comboboCampus(), this.comboSeccion()
      , this.comboCurso(), this.comboColor(),
    this.comboColumnas()
    ]).then(() => {
      this._dropdownSettings = {
        singleSelection: false,
        text: "Todos",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Deseleccionar todos",
        enableSearchFilter: true,
        badgeShowLimit: 14,
        classes: "multipleRepositorio custom-class ",
        searchPlaceholderText: "Buscar",

      };
      this._dropdownSettingColumna = {
        singleSelection: false,
        text: "Seleccione",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Deseleccionar todos",
        enableSearchFilter: false,
        classes: "multipleRepositorio custom-class ",

      };
      this._dropdownSettingSinBuscar = {
        singleSelection: false,
        text: "Todos",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Deseleccionar todos",
        enableSearchFilter: false,
        classes: "multipleRepositorio custom-class ",

      };
     // this.dibujarGrilla();

    })

  }
  ngClick_BuscarDatos(event) {


    this.dibujarGrilla();


  }

  ngChange_selectedItemsColor(event) {


  }
  onClearSelectAll() {
    setInterval((item) => {
      $(".multipleRepositorio span.clear-all").each((index, element) => {
        var parent = $(element).parents(".multipleRepositorio");
        if ($(element).find(".repositorio").length == 0) {
          parent
            .find("span.clear-all")
            .html('<span class="repositorio">x</span>');
          $(element)
            .find(".repositorio")
            .click((event) => {
              this._FORMFICHA.controls[parent.attr("formcontrolname")].setValue(
                []
              );
              if (parent.attr('formcontrolname') == '_selectedItemsColor') {
                this._selectedItemsColor = [];
                this._FORMFICHA.controls["_selectedItemsColumna"].reset();
                $('._selectedItemsColumna').fadeOut();
              }
            });
        }
      });
    }, 1);
  }

  ngItemSelect_color(item: any) {
    console.log(':: selected ::')
    console.log(this._selectedItemsColor)
    if (this._selectedItemsColor.length > 0) {
      $('._selectedItemsColumna').fadeIn();
    } else {
      this._selectedItemsColor = [];
      this._FORMFICHA.controls["_selectedItemsColumna"].reset();
      $('._selectedItemsColumna').fadeOut();
    }
  }
  ngItemDeSelect_color(item: any) {
    console.log(':: deselected ::')
    console.log(this._selectedItemsColor)
    if (this._selectedItemsColor.length > 0) {
      $('._selectedItemsColumna').fadeIn();
    } else {
      this._selectedItemsColor = [];
      this._FORMFICHA.controls["_selectedItemsColumna"].reset();
      $('._selectedItemsColumna').fadeOut();
    }
  }
  ngSelectAll_color(items: any) {
    console.log(':: SelectAll ::')
    console.log(this._selectedItemsColor)
    if (items.length > 0) {
      $('._selectedItemsColumna').fadeIn();
    } else {
      this._selectedItemsColor = [];
      this._FORMFICHA.controls["_selectedItemsColumna"].reset();
      $('._selectedItemsColumna').fadeOut();
    }
  }
  ngDeSelectAll_color(items: any) {
    console.log(':: DeSelectAll ::')
    console.log(this._selectedItemsColor)
    this._selectedItemsColor = [];
    $('._selectedItemsColumna').fadeOut();
    this._FORMFICHA.controls["_selectedItemsColumna"].reset();
  }

  ngClick_DescargarArchivo(event) {
      
      this._ReporteService.DescargarArchivoExcelReporteSecciones(this.parametrosFiltros(), (data: ArchivoBaseBE) => {
        this._utilitarios.descargarArchivo(data.archivoBase64, data.nombreArchivo, null)
      })
   
  }
  //===============================================================================================
  //-- Privado
  //===============================================================================================

  comboPeriodo() {
    return this._clscombobox.getJson(
      {
        entidad: "mPeriodo"
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListPeriodo.push({
            id: parseInt(item.id),
            itemName: item.nombre,
          });

          //this._selectedItemsPeriodo = this._dropdownListPeriodo;
        });
      }
    );
  }
  comboGrado() {
    return this._clscombobox.getJson(
      {
        entidad: "seccionGrados"
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListGrado.push({
            id: item.id,
            itemName: item.nombre,
          });


        });
      }
    );
  }


  comboboCampus() {
    return this._clscombobox.getJson(
      {
        entidad: "mCampusPorPersona",
        parametro: { "@p_idPersona": this._Session.usuario.persona.idPersona },
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListCampus.push({
            id: parseInt(item.id),
            itemName: item.nombre,
          });

          // this._selectedItemsCampus = this._dropdownListCampus;
        });

      }
    );
  }

  comboSeccion() {

    return this._clscombobox.getJson(
      {
        entidad: "ReporteSeccion"
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListSeccion.push({
            id: item.id,
            itemName: item.nombre,
          });
          // this._selectedItemsCampus = this._dropdownListCampus;
        });

      }
    );
  }

  comboCurso() {

    return this._clscombobox.getJson(
      {
        entidad: "mCurso"
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListCurso.push({
            id: item.id,
            itemName: item.nombre,
          });
          // this._selectedItemsCampus = this._dropdownListCampus;
        });

      }
    );
  }

  comboColor() {

    this._clscombobox.getJson(
      {
        entidad: "mColores",

      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListColor.push({
            id: item.id,
            itemName: item.nombre,
          });

          // this._selectedItemsAcademico = this._dropdownListAcademico;
        });
      }
    );

  }

  comboColumnas() {
    this._clscombobox.getJson(
      {
        entidad: "ListarProduccionFichas_columnas",
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListColumna.push({
            id: item.id,
            itemName: item.nombre,
          });
          // this._selectedItemsAcademico = this._dropdownListAcademico;
        });
      }
    );
  }

  parametrosFiltros(){
    let parametro: ReporteSeccionesFichasFiltroBE = {} as ReporteSeccionesFichasFiltroBE;
    parametro.idPeriodoArray = "";
    parametro.idGradoArray = "";
    parametro.idCampusArray = "";
    parametro.idSeccionArray = "";
    parametro.idCursoArray = "";
    parametro.colorArray = "";
    if ((this._FORMFICHA.controls["_selectedItemsPeriodo"].value != null
      && this._FORMFICHA.controls["_selectedItemsPeriodo"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsPeriodo"].value.forEach(element => {
        parametro.idPeriodoArray += element.id + ','
      });
    }

    if ((this._FORMFICHA.controls["_selectedItemsGrado"].value != null
      && this._FORMFICHA.controls["_selectedItemsGrado"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsGrado"].value.forEach(element => {
        parametro.idGradoArray += element.id + ','
      });
    }


    if ((this._FORMFICHA.controls["_selectedItemsCampus"].value != null
      && this._FORMFICHA.controls["_selectedItemsCampus"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsCampus"].value.forEach(element => {
        parametro.idCampusArray += element.id + ','
      });
    }

    if ((this._FORMFICHA.controls["_selectedItemsSeccion"].value != null
      && this._FORMFICHA.controls["_selectedItemsSeccion"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsSeccion"].value.forEach(element => {
        parametro.idSeccionArray += element.id + ','
      });
    }


    if ((this._FORMFICHA.controls["_selectedItemsCurso"].value != null
      && this._FORMFICHA.controls["_selectedItemsCurso"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsCurso"].value.forEach(element => {
        parametro.idCursoArray += element.id + ','
      });
    }
    if ((this._FORMFICHA.controls["_selectedItemsColor"].value != null
      && this._FORMFICHA.controls["_selectedItemsColor"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsColor"].value.forEach(element => {
        parametro.colorArray += element.id + ','
      });
    }
    return parametro;
  }
  dibujarGrilla() {
    var that = this;

  
    this._ReporteService.ListarSeccionesFichas(
      this.parametrosFiltros(),
      (data: ReporteSeccionesFichasBE[]) => {

        var table = $("#tblReporte");
        this._utilitarios.Datatable(table, {
          "columnDefs": [{targets: '_all', width: '16.666667%'}],
          columns: [

            {
              data: "periodo", class: "small_col text-left",
              render: (index, display, rowdata: ReporteSeccionesFichasBE) => {

                return "<span class='pl-3' style='font-size: 0.8rem;'>"+rowdata.periodo+"</span>"

              }
            },
            { data: "grado" , class: "text-left"},
            { data: "nombreCampus", class: "text-left" },
            { data: "classNbr" , class: "text-center"  },
            { data: "curso"  },
            { data: "codigoDocente1",class: "text-left"   },
            { data: "docente1",class: "text-left"  },
            { data: "codigoDocente2" ,class: "text-left"  },
            { data: "docente2" ,class: "text-left"     },
            { data: "nombreCarrera",class: "text-left"    },
            { data: "cantidadMatriculados", class:'text-center'  },
            { data: "fichasRequeridas", class:'text-center' },
            {
              data: "cantidadRepartida",
              class:'text-center' ,
              render: (index, display, rowdata: ReporteSeccionesFichasBE) => {
                if (rowdata.cantidadRepartida != null) {

                  return '<span class="p-1"  style="background:' + rowdata.cantidadRepartidaColor + '">' + rowdata.cantidadRepartida + '</span>'
                } else {
                  return "";
                }

              }
            },

            { data: "cantidadAsignada",class:'text-center' },
            { data: "cantidadReservada",class:'text-center' },
            { data: "fichasDisponibles",class:'text-center' },
            { data: "cantidadAlumnosAsignadas",class:'text-center' },
            { data: "cantidadAlumnosRerservados" ,class:'text-center'},
            { data: "cantidadAlumnosObservados" ,class:'text-center'},
            { data: "estado" },

          ],
          data: data,
          fnDrawCallback: () => {



          }
        });
      }
    );
  }

}
