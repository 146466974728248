import { Injectable } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { MapRoute } from "../../global/maproute";

@Injectable({
  providedIn: "root"
})
export class BancoService {
  private _MapRoute: MapRoute = null;
  private urlUsuario: string = "";
  constructor(private http: HttpClient) {
    this._MapRoute = new MapRoute(http);
    this.urlUsuario = this._MapRoute.tibaURL + "banco";
  }
  RegistrarActualizar(parametro,callback){

    this._MapRoute.post(
      `${this.urlUsuario}/RegistrarActualizar`,
      parametro,
      callback
    );
  }
  AdjuntarDocumentoFicha(parametro,callback){

    this._MapRoute.post(
      `${this.urlUsuario}/AdjuntarDocumentoFicha`,
      parametro,
      callback
    );
  }

  ActualizarTitulo(parametro,callback){
    this._MapRoute.post(
      `${this.urlUsuario}/ActualizarTituloDocumento`,
      parametro,
      callback
    );
  }

  ObtenerBancoFichaDocumento(parametro,callback){
    this._MapRoute.post(
      `${this.urlUsuario}/ObtenerBancoFichaDocumento`,
      parametro,
      callback
    );
  }

  
  EliminarDocumentoBancoFicha(parametro,callback){
    this._MapRoute.post(
      `${this.urlUsuario}/EliminarDocumentoBancoFicha`,
      parametro,
      callback
    );
  }
  EliminarFichaAsociadaAlumno(parametro, callback) {
    this._MapRoute.post(
      `${this.urlUsuario}/EliminarFichaAsociadaAlumno`,
      parametro,
      callback
    );
  }

  AsociarAlumnoBanco(parametro, callback) {
    this._MapRoute.post(
      `${this.urlUsuario}/AsociarAlumnoBanco`,
      parametro,
      callback
    );
  }
  AsignarFichaAlumnos(parametro, callback) {
    this._MapRoute.post(
      `${this.urlUsuario}/AsignarFichaAlumnos`,
      parametro,
      callback
    );
  }

  RevertirFichaAlumnos(parametro, callback) {
    this._MapRoute.post(
      `${this.urlUsuario}/RevertirFichaAlumnos`,
      parametro,
      callback
    );
  }
  RegistrarActualizarBancoAlias(parametro, callback) {
    this._MapRoute.post(
      `${this.urlUsuario}/RegistrarActualizarBancoAlias`,
      parametro,
      callback
    );
  }

  LimpiarRetirado(parametro, callback) {
    console.log('calling...',`${this.urlUsuario}/LimpiarRetirado`)
    this._MapRoute.post(
      `${this.urlUsuario}/LimpiarRetirado`,
      parametro,
      callback
    );
  }

  ListarRetirado(parametro, callback) {
    console.log('calling...',`${this.urlUsuario}/AlumnoRetirado`)
    this._MapRoute.post(
      `${this.urlUsuario}/AlumnoRetirado`,
      parametro,
      callback
    );
  }
 
}
