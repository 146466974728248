import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';


//#region Login
import { LoginComponent } from './login/login.component';
//#endregion Login

//#region Resumenficha
import { ResumenfichaListarComponent } from './resumenficha/resumenficha-listar/resumenficha-listar.component';
//#endregion Resumenficha

//#region Resumenficha
import { SolicitudfichaListarComponent } from './solicitudficha/solicitudficha-listar/solicitudficha-listar.component';
//#endregion Resumenficha

//#region Resumenficha
import { ProyeccionComponent } from './proyeccion/proyeccion.component';
//#endregion Resumenficha

//#region Resumenficha
import { FichaaprobadaComponent } from './fichaaprobada/fichaaprobada.component';
//#endregion Resumenficha

//#region ficha
import { FichaListarComponent } from './ficha/ficha-listar/ficha-listar.component';
//#endregion ficha

//#region FichasasignadasComponent 
import { FichasasignadasComponent } from './fichasasignadas/fichasasignadas.component';
//#nedregion FichasasignadasComponent

//#region AsignarfichaComponent 
import { AsignarfichaComponent } from './asignarficha/asignarficha.component';
//#nedregion AsignarfichaComponent

//#region AsignarfichaComponent 
import { SeccionesasignadasComponent } from './seccionesasignadas/seccionesasignadas.component';
//#nedregion AsignarfichaComponent

//#region AsignarfichaComponent 
import { GestionarusuarioComponent } from './gestionarusuario/gestionarusuario.component';
//#nedregion AsignarfichaComponent

//#region AsignarfichaComponent 
import { ReporteproduccionfichasComponent } from './reporteproduccionfichas/reporteproduccionfichas.component';
import { reporteseccionesfichasComponent } from './reporteseccionesfichas/reporteseccionesfichas.component';
import { ReportefinalproyectosinvestigacionComponent } from './reportefinalproyectosinvestigacion/reportefinalproyectosinvestigacion.component';
import { ReportefinaltrabajosinvestigacionComponent } from './reportefinaltrabajosinvestigacion/reportefinaltrabajosinvestigacion.component';
import { ReportedocumentosoficialesComponent } from './reportedocumentosoficiales/reportedocumentosoficiales.component';
//#nedregion AsignarfichaComponent
//#region Mantenimineto
import {MantenimientocompetenciaComponent} from './mantenimientocompetencia/mantenimientocompetencia.component';
import {MantenimientolineainvestigacionComponent} from './mantenimientolineainvestigacion/mantenimientolineainvestigacion.component';
//#nedregion Mantenimiento
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'resumenficha',
    component: ResumenfichaListarComponent
  },
  {
    path: 'solicitudficha',
    component: SolicitudfichaListarComponent
  },
  {
    path: 'ficha',
    component: FichaListarComponent
  },
  {
    path: 'proyeccion',
    component: ProyeccionComponent
  },
  {
    path: 'fichaaprobada',
    component: FichaaprobadaComponent
  },
  {
    path: 'fichasAsignadas',
    component: FichasasignadasComponent
  },
  {
    path: 'asignarFicha',
    component: AsignarfichaComponent
  },
  {
    path: 'seccionesasignadas',
    component: SeccionesasignadasComponent
  },
  {
    path: 'gestionarusuario',
    component: GestionarusuarioComponent
  },
  {
    path: 'mantenimientocompetencia',
    component: MantenimientocompetenciaComponent
  },
  {
    path: 'mantenimientolineainvestigacion',
    component: MantenimientolineainvestigacionComponent
  },
  {
    path: 'reporteProduccionFichas',
    component: ReporteproduccionfichasComponent
  },
  {
    path: 'reporteSeccionesFichas',
    component: reporteseccionesfichasComponent
  },
  {
    path: 'reportefinalproyectosinvestigacion',
    component: ReportefinalproyectosinvestigacionComponent
  },
  {
    path: 'reportefinaltrabajosinvestigacion',
    component: ReportefinaltrabajosinvestigacionComponent
  },
  {
    path: 'reportedocumentosoficiales',
    component: ReportedocumentosoficialesComponent
  },

];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
