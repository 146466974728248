import {
  Component,
  OnInit,
  AfterViewInit,
  Injectable,
  Output,
  Input,
  EventEmitter,
  ElementRef,
  ViewChild,
  ɵSWITCH_TEMPLATE_REF_FACTORY__POST_R3__,
  ɵConsole
} from "@angular/core";
import {
  FormGroup,
  NgForm,
  NgModelGroup,
  FormBuilder,
  Validators
} from "@angular/forms";
import { JsonPipe } from "@angular/common";
import * as $ from "jquery";
import { strictEqual } from "assert";
import { Alert } from "selenium-webdriver";
import Swal from "sweetalert2";
//---------------------------------------------------------------------
//-- Clases Locales
//---------------------------------------------------------------------
import { ProyeccionService } from "../_servicio/proyeccion.service";
import { ResumenFichaService } from "../_servicio/resumenFicha.service";
import { ComboboxService } from "../_servicioComponentes/combobox.service";
import { Session } from "../../global/session";
import { utilitarios } from "../../global/utilitarios";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { ArchivoService } from "../_servicio/archivo.service";

@Component({
  selector: "app-proyeccion",
  templateUrl: "./proyeccion.component.html",
  styleUrls: ["./proyeccion.component.scss"]
})

//---------------------------------------------------------------------
//-- Clases Raiz Eventos
//---------------------------------------------------------------------
export class ProyeccionComponent implements OnInit {
  nombreCarrera: string;
  _private: clsPrivate = null;
  constructor(
    private combobox: ComboboxService,
    private _Session: Session,
    private _ProyeccionService: ProyeccionService,
    private _ArchivoService: ArchivoService,
    private _utilitarios: utilitarios
  ) {
    this._private = new clsPrivate(
      this.combobox,
      this._ProyeccionService,
      this._ArchivoService,
      this._utilitarios,
      this._Session,
      this.nombreCarrera
    );
  }

  ngOnInit() {
    this._private.mpPeriodoVigente();
    this._private.mpVerificarCargo();
    this._private.mpCampus();
    this._private.mpDibujarGrillaListaProyeccion(
      $("#idPeriodo").attr("data-id"),
      0,
      0,
      call => {
        this._private.mpHabilitarOpcion();
      }
    );
    this._private.mpDibujarHistoriaCarga($("#idPeriodo").attr("data-id"));
  }

  onChangeCampus(event) {
    this.combobox.Clear("cboCarrera");
    this._private.mpCarrera($(event.target).val());
  }

  onChangeArchivo(event) {
    var file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      event.target.value = "";
      this._private.mpRegistrar(
        {
          idPeriodo: $("#idPeriodo").attr("data-id"),
          nombreArchivo: file.name,
          idPersonaRegistra: this._Session.usuario.persona.idPersona,
          archivoProyeccion: reader.result.toString().split(",")[1]
        },
        resp => {
          if (resp != null) {
            Swal.fire({
              html: '<div style="text-align:left">' + resp.mensaje + "</div>",
              showConfirmButton: true,
              allowOutsideClick: false
            });
          } else {
            this._private.mpDibujarGrillaListaProyeccion(
              $("#idPeriodo").attr("data-id"),
              $("#cboCampus").val() == "" ? 0 : $("#cboCampus").val(),
              $("#cboCarrera").val() == "" ? 0 : $("#cboCarrera").val()
            );
            this._private.mpDibujarHistoriaCarga(
              $("#idPeriodo").attr("data-id")
            );
          }
        }
      );
    };
  }

  onClickSubirArchivo(event) {
    if (
      $("#tblHistoricoProyeccion")
        .DataTable()
        .rows()
        .count() > 1
    ) {
      Swal.fire({
        title: "¿Está seguro en reemplazar los datos cargados anteriormente?",
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Si",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          $("#fileArchivo").trigger("click");
        }
      });
    } else $("#fileArchivo").trigger("click");
  }

  onClickBuscar(event) {
    this._private.mpDibujarGrillaListaProyeccion(
      $("#idPeriodo").attr("data-id"),
      $("#cboCampus").val() == "" ? 0 : $("#cboCampus").val(),
      $("#cboCarrera").val() == "" ? 0 : $("#cboCarrera").val(),
      res => {
        this._private.mpVerificarCargo();
        this._private.mpHabilitarOpcion();
      }
    );
  }

  onClickEnviar(event) {
    var tbltblListarProyeccion: any = $("#tblListarProyeccion");

    var parametroProyeccionEnvioBE = {
      idPersona: this._Session.usuario.persona.idPersona,
      idPeriodo: $("#idPeriodo").attr("data-id"),
      proyeccionDetalle: []
    };
    /*    tbltblListarProyeccion
      .DataTable()
      .rows()
      .every(function(rowIdx, tableLoop, rowLoop) {
        var rowData = this.data();
        parametroProyeccionEnvioBE.proyeccionDetalle.push(rowData);
      });
*/
    this._private.mpEnviarCargaProyeccion(parametroProyeccionEnvioBE, res => {
      this._private.mpDibujarGrillaListaProyeccion(
        $("#idPeriodo").attr("data-id"),
        $("#cboCampus").val() == "" ? 0 : $("#cboCampus").val(),
        $("#cboCarrera").val() == "" ? 0 : $("#cboCarrera").val()
      );
    });
  }

  onClickAprobar(event) {
    this._private.AprobarRechazarCargarProyeccion(
      {
        idPeriodo: $("#idPeriodo").attr("data-id"),
        idPersona: this._Session.usuario.persona.idPersona,
        esRechazado: false
      },
      res => {
        this._private.mpDibujarGrillaListaProyeccion(
          $("#idPeriodo").attr("data-id"),
          $("#cboCampus").val() == "" ? 0 : $("#cboCampus").val(),
          $("#cboCarrera").val() == "" ? 0 : $("#cboCarrera").val(),
          call => {
            $("#opcionesAcademico").hide();
          }
        );
      }
    );
  }
  onClickRechazar(event) {
    this._private.AprobarRechazarCargarProyeccion(
      {
        idPeriodo: $("#idPeriodo").attr("data-id"),
        idPersona: this._Session.usuario.persona.idPersona,
        esRechazado: true
      },
      res => {
        this._private.mpDibujarGrillaListaProyeccion(
          $("#idPeriodo").attr("data-id"),
          $("#cboCampus").val() == "" ? 0 : $("#cboCampus").val(),
          $("#cboCarrera").val() == "" ? 0 : $("#cboCarrera").val(),
          call => {
            $("#opcionesAcademico").hide();
          }
        );
      }
    );
  }
  onClickCerrarModal(event) {
    this._private.trFilaSeleccionada = null;
    this._utilitarios.CerrarModal("myModal3");
  }
  onClickActualizarFactor(event) {
    var tbltblListarProyeccion: any = $("#tblListarProyeccion");
    var rowData = tbltblListarProyeccion
      .DataTable()
      .row(this._private.trFilaSeleccionada)
      .data();

    this._ProyeccionService.ActualizarFactorProyeccion(
      {
        esTodoCampus: $("#chkFactor").is(":checked") ? true : false,
        factor: $('[name="idFactor"]:checked').val(),
        idPersonaRegistra: this._private.idPersona,
        idPeriodo: $("#idPeriodo").attr("data-id"),
        idProyeccionDetalle: rowData.idProyeccionDetalle,
        idCarrera: rowData.idCarrera,
        idProyeccion: rowData.idProyeccion
      },
      res => {
        this._private.mpDibujarGrillaListaProyeccion(
          $("#idPeriodo").attr("data-id"),
          $("#cboCampus").val() == "" ? 0 : $("#cboCampus").val(),
          $("#cboCarrera").val() == "" ? 0 : $("#cboCarrera").val()
        );
        this._private.trFilaSeleccionada = null;
        this._utilitarios.CerrarModal("myModal3");
      }
    );
  }
}
//-- End Clases

//---------------------------------------------------------------------
//-- Clases Private
//---------------------------------------------------------------------
class clsPrivate {
  constructor(
    private combobox: ComboboxService,
    private _ProyeccionService: ProyeccionService,
    private _ArchivoService: ArchivoService,
    private _utilitarios: utilitarios,
    private _Session: Session,
    private nombreCarrera
  ) {}

  totalArchivosSubidos: number = 0;
  idPersona: number = this._Session.usuario.persona.idPersona;
  trFilaSeleccionada = null;
  _esAcademico: Boolean = false;
  _esInvestigacion: Boolean = false;
  mpVerificarCargo() {
    var cargos = this._Session.usuario.persona.cargos;
    let esAcademico: Boolean = false;
    let esInvestigacion: Boolean = false;

    for (var i = 0; cargos.length; i++) {
      var element = cargos[i];
      if (element.codigo == this._utilitarios.enumCargo.CoordinadorAcademico) {
        esAcademico = true;
        break;
      }
      if (
        element.codigo == this._utilitarios.enumCargo.CoordinadorInvestigacion
      ) {
        esInvestigacion = true;
        break;
      }
    }

    this._esAcademico = esAcademico;
    this._esInvestigacion = esInvestigacion;
    //this.idPersona = this._esAcademico
    // ? this._Session.usuario.persona.idPersona
    //: 0;
    this.idPersona = this._Session.usuario.persona.idPersona;
  }
  mpHabilitarOpcion() {
    var esPermiteCargarArchivo: boolean = false;
    this._Session.Opciones.forEach(element => {
      if (element.comando == this._utilitarios.enumOpciones.CargaProyeccion) {
        esPermiteCargarArchivo = true;
      }
    });

    if (this._esAcademico) {
      $("#myModalAgregar").hide();
      $("#btnHistorialCarga").hide();
      $('[data-for="chkFactor"]').hide();
      let tblListarProyeccion: any = $("#tblListarProyeccion");
      if (
        tblListarProyeccion
          .DataTable()
          .rows()
          .count() > 0
      ) {
        let rowData = tblListarProyeccion
          .DataTable()
          .row(0)
          .data();

        if (rowData.codigoEstado != this._utilitarios.enumEstado.Aprobadas) {
          $("#opcionesAcademico").show();
        }
      }
    } else if (this._esInvestigacion) {
      $("#opcionesInvestigacion").show();
      if (esPermiteCargarArchivo) {
        $('[data-for="chkFactor"]').show();
      } else {
        $('[data-for="chkFactor"]').hide();
        $("#myModalAgregar").hide();
      }
    }
  }
  mpPeriodoVigente() {
    this.combobox.getJson(
      {
        entidad: "mPeriodoVigente"
      },
      respuesta => {
        if (respuesta.length > 0) {
          var item = respuesta[0];
          $("#idPeriodo").val(item.nombre);
          $("#idPeriodo").attr("data-id", item.id);
        }
      }
    );
  }

  mpCampus() {
    if (this._esAcademico) {
      $("#cboCampus").hide();
      $("#cboCarrera").hide();
      $('[data-for="cboCampus"]').hide();
      $('[data-for="cboCarrera"]').hide();
    } else {
      this.combobox.init(
        {
          html: "Todos",
          id: "cboCampus",
          entidad: "mCampusPorPersona",
          parametro: { "@p_idPersona": this.idPersona }
        },
        data => {}
      );
    }
  }

  mpCarrera(idCampus) {
    this.combobox.init(
      {
        html: "Todos",
        id: "cboCarrera",
        entidad: "mCarreraPorPersonaCampus",
        parametro: {
          "@p_idPersona": this.idPersona,
          "@p_idCampus": idCampus == "" ? -1 : idCampus
        }
      },
      data => {}
    );
  }

  mpDibujarHistoriaCarga(idPeriodo) {
    this._ProyeccionService.Listar(
      {
        idPeriodo: idPeriodo
      },
      data => {
        this.totalArchivosSubidos = data.length;
        let exampleId: any = $("#tblHistoricoProyeccion");
        this._utilitarios.Datatable(exampleId, {
          columns: [
            { data: "nombreArchivo", class: "small_col" },
            { data: "fechaCreacion", class: "fecha small_col" },
            { data: "encargado" },
            {
              data: null,
              class: "text-center small_col",
              render: function() {
                var html = `<div class="ui-icon-visibility" data-ver="">
                            <i class="material-icons" >
                            <a href="javascript:void(0)"  >visibility</a>
                            </i>
                          </div>
                          <div class="ui-icon-download" data-download>
                            <i class="material-icons" >
                              <a href="javascript:void(0)">cloud_download</a>
                            </i>
                          </div>`;
                return html;
              }
            },
            { data: "idProyeccion", visible: false }
          ],
          data: data,
          fnDrawCallback: event => {
            $("#tblHistoricoProyeccion")
              .find("[data-ver]")
              .unbind("click");
            $("#tblHistoricoProyeccion")
              .find("[data-ver]")
              .click(trEvent => {
                var index = $(trEvent.target).parents("tr");
                var rowData: any = $("#tblHistoricoProyeccion")
                  .DataTable()
                  .row(index)
                  .data();

                this._utilitarios.OpenModal("modalHistoricoProyeccionDetalle");
                this.mpDibujarGrillaListarProyeccionHistoricoDetalle(
                  rowData.idProyeccion
                );
              });

            $("#tblHistoricoProyeccion")
              .find("[data-download]")
              .unbind("click");
            $("#tblHistoricoProyeccion")
              .find("[data-download]")
              .click(trEvent => {
                var index = $(trEvent.target).parents("tr");
                var rowData: any = $("#tblHistoricoProyeccion")
                  .DataTable()
                  .row(index)
                  .data();
                this._ArchivoService.descargarArchivoExcelProyeccion(
                  { idProyeccion: rowData.idProyeccion },
                  data => {
                    this._utilitarios.descargarArchivo(
                      data.archivoProyeccion,
                      data.nombreArchivo,
                      null
                    );
                  }
                );
              });
          }
        });
      }
    );
  }

  mpDibujarGrillaListaProyeccion(
    idPeriodo,
    idCampus,
    idCarrera,
    callback = null
  ) {
    this._ProyeccionService.ListarProyeccionDetallePorProyeccion(
      {
        idPeriodo: idPeriodo,
        idPersona: this.idPersona,
        idCampus: idCampus,
        idCarrera: idCarrera,
        codigoCargo: this._esAcademico
          ? this._utilitarios.enumCargo.CoordinadorAcademico
          : this._utilitarios.enumCargo.CoordinadorInvestigacion
      },
      data => {
        let exampleId: any = $("#tblListarProyeccion");
        this._utilitarios.Datatable(exampleId, {
          columns: [
            { data: "campus", class: "small_col" },
            { data: "carrera", class: "small_col" },
            { data: "fechaLimite", class: "small_col fecha" },
            {
              data: "alumnosProyectados",
              class: "text-center",
              render: (index, row, data) => {
                var html = `
                <span ${
                  data.esMayorCantidadAnterior
                    ? 'class="alumInc"'
                    : data.esMenorCantidadAnterior
                    ? 'class="alumDesc"'
                    : ""
                } placement="top" 
                data-tooltip-text="Cantidad anterior: ${
                  data.esMayorCantidadAnterior || data.esMenorCantidadAnterior
                    ? data.cantidadAnterior
                    : 0
                }">
                ${data.alumnosProyectados}
                ${
                  data.esMayorCantidadAnterior
                    ? '<i class="material-icons">arrow_upward</i>'
                    : data.esMenorCantidadAnterior
                    ? '<i class="material-icons">arrow_downward</i>'
                    : "<span style='width: 16px; height: 16px; display:inline-block'>"
                }</span>`;

                if (
                  data.esMayorCantidadAnterior == 0 &&
                  data.esMenorCantidadAnterior == 0
                ) {
                  html = data.alumnosProyectados;
                }
                return html;
              }
            },
            {
              data: "factor",
              class: "text-center",
              render: (index, row, data) => {
                var html = data.factor;
                if (
                  data.codigoEstado != this._utilitarios.enumEstado.Aprobadas
                ) {
                  if (
                    !this._esAcademico ||
                    (this._esAcademico &&
                      data.codigoEstado !=
                        this._utilitarios.enumEstado.Rechazado)
                  ) {
                    html += `
                    <div class="ui-icon-edit" data-toggle="modal" data-factor="">
                    <i class="material-icons">
                        <a href="javascript:void(0)" >edit</a>
                    </i>
                </div>`;
                  }
                }

                return html;
              }
            },
            { data: "fichaSolicitar", class: "text-center" },
            { data: "fichasAjustadas", class: "text-center" },
            { data: "banco", class: "text-center" },
            { data: "totalFichas", class: "text-center" },
            { data: "nombreEstado", class: "small_col" },
            { data: "idProyeccionDetalle", visible: false },
            { data: "idProyeccion", visible: false },
            { data: "codigoEstado", visible: false },
            { data: "idCarrera", visible: false },
            {
              data: "esModificado",
              visible: false,
              render: () => {
                return 0;
              }
            }
          ],
          data: data,
          fnDrawCallback: event => {
            if (callback != null) callback();

            $("#tblListarProyeccion")
              .find("tr [data-factor]")
              .unbind("click");
            $("#tblListarProyeccion")
              .find("tr [data-factor]")
              .click(event => {
                this.trFilaSeleccionada = $(event.target).parents("tr");
                this._utilitarios.OpenModal("myModal3");
                let rowData: any = $("#tblListarProyeccion")
                  .DataTable()
                  .row(this.trFilaSeleccionada)
                  .data();

                $("#nombreCarrera").html(
                  " " + rowData.campus + " / " + rowData.carrera
                );
                $('[name="idFactor"]').removeAttr("checked");
                $('[name="idFactor"]')
                  .parent()
                  .removeClass("active");

                $('[name="idFactor"][value="' + rowData.factor + '"]')
                  .attr("checked", "checked")
                  .parent()
                  .addClass("active");

                $("#chkFactor").prop("checked", false);
              });
          }
        });
      }
    );
  }

  mpDibujarGrillaListarProyeccionHistoricoDetalle(idProyeccion) {
    this._ProyeccionService.ListarProyeccionDetallePorArchivo(
      {
        idProyeccion: idProyeccion
      },
      data => {
        let exampleId: any = $("#tblHistoricoProyeccionDetalle");
        this._utilitarios.Datatable(exampleId, {
          columns: [
            { data: "campus", class: "small_col" },
            { data: "carrera", class: "small_col" },
            {
              data: "cantidadProyectada",
              class: "text-center",
              render: (index, row, data) => {
                if (
                  !data.esMayorCantidadAnterior &&
                  !data.esMenorCantidadAnterior
                ) {
                  return  data.cantidadProyectada;
                } else {
                  var html = `
            <span ${
              data.esMayorCantidadAnterior
                ? 'class="alumInc"'
                : data.esMenorCantidadAnterior
                ? 'class="alumDesc"'
                : ""
            } placement="top" 
            data-tooltip-text="Cantidad anterior: ${data.cantidadAnterior}">
            ${data.cantidadProyectada}
            ${
              data.esMayorCantidadAnterior
                ? '<i class="material-icons">arrow_upward</i>'
                : data.esMenorCantidadAnterior
                ? '<i class="material-icons">arrow_downward</i>'
                : "<span style='width: 16px; height: 16px; display:inline-block'>"
            }</span>`;
                  return html;
                }
              }
            }
          ],
          data: data,
          fnDrawCallback: event => {}
        });
      }
    );
  }

  mpRegistrar(parametro, callback) {
    this._ProyeccionService.Registrar(parametro, respuesta => {
      callback(respuesta);
    });
  }

  mpEnviarCargaProyeccion(parametro, callback) {
    this._ProyeccionService.EnviarCargaProyeccion(parametro, respuesta => {
      callback(respuesta);
    });
  }
  AprobarRechazarCargarProyeccion(parametro, callback) {
    this._ProyeccionService.AprobarRechazarCargarProyeccion(
      parametro,
      respuesta => {
        callback(respuesta);
      }
    );
  }
}

//-------------------------- End Clases
