import {
  Component,
  OnInit,
  Injectable,
  Output,
  EventEmitter,
  Input
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { JsonPipe } from "@angular/common";
import * as $ from "jquery";
import { strictEqual } from "assert";
import { Alert } from "selenium-webdriver";
//---------------------------------------------------------------------
//-- Clases Locales
//---------------------------------------------------------------------

import { utilitarios } from "../../../global/utilitarios";
import { ComboboxService } from "../../_servicioComponentes/combobox.service";
import { ResumenFichaService } from "../../_servicio/resumenFicha.service";
import { Session } from "../../../global/session";

//---------------------------------------------------------------------
//-- Component
//---------------------------------------------------------------------

@Component({
  selector: "app-resumenficha-agregar",
  templateUrl: "./resumenficha-agregar.component.html",
  styleUrls: ["./resumenficha-agregar.component.scss"]
})
@Injectable({ providedIn: "root" })
export class ResumenfichaAgregarComponent implements OnInit {
  private _Session: Session = new Session();
  private _tr: object = null;
  validar: boolean = false;
  @Input() parameter: any; //parent to child
  @Output() messageEvent = new EventEmitter<string>();
  @Output() onListarResumenFichas = new EventEmitter(); //child to parent
  @Output() onInitEventoGrilla = new EventEmitter();
  solicitarFichas: string;
  fechaInicial: any = {
    year: 0,
    month: 0,
    day: 0
  };
  model: any = {
    idResumenFicha: 0,
    idCampus: "",
    idPeriodo: "",
    idPersonaCoordinadorAcademico: "",
    idCarrera: "",
    idPersonaRegistra: this._Session.usuario.persona.idPersona,
    cantidad: "",
    fechaLimite: {}
  };
  countries: any;
  constructor(
    private _ResumenFichaService: ResumenFichaService,
    private combobox: ComboboxService,
    private _utilitarios: utilitarios
  ) {}

  ngOnInit() {
    // alert(JSON.stringify(this.parameter))
    this.messageEvent.emit("4545");
    this.FechaLimite();
    this.onEventosTblListar();
    this.onInitCombobox();
    this.onEventosCombobox();
    this.solicitarFichas = "Solicitar fichas";
  }
  ngAfterViewInit() {}
  onEnviarDatos() {
    var año = this.model.fechaLimite.year;
    var mes = this.model.fechaLimite.month;
    var dia = this.model.fechaLimite.day;
    var dateObj = new Date(año, mes - 1, dia);
    var timestamp = dateObj.getTime().toString() + "-0000";

    var modelJson = {
      idCampus: $("#frmAgregar_campus").val(),
      idPeriodo: this.model.idPeriodo,
      idPersonaCoordinadorAcademico: this.model.idPersonaCoordinadorAcademico,
      idCarrera: this.model.idCarrera,
      idPersonaRegistra: this.model.idPersonaRegistra,
      fechaLimite: "/Date(" + timestamp + ")/",
      cantidad: this.model.cantidad,
      idResumenFicha: this.model.idResumenFicha
    };

    this._ResumenFichaService.RegistrarActualizar(modelJson, res => {
      $("#cboPeriodo").val(modelJson.idPeriodo);
      $("#myModal .close").trigger("click");
      this.Limpiar();
      if (modelJson.idResumenFicha > 0) {
        this.onListarResumenFichas.emit();
        // this.ObtenerPorId(modelJson, data => {
        //   var rowData = $("#tblListar")
        //     .DataTable()
        //     .row(this._tr)
        //     .data(data);
        //   $("#tblListar")
        //     .DataTable()
        //     .row(this._tr)
        //     .invalidate();
        // });
      } else {
        this.onListarResumenFichas.emit();
      }
    });
  }

  onEventosTblListar() {
    $(document).off("click", "#tblListar .data-edit");
    $(document).on("click", "#tblListar .data-edit", event => {
      this.solicitarFichas = "Editar solicitud de fichas";
      var tr = $(event.target).parents("tr");
      var rowData = $("#tblListar")
        .DataTable()
        .row(tr)
        .data();
      this._tr = tr;
      this.onObtenerDatosPorId(rowData);
      event.preventDefault();
    });
    $(document).off("click", "#tblListar .data-delete");
    $(document).on("click", "#tblListar .data-delete", event => {
      this._utilitarios.ConfirmWarning({
        text: "¿Estás seguro en eliminar este registro?",
        yesCallback: valor => {
          var tr = $(event.target).parents("tr");
          var rowData = $("#tblListar")
            .DataTable()
            .row(tr)
            .data();
          this._tr = tr;
          this.onDeletePorId(rowData);
        }
      });

      event.preventDefault();
    });
  }
  onObtenerDatosPorId(parametro) {
    this.ObtenerPorId(parametro, data => {
      data.fechaLimite = this._utilitarios.ConvertFechaServidorToJsonDMY(
        data.fechaLimiteServer
      );
      this.model = data;
      this.fechaInicial = data.fechaLimite;
      this.combobox.init(
        {
          id: "frmAgregar_carrera",
          entidad: "mCarreraPorCampus",
          parametro: { "@p_idCampus": this.model.idCampus },
          valorDefault: this.model.idCarrera
        },
        response => {
          $("#frmAgregar_periodo").removeAttr("required");
          $("#frmAgregar_carrera").attr("disabled", "disabled");
          $("#frmAgregar_cordinadorAcademico").attr("disabled", "disabled");
        }
      );
      this.combobox.init(
        {
          id: "frmAgregar_cordinadorAcademico",
          entidad: "mCoordinadorAcademicoPorCarrera",
          parametro: {
            "@p_idCampus": this.model.idCampus,
            "@p_idCarrera": this.model.idCarrera
          },
          valorDefault: this.model.idPersonaCoordinadorAcademico
        },
        null
      );
      $("#myModalAgregar").trigger("click");
    });
  }
  onDeletePorId(parametro) {
    this._ResumenFichaService.DesactivarSolicitudFicha(parametro, data => {
      /*    this.ObtenerPorId(parametro, data => {
        var rowData = $("#tblListar")
          .DataTable()
          .row(this._tr)
          .data(data);
        $("#tblListar")
          .DataTable()
          .row(this._tr)
          .invalidate();
      });
*/
      this.onListarResumenFichas.emit();
    });
  }
  onInitCombobox() {
    let idPersona = this._Session.usuario["persona"].idPersona;
    this.combobox.init(
      {
        id: "frmAgregar_campus",
        entidad: "mCampusCoordinadorInvestigacion",
        parametro: { "@p_idPersona": idPersona }
      },
      data => {
        //   $("#frmAgregar_campus").attr("disabled", "disabled");
        $("#frmAgregar_campus")
          .children("option")
          .removeAttr("selected");
        var element = $("#frmAgregar_campus").children(
          "option:nth-child(0n+2)"
        );
        element.attr("selected", "selected");
        this.model.idCampus = element.val();
        this.combobox.init(
          {
            id: "frmAgregar_carrera",
            entidad: "mCarreraPorCampus",
            parametro: { "@p_idCampus": this.model.idCampus }
          },
          null
        );
        this.combobox.Clear("frmAgregar_cordinadorAcademico");
      }
    );
    this.combobox.init(
      {
        id: "frmAgregar_periodo",
        entidad: "mPeriodoVigente"
      },
      data => {
        $("#frmAgregar_periodo")
          .children("option")
          .removeAttr("selected");
        var element = $("#frmAgregar_periodo").children(
          "option:nth-child(0n+2)"
        );
        element.attr("selected", "selected");
        this.model.idPeriodo = element.val();
      }
    );
  }
  onEventosCombobox() {
    document.getElementById("frmAgregar_campus").onchange = event => {
      var select = event.target as HTMLSelectElement;
      this.combobox.init(
        {
          id: "frmAgregar_carrera",
          entidad: "mCarreraPorCampus",
          parametro: { "@p_idCampus": select.value }
        },
        null
      );
      this.combobox.Clear("frmAgregar_cordinadorAcademico");
    };
    document.getElementById("frmAgregar_carrera").onchange = event => {
      var idCampus = $("#frmAgregar_campus")
        .find("option:selected")
        .val();
      var select = event.target as HTMLSelectElement;
      this.combobox.init(
        {
          id: "frmAgregar_cordinadorAcademico",
          entidad: "mCoordinadorAcademicoPorCarrera",
          parametro: {
            "@p_idCampus": idCampus,
            "@p_idCarrera": select.value
          }
        },
        null
      );
    };
  }

  //#region privates
  Limpiar() {

    $("#frmAgregar_resetear").trigger("click");
    $("#frmAgregar_carrera").removeAttr("disabled");
    $("#frmAgregar_cordinadorAcademico").removeAttr("disabled");
    $("#frmAgregar_periodo").removeAttr("required");

    this.combobox.Clear("frmAgregar_carrera");
    var periodo = $("#frmAgregar_periodo").children("option:nth-child(0n+2)");
    var campus = $("#frmAgregar_campus").children("option:nth-child(0n+2)");
    periodo.attr("selected", "selected");
    campus.attr("selected", "selected");

    this.FechaLimite();

    this.model.idResumenFicha=0;
    
    setTimeout(() => {
      this.model.idPeriodo = periodo.val();
      this.model.idCampus = campus.val();
      $("#frmAgregar_campus").trigger("change");
    }, 100);
  }
  private ObtenerPorId(parametro, callback): void {
    this._ResumenFichaService.ObtenerPorId(
      {
        idResumenFicha: parametro.idResumenFicha
      },
      data => {
        data.idPersonaRegistra = this._Session.usuario.persona.idPersona;
        data.fechaLimiteServer = data.fechaLimite;
        data.fechaLimite = this._utilitarios.ConvertFechaServidorToDMYHMS(
          data.fechaLimite
        );
        data.fechaRegistro = this._utilitarios.ConvertFechaServidorToDMYHMS(
          data.fechaRegistro
        );

        callback(data);
      }
    );
  }

  private FechaLimite() {
    this.combobox.getJson(
      {
        entidad: "fechaLimiteCoordinadorInvestigacion"
      },
      res => {
        var data = res[0];
        this.fechaInicial = this._utilitarios.ConvertFechaServidorToJsonDMY(
          data.nombre
        );
      }
    );
  }
  //#endregion privates
}
