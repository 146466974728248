import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Input, Directive } from "@angular/core";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { DataTablesModule } from "angular-datatables";
import { ModalComponent } from "../app/_componentes/modal/modal.component";
import { SelectComponent } from "../app/_componentes/select/select.component";
import { NavComponent } from "../app/_componentes/nav.component";
import { HttpClientModule } from "@angular/common/http";
import {FilterPipe} from '../app/pipes/asignarFicha_FilterBancoPorIdBanco';

//-----------------------------------------------------------------------------------------------
//--Componentes
//-----------------------------------------------------------------------------------------------
import { LoginComponent } from "./login/login.component";
import { ResumenfichaAgregarComponent } from "./resumenficha/resumenficha-agregar/resumenficha-agregar.component";
import { ResumenfichaListarComponent } from "./resumenficha/resumenficha-listar/resumenficha-listar.component";
import { SolicitudfichaAgregarComponent } from "./solicitudficha/solicitudficha-agregar/solicitudficha-agregar.component";
import { SolicitudfichaListarComponent } from "./solicitudficha/solicitudficha-listar/solicitudficha-listar.component";
import { FichaAgregarComponent } from "./ficha/ficha-agregar/ficha-agregar.component";
import { FichaListarComponent } from "./ficha/ficha-listar/ficha-listar.component";
import { MenuComponent } from "./_componentes/menu/menu.component";
//-----------------------------------------------------------------------------------------------
//--Multiselect
//-----------------------------------------------------------------------------------------------
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
//-----------------------------------------------------------------------------------------------
//--Ng Bootstrap
//-----------------------------------------------------------------------------------------------
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import {
  NgbDatepickerConfig,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";
import { NgbDateFRParserFormatter } from "../app/_servicioComponentes/ngb-date-fr-parser-formatter";
//-----------------------------------------------------------------------------------------------
//--NDrag drop
//-----------------------------------------------------------------------------------------------
import { DragDropModule, CdkDrag } from "@angular/cdk/drag-drop";

//-----------------------------------------------------------------------------------------------
//--Servicios
//-----------------------------------------------------------------------------------------------

import { UsuarioService } from "./_servicio/usuario.service";
import { ResumenFichaService } from "./_servicio/resumenFicha.service";
import { PersonaService } from "./_servicio/persona.service";
import { SeccionService } from "./_servicio/seccion.service";

import { ProyeccionComponent } from "./proyeccion/proyeccion.component";
import { FichaaprobadaComponent } from "./fichaaprobada/fichaaprobada.component";
import { FichasasignadasComponent } from "./fichasasignadas/fichasasignadas.component";
import { AsignarfichaComponent } from "./asignarficha/asignarficha.component";
import { SeccionesasignadasComponent } from "./seccionesasignadas/seccionesasignadas.component";
import { GestionarusuarioComponent } from './gestionarusuario/gestionarusuario.component';

import { VarDirective } from './_directives/var.directives';
import { ReporteproduccionfichasComponent } from './reporteproduccionfichas/reporteproduccionfichas.component'; 
import { reporteseccionesfichasComponent } from './reporteseccionesfichas/reporteseccionesfichas.component';
import { ReportefinalproyectosinvestigacionComponent } from './reportefinalproyectosinvestigacion/reportefinalproyectosinvestigacion.component';
import { ReportefinaltrabajosinvestigacionComponent } from './reportefinaltrabajosinvestigacion/reportefinaltrabajosinvestigacion.component';
import { ReportedocumentosoficialesComponent } from './reportedocumentosoficiales/reportedocumentosoficiales.component';
import { MantenimientocompetenciaComponent } from './mantenimientocompetencia/mantenimientocompetencia.component';
import { MantenimientolineainvestigacionComponent } from './mantenimientolineainvestigacion/mantenimientolineainvestigacion.component';

@NgModule({
  declarations: [
    
    AppComponent,
    LoginComponent,
    ResumenfichaAgregarComponent,
    ResumenfichaListarComponent,
    SolicitudfichaAgregarComponent,
    SolicitudfichaListarComponent,
    FichaAgregarComponent,
    FichaListarComponent,
    ModalComponent,
    NavComponent,
    MenuComponent,
    SelectComponent,
    ProyeccionComponent,
    FichaaprobadaComponent,
    FichasasignadasComponent,
    AsignarfichaComponent,
    SeccionesasignadasComponent,
    FilterPipe,
    GestionarusuarioComponent,
    VarDirective,
    ReporteproduccionfichasComponent,
    reporteseccionesfichasComponent,
    ReportefinalproyectosinvestigacionComponent,
    ReportefinaltrabajosinvestigacionComponent,
    ReportedocumentosoficialesComponent,
    MantenimientocompetenciaComponent,
    MantenimientolineainvestigacionComponent,
     
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    //Multiselect
    AngularMultiSelectModule,
    //Ng Bootstrap
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    DragDropModule
  ],
  providers: [
    UsuarioService,
    ResumenFichaService,
    PersonaService,
    SeccionService,
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }
  ],
  bootstrap: [AppComponent, MenuComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
