<div class="modal fade" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Anexo 4 - <span>Ficha de trabajo de investigación</span>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCerrarModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!--
            <form name="form" class="card form-signin px-4" [formGroup]="formFicha" (ngSubmit)="onEnviarDatos()"    
            -->
            <form name="form" class="card form-signin px-4" [formGroup]="formFicha" (ngSubmit)="onEnviarDatos('')"
                novalidate id="formFicha">
                <div class="modal-body p-0">
                    <div class="container-fluid px-0">

                        <div class="row section-form">
                            <div class="col-md-9 py-3 scrollbar fichaControles">
                                <div class="row b-bottom mb-2">
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="lblCampus">Campus</label>
                                            <select type="text" class="form-control" id="formFicha_cboCampus"
                                                formControlName="idCampus" #idCampus (change)="onChangeCampus($event)">
                                            </select>
                                            <div *ngIf="formFicha.controls['idCampus'].invalid && (formFicha.controls['idCampus'].dirty || formFicha.controls['idCampus'].touched)"
                                                class="alert alert-danger">
                                                <div *ngIf="formFicha.controls['idCampus'].errors.required"
                                                    class="white-space">
                                                    Campus es
                                                    requerido</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="lblPeriodo">Periodo</label>
                                            <input type="text" class="form-control" id="lblPeriodo" disabled
                                                formControlName="periodo" #periodo readonly>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="lblFacultad">Facultad</label>
                                            <select type="text" id="formFicha_cboFacultad" class="form-control"
                                                formControlName="idFacultad" #idFacultad
                                                (change)="onChangeFacultad($event)">
                                            </select>
                                            <div *ngIf="formFicha.controls['idFacultad'].invalid && (formFicha.controls['idFacultad'].dirty || formFicha.controls['idFacultad'].touched )"
                                                class="alert alert-danger">
                                                <div *ngIf="formFicha.controls['idFacultad'].errors.required">Facultad
                                                    es
                                                    requerido</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="formFicha_cboCarrera">Carrera</label>
                                            <select type="text" id="formFicha_cboCarrera" class="form-control"
                                                formControlName="idCarrera" #idCarrera
                                                (change)="onChangeCarrera($event)">
                                            </select>
                                            <div *ngIf="formFicha.controls['idCarrera'].invalid && (formFicha.controls['idCarrera'].dirty || formFicha.controls['idCarrera'].touched )"
                                                class="alert alert-danger">
                                                <div *ngIf="formFicha.controls['idCarrera'].errors.required">Carrera es
                                                    requerido</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group ">
                                            <!--
                                                class="form-group mt-3 mb-0"
                                            <input type="checkbox" id="chkExtraordinaria" value="1"
                                                (change)="onChangeExtraordinario($event)">
                                                
                                            <label for="chkExtraordinaria">Ficha Extraordinaria</label>
                                            -->
                                            <label for="formFicha_cboCarrera">Tipo ficha</label>
                                            <!--
                                            <select id="chkExtraordinaria" formControlName="chkExtraordinaria"
                                                (change)="onChangeExtraordinario($event)">
                                            </select>
                                            -->
                                            <select id="chkExtraordinaria" formControlName="chkExtraordinaria"
                                                (change)="onChangeExtraordinario($event)">
                                            </select>

                                            <div *ngIf="formFicha.controls['chkExtraordinaria'].invalid && (formFicha.controls['chkExtraordinaria'].dirty || formFicha.controls['chkExtraordinaria'].touched )"
                                                class="alert alert-danger">
                                                <div *ngIf="formFicha.controls['chkExtraordinaria'].errors.required">
                                                    Carrera es
                                                    requerido</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 sectionExtraordinaria mb-3" style="display:none">
                                        <div class="row mx-0">
                                            <div class="col-md-6 alumnoUno" data-id-alumno="0">
                                                <h6>Alumno 1 asociado</h6>
                                                <div class="card row mr-0">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label for="lblCodigoAlum1">Código</label>
                                                            <input type="text" class="form-control"
                                                                id="formFicha_codigoAlumno1" maxlength="50"
                                                                name="formFicha_codigoAlumno1"
                                                                formControlName="codigoAlumno1" #codigoAlumno1
                                                                (change)="ngChange_txtCodigo($event)" data-index="1"
                                                                (keydown.enter)="ngkeydown_buscarAdministrativo($event)">
                                                            <div *ngIf="esExtraordinario && formFicha.controls['codigoAlumno1'].invalid && (formFicha.controls['codigoAlumno1'].dirty || formFicha.controls['codigoAlumno1'].touched )"
                                                                class="alert alert-danger">
                                                                <div
                                                                    *ngIf="esExtraordinario && formFicha.controls['codigoAlumno1'].errors.required">
                                                                    Codigo del alumno es requerido
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="form-group">
                                                            <label for="lblNombreAlum1">Nombre</label>
                                                            <input type="text" class="form-control"
                                                                id="formFicha_NombreAlumno1" maxlength="50"
                                                                name="formFicha_NombreAlumno1"
                                                                formControlName="NombreAlumno1" readonly #NombreAlumno1>
                                                            <div *ngIf="esExtraordinario && formFicha.controls['NombreAlumno1'].invalid && (formFicha.controls['NombreAlumno1'].dirty || formFicha.controls['NombreAlumno1'].touched )"
                                                                class="alert alert-danger">
                                                                <div
                                                                    *ngIf="esExtraordinario && formFicha.controls['NombreAlumno1'].errors.required">
                                                                    Nombre del alumno es requerido
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-md-6 alumnoDos" data-id-alumno="0">
                                                <h6>Alumno 2 asociado</h6>
                                                <div class="card row ml-0">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label for="lblCodigoAlum2">Código</label>
                                                            <input type="text" class="form-control"
                                                                id="formFicha_codigoAlumno2" maxlength="50"
                                                                name="formFicha_codigoAlumno2"
                                                                formControlName="codigoAlumno2" #codigoAlumno2
                                                                (change)="onChangeCodigo2($event)" data-index="2"
                                                                (keydown.enter)="ngkeydown_buscarAdministrativo($event)">
                                                            <div *ngIf="esExtraordinario && formFicha.controls['codigoAlumno2'].invalid && (formFicha.controls['codigoAlumno2'].dirty || formFicha.controls['codigoAlumno2'].touched )"
                                                                class="alert alert-danger">
                                                                <div
                                                                    *ngIf="esExtraordinario && formFicha.controls['codigoAlumno2'].errors.required">
                                                                    Codigo del alumno es requerido
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="form-group">
                                                            <label for="lblNombreAlum2">Nombre</label>
                                                            <input type="text" class="form-control Nombre"
                                                                id="formFicha_NombreAlumno2" maxlength="50"
                                                                name="formFicha_NombreAlumno2"
                                                                formControlName="NombreAlumno2" #NombreAlumno2 readonly
                                                                (change)="onChangeNombreAlumno2($event)">
                                                            <div *ngIf="esExtraordinario && formFicha.controls['NombreAlumno2'].invalid && (formFicha.controls['NombreAlumno2'].dirty || formFicha.controls['NombreAlumno2'].touched )"
                                                                class="alert alert-danger">
                                                                <div
                                                                    *ngIf="esExtraordinario && formFicha.controls['NombreAlumno2'].errors.required">
                                                                    Nombre del alumno es requerido
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <!-- Question 1 nuevoFicha-->
                                <div class="row mx-0" data-id-question="PREGN01">
                                    <div class="col">
                                        <div class="form-group row col-md-12">
                                            <label for="lblPre1"><b>1. </b>Idea de la investigación
                                                <span id="PREGN01" class="radioAprobadoObservado" data-numero="1">
                                                    <input type="radio" id="PREGN01_1" name="PREGN01" value="APSA">
                                                    <label for="PREGN01_1" class="aprobado">Aprobado</label>
                                                    <input type="radio" id="PREGN01_2" name="PREGN01" data-numero="1"
                                                        value="OBOD">
                                                    <label for="PREGN01_2" class="observado">Observado</label>
                                                </span>
                                            </label>
                                            <span data-for-id-question="PREGN01"
                                                class=" col-md-1 help-question material-icons">info</span>

                                            <div class="row col-md-12">
                                                <label>a) Tema de investigación: </label>
                                                <textarea class="form-control col-md-11" type="text"
                                                    placeholder="Problema de Investigación"
                                                    id="formFicha_temaInvestigacion" name="formFicha_temaInvestigacion"
                                                    formControlName="temaInvestigacion">
                                                </textarea>
                                                <div *ngIf="formFicha.controls['temaInvestigacion'].invalid && (formFicha.controls['temaInvestigacion'].dirty || formFicha.controls['temaInvestigacion'].touched )"
                                                    class="alert alert-danger col-md-12">
                                                    <div
                                                        *ngIf=" formFicha.controls['temaInvestigacion'].errors.required">
                                                        Problema Investigación es
                                                        requerido</div>
                                                </div>
                                            </div>
                                            <div class="row col-md-12 mt-1">
                                                <label>b) Problemática ¿Qué está mal o qué se puede mejorar?: </label>
                                                <textarea class="form-control col-md-11" type="text"
                                                    placeholder="Problema de Investigación"
                                                    id="formFicha_problemaInvestigacion"
                                                    name="formFicha_problemaInvestigacion"
                                                    formControlName="problemaInvestigacion" #problemaInvestigacion>
                                                </textarea>
                                                <div *ngIf="formFicha.controls['problemaInvestigacion'].invalid && (formFicha.controls['problemaInvestigacion'].dirty || formFicha.controls['problemaInvestigacion'].touched )"
                                                    class="alert alert-danger col-md-12">
                                                    <div
                                                        *ngIf=" formFicha.controls['problemaInvestigacion'].errors.required">
                                                        Problema Investigación es
                                                        requerido</div>
                                                </div>
                                            </div>

                                            <div class="row col-md-12 mt-1">
                                                <label>c) Población y lugar de estudio (de corresponder): </label>
                                                <textarea class="form-control col-md-11" type="text"
                                                    placeholder="Problema de Investigación"
                                                    id="formFicha_poblacionLugarEstudio"
                                                    name="formFicha_poblacionLugarEstudio"
                                                    formControlName="poblacionLugarEstudio">
                                                </textarea>
                                                <!--
 <div *ngIf="formFicha.controls['poblacionLugarEstudio'].invalid && (formFicha.controls['poblacionLugarEstudio'].dirty || formFicha.controls['poblacionLugarEstudio'].touched )"
                                                    class="alert alert-danger col-md-12">
                                                    <div
                                                        *ngIf=" formFicha.controls['poblacionLugarEstudio'].errors.required">
                                                        Problema Investigación es
                                                        requerido</div>
                                                </div>
                                                -->

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <!-- Question 2 nuevoFicha-->
                                <div class="row mx-0" data-id-question="PREGN02">
                                    <div class="col">
                                        <div class="form-group row col-md-12">
                                            <label for="lblPre1"><b>2.</b>Línea de investigación de la UTP a la que
                                                responde la investigación propuesta
                                                <span id="PREGN02" class="radioAprobadoObservado" data-numero="2">
                                                    <input type="radio" id="PREGN02_1" name="PREGN02" value="APSA">
                                                    <label for="PREGN02_1" class="aprobado">Aprobado</label>
                                                    <input type="radio" id="PREGN02_2" name="PREGN02" data-numero="2"
                                                        value="OBOD">
                                                    <label for="PREGN02_2" class="observado">Observado</label>
                                                </span>
                                            </label>

                                            <select type="text" class="form-control col-md-11"
                                                id="formFicha_lineaInvestigacion" formControlName="lineaInvestigacion"
                                                #lineaInvestigacion [(ngModel)]="this.model.lineaInvestigacion">
                                            </select>
                                            <span data-for-id-question="PREGN02"
                                                class=" col-md-1 help-question material-icons">info</span>
                                            <div *ngIf="formFicha.controls['lineaInvestigacion'].invalid && (formFicha.controls['lineaInvestigacion'].dirty || formFicha.controls['lineaInvestigacion'].touched )"
                                                class="alert alert-danger col-md-12">
                                                <div *ngIf=" formFicha.controls['lineaInvestigacion'].errors.required">
                                                    Linea de investigación es requerido</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Question 3 -->

                                <div class="col-md-12 row mx-0 position-relative" data-id-question="PREG002">

                                    <div class="form-group row col-md-11">
                                        <label for="lblPre2"><b>3. </b>Indica la(s) competencias del modelo del
                                            egresado que serán desarrolladas fundamentalmente con este Trabajo de
                                            Investigación.
                                            <span id="PREG002" class="radioAprobadoObservado" data-numero="3">
                                                <input type="radio" id="PREG002_1" name="PREG002" value="APSA">
                                                <label for="PREG002_1" class="aprobado">Aprobado</label>
                                                <input type="radio" id="PREG002_2" name="PREG002" value="OBOD">
                                                <label for="PREG002_2" class="observado">Observado</label>
                                            </span>
                                        </label>
                                    </div>
                                    <span data-for-id-question="PREG002"
                                        class=" col-md-1 help-question material-icons">info</span>
                                    <div class="row col-md-12" id="divCompentencias">
                                    </div>
                                    <div *ngIf="errorChkCompetencia" class="alert alert-danger col-md-12">
                                        <div>Es necesario ingresar una
                                            competencia.</div>
                                    </div>
                                </div>
                                <!-- Question 4-->
                                <div class="row mx-0" data-id-question="PREG001">
                                    <div class="col">
                                        <div class="form-group row col-md-12">
                                            <label for="lblPre1"><b>4. </b>Título tentativo del trabajo de investigación
                                                (éste será ajustado con el desarrollo de la investigación).
                                                <span id="PREG001" class="radioAprobadoObservado" data-numero="4">
                                                    <input type="radio" id="PREG001_1" name="PREG001" value="APSA">
                                                    <label for="PREG001_1" class="aprobado">Aprobado</label>
                                                    <input type="radio" id="PREG001_2" name="PREG001" data-numero="4"
                                                        value="OBOD">
                                                    <label for="PREG001_2" class="observado">Observado</label>
                                                </span>
                                            </label>

                                            <textarea class="form-control col-md-11"
                                                style="overflow-y: scroll;height: 80px;" type="text"
                                                placeholder="Titulo Ficha" id="formFicha_tituloFicha" maxlength="600"
                                                name="formFicha_tituloFicha" formControlName="tituloFicha"
                                                #tituloFicha></textarea>

                                            <span data-for-id-question="PREG001"
                                                class=" col-md-1 help-question material-icons">info</span>
                                            <div *ngIf="esEnviado && formFicha.controls['tituloFicha'].invalid && (formFicha.controls['tituloFicha'].dirty || formFicha.controls['tituloFicha'].touched )"
                                                class="alert alert-danger col-md-12">
                                                <div
                                                    *ngIf="esEnviado && formFicha.controls['tituloFicha'].errors.required">
                                                    Titulo es
                                                    requerido</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Question 5 nuevoFicha-->
                                <!--
                                <div class="row mx-0 col-md-12" data-id-question="PREGN05">
                                    <div class="form-group row col-md-12">
                                        <label for="cboPre4"><b>5.</b>Tipo de investigación (ver anexo 1).
                                            <span id="PREGN05" class="radioAprobadoObservado" data-numero="5">
                                                <input type="radio" id="PREGN05_1" name="PREGN05" value="APSA">
                                                <label for="PREGN05_1" class="aprobado">Aprobado</label>
                                                <input type="radio" id="PREGN05_2" name="PREGN05" value="OBOD">
                                                <label for="PREGN05_2" class="observado">Observado</label>
                                            </span>
                                        </label>
                                         //<label for="formFicha_esPerspectiva">Tiene perspectiva</label>
                                        <select type="text" class="form-control col-md-11"
                                            id="formFicha_tipoInvestigacion" formControlName="tipoInvestigacion"
                                            #tipoInvestigacion [(ngModel)]="this.model.tipoInvestigacion">
                                        </select>
                                        <span data-for-id-question="PREGN05"
                                            class=" col-md-1 help-question material-icons">info</span>
                                        <div *ngIf="esEnviado && formFicha.controls['tipoInvestigacion'].invalid && (formFicha.controls['tipoInvestigacion'].dirty || formFicha.controls['tipoInvestigacion'].touched )"
                                            class="alert alert-danger col-md-12">
                                            <div
                                                *ngIf="esEnviado && formFicha.controls['tipoInvestigacion'].errors.required">
                                                seleccionar
                                                perspectiva</div>
                                        </div>
                                    </div>

                                </div>
                                -->
                                <!--  -->
                                <!-- Question 6 nuevoFicha -->
                                <!-- <div class="row mx-0  col-md-12" data-id-question="PREGN06">

                                    <div class="form-group row col-md-12 mb-0">
                                        <label for="cboPre3"><b>6. </b>Considerando que el trabajo de campo se realizará
                                            en el curso Taller de investigación, indique el enfoque de investigación:
                                            <span id="PREGN06" class="radioAprobadoObservado" data-numero="6">
                                                <input type="radio" id="PREGN06_1" name="PREGN06" value="APSA">
                                                <label for="PREGN06_1" class="aprobado">Aprobado</label>
                                                <input type="radio" id="PREGN06_2" name="PREGN06" value="OBOD">
                                                <label for="PREGN06_2" class="observado">Observado</label>
                                            </span>
                                        </label>
                                        <select type="text" class="form-control col-md-11"
                                            id="formFicha_tallerInvestigacion" formControlName="tallerInvestigacion"
                                            #tallerInvestigacion [(ngModel)]="this.model.tallerInvestigacion">
                                        </select>
                                        <span data-for-id-question="PREGN06"
                                            class=" col-md-1 help-question material-icons">info</span>
                                        <div *ngIf="esEnviado && formFicha.controls['tallerInvestigacion'].invalid && (formFicha.controls['tallerInvestigacion'].dirty || formFicha.controls['tallerInvestigacion'].touched )"
                                            class="alert alert-danger col-md-12">
                                            <div
                                                *ngIf="esEnviado && formFicha.controls['tallerInvestigacion'].errors.required">
                                                Numero
                                                Alumno es
                                                requerido</div>
                                        </div>
                                    </div>


                                </div> -->
                                <!-- Question 7 -->
                                <div class="row mx-0 col-md-12 mt-1" data-id-question="PREG005">

                                    <div class="form-group row mb-0 col-md-11">
                                        <label for="cboPre5"><b>5. </b>Enuncie 5 palabras claves con su versión en ingles que le permitan al alumno realizar la búsqueda de información en revistas científicas.
                                            <span id="PREG005" class="radioAprobadoObservado" data-numero="5">
                                                <input type="radio" id="PREG005_1" name="PREG005" value="APSA">
                                                <label for="PREG005_1" class="aprobado">Aprobado</label>
                                                <input type="radio" id="PREG005_2" name="PREG005" value="OBOD">
                                                <label for="PREG005_2" class="observado">Observado</label>
                                            </span>
                                        </label>
                                    </div>

                                    <span data-for-id-question="PREG005" style="margin-left: 2px;"
                                        class=" col-md-1 help-question material-icons">info</span>
                                    <div class="col-md-11 row mx-0 px-0" id="divPalabrasClaves">
                                        <div data-row="1" class="col-md-12 row px-0 mx-0">
                                            <div class="col-md-4" data-for="1">
                                                <div class="form-group">
                                                    <label for="lblPalabra1">Palabra clave N°1</label>
                                                    <input type="text" class="form-control" id="lblPalabra1"
                                                        formControlName="palabra1" #palabra1>
                                                    <div *ngIf="formFicha.controls['palabra1'].invalid && (formFicha.controls['palabra1'].dirty || formFicha.controls['palabra1'].touched )"
                                                        class="alert alert-danger">
                                                        <div *ngIf=" formFicha.controls['palabra1'].errors.required">
                                                            Palabra
                                                            Nº1 es
                                                            requerido
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8" data-for="1">
                                                <div class="form-group">
                                                    <label for="lblPalabra1">Palabra clave N°1 (Ingles) </label>
                                                    <input type="text" class="form-control"
                                                        formControlName="palabra1_auxiliar" #palabra1_auxiliar>
                                                    <div *ngIf="formFicha.controls['palabra1_auxiliar'].invalid && (formFicha.controls['palabra1_auxiliar'].dirty || formFicha.controls['palabra1_auxiliar'].touched )"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf=" formFicha.controls['palabra1_auxiliar'].errors.required">
                                                            Palabra Nº1 (Ingles) es requerido
                                                        </div>
                                                    </div>

                                                    <!-- <label for="cboPalabra1">Repositorios</label>
                                                    <angular2-multiselect [data]="dropdownList"
                                                        [settings]="dropdownSettings" formControlName="idRepositorio1"
                                                        (onDeSelect)="OnItemDeSelect_idRepositorio1($event)"
                                                        #idRepositorio1 id="cboPalabra1">
                                                    </angular2-multiselect>
                                                    <div *ngIf=" formFicha.controls['idRepositorio1'].invalid && (formFicha.controls['idRepositorio1'].dirty || formFicha.controls['idRepositorio1'].touched )"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf=" formFicha.controls['idRepositorio1'].errors.required">
                                                            El repositorio
                                                            es obligatorio.</div>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div data-row="2" class="col-md-12 row px-0 mx-0">
                                            <div class="col-md-4" data-for="2">
                                                <div class="form-group">
                                                    <label for="lblpalabra2">Palabra clave N°2</label>
                                                    <input type="text" class="form-control" id="lblpalabra2"
                                                        formControlName="palabra2" #palabra2>
                                                    <div *ngIf="formFicha.controls['palabra2'].invalid && (formFicha.controls['palabra2'].dirty || formFicha.controls['palabra2'].touched )"
                                                        class="alert alert-danger">
                                                        <div *ngIf="formFicha.controls['palabra2'].errors.required">
                                                            Palabra
                                                            Nº2 es
                                                            requerido
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8" data-for="2">
                                                <div class="form-group">
                                                    <label for="lblPalabra2">Palabra clave N°2 (Ingles) </label>
                                                    <input type="text" class="form-control" id="lblPalabra2"
                                                        formControlName="palabra2_auxiliar" #palabra2_auxiliar>
                                                    <div *ngIf="formFicha.controls['palabra2_auxiliar'].invalid && (formFicha.controls['palabra2_auxiliar'].dirty || formFicha.controls['palabra2_auxiliar'].touched )"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf=" formFicha.controls['palabra2_auxiliar'].errors.required">
                                                            Palabra Nº2 (Ingles) es requerido
                                                        </div>
                                                    </div>

                                                    <!--
                                                         <label for="">Repositorios</label>
                                            <angular2-multiselect [data]="dropdownList"
                                                [settings]="dropdownSettings" formControlName="idRepositorio2"
                                                (onDeSelect)="OnItemDeSelect_idRepositorio1($event)"
                                                #idRepositorio2>
                                            </angular2-multiselect>
                                            <div *ngIf="formFicha.controls['idRepositorio2'].invalid && (formFicha.controls['idRepositorio2'].dirty || formFicha.controls['idRepositorio2'].touched )"
                                                class="alert alert-danger">
                                                <div
                                                    *ngIf="formFicha.controls['idRepositorio2'].errors.required">
                                                    El repositorio
                                                    es obligatorio.</div>
                                            </div> -->


                                                </div>
                                            </div>
                                        </div>
                                        <div data-row="3" class="col-md-12 row px-0 mx-0">

                                            <div class="col-md-4" data-for="3">
                                                <div class="form-group">
                                                    <label for="lblpalabra3">Palabra clave N°3</label>
                                                    <input type="text" class="form-control" id="lblpalabra3"
                                                        formControlName="palabra3" #palabra3>
                                                    <div *ngIf="formFicha.controls['palabra3'].invalid && (formFicha.controls['palabra3'].dirty || formFicha.controls['palabra3'].touched )"
                                                        class="alert alert-danger">
                                                        <div *ngIf="formFicha.controls['palabra3'].errors.required">
                                                            Palabra
                                                            Nº3 es
                                                            requerido
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8" data-for="3">
                                                <div class="form-group">

                                                    <label for="lblPalabra3">Palabra clave N°3 (Ingles) </label>
                                                    <input type="text" class="form-control" id="lblPalabra3"
                                                        formControlName="palabra3_auxiliar" #palabra3_auxiliar>
                                                    <div *ngIf="formFicha.controls['palabra3_auxiliar'].invalid && (formFicha.controls['palabra3_auxiliar'].dirty || formFicha.controls['palabra3_auxiliar'].touched )"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf=" formFicha.controls['palabra3_auxiliar'].errors.required">
                                                            Palabra Nº3 (Ingles) es requerido
                                                        </div>
                                                    </div>
                                                    <!--
                                                            <label for="">Repositorios</label>
                                                            <angular2-multiselect [data]="dropdownList"
                                                                [settings]="dropdownSettings" formControlName="idRepositorio3"
                                                                (onDeSelect)="OnItemDeSelect_idRepositorio1($event)"
                                                                #idRepositorio3>
                                                            </angular2-multiselect>
                                                            <div *ngIf="formFicha.controls['idRepositorio3'].invalid && (formFicha.controls['idRepositorio3'].dirty || formFicha.controls['idRepositorio3'].touched )"
                                                                class="alert alert-danger">
                                                                <div
                                                                    *ngIf="formFicha.controls['idRepositorio3'].errors.required">
                                                                    El repositorio
                                                                    es obligatorio.</div>
                                                            </div>

                                                    -->

                                                </div>
                                            </div>

                                        </div>
                                        <div data-row="4" class="col-md-12 row px-0 mx-0">
                                            <div class="col-md-4" data-for="4">
                                                <div class="form-group">
                                                    <label for="lblpalabra4">Palabra clave N°4</label>
                                                    <input type="text" class="form-control" id="lblpalabra4"
                                                        formControlName="palabra4" #palabra4>
                                                    <div *ngIf="formFicha.controls['palabra4'].invalid && (formFicha.controls['palabra4'].dirty || formFicha.controls['palabra4'].touched )"
                                                        class="alert alert-danger">
                                                        <div *ngIf="formFicha.controls['palabra4'].errors.required">
                                                            Palabra
                                                            Nº4 es
                                                            requerido
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8" data-for="4">
                                                <div class="form-group">

                                                    <label for="lblPalabra4">Palabra clave N°4 (Ingles) </label>
                                                    <input type="text" class="form-control" id="lblPalabra4"
                                                        formControlName="palabra4_auxiliar" #palabra4_auxiliar>
                                                    <div *ngIf="formFicha.controls['palabra4_auxiliar'].invalid && (formFicha.controls['palabra4_auxiliar'].dirty || formFicha.controls['palabra4_auxiliar'].touched )"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf=" formFicha.controls['palabra4_auxiliar'].errors.required">
                                                            Palabra Nº4 (Ingles) es requerido
                                                        </div>
                                                    </div>
                                                    <!--
                                                                    <label for="">Repositorios</label>
                                                                    <angular2-multiselect [data]="dropdownList"
                                                                        [settings]="dropdownSettings" formControlName="idRepositorio4"
                                                                        (onDeSelect)="OnItemDeSelect_idRepositorio1($event)"
                                                                        #idRepositorio4>
                                                                    </angular2-multiselect>
                                                                    <div *ngIf="formFicha.controls['idRepositorio4'].invalid && (formFicha.controls['idRepositorio4'].dirty || formFicha.controls['idRepositorio4'].touched )"
                                                                        class="alert alert-danger">
                                                                        <div
                                                                            *ngIf="formFicha.controls['idRepositorio4'].errors.required">
                                                                            El repositorio
                                                                            es obligatorio.</div>
                                                                    </div>
                                                    -->

                                                </div>
                                            </div>

                                        </div>
                                        <div data-row="5" class="col-md-12 row px-0 mx-0">
                                            <div class="col-md-4" data-for="5">

                                                <div class="form-group">

                                                    <label for="lblpalabra5">Palabra clave N°5</label>
                                                    <input type="text" class="form-control" id="lblpalabra5"
                                                        formControlName="palabra5" #palabra5>
                                                    <div *ngIf=" formFicha.controls['palabra5'].invalid && (formFicha.controls['palabra5'].dirty || formFicha.controls['palabra5'].touched )"
                                                        class="alert alert-danger">
                                                        <div *ngIf="formFicha.controls['palabra5'].errors.required">
                                                            Palabra
                                                            Nº5 es
                                                            requerido
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-md-8" data-for="5">
                                                <div class="form-group">
                                                    <label for="lblPalabra5">Palabra clave N°5 (Ingles) </label>
                                                    <input type="text" class="form-control" id="lblPalabra5"
                                                        formControlName="palabra5_auxiliar">
                                                    <div *ngIf="formFicha.controls['palabra5_auxiliar'].invalid && (formFicha.controls['palabra5_auxiliar'].dirty || formFicha.controls['palabra5_auxiliar'].touched )"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf=" formFicha.controls['palabra5_auxiliar'].errors.required">
                                                            Palabra Nº5 (Ingles) es requerido
                                                        </div>
                                                    </div>
                                                    <!--
                                                   <label for="">Repositorios</label>
                                                    <angular2-multiselect [data]="dropdownList"
                                                        [settings]="dropdownSettings" formControlName="idRepositorio5"
                                                        (onDeSelect)="OnItemDeSelect_idRepositorio1($event)"
                                                        #idRepositorio5>
                                                    </angular2-multiselect>
                                                    <div *ngIf=" formFicha.controls['idRepositorio5'].invalid && (formFicha.controls['idRepositorio5'].dirty || formFicha.controls['idRepositorio5'].touched )"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="formFicha.controls['idRepositorio5'].errors.required">
                                                            El repositorio
                                                            es obligatorio.</div>
                                                    </div>
                                                    -->

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <!-- Question 8 -->
                                <div class="row mx-0" data-id-question="PREG008">
                                    <div class="col">
                                        <div class="form-group row col-md-12">
                                            <label for="lblPre7"><b>6. </b>Objetivo del trabajo de
                                                investigación, de forma clara y comprensible para los alumnos.
                                                <span id="PREG008" class="radioAprobadoObservado" data-numero="6">
                                                    <input type="radio" id="PREG008_1" name="PREG008" value="APSA">
                                                    <label for="PREG008_1" class="aprobado">Aprobado</label>
                                                    <input type="radio" id="PREG008_2" name="PREG008" value="OBOD">
                                                    <label for="PREG008_2" class="observado">Observado</label>
                                                </span>
                                            </label>

                                            <textarea class="form-control col-md-11"
                                                style="overflow-y: scroll;height: 80px;" type="text"
                                                id="formFicha_observacionAccionesEspecificacion" maxlength="600"
                                                name="formFicha_observacionAccionesEspecificacion"
                                                formControlName="observacionAccionesEspecificacion"
                                                #observacionAccionesEspecificacion></textarea>

                                            <span data-for-id-question="PREG008"
                                                class=" col-md-1 help-question material-icons">info</span>
                                            <div *ngIf="esEnviado && formFicha.controls['observacionAccionesEspecificacion'].invalid && (formFicha.controls['observacionAccionesEspecificacion'].dirty || formFicha.controls['observacionAccionesEspecificacion'].touched )"
                                                class="alert alert-danger col-md-12">
                                                <div
                                                    *ngIf="esEnviado && formFicha.controls['observacionAccionesEspecificacion'].errors.required">
                                                    Observación es
                                                    requerido</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Question 9 nuevoFicha -->
                                <div class="row mx-0" data-id-question="PREGN09">
                                    <div class="col">
                                        <div class="form-group row col-md-12">
                                            <label for="lblPre7"><b>7. </b>Indicar las actividades clave que el alumno
                                                debe realizar durante el desarrollo de su investigación
                                                <span id="PREGN09" class="radioAprobadoObservado" data-numero="7">
                                                    <input type="radio" id="PREGN09_1" name="PREGN09" value="APSA">
                                                    <label for="PREGN09_1" class="aprobado">Aprobado</label>
                                                    <input type="radio" id="PREGN09_2" name="PREGN09" value="OBOD">
                                                    <label for="PREGN09_2" class="observado">Observado</label>
                                                </span>
                                            </label>


                                            <textarea class="form-control col-md-11"
                                                style="overflow-y: scroll;height: 80px;" type="text"
                                                id="formFicha_actividadesClaves" maxlength="600"
                                                name="formFicha_actividadesClaves" formControlName="actividadesClaves"
                                                #actividadesClaves></textarea>

                                            <span data-for-id-question="PREGN09"
                                                class=" col-md-1 help-question material-icons">info</span>
                                            <div *ngIf="esEnviado && formFicha.controls['actividadesClaves'].invalid && (formFicha.controls['actividadesClaves'].dirty || formFicha.controls['actividadesClaves'].touched )"
                                                class="alert alert-danger col-md-12">
                                                <div
                                                    *ngIf="esEnviado && formFicha.controls['actividadesClaves'].errors.required">
                                                    Observación es
                                                    requerido</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Question  Pregunta Nueva -->
                                <div class="row mx-0 col-md-12" data-id-question="PREGN08">
                                    <div class="form-group row col-md-12 mb-0">
                                        <label for="cboPre3"><b>8. </b>Sobre el tiempo requerido para realizar la
                                            investigación
                                            <span id="PREGN08" class="radioAprobadoObservado" data-numero="8">
                                                <input type="radio" id="PREGN08_1" name="PREGN08" value="APSA">
                                                <label for="PREGN08_1" class="aprobado">Aprobado</label>
                                                <input type="radio" id="PREGN08_2" name="PREGN08" value="OBOD">
                                                <label for="PREGN08_2" class="observado">Observado</label>
                                            </span>
                                        </label>
                                        <div class="col-md-12 row preguntasInformacionEntidad"
                                            style="font-size: 13px !important;"
                                            *ngFor="let itemRoot of _preguntasActividad">
                                            <div class="col-md-7">
                                                <label data-id="{{itemRoot.id}}">
                                                    {{itemRoot.nombre}}
                                                </label>
                                            </div>
                                            <div class="col-md-4">
                                                <input type="radio" name="{{itemRoot.id}}" [value]="1"
                                                    formControlName="{{itemRoot.id}}">
                                                <label for="{{itemRoot.id}}"> Si</label>

                                                <input type="radio" class="ml-2" name="{{itemRoot.id}}" [value]="0"
                                                    formControlName="{{itemRoot.id}}">
                                                <label for="{{itemRoot.id}}"> No</label>

                                                <div class="alert alert-danger"
                                                    *ngIf=" esEnviado && this.formFicha.controls[itemRoot.id].invalid && (formFicha.controls[itemRoot.id].dirty || formFicha.controls[itemRoot.id].touched )">
                                                    <div
                                                        *ngIf=" esEnviado && formFicha.controls[itemRoot.id].errors.required">
                                                        Marque una respuesta.
                                                    </div>
                                                </div>

                                            </div>
                                        </div>





                                    </div>
                                </div>

                                <!-- Question 10 nuevoFicha -->
                                <div class="row mx-0" data-id-question="PREG010">
                                    <div class="col">
                                        <div class="form-group row col-md-12">
                                            <label for="lblPre7"><b>9. </b>Incorpora todas las observaciones y
                                                recomendaciones que consideres de utilidad para el alumno y a los
                                                profesores del curso con el fin de que desarrollen con éxito todas las
                                                actividades.
                                                <span id="PREG010" class="radioAprobadoObservado" data-numero="9">
                                                    <input type="radio" id="PREG010_1" name="PREG010" value="APSA">
                                                    <label for="PREG010_1" class="aprobado">Aprobado</label>
                                                    <input type="radio" id="PREG010_2" name="PREG010" value="OBOD">
                                                    <label for="PREG010_2" class="observado">Observado</label>
                                                </span>
                                            </label>

                                            <textarea class="form-control col-md-11"
                                                style="overflow-y: scroll;height: 80px;" type="text"
                                                id="formFicha_incorporaObservaciones" maxlength="600"
                                                name="formFicha_incorporaObservaciones"
                                                formControlName="incorporaObservaciones"
                                                #incorporaObservaciones></textarea>


                                            <span data-for-id-question="PREG010"
                                                class=" col-md-1 help-question material-icons">info</span>
                                            <div *ngIf="esEnviado && formFicha.controls['incorporaObservaciones'].invalid && (formFicha.controls['incorporaObservaciones'].dirty || formFicha.controls['incorporaObservaciones'].touched )"
                                                class="alert alert-danger col-md-12">
                                                <div
                                                    *ngIf="esEnviado && formFicha.controls['incorporaObservaciones'].errors.required">
                                                    Observación es
                                                    requerido</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Question 11 nuevoFicha -->
                                <div class="row mx-0 col-md-12" data-id-question="PREG011">
                                    <div class="form-group row col-md-12 mb-0">
                                        <label for="cboPre3"><b>10. </b>En el caso que utilice información de una
                                            entidad (empresa, entidad gubernamental, etc.) antes de iniciar la
                                            investigación, se debe contar con la respectiva autorización.
                                            <span id="PREG011" class="radioAprobadoObservado" data-numero="10">
                                                <input type="radio" id="PREG011_1" name="PREG011" value="APSA">
                                                <label for="PREG011_1" class="aprobado">Aprobado</label>
                                                <input type="radio" id="PREG011_2" name="PREG011" value="OBOD">
                                                <label for="PREG011_2" class="observado">Observado</label>
                                            </span>
                                        </label>
                                        <span data-for-id-question="PREG011"
                                            class=" col-md-1 help-question material-icons">info</span>

                                        <div class="col-md-12 row preguntasInformacionEntidad"
                                            style="font-size: 13px !important;"
                                            *ngFor="let itemRoot of _preguntasInformacionEntidad">
                                            <div class="col-md-7">
                                                <label data-id="{{itemRoot.id}}">
                                                    {{itemRoot.nombre}}
                                                </label>
                                            </div>
                                            <div class="col-md-4">
                                                <input type="radio" name="{{itemRoot.id}}" [value]="1"
                                                    formControlName="{{itemRoot.id}}"
                                                    (change)="ngChange_preguntasInformacionEntida($event,itemRoot)">
                                                <label for="{{itemRoot.id}}"> Si</label>

                                                <input type="radio" class="ml-2" name="{{itemRoot.id}}" [value]="0"
                                                    (change)="ngChange_preguntasInformacionEntida($event,itemRoot)"
                                                    formControlName="{{itemRoot.id}}">
                                                <label for="{{itemRoot.id}}"> No</label>

                                                <div class="alert alert-danger"
                                                    *ngIf=" esEnviado && this.formFicha.controls[itemRoot.id].invalid && (formFicha.controls[itemRoot.id].dirty || formFicha.controls[itemRoot.id].touched )">
                                                    <div
                                                        *ngIf=" esEnviado && formFicha.controls[itemRoot.id].errors.required">
                                                        Marque una respuesta.
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <!--
                                       <select type="text" class="form-control col-md-11"
                                            id="formFicha_informacionEntidad" formControlName="informacionEntidad"
                                            #informacionEntidad [(ngModel)]="this.model.informacionEntidad"
                                            (change)="onChangeInformacionEntidad()">
                                        </select>
                                   -->

                                        <!-- <div *ngIf="esEnviado && formFicha.controls['informacionEntidad'].invalid && (formFicha.controls['informacionEntidad'].dirty || formFicha.controls['informacionEntidad'].touched )"
                                            class="alert alert-danger">
                                            <div
                                                *ngIf="esEnviado && formFicha.controls['informacionEntidad'].errors.required">
                                                Numero
                                                Alumno es
                                                requerido</div>
                                        </div> -->

                                        <div class="mt-1 col-md-11 row  informacionEntidad" style="display:none;">
                                            <label>Indique nombre de la entidad:</label>
                                            <input class="form-control" type="text" placeholder="Entidad"
                                                id="formFicha_informacionEntidadObservacion" maxlength="200"
                                                name="formFicha_informacionEntidadObservacion"
                                                formControlName="informacionEntidadObservacion"
                                                #informacionEntidadObservacion />
                                            <div *ngIf="formFicha.controls['informacionEntidadObservacion'].invalid && (formFicha.controls['informacionEntidadObservacion'].dirty || formFicha.controls['informacionEntidadObservacion'].touched )"
                                                class="alert alert-danger col-md-12">
                                                <div
                                                    *ngIf="formFicha.controls['informacionEntidadObservacion'].errors.required">
                                                    Entidad requeridad</div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <!-- Question 12 nuevoFicha -->
                                <div class="row mx-0 col-md-12" data-id-question="PREG012">

                                    <div class="form-group row col-md-12 mb-0">
                                        <label for="cboPre3"><b>11. </b>Sobre ética en la investigación. ¿recomienda que
                                            el proyecto de trabajo de investigación resultante sea evaluado por el
                                            comité de ética?
                                            <span id="PREG012" class="radioAprobadoObservado" data-numero="11">
                                                <input type="radio" id="PREG012_1" name="PREG012" value="APSA">
                                                <label for="PREG012_1" class="aprobado">Aprobado</label>
                                                <input type="radio" id="PREG012_2" name="PREG012" value="OBOD">
                                                <label for="PREG012_2" class="observado">Observado</label>
                                            </span>
                                        </label>
                                        <select type="text" class="form-control col-md-11"
                                            id="formFicha_eticaInvestigacion" formControlName="eticaInvestigacion"
                                            #eticaInvestigacion [(ngModel)]="this.model.eticaInvestigacion">
                                        </select>
                                        <span data-for-id-question="PREG012"
                                            class=" col-md-1 help-question material-icons">info</span>
                                        <div *ngIf="esEnviado && formFicha.controls['eticaInvestigacion'].invalid && (formFicha.controls['eticaInvestigacion'].dirty || formFicha.controls['eticaInvestigacion'].touched )"
                                            class="alert alert-danger col-md-12">
                                            <div
                                                *ngIf="esEnviado && formFicha.controls['eticaInvestigacion'].errors.required">
                                                Numero
                                                Alumno es
                                                requerido</div>
                                        </div>
                                    </div>

                                </div>


                                <!-- Question 13 -->
                                <div class="row mx-0 omitir">
                                    <div class="col-md-12">
                                        <div class="form-group mb-0">
                                            <label for="cboPre5"><b>13. </b>Esta Ficha de Tarea de Investigación ha sido
                                                aprobada
                                                como tarea de Investigación para el Grado de Bachiller en esta carrera
                                                por:</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 row px-0 mx-0">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="idNombreCoordinador">Nombre</label>
                                                <input type="text" class="form-control" id="idNombreCoordinador"
                                                    disabled>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="idCodigoCoordinador">Código</label>
                                                <input type="text" class="form-control" id="idCodigoCoordinador"
                                                    disabled>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="idCargo">Cargo</label>
                                                <input type="text" class="form-control" id="idCargo" disabled>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="idFechaAprobacion">Fecha de aprobación de la ficha</label>
                                                <input type="text" class="form-control" id="idFechaAprobacion" disabled>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-3 py-3 scrollbar">

                                <div class="observacionesDetalle" style="display: none">
                                    <h5>Observaciones</h5>
                                    <p>
                                        Se han registrado las siguientes observaciones porfavor enviarlas al docente
                                        para
                                        su revisión.
                                    </p>
                                    <div class="observacionMovimiento">

                                    </div>
                                </div>
                                <div class="videoInstructivo "
                                    *ngIf="_codigoEstadoFicha!='APSA'
                                && (this._codigoEstadoBanco!=_utilitarios.enumEstado.AsignadoCursoTaller && this._codigoEstadoBanco!=_utilitarios.enumEstado.Asignado)"
                                    style="display: none">
                                    <h5>Registro de ficha</h5>
                                    <span>Video instructivo</span>
                                    <div style="background-color:gray; width: 100%; height: 100px">

                                    </div>
                                </div>

                                <div class="col-md-12 mt-4"
                                    *ngIf="this._codigoEstadoBanco!=_utilitarios.enumEstado.AsignadoCursoTaller && this._codigoEstadoBanco!=_utilitarios.enumEstado.Asignado">
                                    <button type="button" id="btnGuardar" class="btn btn-save mt-1"
                                        (click)="onEnviarDatos('guardar')" style="display: none">Guardar<i
                                            class="material-icons">save</i></button>
                                </div>
                                <div class="col-md-12 mt-4" *ngIf="_codigoEstadoFicha=='APSA'">
                                    <button type="button" class="btn btn-save mt-1"
                                        (click)="ngClickDescargarPDF()">Descargar PDF<i
                                            class="material-icons">cloud_download</i></button>
                                </div>
                                <div class="col-md-12 mt-4" *ngIf="_codigoEstadoFicha=='APSA'">
                                    <button type="button" class="btn btn-save mt-1" (click)="ngClickVerPDF()">Imprimir<i
                                            class="material-icons">local_printshop</i></button>
                                </div>
                                <div class="col-md-12 mt-4" *ngIf="
                                (_codigoEstadoFicha=='APSA'
                                && _codigoEstadoBanco!='ASCRT'
                                 && _idBanco!=null)
                                 || (this._codigoEstadoBanco==_utilitarios.enumEstado.AsignadoCursoTaller
                                 &&  _totalConfirmadosPorSeccion>0)">
                                    <button type="button" class="btn btn-save mt-1" style="text-transform: none;"
                                        (click)="ngClickSubirArchivo($event)">Subir documentos<i
                                            class="material-icons">cloud_upload</i></button>
                                    <input (change)="onChangeArchivo($event)" type="file" style="display: none;"
                                        id="fileSubirDocumento">
                                </div>

                                <div *ngIf="(_codigoEstadoFicha=='APSA' 
                                ||   _codigoEstadoBanco=='ASCRT' ) && _idBanco!=null">
                                    <hr />
                                    <h5>Archivos</h5>
                                    <div class="col-md-12 mt-0 p-0"
                                        *ngFor="let itemArchivo of _BancoArchivoBinarioBE; let indexFicha = index">
                                        <div class="col-md-12 mt-1"
                                            style="background: #dcdee2;border-radius: 8px 8px 8px 8px;position: relative;">
                                            {{itemArchivo.nombreArchivo}}
                                            <span  style="position: absolute;right: 19px;" *ngIf="_codigoEstadoBanco!='ASCRT'">
                                                <a role="button" href="javascript:void(0)"
                                                    (click)="ngClick_EliminarDocumento(itemArchivo,indexFicha)">
                                                    <i class="material-icons f-size-15">close</i>
                                                </a>
                                            </span>
                                            <span   style="position: absolute;right: 1px;" class="float-right ">
                                                <a role="button" href="javascript:void(0)"
                                                    (click)="ngClick_DescargarDocumento(itemArchivo)">
                                                    <i class="material-icons f-size-15">cloud_upload</i>
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="_codigoEstadoBanco=='ASCRT'  
                                && _idBanco!=null
                                && _totalConfirmadosPorSeccion>0
                                " class="documentoFinal">
                                    <hr />
                                    <h5>Documento Final</h5>

                                    <div class="col-md-12 mt-0 p-0 "
                                        *ngFor="let itemDocumento of _fichaDocumentosFinales; let indexFicha = index">
                                        <div class="col-md-12 mt-1">
                                            <div class="titulo">{{itemDocumento.tipoDocumento}}</div>
                                            <div *ngFor="let itemchild of itemDocumento.bancoArchivoBinarios;">
                                                <div class="detalle">Documento subido por
                                                    <span>{{itemDocumento.nombreApellidos}}</span> el
                                                    dia
                                                    <span>{{itemDocumento.fechaEjecucion}}</span>
                                                </div>
                                                <div class="archivo col-md-12">
                                                    <a role="button" href="javascript:void(0)"
                                                        (click)="ngClick_DescargarDocumento(itemchild)">
                                                        {{itemchild.nombreArchivo}}

                                                    </a>
                                                    <span class="float-right">
                                                        <a role="button" href="javascript:void(0)" title="Eliminar"
                                                            (click)="ngClick_EliminarDocumento(itemchild,indexFicha,true)">
                                                            <i class="material-icons f-size-15">delete_outline</i>
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-4" *ngIf="_codigoEstadoBanco=='ASCRT'  && _idBanco!=null
                                && _totalConfirmadosPorSeccion>0">
                                    <button type="button" class="btn btn-save mt-1 ui-badge-Pendiente"
                                        style="text-transform: none;" (click)="ngClick_MostrarTipoDocumento()">Nuevo
                                        documentos
                                        <i class="material-icons">cloud_upload</i>
                                    </button>
                                </div>


                                <div class="col-md-12 mt-4" id="divObservacion" style="display:none">
                                    <button (click)="onEnviarDatos('enviar')" type="button" class="btn btn-send mt-1">
                                        Enviar Observación<i class="material-icons">send</i></button>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <button *ngIf="this.esEnviado" type="submit" class="btn btn-send mt-1">Enviar<i
                                            class="material-icons">send</i></button>
                                </div>
                                <div class="col-md-12 mt-4" id="divAprobar" style="display:none">
                                    <button (click)="onEnviarDatos('aprobar')" type="button"
                                        class="btn btn-aprobar mt-1">
                                        Aprobar<i class="material-icons">done</i></button>
                                </div>
                                <!-- this button is hidden, used to close from typescript -->
                                <button type="button" id="close-modal" data-dismiss="modal"
                                    style="display: none">Close</button>

                            </div>
                        </div>


                    </div>

                </div>
            </form>

        </div>
    </div>
</div>


<div class="modal fade" id="modalImpresion" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">

                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="row  offset-md-1 col-md-11 mb-2">
                <div class="col-md-6 mt-2" *ngIf="tipoCarga==1">
                    <button type="button" class="btn btn-save mt-1" (click)="ngClickVerPDF()">Imprimir<i
                            class="material-icons">local_printshop</i></button>
                </div>
                <div class="col-md-6 mt-2" *ngIf="tipoCarga==1">
                    <button type="button" class="btn btn-save mt-1" (click)="ngClickDescargarPDF()">Descargar PDF<i
                            class="material-icons">cloud_download</i></button>
                </div>
            </div>
            <div class="modal-footer">

            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="modalTipoDocumento" data-backdrop="static" data-keyboard="false" style="z-index: 9999;">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Adjuntar Documento
                </h5>
                <button type="button" class="close" (click)="ngClick_cerrarmodalTipoDocumento($event)"
                    data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="row  offset-md-1 col-md-11 mb-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="lblCampus">Tipo documento</label>
                        <select type="text" (change)="reset_formTipoDocumentoLoad()" class="form-control"
                            id="cboTipoDocumento" [(ngModel)]="_tipoDocumento">
                        </select>
                        <div *ngIf="_tipoDocumento=='' && !_formTipoDocumentoLoad" class="alert alert-danger">
                            <div class="white-space">
                                Tipo documento es requerido</div>
                        </div>
                    </div>
                </div>



                <div class="col-md-12">
                    <div class="form-group">
                        <label for="lblCampus">Adjuntar</label>
                        <div (click)="ngChangeAdjuntarTipoDocumento($event)" class="outline">
                            <input type="text" readonly style="border:none" class="outline-none"
                                [(ngModel)]="_tipoDocumentoArchivo" />
                            <span class="float-right">
                                <i class="material-icons">cloud_upload</i>
                            </span>
                        </div>
                        <div *ngIf="_tipoDocumentoArchivo=='' && !_formTipoDocumentoLoad" class="alert alert-danger">
                            <div class="white-space"> Es necesario subir un archivo</div>
                        </div>
                        <input type="file" (change)="onChangeArchivoTipoDocumento($event)" id="fldSubirArchivo"
                            style="display: none;" />
                    </div>
                </div>
                <div class="col-md-12">

                    <button type="button" class="btn btn-save mt-1"
                        (click)="ngClick_GuardarAdjuntarTipoDocumento($event)">Guardar<i
                            class="material-icons">save</i></button>
                </div>

            </div>
        </div>
    </div>
</div>
<div data-for="modalTipoDocumento">

</div>
<!--

<app-ficha-agregar (dibujarGrilla)="onClickBuscar()" [tipoCarga]="1"></app-ficha-agregar>
-->