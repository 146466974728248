import { Component, ViewChild, OnInit } from "@angular/core";
import { JsonPipe, getLocaleDateTimeFormat } from "@angular/common";
import { Router } from "@angular/router";
import * as $ from "jquery";

//---------------------------------------------------------------------
//-- Clases Locales
//---------------------------------------------------------------------

import { FichaService } from "../../_servicio/ficha.service";
import { ComboboxService } from "../../_servicioComponentes/combobox.service";
import { Session } from "../../../global/session";
import { utilitarios } from "../../../global/utilitarios";

@Component({
  selector: "app-ficha-listar",
  templateUrl: "./ficha-listar.component.html",
  styleUrls: ["./ficha-listar.component.scss"]
})
export class FichaListarComponent implements OnInit {
  model: any = {
    idPeriodo: ""
  };
  constructor(
    private combobox: ComboboxService,
    private _FichaService: FichaService,
    private _Session: Session,
    private _utilitarios: utilitarios
  ) {}

  //#region Init
  ngOnInit() {

 
    this.onInitCombobox();
    this.dibujarGrilla();
  }
  //#endregion Init

  //#region combobox
  onInitCombobox() {
    this.cargarCombobox();
  }
  onChangeCampus(event) {
    this.combobox.init(
      {
        id: "cboCarrera",
        entidad: "mCarreraPorCampus",
        html: "todos",
        parametro: {
          "@p_idCampus": $(event.target).val()
        }
      },
      null
    );
  }
  //#endregion combobox

  //#region Grilla
  onClickBuscar() {
    this.dibujarGrilla();
    this.dibujarresumen();
  }

  //#endregion Grilla

  //#region Privates
  private cargarCombobox() {
    this.combobox.init(
      {
        id: "cboPeriodo",
        entidad: "mPeriodo",
        html: "todos"
      },
      respuesta => {
        $("#cboPeriodo")
          .find("option")
          .removeAttr("selected");
        var element = $("#cboPeriodo").children("option:nth-child(0n+2)");
        element.attr("selected", "selected");
        this.model.idPeriodo = element.val();
        this.dibujarresumen();
      }
    );
    this.combobox.init(
      {
        id: "cboCampus",
        entidad: "mCampusDocente",
        html: "todos",
        parametro: {
          "@p_idPersona": this._Session.usuario.persona.idPersona
        }
      },
      null
    );

    this.combobox.init(
      {
        id: "cboEstado",
        entidad: "EstadoFichaDocentes",
        html: "todos"
      },
      null
    );
  }

    dibujarGrilla() {
    var that = this;
    this._FichaService.Listar(
      {
        idPersona: this._Session.usuario.persona.idPersona,
        idPeriodo: $("#cboPeriodo").val() == "" ? 0 : $("#cboPeriodo").val(),
        idCampus: $("#cboCampus").val() == "" ? 0 : $("#cboCampus").val(),
        idCarrera: $("#cboCarrera").val() == "" ? 0 : $("#cboCarrera").val(),
        estado: $("#cboEstado").val() == "" ? 0 : $("#cboEstado").val()
      },
      data => {
        var table = $("#frmFichaTblListarFicha");
        this._utilitarios.Datatable(table, {
          columns: [
            { data: "tituloFicha" },
            { data: "periodo", class: "small_col text-center" },
            { data: "campus", class: "small_col" },
            { data: "carrera", class: "small_col" },
            { data: "nombreDocenteAsesor" },
            { data: "nombreTipoFicha" },
            {
              data: "nombreEstado",
              class: "small_col text-center",
              render: function(index, display, rowdata) {
                return (
                  '<span class="ui-badge-' +
                  rowdata.nombreEstado +
                  '">' +
                  rowdata.nombreEstado +
                  "</span>"
                );
              }
            },
            {
              data: null,
              class: "text-center small_col",
              render: function(index, display, rowdata) {
                var op = {
                  edit: true,
                  delete: true,
                  ver: true,
                  inactivo: false
                };

                switch (rowdata.codigoEstado.toString()) {
                  case "ENOD":
                    op.edit = false;
                    op.delete = false;
                    break;

                  case "ANOD":
                    op.edit = false;
                    op.delete = false;
                    break;
                  case that._utilitarios.enumEstado.Aprobadas:
                    op.edit = false;
                    op.delete = false;
                    break;
                  case that._utilitarios.enumEstado.Observado:
                    op.ver = false;
                    op.inactivo = false;
                    break;
                  case that._utilitarios.enumEstado.Inactivo:
                    op.edit = false;
                    op.delete = false;
                    op.inactivo = true;
                    break;
                  case that._utilitarios.enumEstado.Guardado:
                    op.inactivo = false;
                    op.ver = false;
                    break;
                  default:
                    op.ver = false;
                    break;
                }

                if(rowdata.codigoTipoFicha=='FCAXTL'){

                  op.edit=false;
                  op.inactivo=false;
                  op.delete=false;
                  op.ver = true;
                }

                //if (rowdata.codigoEstadoPeriodo != "VIET") {
                //if (rowdata.codigoEstado !=  that._utilitarios.enumEstado.Guardado) {
                //op.edit = false;
                //op.delete = false;
                // }

                var html = `                                
                ${
                  op.edit
                    ? '<div class="ui-icon-edit" data-edit><a href="javascript:void(0)"  class="edit"><i class="material-icons">edit</i></a></div>'
                    : ""
                }
                 
                ${
                  op.delete
                    ? '<div class="ui-icon-delete" data-inactivo><a href="javascript:void(0)" class="delete"><i class="material-icons">delete</i></a></div>'
                    : ""
                }
                ${
                  op.ver
                    ? '<div class="ui-icon-view"  data-view><a href="javascript:void(0)"  class="view"><i class="material-icons">visibility</i></a></div>'
                    : ""
                } 
                ${
                  op.inactivo
                    ? '<div class="ui-icon-update"  data-reactivar><a href="javascript:void(0)"  class="update"><i class="material-icons">update</i></a></div>'
                    : ""
                }      
               `;

                return html;
              }
            },
            { data: "codigoEstado", visible: false },
            { data: "codigoEstadoPeriodo", visible: false },
            { data: "idCampusFacultadCarrera", visible: false }
          ],
          data: data
        });
      }
    );
  }

  dibujarresumen() {
    var resumen = $("#resumen");
    resumen.html("");
    this._FichaService.ListarFichaResumen(
      {
        idPersona: this._Session.usuario.persona.idPersona,
        idPeriodo: $("#cboPeriodo").val() == "" ? 0 : $("#cboPeriodo").val(),
        idCampus: $("#cboCampus").val() == "" ? 0 : $("#cboCampus").val(),
        idCarrera: $("#cboCarrera").val() == "" ? 0 : $("#cboCarrera").val(),
        estado: $("#cboEstado").val() == "" ? 0 : $("#cboEstado").val()
      },
      respuesta => {
        var html = $("<div></div>");

        var auxiliar = respuesta as any;
        auxiliar.map(dt => {
          var carreraHTML = $(
            '<div class=" mt-3 mr-3"><h6 class="col-md-12">' +
              dt.carrera +
              "</h6></div>"
          );

          dt.fichaResumenes.map(fr => {
            if (fr.esExtraordinaria == 0) {
              var htmlOrdinario =
                '<blockquote style="margin:0px 0px 0px 20px"><div class="col-md-12" style="display:block">';
              htmlOrdinario += "Ordinario";
              htmlOrdinario += "</div>";
              htmlOrdinario += '<div class="det-resumen card">';
              htmlOrdinario +=
                "<span>Solicitante: <b>" + fr.solicitante + "</b>";
              htmlOrdinario += "</span><b>|</b>";
              htmlOrdinario +=
                "<span>Solicitadas: <b>" + fr.fichaSolicitadas + "</b></span>";
              htmlOrdinario +=
                "<b>|</b><span>Guardadas:<b>" +
                fr.fichaGuardadas +
                "</b></span>";

              htmlOrdinario +=
                "<b>|</b><span>Enviadas:<b>" + fr.fichaEnviadas + "</b></span>";

              htmlOrdinario +=
                "<b>|</b><span>Aprobadas:<b>" +
                fr.fichaAprobadas +
                "</b></span>";
              htmlOrdinario +=
                "<b>|</b><span>Observación: <b>" +
                fr.fichaObservacion +
                "</b></span>";
              htmlOrdinario +=
                "    <b>|</b><span>Eliminadas:<b>" +
                fr.fichaEliminada +
                "</b></span>";
              htmlOrdinario += "</div></blockquote>";

              carreraHTML.append(htmlOrdinario);
            } else {
              var htmlExtraordinario =
                '<blockquote style="margin:0px 0px 0px 20px"><div class="col-md-12" style="display:block">';
              htmlExtraordinario += "Extraordinario";
              htmlExtraordinario += "</div>";
              htmlExtraordinario += '<div class="det-resumen card">';

              htmlExtraordinario +=
                "<span>Guardadas:<b>" + fr.fichaGuardadas + "</b></span>";

              htmlExtraordinario +=
                "<b>|</b><span>Aprobadas:<b>" +
                fr.fichaAprobadas +
                "</b></span>";

              htmlExtraordinario +=
                "<b>|</b><span>Enviadas:<b>" + fr.fichaEnviadas + "</b></span>";

              htmlExtraordinario +=
                "<b>|</b><span>Observación: <b>" +
                fr.fichaObservacion +
                "</b></span>";
              htmlExtraordinario +=
                "    <b>|</b><span>Eliminadas:<b>" +
                fr.fichaEliminada +
                "</b></span>";
              htmlExtraordinario += "</div></blockquote>";
              carreraHTML.append(htmlExtraordinario);
            }
          });

          html.append(carreraHTML);
        });
        resumen.append(html);
      }
    );
  }
  //#endregion Privates
}
