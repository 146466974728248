<div class="wrapper-login">
  <div class="login-content">
      <div class="img-logo">
          <img class="img-fluid" src="./assets/images/logo-utp.png">
      </div>
      <form class="card form-signin p-4" autocomplete="off" [formGroup]="angForm" novalidate id="angForm">
        <input id="username" style="display:none" type="text" name="fakeusernameremembered">
        <input id="password" style="display:none" type="password" name="fakepasswordremembered">
        
        <div class="mb-3">
          <label for="inputUsuario" class="sr-only">Usuario</label>
          <input type="text" id="inputUsuario" class="form-control" placeholder="Usuario"   autofocus="" formControlName="codigo" #codigo>
          <div *ngIf="angForm.controls['codigo'].invalid && (angForm.controls['codigo'].dirty || angForm.controls['codigo'].touched)" class="alert alert-danger">
            <div *ngIf="angForm.controls['codigo'].errors.required">
              Usuario es requerido.
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="inputPassword" class="sr-only">Contraseña</label>
          <input type="password" id="inputPassword" class="  form-control" placeholder="Contraseña" formControlName="contrasenia" #contrasenia>
          <div *ngIf="angForm.controls['contrasenia'].invalid && (angForm.controls['contrasenia'].dirty || angForm.controls['contrasenia'].touched)" class="alert alert-danger">
            <div *ngIf="angForm.controls['contrasenia'].errors.required">
              Contraseña es requerido.
            </div>
          </div>
        </div>
          <div class="checkbox my-1 ">
            <label>
              <input type="checkbox" value="remember-me" id="rdbRecordame"><span class="text-small">Recordarme</span>
            </label>
          </div>
          <button  type="submit" 
          (click)="validarUsuario(angForm)"          
          class="form-group btn btn-login">Ingresar</button>
          <p id="errorLogin" style="display: none">Usuario o contraseña incorrectos.</p>
          <p class="text-small text-center">Si olvidaste tu contraseña ingresa <a href="http://contrasena.utp.edu.pe/Recuperacion/OlvideMiClave.aspx">aquí</a> para reestablecer tu contraseña.</p>
      </form>
      <div class="card form-signin p-4" id="divroles" style="display:none">
          <div class="text-center">
              <span>Bienvenid@</span>
              <h5 id="persona"> {{persona}}</h5>
              <span class="text-small">¿Con que tipo de usuario desea ingresar al sistema?</span>
          </div>
          <div class="card my-3">
              <!--<div class="row">
                  <div class="col-md-6">
                      <div class="form-check">
                          <label class="form-check-label" for="idCoordinador">Coordinador</label>
                          <input class="form-check-input" type="radio" name="rol" id="idCoordinador" value="1,2">
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-check">
                          <label class="form-check-label" for="idDocente">Docente</label>
                          <input class="form-check-input" type="radio" name="rol" id="idDocente" value="3">
                      </div>
                  </div>
              </div>-->
              <div class="btn-group btn-group-toggle buttonRol" data-toggle="buttons" id="contenedorCargos" >
          
              </div>
          </div>
          <div class="form-group">
            <button type="button"
            (click)="seleccionarrol()"
            class="form-group btn btn-login" id="loginRol">Ingresar</button>
          </div>
          <p class="text-small text-center" id="forgotPassword">Si olvidate tu contraseña ingresa <a href="youtube.com" target="_blank">aquí</a> para reestablecer tu contraseña.</p>
      </div>
  </div>
</div>
<!-- Footer -->
<footer class="sticky-footer" id="footer" style="display:none">  
    <div class="container my-auto">
      <div class="copyright text-center my-auto">
        <span>Copyright &copy; Universidad tecnológica del Perú</span>
      </div>
    </div>
  </footer>
  <!-- End of Footer -->