import { JsonPipe } from "@angular/common";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import Swal from "sweetalert2";
import * as $ from "jquery";
import { Session } from "../global/session";
import { Router } from "@angular/router";
export class MapRoute {
  /**
   *
   */
  private _Session: Session = new Session();

  constructor(private http: HttpClient) {}
  //------------------------------------------------------------------------------------------
  //-- URL - TIBA
  //------------------------------------------------------------------------------------------
  private _urlBase: string;
  get urlBase() {
    return document.querySelector("base").getAttribute("href");
  }
  //------------------------------------------------------------------------------------------
  //-- URL - TIBA
  //------------------------------------------------------------------------------------------
  private _tibaURL: string;
  get tibaURL() {
    let inputHTML = document.getElementById("tibaURL") as HTMLInputElement;

    //  return "https://tibaservices.azurewebsites.net/api/";
    //return "http://localhost/UTP.WS.TIBA.Service/api/";
    // return "http://143.208.132.83:81/SiteQA/Tiba/ws/api/";
    return inputHTML.value.toString();
  }

  //------------------------------------------------------------------------------------------
  //-- POST
  //------------------------------------------------------------------------------------------
  post(url, parametro, callback) {
    /* this.http.post(`${url}`, parametro).subscribe(res => {
      if (typeof res["idError"] != "undefined") {
        if (res["idError"] > 0) {
          if (res["mensaje"] == null) {
            Swal.fire({
              html:
                '<span style="font-weight: 600">' +
                "Error en el servidor" +
                "</span>",
              timer: 1500,
              showConfirmButton: false
            });
          } else {
            var mensaje = res["mensaje"].toString().replace(/\r\n/, "<br/>");
            //$('#confirm').find('.mensaje').html(mensaje)
            //$('#btnconfirm').trigger('click')

            Swal.fire({
              html:
                '<span style="font-weight: 600">' + res["mensaje"] + "</span>",
              timer: 1500,
              showConfirmButton: false
            });
            if (res["idError"] == 2) {
              callback(res["data"]);
            }
          }
        } else {
          if (res["mensaje"] != "" && res["mensaje"] != null) {
            Swal.fire({
              html:
                '<span style="font-weight: 600">' + res["mensaje"] + "</span>",
              timer: 1500,
              showConfirmButton: false
            });
          }
          if (callback != null) {
            callback(res["data"]);
          }
        }
      } else {
        console.log(res);
      }
    });
*/
    /*$("#previewUTP").css("display", "flex");*/
    var preloaderTimeout = null;
    let that = this;

    return $.ajax({
      beforeSend: function(request) {
        // setting a timeout

        $("#previewUTP").css("display", "flex");
        if (that._Session.token != null) {
          request.setRequestHeader(
            "Authorization",
            "Bearer " + that._Session.token
          );
        }
      },
      type: "POST",
      url: `${url}`,
      data: parametro == null ? null : JSON.stringify(parametro),
      contentType: "application/json; charset=utf-8",
      error: function(request, status, error) {
        setTimeout(() => {
          var re = /^https?:\/\/[^/]+/i;
          $("#previewUTP").css("display", "none");
          if (request.status == 401) {
            Swal.fire({
              html:
                '<span style="font-weight: 600">' +
                "La sesión ha caducado.<br/> <span style='font-size:12px;'>Vuelva a iniciar sessión <a class='closeLogin' href='" +
                "/login" +
                "'>Aqui</a>.</span> " +
                "</span>",
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: "Cerrar",
              allowOutsideClick: false
            })
            $(".closeLogin").click(() => {
               localStorage.clear();
               sessionStorage.clear();
            });
          }
        }, 500);
      },
      success: res => {
        setTimeout(() => {
          $("#previewUTP").css("display", "none");
          if (typeof res["idError"] != "undefined") {
            if (res["idError"] > 0) {
              if (res["mensaje"] == null) {
                Swal.fire({
                  html:
                    '<span style="font-weight: 600">' +
                    "Error en el servidor" +
                    "</span>",
                  timer: 1500,
                  showConfirmButton: false
                });
              } else {
                var mensaje = res["mensaje"]
                  .toString()
                  .replace(/\r\n/, "<br/>");
                //$('#confirm').find('.mensaje').html(mensaje)
                //$('#btnconfirm').trigger('click')

                Swal.fire({
                  html:
                    '<span style="font-weight: 600">' +
                    res["mensaje"] +
                    "</span>",
                  timer: 2500,
                  showConfirmButton: false
                });
                if (res["idError"] == 2) {
                  callback(res["data"]);
                }
              }
            } else {
              if (res["mensaje"] != "" && res["mensaje"] != null) {
                Swal.fire({
                  html:
                    '<span style="font-weight: 600">' +
                    res["mensaje"] +
                    "</span>",
                  timer: 2500,
                  showConfirmButton: false
                });
              }
              if (callback != null) {
                callback(res["data"]);
              }
            }
          } else {
            console.log(res);
          }
        }, 500);
      },
      dataType: "JSON"
    });
  }
}
