<div class="row justify-content-between">
    <div class="col-md-6">
        <!-- Title section -->
        <h3>Secciones asignadas</h3>
        <!-- End Title section -->
    </div>
</div>

<!-- Content section -->
<div class="card">
    <!-- Filters -->
    <h6></h6>
    <form id="frmSeccionesAsignadasFiltro" name="form" style="border:none;border-radius: none !important;" class=""
        [formGroup]="_FORMFICHA">
        <div class="col-md-10">
            <div class="row">
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="cboPeriodo">Periodo</label>
                        <input class="form-control" type="text" id="cboPeriodo" formControlName="periodo" disabled
                            #periodo>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="form-group campus" data-for="cboCampus" style="display: none;">
                        <label for="cboCampus">Campus</label>
                        <select id="cboCampus" class="form-control" data-html="Todos" (change)="ngChangeCampus($event)"
                            formControlName="campus" #campus>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group curso" data-for="cboCurso" style="display: none;">
                        <label for="cboCurso">Curso</label>
                        <select id="cboCurso" class="form-control" data-html="Todos" formControlName="curso" #curso
                        (change)="ngChangeCurso($event)"
                        >
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group curso" data-for="cboseccion" style="display: none;">
                        <label for="cboseccion">Secciones</label>
                        <select id="cboseccion" class="form-control" data-html="Todos" formControlName="seccion" #seccion>
                        </select>
                    </div>
                </div>
                <div class="col-md-3 pull-right buscar" style="display: none;">
                    <button class="btn btn-buscar" type="button" (click)="ngClickBuscarSecciones()">Buscar<i
                            class="material-icons">search</i></button>
                </div>

            </div>
        </div>
    </form>
    <!-- End Filters -->
    <!-- Table -->
    <div class="mx-3">
        <table class="table" id="frmFichaTblListarFicha">
            <thead>
                <tr>
                    <th scope="col">Sección</th>
                    <th scope="col">Curso</th>
                    <th scope="col">Fichas asignadas</th>
                    <th scope="col">Fichas sin asignar</th>
                    <th scope="col">Alumnos asignados</th>
                    <th scope="col">Alumnos sin asignar</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Opciones</th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
    <!-- End Table -->
</div>