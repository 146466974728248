import { JsonPipe } from "@angular/common";
import { ValueTransformer } from "@angular/compiler/src/util";
import { Injectable } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import "datatables.net";
import "datatables.net-responsive";
import Swal from "sweetalert2";

import { enumCargo, enumEstado, enumOpciones, enumRoles } from "./enum";

declare var $: any;
@Injectable({ providedIn: "root" })
export class utilitarios {
  enumCargo: enumCargo = enumCargo.Instance;
  enumEstado: enumEstado = enumEstado.Instance;
  enumOpciones: enumOpciones = enumOpciones.Instance;
  enumRoles: enumRoles = enumRoles.Instance;
  constructor() { }

  Datatable(element, config) {
    var _that = this;
    var defaults = {
      Url: "",
      columns: [],
      fnDrawCallback: null,
      fnRowCallback: null,
      preDrawCallback: null,
      parametros: [{ Name: "", Value: "" }],
      esAjax: false,
      data: {},
      responsive: false,
      scrollY: "1500px",
      bAutoWidth: false,
      columnDefs: null,
      sScrollX: "100%"  

    };

    var settings = $.extend({}, defaults, config);
     
    var settingsDatatable = {
      searching: false,
      ordering: false,
      bProcessing: true,
      bDestroy: true,
      iDisplayLength: 10,
      bLengthChange: false,
      bAutoWidth: settings.bAutoWidth,
      columnDefs: settings.columnDefs,
      bInfo: false,
      responsive: settings.responsive,
      scrollY: settings.scrollY,
      scrollX: settings.sScrollX,
      //"scrollX": true,

      language: {
        sProcessing: "Procesando...",
        sLengthMenu: "Mostrar _MENU_ registros",
        sZeroRecords: "No se encontraron resultados",
        sEmptyTable: "Ningún dato disponible en esta tabla",
        sInfo:
          "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
        sInfoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
        sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
        sInfoPostFix: "",
        sSearch: "Buscar:",
        sUrl: "",
        sInfoThousands: ",",
        sLoadingRecords: "Cargando...",
        oPaginate: {
          sFirst: "Primero",
          sLast: "Último",
          sNext: ">>",
          sPrevious: "<<",
        },
        oAria: {
          sSortAscending:
            ": Activar para ordenar la columna de manera ascendente",
          sSortDescending:
            ": Activar para ordenar la columna de manera descendente",
        },
      },
      preDrawCallback: function () {
        if (settings.preDrawCallback != null) settings.preDrawCallback();
      },
      fnServerParams: function (aoData) {
        aoData.push(settings.parametros);
      },
      fnDrawCallback: function (oSettings) {
        if (settings.fnDrawCallback != null) settings.fnDrawCallback();
      },
      fnRowCallback: function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        var fechas = $(nRow).find(".fecha");
        if (fechas.length > 0) {
          fechas.each(function (index) {
            if ($(this).html() != "" && !$(this).hasClass("fechacomplete")) {
              $(this).html(
                _that.ConvertFechaServidorToDMYHMS($(this).html()).toString()
              );
              $(this).addClass("fechacomplete");
            }
          });
        }

        if (settings.fnRowCallback != null)
          settings.fnRowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull);
      },
      aoColumns: settings.columns,
    };
    if (!settings.esAjax) settingsDatatable["aaData"] = settings.data;
    //     else
    //       settingsDatatable["sAjaxSource"] = getPath() + settings.Url;

    element.DataTable(settingsDatatable);
  }

  ConvertJsonDate(_fecha: String) {
    var fechaRegistro = "";
    if ($("#fechaRegistro").val() != "") {
      var txtFechaRegistro = _fecha;
      var dmy = txtFechaRegistro.toString().split("/").reverse();
      var año = parseInt(dmy[0]);
      var mes = parseInt(dmy[1]);
      var dia = parseInt(dmy[2]);
      var dateObj = new Date(año, mes - 1, dia);
      fechaRegistro = dateObj.getTime().toString() + "-0000";
      fechaRegistro = "/Date(" + fechaRegistro + ")/";
    }

    return fechaRegistro;
  }

  ConvertFechaServidorToDMYHMS(fechaServidor: String) {
    var resultado: String = "";
    if (fechaServidor != null && fechaServidor != "") {
      var format = new Date(fechaServidor.toString());
      var dia = format.getDate().toString();
      dia = (parseInt(dia) < 10 ? "0" + dia : dia).toString();
      var mes = (format.getMonth() + 1).toString();
      mes = (parseInt(mes) < 10 ? "0" + mes : mes).toString();
      var año = format.getFullYear().toString();
      var hora =
        format.getHours() < 10 ? "0" + format.getHours() : format.getHours();
      var minuto =
        format.getMinutes() < 10
          ? "0" + format.getMinutes()
          : format.getMinutes();
      var segundo =
        format.getSeconds() < 10
          ? "0" + format.getSeconds()
          : format.getSeconds();
      var hms = " " + hora + ":" + minuto + ":" + segundo;
      hms = hora > 0 ? hms : "";
      resultado = dia + "/" + mes + "/" + año + hms;
    }
    return resultado;
  }

  ConvertFechaServidorToJsonDMY(fechaServidor: String) {
    var resultado: any = null;
    if (fechaServidor != null && fechaServidor != "") {
      var format = null;
      var dia = 0;
      var mes = 0;
      var año = 0;

      if (fechaServidor.indexOf("/") > 0) {
        dia = parseInt(fechaServidor.split("/")[0].toString());
        mes = parseInt(fechaServidor.split("/")[1].toString());
        año = parseInt(fechaServidor.split("/")[2].toString());
      } else {
        format = new Date(fechaServidor.toString());
        dia = parseInt(format.getDate().toString());
        mes = parseInt((format.getMonth() + 1).toString());
        año = parseInt(format.getFullYear().toString());
      }

      return {
        year: año,
        month: mes,
        day: dia,
      };
    }
    return null;
  }
  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  limpiarTodosLosInput(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.setValue("");
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  Mensaje(mensaje: string) {
    Swal.fire({
      html: '<span style="font-weight: 600">' + mensaje + "</span>",
      timer: 1500,
      showConfirmButton: false,
    });
  }
  ConfirmWarning(config) {
    var defaults = {
      title: "Mensaje",
      text: "",
      yesCallback: null,
      noCallback: null,
    };
    var settings = $.extend({}, defaults, config);
    Swal.fire({
      //title: settings.title,
      html:
        '<span style="font-weight: 400; font-size: 1.3">' +
        settings.text +
        "</span>",
      //type: "warning",
      showConfirmButton: true,
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#d01123",
      confirmButtonClass: "buttonSwal",
      showCancelButton: true,
      cancelButtonText: "No, cancelar acción",
      //cancelButtonColor: "#d01123",
      cancelButtonClass: "buttonSwal",
    }).then((willDelete) => {
      if (willDelete.value) {
        //   Swal.fire("Aceptó");
        if (settings.yesCallback != null) {
          settings.yesCallback(willDelete.value);
        }
      } else {
        // Swal.fire("Canceló");
        if (settings.noCallback != null) {
          settings.noCallback(willDelete.value);
        }
      }
    });
  }
  createAndDownloadBlobFile(body, filename, extension = "") {
    /* var dec = window.atob(body);
    var myArr = new Uint8Array(dec.length);
    for (var i = 0; i < Object.keys(dec).length; i++) {
      myArr[i] = dec.charCodeAt(i);
    }*/
    const blob = new Blob([body], { type: "application/vnd.ms-excel" });
    const fileName = `${filename}`;

    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        link.innerHTML = "dd";
        document.body.appendChild(link);
        link.click(); //Dispara su evento click de ese elemento
        document.body.removeChild(link);
      }
    }
  }
  CerrarModal(id: string) {
    $("#" + id).modal("hide");
  }

  OpenModal(id: string) {
    $("#" + id).modal("show");
  }

  verBrowserArchivo(data: string) {
    var winparams =
      "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes," +
      "resizable,screenX=50,screenY=50,width=850,height=1050";

    var htmlPop =
      "<embed width=100% height=100%" +
      ' type="application/pdf"' +
      ' src="data:application/pdf;base64,' +
      escape(data) +
      '"></embed>';

    var printWindow = window.open("", "PDF", winparams);
    printWindow.document.write(htmlPop);
    printWindow.print();
  }
  descargarArchivo(base64, filename, extension) {
    var binaryString = window.atob(base64); // Comment this if not using base64
    var bytes = new Uint8Array(binaryString.length);
    let buffer = bytes.map(function (byte, i) {
      return binaryString.charCodeAt(i);
    });

    if (extension === void 0) {
      extension = "pdf";
    }

    var blob = new Blob([buffer]);
    var fileNameRESULT = (extension == null) ? filename : filename + "." + extension;
    if (extension == 'omitir') {
      fileNameRESULT = filename;
    }
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileNameRESULT);
    } else {
      var link = document.createElement("a");
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileNameRESULT);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
