import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';



@Component({
    selector: 'nav-navigation',
    template: 
        `<nav class="navbar navbar-expand-sm bg-light" id="nav-layout-menu" >
        <div class="container-fluid">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a routerLink="login" class="nav-link" routerLinkActive="active">
                Login
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="resumenficha" class="nav-link" >
                resumenficha
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="solicitudficha" class="nav-link" >
                solicitudficha
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="ficha" class="nav-link" >
                ficha
              </a>
            </li>
          </ul>
        </div>
      </nav>`
})

export class NavComponent {
    @Input() id: string;
    private element: any;
    constructor(private el: ElementRef) {
        //--> Obtiene el elemento con el id
        this.element = el.nativeElement;
    }

  
}