<div class="row justify-content-between">
    <div class="col-md-6">
        <!-- Title section -->
        <h3>Solicitud de fichas</h3>
        <i class="material-icons" data-toggle="modal" data-target="#myModal" id="myModalAgregar">add_circle</i>
        <!-- End Title section -->
    </div>
</div>
<!-- Content section -->
<div class="card">
    <!-- Filters -->
    <h6>Filtros</h6>
    <div class="row justify-content-between m-0">
        <div class="col-md-10">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="cboPeriodo">Periodo</label>
                        <select id="cboPeriodo" [(ngModel)]="model.idPeriodo"></select>
                        <!--<input type="text" class="form-control" id="cboPeriodo"  placeholder="Enter">-->
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="cboAcademico">Coordinador Academico</label>
                       <select id="cboAcademico" (change)="ngChange_coordinadorAcademico($event)"></select> 
                      
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="cboCarrera">Carrera</label>
                        <select id="cboCarrera" data-html="todos"  ></select>
                    </div>
                </div>
          
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="fechaRegistro">Fecha Registro</label>

                        <div class="input-group">
                            <input class="form-control dateUtp" [(ngModel)]="model.fechaRegistro" type="text"
                                placeholder="Seleccione" id="fechaRegistro" ngbDatepicker #d="ngbDatepicker" required
                                readonly />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar-utp"
                                    (click)="d.toggle();onClickCalendarUTP(d)" type="button"><i
                                        class="material-icons">event</i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <button class="btn btn-buscar" type="submit" (click)="onListarResumenFichas()">
                Buscar<i class="material-icons">search</i>
            </button>
        </div>
    </div>
    <!-- End Filters -->
    <!-- Table -->
    <div class="mx-3">
        <table class="table" id="tblListar">
            <thead>
                <tr>
                    <th scope="col">Periodo</th>
                    <th scope="col">Campus</th>
                    <th scope="col">Carrera</th>
                    <th scope="col">Coordinador académico</th>
                    <th scope="col">Fec. Registro</th>
                    <th scope="col">Solicitadas</th>
                    <th scope="col">Fec. Límite</th>
                    <th scope="col">Aprob.ord</th>
                    <th scope="col">Aprob.extr</th>
                    <th scope="col">Tipo carga</th>
                    <th scope="col">Opciones</th>
                </tr>
            </thead>
            <tbody>

            </tbody>
        </table>
    </div>
    <!-- End Table -->
</div>
<!-- End Content section -->
<!--<div>
    <h1>Home</h1>
    <p>{{bodyText}}</p>
    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#myModal" (click)="openModal('custom-modal-1')">Open Modal 1</button>
    <button (click)="openModal('custom-modal-2')">Open Modal 2</button>
</div>
-->
<p style="display:none">{{message}}</p>
<app-resumenficha-agregar (onListarResumenFichas)="onListarResumenFichas()"
    (onInitEventoGrilla)="onInitEventoGrilla($event)" (messageEvent)="receiveMessage($event)" [parameter]="parameter">
</app-resumenficha-agregar>