import { Component, OnInit } from '@angular/core';
import { ReporteService } from '../_servicio/reporte.service';
import { ReporteProduccionFichasFiltroBE } from '../model/reporte/ReporteProduccionFichasFiltroBE';
import { ReporteListarProduccionFichasBE } from '../model/reporte/ReporteListarProduccionFichasBE';
import { utilitarios } from 'src/global/utilitarios';
import { ComboboxService } from "../_servicioComponentes/combobox.service";
import { Session } from 'src/global/session';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ArchivoBaseBE } from '../model/reporte/ArchivoBaseBE';

@Component({
  selector: 'app-reporteproduccionfichas',
  templateUrl: './reporteproduccionfichas.component.html',
  styleUrls: ['./reporteproduccionfichas.component.scss']
})
export class ReporteproduccionfichasComponent implements OnInit {

  _utilitarios: utilitarios = new utilitarios();
  _dropdownSettings: any = {};
  _dropdownSettingColumna: any = {};
  _dropdownSettingSinBuscar: any = {};

  _dropdownListPeriodo = [];
  _selectedItemsPeriodo = [];
  _dropdownListCampus = [];
  _selectedItemsCampus = [];
  _dropdownListCarrera = [];
  _selectedItemsCarrera = [];
  _dropdownListAcademico = [];
  _selectedItemsAcademico = [];

  _dropdownListColor = [];
  _selectedItemsColor = [];

  _dropdownListColumna = [];
  _selectedItemsColumna = [];


  _FORMFICHA: FormGroup;

  public _BUILDER: any = {
    _selectedItemsPeriodo: ["", ""],
    _selectedItemsCampus: ["", ""],
    _selectedItemsCarrera: ["", ""],
    _selectedItemsAcademico: ["", ""],
    _selectedItemsColor: ["", ""],
    _selectedItemsColumna: ["", Validators.required],
  };
  constructor(
    private _ReporteService: ReporteService,
    private _clscombobox: ComboboxService,
    private formBuilder: FormBuilder,
    private _Session: Session) {
    this._FORMFICHA = formBuilder.group(this._BUILDER);

  }

  ngOnInit(): void {
    this.onClearSelectAll();
    $('[class="container-fluid pb-5"]').removeAttr('style')


    Promise.all([this.comboPeriodo(), this.comboboCampus(), this.comboCarrera()
      , this.comboAcademico(), this.comboColor(),
    this.comboColumnas()
    ]).then(() => {
      this._dropdownSettings = {
        singleSelection: false,
        text: "Todos",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Deseleccionar todos",
        enableSearchFilter: true,
        badgeShowLimit: 14,
        classes: "multipleRepositorio custom-class ",
        searchPlaceholderText: "Buscar",

      };
      this._dropdownSettingColumna = {
        singleSelection: false,
        text: "Seleccione",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Deseleccionar todos",
        enableSearchFilter: false,
        classes: "multipleRepositorio custom-class ",

      };
      this._dropdownSettingSinBuscar = {
        singleSelection: false,
        text: "Todos",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Deseleccionar todos",
        enableSearchFilter: false,
        classes: "multipleRepositorio custom-class ",

      };
      this.dibujarGrilla();

    })

  }
  ngClick_BuscarDatos(event) {

    if (!$('._selectedItemsColumna').is(':hidden')) {

      this._FORMFICHA.controls["_selectedItemsColumna"].setValidators([Validators.required]);
      //this.formFicha.controls["_selectedItemsColumna"].setValue("");
      this._FORMFICHA.controls["_selectedItemsColumna"].markAsTouched({
        onlySelf: true,
      });
      this._FORMFICHA.controls["_selectedItemsColumna"].updateValueAndValidity();
      if (!this._FORMFICHA.controls["_selectedItemsColumna"].invalid) {
        this.dibujarGrilla();
      }

    } else {

      this._FORMFICHA.controls["_selectedItemsColumna"].clearValidators();
      this._FORMFICHA.controls["_selectedItemsColumna"].setErrors(null);
      this._FORMFICHA.controls["_selectedItemsColumna"].reset();
      this.dibujarGrilla();
    }


  }
  ngClick_DescargarArchivo(event) {

    if (!$('._selectedItemsColumna').is(':hidden')) {

      this._FORMFICHA.controls["_selectedItemsColumna"].setValidators([Validators.required]);
      //this.formFicha.controls["_selectedItemsColumna"].setValue("");
      this._FORMFICHA.controls["_selectedItemsColumna"].markAsTouched({
        onlySelf: true,
      });
      this._FORMFICHA.controls["_selectedItemsColumna"].updateValueAndValidity();
      if (!this._FORMFICHA.controls["_selectedItemsColumna"].invalid) {
        this._ReporteService.DescargarArchivoExcelProduccionFichas(this.parametrosFiltros(), (data: ArchivoBaseBE) => {
          this._utilitarios.descargarArchivo(data.archivoBase64, data.nombreArchivo, null)
        })
      }

    } else {

      this._FORMFICHA.controls["_selectedItemsColumna"].clearValidators();
      this._FORMFICHA.controls["_selectedItemsColumna"].setErrors(null);
      this._FORMFICHA.controls["_selectedItemsColumna"].reset();
      this._ReporteService.DescargarArchivoExcelProduccionFichas(this.parametrosFiltros(), (data: ArchivoBaseBE) => {
        this._utilitarios.descargarArchivo(data.archivoBase64, data.nombreArchivo, null)
      })
    }

  }
  ngChange_selectedItemsColor(event) {


  }
  onClearSelectAll() {
    setInterval((item) => {
      $(".multipleRepositorio span.clear-all").each((index, element) => {
        var parent = $(element).parents(".multipleRepositorio");
        if ($(element).find(".repositorio").length == 0) {
          parent
            .find("span.clear-all")
            .html('<span class="repositorio">x</span>');
          $(element)
            .find(".repositorio")
            .click((event) => {
              this._FORMFICHA.controls[parent.attr("formcontrolname")].setValue(
                []
              );
              if (parent.attr('formcontrolname') == '_selectedItemsColor') {
                this._selectedItemsColor = [];
                this._FORMFICHA.controls["_selectedItemsColumna"].reset();
                $('._selectedItemsColumna').fadeOut();
              }
            });
        }
      });
    }, 1);
  }

  ngItemSelect_color(item: any) {
    console.log(':: selected ::')
    console.log(this._selectedItemsColor)
    if (this._selectedItemsColor.length > 0) {
      $('._selectedItemsColumna').fadeIn();
    } else {
      this._selectedItemsColor = [];
      this._FORMFICHA.controls["_selectedItemsColumna"].reset();
      $('._selectedItemsColumna').fadeOut();
    }
  }
  ngItemDeSelect_color(item: any) {
    console.log(':: deselected ::')
    console.log(this._selectedItemsColor)
    if (this._selectedItemsColor.length > 0) {
      $('._selectedItemsColumna').fadeIn();
    } else {
      this._selectedItemsColor = [];
      this._FORMFICHA.controls["_selectedItemsColumna"].reset();
      $('._selectedItemsColumna').fadeOut();
    }
  }
  ngSelectAll_color(items: any) {
    console.log(':: SelectAll ::')
    console.log(this._selectedItemsColor)
    if (items.length > 0) {
      $('._selectedItemsColumna').fadeIn();
    } else {
      this._selectedItemsColor = [];
      this._FORMFICHA.controls["_selectedItemsColumna"].reset();
      $('._selectedItemsColumna').fadeOut();
    }
  }
  ngDeSelectAll_color(items: any) {
    console.log(':: DeSelectAll ::')
    console.log(this._selectedItemsColor)
    this._selectedItemsColor = [];
    $('._selectedItemsColumna').fadeOut();
    this._FORMFICHA.controls["_selectedItemsColumna"].reset();
  }

  //===============================================================================================
  //-- Privado
  //===============================================================================================

  comboboCampus() {
    return this._clscombobox.getJson(
      {
        entidad: "mCampusPorPersona",
        parametro: { "@p_idPersona": this._Session.usuario.persona.idPersona },
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListCampus.push({
            id: parseInt(item.id),
            itemName: item.nombre,
          });

          // this._selectedItemsCampus = this._dropdownListCampus;
        });

      }
    );
  }

  comboCarrera() {
    return this._clscombobox.getJson(
      {
        entidad: "mCarreraPorPersonaCampus"
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListCarrera.push({
            id: parseInt(item.id),
            itemName: item.nombre,
          });
          // this._selectedItemsCarrera = this._dropdownListCarrera;
        });
      }
    );

  }
  comboPeriodo() {
    return this._clscombobox.getJson(
      {
        entidad: "mPeriodo"
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListPeriodo.push({
            id: parseInt(item.id),
            itemName: item.nombre,
          });

          //this._selectedItemsPeriodo = this._dropdownListPeriodo;
        });
      }
    );

  }

  comboAcademico() {
    this._clscombobox.getJson(
      {
        entidad: "mAcademicosFichasPorCoordInvestigacion",
        parametro: {
          "@p_idPersona": this._Session.usuario.persona.idPersona,
          "@p_codigoCargo":       this._Session.CargoElegido
        }
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListAcademico.push({
            id: parseInt(item.id),
            itemName: item.nombre,
          });

          // this._selectedItemsAcademico = this._dropdownListAcademico;
        });
      }
    );


  }

  comboColor() {

    this._clscombobox.getJson(
      {
        entidad: "mColores",

      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListColor.push({
            id: item.id,
            itemName: item.nombre,
          });

          // this._selectedItemsAcademico = this._dropdownListAcademico;
        });
      }
    );

  }

  comboColumnas() {
    this._clscombobox.getJson(
      {
        entidad: "ListarProduccionFichas_columnas",
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListColumna.push({
            id: item.id,
            itemName: item.nombre,
          });
          // this._selectedItemsAcademico = this._dropdownListAcademico;
        });
      }
    );
  }

  parametrosFiltros() {
    let parametro: ReporteProduccionFichasFiltroBE = {} as ReporteProduccionFichasFiltroBE;
    parametro.idPeriodoArray = "";
    parametro.idCampusArray = "";
    parametro.idCarreraArray = "";
    parametro.idCoodirnadorAcademicoArray = "";
    parametro.colorCantidadOrdinarioArray = "";
    parametro.columnNameArray = "";

    if ((this._FORMFICHA.controls["_selectedItemsPeriodo"].value != null
      && this._FORMFICHA.controls["_selectedItemsPeriodo"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsPeriodo"].value.forEach(element => {
        parametro.idPeriodoArray += element.id + ','
      });
    }
    if ((this._FORMFICHA.controls["_selectedItemsCampus"].value != null
      && this._FORMFICHA.controls["_selectedItemsCampus"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsCampus"].value.forEach(element => {
        parametro.idCampusArray += element.id + ','
      });
    }
    if ((this._FORMFICHA.controls["_selectedItemsCarrera"].value != null
      && this._FORMFICHA.controls["_selectedItemsCarrera"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsCarrera"].value.forEach(element => {
        parametro.idCarreraArray += element.id + ','
      });
    }
    if ((this._FORMFICHA.controls["_selectedItemsAcademico"].value != null
      && this._FORMFICHA.controls["_selectedItemsAcademico"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsAcademico"].value.forEach(element => {
        parametro.idCoodirnadorAcademicoArray += element.id + ','
      });

    }

    if ((this._FORMFICHA.controls["_selectedItemsColor"].value != null
      && this._FORMFICHA.controls["_selectedItemsColor"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsColor"].value.forEach(element => {
        parametro.colorCantidadOrdinarioArray += element.id + ','
      });
    }
    if ((this._FORMFICHA.controls["_selectedItemsColumna"].value != null
      && this._FORMFICHA.controls["_selectedItemsColumna"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsColumna"].value.forEach(element => {
        parametro.columnNameArray += element.id + ','
      });
    }
    return parametro;
  }
  dibujarGrilla() {
    var that = this;


    this._ReporteService.ListarProduccionFichas(
      this.parametrosFiltros(),
      (data: ReporteListarProduccionFichasBE[]) => {

        var table = $("#tblReporte");
        this._utilitarios.Datatable(table, {
          columns: [

            { data: "periodo", class: "small_col text-center" },
            { data: "nombreCampus" },
            { data: "nombreCarrera", class: "small_col" },
            { data: "coordinadorAcademicoSolicitado" },
            { data: "cantidadMatriculados" },
            { data: "cantidadProyectada" },
            {
              data: "cantidadSolicitud",
              render: (index, display, rowdata: ReporteListarProduccionFichasBE) => {
                if (rowdata.cantidadSolicitud != null) {

                  return '<span class="table-span-cell " style="background:' + rowdata.colorCantidadSolicitud + '">' + rowdata.cantidadSolicitud + '</span>'
                } else {
                  return "";
                }

              }
            },
            {
              data: "cantidadOrdinario",
              render: (index, display, rowdata: ReporteListarProduccionFichasBE) => {
                if (rowdata.cantidadOrdinario != null) {
                  return '<span class="table-span-cell " style="background:' + rowdata.colorCantidadOrdinario + '">' + rowdata.cantidadOrdinario + '</span>'
                } else {
                  return "";
                }

              }
            },
            { data: "cantidadExtraordinario" },
            { data: "cantidadIntegrado" },
            { data: "totalPorCarrera" },



          ],
          data: data,
          fnDrawCallback: () => {



          }
        });
      }
    );
  }

}
