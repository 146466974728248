
<input type="hidden" id="lblDocentes" value="">

<div class="modal fade" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{solicitarFichas}} - <span>Al docente</span></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCerrarModel()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        
            <form name="form" class="card form-signin p-4" onkeydown="return event.key != 'Enter';" [formGroup]="formSolicitudFicha" (ngSubmit)="onEnviarDatos()"
                novalidate id="formSolicitudFicha">
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="frmAgregarSolicitud_campus">Campus</label>
                            <select id="frmAgregarSolicitud_campus" name="frmAgregarSolicitud_campus"
                                class="form-control" formControlName="idCampus" #idCampus
                                (change)="onChangeCampus($event)">
                            </select>
                            <div *ngIf="formSolicitudFicha.controls['idCampus'].invalid && (formSolicitudFicha.controls['idCampus'].dirty || formSolicitudFicha.controls['idCampus'].touched )"
                                class="alert alert-danger">
                                <div *ngIf="formSolicitudFicha.controls['idCampus'].errors.required">Campus es requerido
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="frmAgregarSolicitud_periodo">Periodo</label>
                            <select id="frmAgregarSolicitud_periodo" name="frmAgregarSolicitud_periodo"
                                class="form-control" formControlName="idPeriodo" #idPeriodo>
                            </select>
                            <div *ngIf="formSolicitudFicha.controls['idPeriodo'].invalid && (formSolicitudFicha.controls['idPeriodo'].dirty || formSolicitudFicha.controls['idPeriodo'].touched )"
                                class="alert alert-danger">
                                <div *ngIf="formSolicitudFicha.controls['idPeriodo'].errors.required">Periodo es
                                    requerido</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="frmAgregarSolicitud_fechalimite">Fecha Limite</label>
                            <input class="form-control" type="text" placeholder="Fecha Limite"
                                id="frmAgregarSolicitud_fechalimite" name="frmAgregarSolicitud_fechalimite"
                                formControlName="fechaLimite" #fechaLimite disabled />
                            <div *ngIf="formSolicitudFicha.controls['fechaLimite'].invalid && (formSolicitudFicha.controls['fechaLimite'].dirty || formSolicitudFicha.controls['fechaLimite'].touched )"
                                class="alert alert-danger">
                                <div *ngIf="formSolicitudFicha.controls['fechaLimite'].errors.required">Fecha Limite es
                                    requerido</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="frmAgregarSolicitud_carrera">Carrera</label>
                            <select id="frmAgregarSolicitud_carrera" name="frmAgregarSolicitud_carrera"
                                class="form-control" formControlName="idCarreraSolicitud" #idCarreraSolicitud (change)="onChangeCarreraSolicitud($event)">
                            </select>
                            <div *ngIf="formSolicitudFicha.controls['idCarreraSolicitud'].invalid && (formSolicitudFicha.controls['idCarreraSolicitud'].dirty || formSolicitudFicha.controls['idCarreraSolicitud'].touched )"
                                class="alert alert-danger">
                                <div *ngIf="formSolicitudFicha.controls['idCarreraSolicitud'].errors.required">Seleccione una 
                                    carrera</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <h5 class="uiH5">Pendientes:<span>{{pendiente}}</span></h5>
                    </div>
                </div>
                <div class="row justify-content-md-center">
                    <div class="col-md-10">
                        <div class="card m-0 p-0">
                            <h4>Seleccionar docentes</h4>
                            <div class="row m-0 header-asig">
                                <div class="col-md-1 px-2">
                                    <div class="form-group">
                                        <label for="chkAll">Todos</label>
                                        <input type="checkbox" id="chkAll" name="chkAll"
                                            (change)="onSeleccionarTodos($event)">
                                        <label for="chkAll"></label>
                                    </div>
                                </div>
                                <div class="col-md-11">
                                    <div class="form-group">
                                        <div class="px-2"><label>(Ingrese el docente y presione Enter para realizar la busquedad.)</label></div>
                                        <label for="lblBuscar"><i class="material-icons">search</i></label>
                                        <input type="text" class="form-control" autocomplete="off" id="lblBuscar"
                                            (keyup)="onChangeBuscarDocente($event)">
                                    </div>
                                </div>
                            </div>
                            <div class=" content-asig" id="divDocentesDinamico">
                                <div class="row mx-0">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 text-center">
                        <h5 class="uiH5 pt-2">Fichas asignadas:<span>{{solicitadasCnt}}</span></h5>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="reset" class="btn btn-primary" id="frmAgregarSolicitud_resetear"
                        style="display:none">resetear</button>
                    <button type="submit" class="btn btn-save col-md-4">Guardar<i
                            class="material-icons">save</i></button>
                    <!-- this button is hidden, used to close from typescript -->
                    <!--<button type="button" id="close-modal" class="btn btn-danger" (click)="onCerrarModel()"
                        data-dismiss="modal">
                        Close
                    </button>-->
                </div>
            </form>
        </div>
    </div>
</div>