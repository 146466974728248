import { Injectable } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { MapRoute } from "../../global/maproute";
@Injectable({
  providedIn: "root"
})
export class ProyeccionService {
  private _MapRoute: MapRoute = null;
  private urlUsuario: string = "";
  constructor(private http: HttpClient) {
    this._MapRoute = new MapRoute(http);
    this.urlUsuario = this._MapRoute.tibaURL + "Proyeccion";
  }
  Listar(parametro, callback) {
    var model = {
      idPeriodo: parametro.idPeriodo
    };
    this._MapRoute.post(`${this.urlUsuario}/Listar`, parametro, callback);
  }
  ListarProyeccionDetallePorProyeccion(parametro, callback) {
    var model = {
      idPeriodo: parametro.idPeriodo,
      idPersona: parametro.idPersona,
      idProyeccion: parametro.idProyeccion,
      idCampus: parametro.idCampus,
      idCarrera: parametro.idCarrera,
      codigoCargo: parametro.codigoCargo
    };
    this._MapRoute.post(
      `${this.urlUsuario}/ListarProyeccionDetallePorProyeccion`,
      parametro,
      callback
    );
  }

  ListarProyeccionDetallePorArchivo(parametro, callback) {
    var model = { 
      idProyeccion: parametro.idProyeccion,
 
    };
    this._MapRoute.post(
      `${this.urlUsuario}/ListarProyeccionDetallePorArchivo`,
      parametro,
      callback
    );
  }


  ObtenerArchivoProyeccion(parametro, callback) {
    var model = {
      idProyeccion: parametro.idProyeccion
    };
    this._MapRoute.post(
      `${this.urlUsuario}/ObtenerArchivoProyeccion`,
      parametro,
      callback
    );
  }
 
  Registrar(parametro, callback) {
    var model = {
      idPeriodo: parametro.idPeriodo,
      nombreArchivo: parametro.nombreArchivo,
      idPersonaRegistra: parametro.idPersonaRegistra,
      archivoProyeccion: parametro.archivoProyeccion
    };
    this._MapRoute.post(`${this.urlUsuario}/Registrar`, parametro, callback);
  }

  EnviarCargaProyeccion(parametro, callback) {
    var model = {
      idPersona: parametro.idPersona,
      idPeriodo: parametro.idPeriodo
    };
    this._MapRoute.post(
      `${this.urlUsuario}/EnviarCargaProyeccion`,
      parametro,
      callback
    );
  }

  AprobarRechazarCargarProyeccion(parametro, callback) {
    var model = {
      idPeriodo: parametro.idPeriodo,
      idPersona: parametro.idPersona,
      esRechazado: parametro.esRechazado
    };
    this._MapRoute.post(
      `${this.urlUsuario}/AprobarRechazarCargarProyeccion`,
      parametro,
      callback
    );
  }

  ActualizarFactorProyeccion(parametro, callback) {
    var model = {
      esTodoCampus: parametro.idPeriodo,
      factor: parametro.factor,
      idPersonaRegistra: parametro.idPersonaRegistra,
      idPeriodo: parametro.idPeriodo,
      idProyeccionDetalle: parametro.idProyeccionDetalle,
      idCarrera: parametro.idCarrera,
      idProyeccion: parametro.idProyeccion
    };
    this._MapRoute.post(
      `${this.urlUsuario}/ActualizarFactorProyeccion`,
      parametro,
      callback
    );
  }

  DescargarArchivoExcel(parametro) {
    var link = document.createElement("a");

    link.href =
      this._MapRoute.tibaURL +
      "archivo/excel?idProyeccion=" +
      parametro.idProyeccion;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
