import {
    Component,
    ViewChild,
    OnInit,
    ElementRef,
    Output,
    Input,
    EventEmitter,
    ɵConsole,
    NgModule,
    Injectable
  } from "@angular/core";
  import { JsonPipe, getLocaleDateTimeFormat } from "@angular/common";
  import { Router } from "@angular/router";
  
  import pdfMake from "pdfmake/build/pdfmake.js"; // Could not find a declaration file for
  import pdfFonts from "pdfmake/build/vfs_fonts";
  import {
    PreguntaAsocidasModel,
    PreguntaFichaModel,
    PreguntaMaestraModel
  } from "../model/PreguntaAsocidasModel";
  
  import { FichaBE } from "../model/fichaModel";
  import { inject } from "@angular/core/testing";
  import { ResumenfichaListarComponent } from "src/app/resumenficha/resumenficha-listar/resumenficha-listar.component";
  
  import { utilitarios } from "../../global/utilitarios";
  @Injectable({
    providedIn: "root"
  })

export class fichaReport {
  /**
   *
   */
  private _ficha: FichaBE;
  private _utilitarios: utilitarios = new utilitarios();
  constructor() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  descargarPDF(
    ficha: FichaBE,
    preguntas: Array<PreguntaFichaModel>,
    maestras: Array<PreguntaMaestraModel>
  ) {
    this._ficha = ficha;
    const documentDefinition = this.getDocumentDefinition(preguntas, maestras);
    pdfMake.createPdf(documentDefinition).download();
  }
  openPDF(
    ficha: FichaBE,
    preguntas: Array<PreguntaFichaModel>,
    maestras: Array<PreguntaMaestraModel>
  ) {
    this._ficha = ficha;
    const documentDefinition = this.getDocumentDefinition(preguntas, maestras);
    pdfMake.createPdf(documentDefinition).open();
  }
  private getDocumentDefinition(
    preguntas: Array<PreguntaFichaModel>,
    maestras: Array<PreguntaMaestraModel>
  ) {
    let rect = {
      type: "rect",
      x: 15,
      y: 0, //could be negative to account for padding
      w: 30,
      h: 1,
      color: "red"
    };
    return {
      content: [
        {},
        {
          text: "FICHA DE TRABAJO DE INVESTIGACIÓN",
          bold: true,
          fontSize: 20,
          alignment: "center",
          margin: [0, 0, 0, 0]
        },

        {
          text: "(A SER LLENADA POR UN DOCENTE)",
          bold: true,
          fontSize: 12,
          alignment: "center",
          margin: [0, 0, 0, 0]
        },
        this.getLineCanvas(),
        {
          columns: [
            {
              text: "DATOS DEL DOCENTE",
              style: ["jobTitle"]
            }
          ]
        },

        {
          layout: this.getLayoutTable(),
          margin: [0, 6, 0, 6],
          table: {
            widths: ["auto", "*"],
            headerRows: 1,
            body: [
              [
                {
                  text: "APELLIDOS Y NOMBRES:",
                  style: ["labelPanel"],
                  relativePosition: { y: 2, x: 0 }
                },
                {
                  text: this._ficha.nombreDocenteRegistra,
                  style: ["labelPanelData"],
                  border: [false, false, false, true]
                }
              ]
            ]
          }
        },

        {
          layout: this.getLayoutTable(),
          margin: [0, 6, 0, 6],
          table: {
            widths: ["auto", "*", "auto", "*"],
            headerRows: 1,
            body: [
              [
                {
                  text: "CARRERA:",
                  style: ["labelPanel"],
                  relativePosition: { y: 2, x: 0 }
                },
                {
                  text: this._ficha.carrera,
                  style: ["labelPanelData"],

                  border: [false, false, false, true]
                },
                {
                  text: "CAMPUS:",
                  style: ["labelPanel"],
                  relativePosition: { y: 2, x: 0 }
                },
                {
                  text: this._ficha.campus,
                  style: ["labelPanelData"],

                  border: [false, false, false, true]
                }
              ]
            ]
          }
        },
        {
          layout: this.getLayoutTable(),
          margin: [0, 6, 0, 6],
          table: {
            widths: ["auto", "*", "auto", "*"],
            headerRows: 1,
            body: [
              [
                {
                  text: "CÓDIGO:",
                  style: ["labelPanel"],
                  relativePosition: { y: 2, x: 0 }
                },
                {
                  text: this._ficha.codigoDocente,
                  style: ["labelPanelData"],
                  border: [false, false, false, true]
                },
                {
                  text: "FECHA:",
                  style: ["labelPanel"],
                  relativePosition: { y: 2, x: 0 }
                },
                {
                  text: this._utilitarios.ConvertFechaServidorToDMYHMS(
                    this._ficha.fechaRegistro.toString()
                  ),
                  style: ["labelPanelData"],
                  border: [false, false, false, true]
                }
              ]
            ]
          }
        },
        this.getLineCanvas(),
        {
          columns: [
            {
              text: "DATOS DE LOS ALUMNOS (EN CASO DE TENERLOS IDENTIFICADOS) ",
              style: ["jobTitle"]
            }
          ]
        },
        {
          layout: this.getLayoutTable(),
          margin: [0, 6, 0, 6],
          table: {
            widths: ["auto", "*", "auto", 60],
            body: this.dibujarAlumnos()
          }
        },
        this.getLineCanvas(),
        {
          layout: this.getLayoutTable2(),
          table: {
            widths: ["auto", "*"],
            body: this.dibujarBodyPreguntas(preguntas, maestras)
          }
        }
      ],
      info: {
        title: "_RESUME",
        author: "Jhoel",
        subject: "RESUME",
        keywords: "RESUME, ONLINE RESUME"
      },
      styles: {
        labelPanel2: {
          fontSize: 9,
          border: [false, false, false, false],

          bold: true
        },
        labelPanel: {
          fontSize: 9,
          border: [false, false, false, false]
        },
        labelPanelData: {
          fontSize: 9,
          alignment: "left",
          margin: [0, 0, 0, 0]
        },
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 20, 0, 10],
          decoration: "underline"
        },
        background1: {
          background: "#5190a5",
          border: [true, true, true, true],
          width: "*"
        },
        name: {
          fontSize: 16,
          bold: true
        },
        jobTitle: {
          fontSize: 10,
          bold: true
        },
        sign: {
          margin: [0, 50, 0, 10],
          alignment: "right",
          italics: true
        },
        tableHeader: {
          bold: true
        }
      }
    };
  }
  private dibujarAlumnos() {
    let respuesta = [];

    this._ficha.fichaAlumnos.map(item => {
      if(item.codigo!=""){

        respuesta.push([
          {
            text: "APELLIDOS Y NOMBRES:",
            style: ["labelPanel"],
            relativePosition: { y: 2, x: 0 }
          },
          {
            text: item.nombre,
            style: ["labelPanelData"],
  
            border: [false, false, false, true]
          },
          {
            text: "Codigo:",
            style: ["labelPanel"],
            relativePosition: { y: 2, x: 0 }
          },
          {
            text: item.codigo,
            style: ["labelPanelData"],
  
            border: [false, false, false, true]
          }
        ]);
      }

    });

    if(respuesta.length==0){
      respuesta=

      [
                    [
                      {
                        text: "APELLIDOS Y NOMBRES:",
                        style: ["labelPanel"],
                        relativePosition: { y: 2, x: 0 }
                      },
                      {
                        text: " ",
                        style: ["labelPanelData"],
      
                        border: [false, false, false, true]
                      },
                      {
                        text: "Codigo:",
                        style: ["labelPanel"],
                        relativePosition: { y: 2, x: 0 }
                      },
                      {
                        text: " ",
                        style: ["labelPanelData"],
      
                        border: [false, false, false, true]
                      }
                    ]
                  ]
      
      
    }
    return respuesta;
  }
  private dibujarBodyPreguntas(
    preguntas: Array<PreguntaFichaModel>,
    maestras: Array<PreguntaMaestraModel>
  ) {
    let array = [];
    preguntas.map(item => {
      let row = [];

      row.push({
        text: item.formatoIndice + ".",
        style: ["labelPanel2"]
      });
      row.push({
        text: item.descripcion,
        style: ["labelPanel2"]
      });

      array.push(row);
      array.push([{}, this.asignarRespuesta(item, maestras)]);
    });

    return array;
  }
  private asignarRespuesta(
    item: PreguntaFichaModel,
    maestras: Array<PreguntaMaestraModel>
  ) {
    let arrayInv: Array<any>;
    let respuesta: any;
    let colTbl: any = {};
    let formatoIndice = item.formatoIndice;
    switch (parseInt(formatoIndice)) {
      case 1:
        respuesta = {
          text: this._ficha.problemaInvestigacion,
          style: ["labelPanelData"],
          border: [false, false, false, true]
        };
        break;
      case 2:
        respuesta = {
          text: this._ficha.lineaInvestigacion,
          style: ["labelPanelData"],
          border: [false, false, false, true]
        };
        break;
      case 3:
        colTbl = {
          margin: [20, 0, 0, 0],
          table: {
            body: [
              [
                {
                  text: "Nro.",
                  style: ["labelPanel2"]
                },
                {
                  text: "Competencias",
                  style: ["labelPanel2"]
                }
              ]
            ]
          }
        };
        this._ficha.fichaCompetencias.map((item, index) => {
          colTbl.table.body.push([
            {
              text: (index + 1).toString(),
              style: ["labelPanelData"],
              alignment: "center"
            },
            { text: item.competencia, style: ["labelPanelData"] }
          ]);
        });
        respuesta = colTbl;
        break;

      case 4:
        respuesta = {
          text: this._ficha.tituloFicha,
          style: ["labelPanelData"],
          border: [false, false, false, true]
        };

        break;

      case 5:
        colTbl = {
          margin: [20, 0, 0, 0],
          table: {
            body: [
              [
                {
                  text: "Tipo de investigación",
                  style: ["labelPanel2"]
                },
                {
                  text: "Marca con X",
                  style: ["labelPanel2"]
                }
              ]
            ]
          }
        };

        arrayInv = maestras.filter(
          x => x.codigoPregunta == item.codigoPreguntaFicha
        );
        arrayInv.map(item => {
          colTbl.table.body.push([
            {
              text: item.nombre,
              style: ["labelPanelData"]
            },
            {
              text: this._ficha.idTipoInvestigacion == item.id ? "x" : "",
              style: ["labelPanelData"]
            }
          ]);
        });

        respuesta = colTbl;
        break;

      case 6:
        colTbl = {
          margin: [20, 0, 0, 0],
          table: {
            body: [
              [
                {
                  text: "Método  de investigación",
                  style: ["labelPanel2"]
                },
                {
                  text: "Marca con X",
                  style: ["labelPanel2"]
                }
              ]
            ]
          }
        };

        arrayInv = maestras.filter(
          x => x.codigoPregunta == item.codigoPreguntaFicha
        );
        arrayInv.map(item => {
          colTbl.table.body.push([
            {
              text: item.nombre,
              style: ["labelPanelData"]
            },
            {
              text: this._ficha.idMetodoInvestigacion == item.id ? "x" : "",
              style: ["labelPanelData"]
            }
          ]);
        });

        respuesta = colTbl;
        break;
      case 7:
        colTbl = {
          margin: [20, 0, 0, 0],
          table: {
            body: [
              [
                {
                  text: "Palabra Clave",
                  style: ["labelPanel2"]
                },
                {
                  text: "Repositorios",
                  style: ["labelPanel2"]
                }
              ]
            ]
          }
        };

        this._ficha.fichaPalabraClaves.map(itemPalabra => {
          let repositorio = [];
          itemPalabra.fichaPalabraClaveRepositorios.map(itemRepo => {
            repositorio.push(itemRepo.repositorio);
          });
          colTbl.table.body.push([
            { text: itemPalabra.palabraClave, style: ["labelPanelData"] },
            { text: repositorio.join(","), style: ["labelPanelData"] }
          ]);
        });

        respuesta = colTbl;
        break;
      case 8:
        respuesta = {
          text: this._ficha.observacionAccionesEspecificacion,
          style: ["labelPanelData"],
          border: [false, false, false, true]
        };
        break;
      case 9:
        respuesta = {
          text: this._ficha.actividadesClaves,
          style: ["labelPanelData"],
          border: [false, false, false, true]
        };
        break;
      case 10:
        respuesta = {
          text: this._ficha.incorporaObservaciones,
          style: ["labelPanelData"],
          border: [false, false, false, true]
        };
        break;

      case 11:
        colTbl = {
          margin: [20, 0, 0, 0],
          table: {
            widths: [150, 50],
            body: [
              [
                {
                  text:
                    "¿Cuenta con la autorización para el uso de información?",
                  style: ["labelPanel2"],
                  alignment: "center"
                },
                {
                  text: "Marcar con X",
                  style: ["labelPanel2"],
                  alignment: "center"
                }
              ]
            ]
          }
        };
        arrayInv = maestras.filter(
          x => x.codigoPregunta == item.codigoPreguntaFicha
        );

        arrayInv.map(item => {
          colTbl.table.body.push([
            {
              text: item.nombre,
              style: ["labelPanelData"],
              alignment: "center"
            },
            {
              text: this._ficha.esAutorizacionInformacion == item.id ? "x" : "",
              style: ["labelPanelData"]
            }
          ]);
        });

        respuesta = [];
        respuesta.push(colTbl);

        if (this._ficha.esAutorizacionInformacion == 1) {
          respuesta.push({
            layout: this.getLayoutTable(),
            margin: [20, 20, 0, 0],
            table: {
              body: [
                [
                  {
                    text: "Indique nombre de la entidad:",
                    style: ["labelPanel2"],

                    border: [false, false, false, false]
                  },
                  {
                    text: this._ficha.descripcionEntidadesAutorizadas,
                    style: ["labelPanelData"],
                    border: [false, false, false, true]
                  }
                ]
              ]
            }
          });
        }
        break;
      case 12:
        colTbl = {
          margin: [20, 0, 0, 0],

          table: {
            widths: [150, 50],
            body: [
              [
                {
                  text:
                    "¿recomienda que el proyecto de trabajo de investigación resultante sea evaluado por el comité de ética?",
                  style: ["labelPanel2"],
                  alignment: "center"
                },
                {
                  text: "Marcar con X",
                  style: ["labelPanel2"],
                  alignment: "center"
                }
              ]
            ]
          }
        };
        arrayInv = maestras.filter(
          x => x.codigoPregunta == item.codigoPreguntaFicha
        );
        arrayInv.map(item => {
          colTbl.table.body.push([
            {
              text: item.nombre,
              style: ["labelPanelData"],
              alignment: "center"
            },
            {
              text: this._ficha.esEvaluadoPorComiteEtica == item.id ? "x" : "",
              style: ["labelPanelData"]
            }
          ]);
        });
        respuesta = colTbl;
        break;
      default:

        colTbl = {
          margin: [20, 0, 0, 0],
          table: {
            widths: ["*", "auto", "auto", "*"],
            body: [
              [
                {
                  text: "Nombres y Apellidos",
                  style: ["labelPanel2"]
                },
                {
                  text: "Carrera",
                  style: ["labelPanel2"]
                },
                {
                  text: "Campus",
                  style: ["labelPanel2"]
                },
                {
                  text: "Fecha de aprobación",
                  style: ["labelPanel2"]
                }
              ]
            ]
          }
        };
        colTbl.table.body.push([
          {
            text: this._ficha.nombreCoordinadorAcademico,
            style: ["labelPanelData"]
          },
          { text: this._ficha.carreraAcademico, style: ["labelPanelData"] },
          { text: this._ficha.campusAcademico, style: ["labelPanelData"] },
          {
            text: this._utilitarios.ConvertFechaServidorToDMYHMS(
              this._ficha.fechaAprobacion?.toString()
            ),
            style: ["labelPanelData"]
          }
        ]);
        respuesta = colTbl;
        
        break;
    }
    return respuesta;
  }
  private getLayoutTable() {
    return {
      defaultBorder: false,
      paddingLeft: function(i, node) {
        return 0;
      },
      paddingRight: function(i, node) {
        return 3;
      },
      paddingTop: function(i, node) {
        return 0;
      },
      paddingBottom: function(i, node) {
        return 0;
      },
      vLineStyle: function(i, node) {
        return { dash: { length: 2 } };
      },
      hLineStyle: function(i, node) {
        return { dash: { length: 2, space: 1 } };
      }
    };
  }
  private getLayoutTable2() {
    return {
      defaultBorder: false,
      paddingLeft: function(i, node) {
        return 0;
      },
      paddingRight: function(i, node) {
        return 3;
      },
      paddingTop: function(i, node) {
        return 12;
      },
      paddingBottom: function(i, node) {
        return 0;
      },

      vLineStyle: function(i, node) {
        return { dash: { length: 2 } };
      },
      hLineStyle: function(i, node) {
        return { dash: { length: 2, space: 1 } };
      }
    };
  }
  private getLineCanvas() {
    return {
      margin: [0, 12, 0, 12],
      canvas: [
        {
          type: "line",
          x1: 0,
          y1: 0,
          x2: 515,
          y2: 0,
          lineWidth: 1
        }
      ]
    };
  }
}

