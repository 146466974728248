import {
  Component,
  ViewChild,
  OnInit,
  ElementRef,
  Output,
  Input,
  EventEmitter,
  ɵConsole,
  NgModule,
} from "@angular/core";
import { JsonPipe, getLocaleDateTimeFormat } from "@angular/common";
import { Router } from "@angular/router";
import * as $ from "jquery";

//---------------------------------------------------------------------
//-- Clases Locales
//---------------------------------------------------------------------

import { FichaService } from "../../_servicio/ficha.service";
import { ArchivoService } from "../../_servicio/archivo.service";
import { ComboboxService } from "../../_servicioComponentes/combobox.service";
import { PreguntaFichaService } from "../../_servicio/preguntaFicha.service";
import { BancoService } from "../../_servicio/banco.service";
///--
import { BancoArchivoBinarioBE, BancoArchivoTipoDocumentoBE } from "../../model/BancoModel";
///--
import { Session } from "../../../global/session";
import { utilitarios } from "../../../global/utilitarios";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { DataTablesModule } from "angular-datatables";
import Swal from "sweetalert2";
import { FichaBE } from "../../model/fichaModel";
import { fichaReport } from "../../report/fichaReport";
import { PersonaService } from 'src/app/_servicio/persona.service';
import { PersonaBE } from 'src/app/model/PersonaModel';
import { convertActionBinding } from '@angular/compiler/src/compiler_util/expression_converter';
@Component({
  selector: "app-ficha-agregar",
  templateUrl: "./ficha-agregar.component.html",
  styleUrls: ["./ficha-agregar.component.scss"],
})
export class FichaAgregarComponent implements OnInit {
  @Output() dibujarGrilla = new EventEmitter();
  @Output() dibujarresumen = new EventEmitter();
  @Input() tipoCarga: number = 0;

  mensajeInformativo: any = [];
  settings = {};

  model = {
    //  tipoInvestigacion: "",
    // tallerInvestigacion: "",
    informacionEntidad: "",
    eticaInvestigacion: "",
    lineaInvestigacion: "",
  };
  esTitulo: boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: any = {};
  formFicha: FormGroup;
  nombreApellidos: string = "";
  idPeriodo: number = 0;
  _formTipoDocumentoLoad: boolean = true;
  _tipoDocumento: string = "";
  _tipoDocumentoArchivo: string = "";
  _tipoDocumentoArchivoBase64: string = "";
  _BancoArchivoBinarioBE: BancoArchivoBinarioBE[] = [];
  _fichaDocumentosFinales: BancoArchivoTipoDocumentoBE[] = [];
  _idCampusFacultadCarrera: number = 0;
  _idFicha: number = 0;
  _idBanco: number = null;
  _codigoEstadoFicha: string = "";
  _codigoEstadoBanco: string = "";
  _totalConfirmadosPorSeccion: number = 0;
  _disabledMultiSelect: boolean = false;
  _preguntasInformacionEntidad: Array<any> = [];
  _preguntasActividad: Array<any> = [];


  esObservacion: boolean = false;
  esEnviado: boolean = false;
  esExtraordinario: boolean = false;

  _eliminadosRepositorio: Array<any> = [];
  divCompentencias = null;
  //-->Error
  errorChkCompetencia: boolean = false;

  errorPalabraClave: boolean = true;

  _utilitarios: utilitarios = new utilitarios();
  constructor(
    private _PersonaService: PersonaService,
    private formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private combobox: ComboboxService,
    private _ArchivoService: ArchivoService,
    private _FichaService: FichaService,
    private _Session: Session,
    private _PreguntaFichaService: PreguntaFichaService,
    private _BancoService: BancoService,
    private _fichaReport: fichaReport
  ) {
    this.buildFormSolicitud();
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      text: "Seleccionar",
      selectAllText: "Seleccionar todos",
      unSelectAllText: "Deseleccionar todos",
      enableSearchFilter: false,
      classes: "multipleRepositorio custom-class",
    };

    this.divCompentencias = $("#divCompentencias");
    this.nombreApellidos = this._Session.usuario.persona.nombreApellidos;

    this.onInitCombobox();
    this.cargarRepositorios();
    this.periodoVigente();
    Promise.all([
      this.dibujartipoInvestigacion(),
      this.dibujartallerInvestigacion(),
      this.dibujarMensajeInformativo(),
    ]).then(() => {
      // this.formFicha.controls["tipoInvestigacion"].setValue("");
      this.dibujarinformacionEntidad();
      this.dibujareticaInvestigacion();

      this.eventos();
    });

    /*
    this.dibujarDatosAsesor({
      codigo: this._Session.usuario.persona.codigo,
      nombreApellidos: this._Session.usuario.persona.nombreApellidos,
      correo: this._Session.usuario.persona.correo,
      telefono: this._Session.usuario.persona.telefono
    });
*/
  }
  ngAfterViewInit() {
    const parentDialog = this.elementRef.nativeElement.parentNode.parentNode;
    parentDialog.classList.add("modal-xl");
    parentDialog.classList.remove("modal-lg");

    this.onClearSelectAll();
    this.onChangesForm();

    $(document).ready((r) => {
      // this.removerTodaValidacionPalabraClave();
      this.onChangePalabrasClaves();
    });
  }
  ngClickImprimir() {
    this._utilitarios.OpenModal("modalImpresion");
  }
  ngClickVerPDF() {
    let parameter: FichaBE = {
      idFicha: this._idFicha,
    } as FichaBE;
    this._FichaService.ImprimirFichaPDF(parameter, (data) => {
      var registro = Date.now();

      this._utilitarios.verBrowserArchivo(data);
    });
    /**
         this._PreguntaFichaService.ObtenerPreguntasAsociadas().then((data: any) => {
      this._FichaService.ObtenerPorId(
        {
          idFicha: this._idFicha,
        },
        (ficha) => {
          this._fichaReport.openPDF(
            ficha,
            data.preguntaFichas,
            data.preguntaMaestras
          );
        }
      );
    });
     */
  }
  ngClickDescargarPDF() {
    let parameter: FichaBE = {
      idFicha: this._idFicha,
    } as FichaBE;
    this._FichaService.ImprimirFichaPDF(parameter, (data) => {
      var registro = Date.now();

      this._utilitarios.descargarArchivo(
        data,
        "ficha" + registro.toLocaleString(),
        "pdf"
      );
    });
    /*
     this._PreguntaFichaService.ObtenerPreguntasAsociadas().then((data: any) => {
      this._FichaService.ObtenerPorId(
        {
          idFicha: this._idFicha,
        },
        (ficha) => {
          this._fichaReport.descargarPDF(
            ficha,
            data.preguntaFichas,
            data.preguntaMaestras
          );
        }
      );
    });
    */
  }

  ngClick_EliminarDocumento(parametro: BancoArchivoBinarioBE, index, esDocumentoFinal: boolean = false) {
    Swal.fire({
      //title
      //text
      html:
        "Al confirmar la ficha, se estará eliminando el documento.</br><b>¿Está seguro en continuar?</b>",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#43b12b",
      confirmButtonText: "Si",
      cancelButtonColor: "red",
      cancelButtonText: "No",
    }).then(
      (confirm) => {
        if (confirm.value) {
          this._BancoService.EliminarDocumentoBancoFicha(parametro, (data) => {
            if (esDocumentoFinal) {
              let encontro = this._fichaDocumentosFinales[index];
              let indexBanco = encontro.bancoArchivoBinarios.findIndex(x => x.idBancoArchivoBinario == parametro.idBancoArchivoBinario)
              encontro.bancoArchivoBinarios.splice(indexBanco, 1);
              this._fichaDocumentosFinales.splice(index, 1)
            } else {

              this._BancoArchivoBinarioBE.splice(index, 1);
            }

          });
        }
      },
      function () {
        return false;
      }
    );
  }
  ngClick_MostrarTipoDocumento() {
    this._formTipoDocumentoLoad = true;
    this._tipoDocumento = "";
    this._tipoDocumentoArchivo = "";
    this._tipoDocumentoArchivoBase64 = "";
    this._utilitarios.OpenModal('modalTipoDocumento');
    $('[data-for="modalTipoDocumento"]').addClass('background-modal');

  }

  ngClick_cerrarmodalTipoDocumento(event) {
    $('[data-for="modalTipoDocumento"]').removeClass('background-modal');
    this._utilitarios.CerrarModal("modalTipoDocumento");

  }
  ngClick_DescargarDocumento(parametro: BancoArchivoBinarioBE) {
    this._BancoService.ObtenerBancoFichaDocumento(
      parametro,
      (data: BancoArchivoBinarioBE) => {
        this._utilitarios.descargarArchivo(
          data.archivoBase64,
          data.nombreArchivo,
          "omitir"
        );
      }
    );
  }
  //#region combobox
  onInitCombobox() {
    this.cargarCombobox();
  }

  ngkeydown_buscarAdministrativo(event) {

    if (event.currentTarget) {
      let codigoAlumno1 = $("#formFicha_codigoAlumno1").val();
      let codigoAlumno2 = $("#formFicha_codigoAlumno2").val();
      if (codigoAlumno1 == codigoAlumno2
        && (codigoAlumno1 != '' || codigoAlumno2 != '')
      ) {

        Swal.fire({
          html:
            'El codigo no puede ser duplicado.',
          timer: 2500,
          showConfirmButton: false
        });

      } else {

        let index = $(event.currentTarget).attr('data-index');
        let parametro: PersonaBE = {} as PersonaBE;
        parametro.idPeriodo = this.idPeriodo;
        parametro.idFicha = this._idFicha;
        parametro.codigo = event.currentTarget.value;

        $("#formFicha_NombreAlumno" + index).removeClass('find');
        this._PersonaService.ObtenerPersonPorCodigoPeopleSoft(parametro, (data) => {
          if (data.nombres == "") {
            this.formFicha.controls["NombreAlumno" + index].setValue('');

          } else {
            $("#formFicha_NombreAlumno" + index).addClass('find');
            this.formFicha.controls["NombreAlumno" + index].setValue(data.nombres + " " + data.apellidos);
          }
        })
      }

    }
  }

  ngChange_txtCodigo(event) {

    this.formFicha.controls["NombreAlumno1"].setValue("");

    //if ($('#chkExtraordinaria').prop('checked')) {
    if ($('#chkExtraordinaria').val() == '2') {
      this.esExtraordinario = true;
      if (this.formFicha.controls["codigoAlumno1"].value.toString() != "") {

        this.formFicha.controls["NombreAlumno1"].setValidators([
          Validators.required,
        ]);
        this.formFicha.controls["NombreAlumno1"].setValue("");
        this.formFicha.controls["NombreAlumno1"].markAsTouched({
          onlySelf: true,
        });
      }
    }
  }


  onChangeCodigo2(event) {
    if ($("#formFicha_codigoAlumno2").val().toString().trim() == "") {
      if (
        $("#formFicha_codigoAlumno2").val().toString().trim() == "" &&
        $("#formFicha_NombreAlumno2").val().toString().trim() != ""
      ) {
        this.formFicha.controls["codigoAlumno2"].setValidators([
          Validators.required,
        ]);
        this.formFicha.controls["codigoAlumno2"].setValue("");
      } else {
        this.formFicha.controls["NombreAlumno2"].clearValidators();
        this.formFicha.controls["NombreAlumno2"].setErrors(null);
        this.formFicha.controls["NombreAlumno2"].reset();

        this.formFicha.controls["codigoAlumno2"].clearValidators();
        this.formFicha.controls["codigoAlumno2"].setErrors(null);
        this.formFicha.controls["codigoAlumno2"].reset();
      }
    } else {
      if ($("#formFicha_NombreAlumno2").val().toString().trim() != ""
        || (!$("#formFicha_NombreAlumno2").hasClass('find')
          && $("#formFicha_codigoAlumno2").val() != '')
      ) {


        this.formFicha.controls["NombreAlumno2"].setValidators([
          Validators.required,
        ]);
        this.formFicha.controls["NombreAlumno2"].setValue("");
        this.formFicha.controls["NombreAlumno2"].markAsTouched({
          onlySelf: true,
        });
      }/* else if (
       $("#formFicha_NombreAlumno2").val().toString().trim() == ""
        
        ) { 
        alert('2')
        $("#formFicha_codigoAlumno2").val('');
        $("#formFicha_NombreAlumno2").removeClass('find')
        this.formFicha.controls["NombreAlumno2"].setValidators([
          Validators.required,
        ]);
        this.formFicha.controls["NombreAlumno2"].setValue("");
      }*/
      else if ($("#formFicha_NombreAlumno2").hasClass('find')) {
     

        $("#formFicha_codigoAlumno2").val('');
        $("#formFicha_NombreAlumno2").removeClass('find');

        this.formFicha.controls["NombreAlumno2"].clearValidators();
        this.formFicha.controls["NombreAlumno2"].setErrors(null);
        this.formFicha.controls["NombreAlumno2"].reset();

      }
    }
  }

  onChangeNombreAlumno2(event) {
    if ($("#formFicha_NombreAlumno2").val().toString().trim() == "") {
      if (
        $("#NombreAlumno2").val().toString().trim() == "" &&
        $("#codigoAlumno2").val().toString().trim() != ""
      ) {
        this.formFicha.controls["NombreAlumno2"].setValidators([
          Validators.required,
        ]);
        this.formFicha.controls["NombreAlumno2"].setValue("");
      } else {
        this.formFicha.controls["NombreAlumno2"].clearValidators();
        this.formFicha.controls["NombreAlumno2"].setErrors(null);
        this.formFicha.controls["NombreAlumno2"].reset();
        this.formFicha.controls["codigoAlumno2"].clearValidators();
        this.formFicha.controls["codigoAlumno2"].setErrors(null);
        this.formFicha.controls["codigoAlumno2"].reset();
      }
    } else {
      if ($("#codigoAlumno2").val().toString().trim() == "") {
        this.formFicha.controls["codigoAlumno2"].setValidators([
          Validators.required,
        ]);
        this.formFicha.controls["codigoAlumno2"].setValue("");
      }
    }
  }

  onChangeExtraordinario(event) {
    var chk = $(event.target);

    //if (chk.is(":checked")) {
    if (chk.val() == '2') {

      this.esExtraordinario = true;
      this.formFicha.controls["NombreAlumno1"].setValidators([
        Validators.required,
      ]);
      this.formFicha.controls["NombreAlumno1"].setValue("");

      this.formFicha.controls["codigoAlumno1"].setValidators([
        Validators.required,
      ]);
      this.formFicha.controls["codigoAlumno1"].setValue("");
      //Alumno 2
      /*
      this.formFicha.controls["NombreAlumno2"].setValidators([
        Validators.required
      ]);
      this.formFicha.controls["NombreAlumno2"].setValue("");

      this.formFicha.controls["codigoAlumno2"].setValidators([
        Validators.required
      ]);
      this.formFicha.controls["codigoAlumno2"].setValue("");
     */

      $(".alumnoUno").find("input[data-index]").prop("disabled", false);
      $(".alumnoDos").find("input[data-index]").prop("disabled", false);

      $(".sectionExtraordinaria").show();
    } else {
      this.esExtraordinario = false;
      this.formFicha.controls["NombreAlumno1"].clearValidators();
      this.formFicha.controls["NombreAlumno1"].setErrors(null);
      this.formFicha.controls["NombreAlumno1"].reset();
      this.formFicha.controls["codigoAlumno1"].clearValidators();
      this.formFicha.controls["codigoAlumno1"].setErrors(null);
      this.formFicha.controls["codigoAlumno1"].reset();
      //
      this.formFicha.controls["NombreAlumno2"].clearValidators();
      this.formFicha.controls["NombreAlumno2"].setErrors(null);
      this.formFicha.controls["NombreAlumno2"].reset();
      this.formFicha.controls["codigoAlumno2"].clearValidators();
      this.formFicha.controls["codigoAlumno2"].setErrors(null);
      this.formFicha.controls["codigoAlumno2"].reset();
      $(".sectionExtraordinaria").hide();
    }
  }
  onChangeCampus(event) {
    var entidad = "";
    entidad = this.obtenerEntidadCombobox("fa");
    var params =
      this._Session.rolMarcado ==
        this._utilitarios.enumRoles.coordinadorInvestigacion
        ? {
          "@p_idCampus": $(event.target).val(),
        }
        : {
          // "@p_idPersona": this._Session.usuario.persona.idPersona,
          "@p_idCampus": $(event.target).val(),
        };
    this.combobox.init(
      {
        id: "formFicha_cboFacultad",
        entidad: entidad,
        parametro: params,
      },
      (select) => {
        this.combobox.Clear("formFicha_cboCarrera");
      }
    );
  }

  onChangeFacultad(event) {
    var entidad = "";
    entidad = this.obtenerEntidadCombobox("ca");
    var params =
      this._Session.rolMarcado ==
        this._utilitarios.enumRoles.coordinadorInvestigacion
        ? {
          "@p_idCampus": $("#formFicha_cboCampus").val(),
          "@p_idFacultad": $(event.target).val(),
        }
        : {
          "@p_idPersona": this._Session.usuario.persona.idPersona,
          "@p_idCampus": $("#formFicha_cboCampus").val(),
          "@p_idFacultad": $(event.target).val(),
        };
    this.combobox.init(
      {
        id: "formFicha_cboCarrera",
        entidad: entidad,
        parametro: params,
      },
      null
    );
  }

  onChangeCarrera(event) {
    if (event.currentTarget == event.target) {
      this.dibujarLineaInvestigacion($(event.target).val()).then(() => {
        this.dibujarCompetencias($(event.target).val());
      });
    }
  }

  onClearSelectAll() {
    setInterval((item) => {
      $(".multipleRepositorio span.clear-all").each((index, element) => {
        var parent = $(element).parents(".multipleRepositorio");
        if ($(element).find(".repositorio").length == 0) {
          parent
            .find("span.clear-all")
            .html('<span class="repositorio">x</span>');
          $(element)
            .find(".repositorio")
            .click((event) => {
              this.formFicha.controls[parent.attr("formcontrolname")].setValue(
                []
              );
            });
        }
      });
    }, 1);
  }

  //#endregion combobox

  //#region Formulario

  onChangeInformacionEntidad() {
    this.formFicha.controls["informacionEntidadObservacion"].setValue("");

    if ($("#formFicha_informacionEntidad").val() == "1") {
      $(".informacionEntidad").show();
      this.formFicha.controls["informacionEntidadObservacion"].setValidators([
        Validators.required,
      ]);
      this.formFicha.controls["informacionEntidadObservacion"].setValue("");
    } else {
      $(".informacionEntidad").hide();
      this.formFicha.controls[
        "informacionEntidadObservacion"
      ].clearValidators();
      this.formFicha.controls["informacionEntidadObservacion"].setErrors(null);
      this.formFicha.controls["informacionEntidadObservacion"].reset();
    }
  }
  ngClick_GuardarAdjuntarTipoDocumento(event) {
    this._formTipoDocumentoLoad = false;
    if (this._tipoDocumentoArchivo != ""
      && this._tipoDocumentoArchivoBase64 != ""
      && this._tipoDocumento != "") {
      this._BancoService.AdjuntarDocumentoFicha(
        {
          archivoBase64: this._tipoDocumentoArchivoBase64,
          idFicha: this._idFicha,
          nombreArchivo: this._tipoDocumentoArchivo,
          idDocumentoProceso: 2,
          idTipoDocumento: this._tipoDocumento,
          idPersonaRegistra: this._Session.usuario.persona.idPersona,
        },
        (data) => {
          console.log(this._fichaDocumentosFinales)
          let encontro = this._fichaDocumentosFinales.find(x => x.idTipoDocumento == parseInt(this._tipoDocumento));
          let model: BancoArchivoTipoDocumentoBE = typeof (encontro) == 'undefined' ? {} as BancoArchivoTipoDocumentoBE : encontro;
          let modelArchivo: BancoArchivoBinarioBE = {} as BancoArchivoBinarioBE;
          model.bancoArchivoBinarios = model.bancoArchivoBinarios == null ? [] as BancoArchivoBinarioBE[] :
            model.bancoArchivoBinarios;
          model.idTipoDocumento = parseInt(this._tipoDocumento) as number;
          model.tipoDocumento = $('#cboTipoDocumento').find('option:selected').html();
          model.nombreApellidos = this._Session.usuario.persona.nombreApellidos;
          model.fechaEjecucion = this._utilitarios.ConvertFechaServidorToDMYHMS(data.fechaEjecucion) as string;
          modelArchivo.nombreArchivo = data.nombreArchivo;
          modelArchivo.idBancoArchivoBinario = data.idBancoArchivoBinario;

          if (model.bancoArchivoBinarios.length == 0) {
            model.bancoArchivoBinarios.push(modelArchivo)
          } else {
            model.bancoArchivoBinarios[0] = modelArchivo
          }

          if (typeof (encontro) == 'undefined') {
            this._fichaDocumentosFinales.push(model)
          }
          $('[data-for="modalTipoDocumento"]').removeClass('background-modal');
          this._utilitarios.CerrarModal("modalTipoDocumento");
        }
      );

    }

  }
  reset_formTipoDocumentoLoad() {

    this._formTipoDocumentoLoad = false;
  }
  ngChange_preguntasInformacionEntida(event, ayudaLista) {

    let radio = $(event.currentTarget)
    var elName = radio.attr('ng-reflect-form-control-name') as string;

    if (radio.prop('checked') && this.formFicha.controls[elName].value == 1) {

      if (ayudaLista.id == "INVINF") {
        $(".informacionEntidad").show();
        this.formFicha.controls["informacionEntidadObservacion"].setValidators([
          Validators.required,
        ]);
        this.formFicha.controls["informacionEntidadObservacion"].setValue("");
      }

    } else {
      $(".informacionEntidad").hide();
      this.formFicha.controls[
        "informacionEntidadObservacion"
      ].clearValidators();
      this.formFicha.controls["informacionEntidadObservacion"].setErrors(null);
      this.formFicha.controls["informacionEntidadObservacion"].reset();
    }


  }
  //*aqui
  onEnviarDatos(op) {
    //----------------------------------------------------------------------------
    //-- Verificar Observaciones (Acadeimico/Docente)
    //---------------------------------------------------------------------------
    if (this.tipoCarga == 1 || this.tipoCarga == 2) {
      var codigoEstadoAproObs = "";
      var htmlSinObservacion = "";
      var parametro = {
        idCampusFacultadCarrera: 0,
        codigoCargo: this._Session.CargoElegido,
        codigoEstadoProcesado: "",
        idFicha: this._idFicha,
        idPersonaRegistro: this._Session.usuario.persona.idPersona,
        fichaMovimientoPreguntas: [],
        ficha: {},
      };
      var selectorQueryTextArea =
        this.tipoCarga == 1
          ? ".observacionMovimiento div[data-for][data-numero]:not(:hidden)"
          : ".observacionMovimiento div[data-for][data-numero]:not(:hidden) .observacionMovimiento-detalle > div";

      $(selectorQueryTextArea).each((index, event) => {
        var divPreguntas = $(event);
        var textArea = divPreguntas.find("textarea");
        if (this.tipoCarga == 1) {
          if (
            textArea.val().toString().trim() == "" &&
            $(
              "div[id='" +
              divPreguntas.attr("data-for") +
              "_acordion'] .observacionMovimiento-detalle > div[data-id]"
            ).length == 0
          ) {
            htmlSinObservacion += `${$(event).attr("data-numero")},`;
          }
        } else {
          if (textArea.length > 0) {
            if (
              textArea.val().toString().trim() == "" &&
              $(event).find('input[value="REPODO"]:checked').length > 0
            ) {
              htmlSinObservacion += `Nro.${$(event)
                .parents("div[data-for]")
                .attr("data-numero")}:La observacion  ${index + 1
                } no puede estar vacia al ser rechazada.,`;
            }
          }
        }
      });

      if (htmlSinObservacion != "") {
        var html = `
        <blockquote>
        <dl style="text-align:left">
        <dt>Las siguiente puntos no tienen observaciones:</dt>
        <dd>${htmlSinObservacion
            .toString()
            .substr(0, htmlSinObservacion.length - 1)}</dd>         
        </dl>
        </blockquote> 
        `;
        Swal.fire({
          html: html,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      } else {
        let esGuardadoPorAcademico = 0;
        let entrar = false;

        if (
          (this._Session.rolMarcado ==
            this._utilitarios.enumRoles.coordinadorAcademico ||
            this._Session.rolMarcado ==
            this._utilitarios.enumRoles.revisor ||
            this._Session.rolMarcado ==
            this._utilitarios.enumRoles.administrador) &&
          this._codigoEstadoFicha == this._utilitarios.enumEstado.Enviado &&
          (op == 'guardar' ||
            op == "enviar" ||
            op == "aprobar")
        ) {
          esGuardadoPorAcademico = 1;
          entrar = true;

        }
        if (op == "enviar" || op == "aprobar" || entrar) {
          //---------------------------------------------------------------------------------------
          //-- Es enviado por el  academico al Docente
          //---------------------------------------------------------------------------------------

          if (this.tipoCarga == 1) {

            if (op == 'guardar') {
              parametro.codigoEstadoProcesado = this._utilitarios.enumEstado.Enviado;
            } else {
              parametro.codigoEstadoProcesado =
                op == "aprobar"
                  ? this._utilitarios.enumEstado.Aprobadas
                  : this._utilitarios.enumEstado.Observado;
            }


            parametro.idCampusFacultadCarrera = this._idCampusFacultadCarrera;
            this._codigoEstadoFicha = parametro.codigoEstadoProcesado;
            $('.radioAprobadoObservado  input[type="radio"]:checked').each(
              (index, event) => {
                var divPreguntas = $(event).parents("span");
                var estadoChecked = $(event).val();
                var estado = "";

                // Verificando observaciones
                if (estadoChecked == this._utilitarios.enumEstado.Aprobadas) {
                  parametro.fichaMovimientoPreguntas.push({
                    codigoPregunta: divPreguntas.attr("id"),
                    codigoEstado: estadoChecked,
                    observacion: null,
                  });
                  estado = this._utilitarios.enumEstado.Inactivo;

                  $(
                    ".observacionMovimiento > div[data-for='" +
                    divPreguntas.attr("id") +
                    "'] .observacionMovimiento-detalle div[data-codigoestado]"
                  ).each((iom, om) => {
                    var codigoEstado = $(om).attr("data-codigoestado");
                    if (
                      codigoEstado == this._utilitarios.enumEstado.Observado ||
                      codigoEstado ==
                      this._utilitarios.enumEstado.RechazadoDocente ||
                      codigoEstado ==
                      this._utilitarios.enumEstado.ObservacionAprobadaDocente
                    ) {
                      parametro.fichaMovimientoPreguntas.push({
                        codigoPregunta: divPreguntas.attr("id"),
                        codigoEstado: estado,
                        idFichaMovimientoPreguntaObservacion: $(om).attr(
                          "data-id"
                        ),
                      });
                    }
                  });
                } else {
                  estado = this._utilitarios.enumEstado.Enviado;
                  var observacionMovimientoDetalle = $(
                    ".observacionMovimiento > div[data-for='" +
                    divPreguntas.attr("id") +
                    "']"
                  );

                  var textareaObsMovimiento = observacionMovimientoDetalle.find(
                    "div.observacion-nuevo textarea"
                  );
                  if (textareaObsMovimiento.val().toString().trim() != "") {
                    parametro.fichaMovimientoPreguntas.push({
                      codigoPregunta: divPreguntas.attr("id"),
                      codigoEstado: estado,
                      idFichaMovimientoPreguntaObservacion: null,
                      observacion: textareaObsMovimiento.val(),
                    });
                  }

                  observacionMovimientoDetalle
                    .find(".observacionMovimiento-detalle")
                    .children("div[data-codigoestado]")
                    .each((index, obs) => {
                      var idFichaMovimientoPreguntaObservacion = $(obs).attr(
                        "data-id"
                      );
                      parametro.fichaMovimientoPreguntas.push({
                        codigoPregunta: divPreguntas.attr("id"),
                        codigoEstado: estado,
                        idFichaMovimientoPreguntaObservacion: idFichaMovimientoPreguntaObservacion,
                        observacion: null,
                      });
                    });
                }
              }
            );
          } else if (this.tipoCarga == 2) {
            //*aqui
            //---------------------------------------------------------------------------------------
            //-- Es enviado por el docente al academico
            //---------------------------------------------------------------------------------------
            parametro.codigoEstadoProcesado = this._utilitarios.enumEstado.Enviado;
            this._codigoEstadoFicha = parametro.codigoEstadoProcesado;
            $(selectorQueryTextArea).each((index, event) => {
              var divPreguntas = $(event);
              var textArea = divPreguntas.find("textarea");
              if (textArea.length > 0) {
                if (textArea.val().toString().trim() != "") {
                  if (
                    $(event).find('input[value="REPODO"]:checked').length > 0
                  ) {
                    //Pregunta y sustento por observacion
                    parametro.fichaMovimientoPreguntas.push({
                      codigoPregunta: divPreguntas
                        .parent()
                        .parent()
                        .parent()
                        .attr("data-for"),
                      codigoEstado: this._utilitarios.enumEstado
                        .RechazadoDocente,
                      observacion: textArea.val().toString().trim(),
                      idFichaMovimientoPreguntaObservacion: divPreguntas.attr(
                        "data-id"
                      ),
                    });
                  }
                }
              }
            });

            $(
              '.observacionMovimiento-detalle > div > span input[type="radio"]:checked'
            ).each((index, obs) => {
              var idFichaMovimientoPreguntaObservacion = $(obs)
                .parents("[data-id]")
                .attr("data-id");
              var codigoPregunta = $(obs)
                .parents("[data-for]")
                .attr("data-for");
              if (
                $(obs).val() ==
                this._utilitarios.enumEstado.ObservacionAprobadaDocente
              ) {
                parametro.fichaMovimientoPreguntas.push({
                  codigoPregunta: codigoPregunta,
                  codigoEstado: $(obs).val(),
                  observacion: null,
                  idFichaMovimientoPreguntaObservacion: idFichaMovimientoPreguntaObservacion,
                });
              }
            });
          }

          //#region ModificarCambios-20200219
          var ficha = this.modelAsignarEntidadFicha(this.formFicha.value, 0, esGuardadoPorAcademico);
          //#endregion ModificarCambios-20200219

          parametro.ficha = this.modeloEnvioDatos(ficha);
          //---------------------------------------------------------------------------------------
          //-- Procesa Información
          //---------------------------------------------------------------------------------------
          this._FichaService.AgregarFichaMovimientoObservacion(
            parametro,
            (r) => {
              this._FichaService.ObtenerObservacionesPorFicha(
                {
                  idFicha: this._idFicha,
                },
                (dataPrg) => {
                  this.dibujarObservaciones(dataPrg.fichaMovimientoPreguntas);
                  this.habilitarEnvioObservacion();
                  this.habilitaOcultarElementosPorROL();
                  //*aqui 
                  //this.enviarDatos(this.formFicha.value, false, 1);
                  this.dibujarGrilla.emit();
                  //if (op == "aprobar") {
                  this._utilitarios.CerrarModal("myModal");
                  //}
                }
              );
            }
          );
        }
        else {

          //*aqui --- se removio



          //*Aqui
          var ficha = this.modelAsignarEntidadFicha(this.formFicha.value, 0, 1);
          parametro.ficha = this.modeloEnvioDatos(ficha);
          this._FichaService.AgregarFichaMovimientoObservacion(
            parametro,
            (r) => {
              this._FichaService.ObtenerObservacionesPorFicha(
                {
                  idFicha: this._idFicha,
                },
                (dataPrg) => {
                  //  this.enviarDatos(this.formFicha.value, false, 1);
                  this.dibujarObservaciones(dataPrg.fichaMovimientoPreguntas);
                  this.habilitaOcultarElementosPorROL();
                }
              );
            }
          );


        }
      }
    } else {
      //---------------------------------------------------------------------------
      //-- Se guarda de manera normal
      //---------------------------------------------------------------------------
      this.esEnviado = op == "guardar" ? false : this.esEnviado;


      var error = false;
      $("#divPalabrasClaves > [data-row] input[type='text']").each(
        (index, element) => {
          var input = $(element).attr("formControlName");
          /*
           var select = $(element)
            .parents("div[data-row]")
            .find("angular2-multiselect")
            .attr("formControlName");

          if (
            this.formFicha.controls[select].value != null &&
            this.formFicha.controls[select].value.length > 0
          ) {
            if ($(element).val() == "") {
              error = true;
              this.asignarValidacion(select, input);
            }
          }

          if ($(element).val() != "") {
            if (
              this.formFicha.controls[select].value == null ||
              this.formFicha.controls[select].value.length == 0
            ) {
              error = true;
              this.asignarValidacion(select, input);
            }
          }
          */


        }
      );
      //---------------------------------------------------------------------------
      //-- Si no es enviado se guarda
      //---------------------------------------------------------------------------
      if (this.esEnviado == false) {
        let errorGrabar: number = 0;

        /*
     
        if (this.formFicha.controls["problemaInvestigacion"].invalid) {
          errorGrabar = 1;
          this.formFicha.controls["problemaInvestigacion"].markAsTouched({
            onlySelf: true
          });
        }

        if (this.formFicha.controls["lineaInvestigacion"].invalid) {
          errorGrabar = 1;
          this.formFicha.controls["lineaInvestigacion"].markAsTouched({
            onlySelf: true
          });
        }
        if (this.formFicha.controls["actividadesClaves"].invalid) {
          errorGrabar = 1;
          this.formFicha.controls["actividadesClaves"].markAsTouched({
            onlySelf: true
          });
        }
        if (this.formFicha.controls["incorporaObservaciones"].invalid) {
          errorGrabar = 1;
          this.formFicha.controls["incorporaObservaciones"].markAsTouched({
            onlySelf: true
          });
        }
        if (this.formFicha.controls["tituloFicha"].invalid) {
          errorGrabar = 1;
          this.formFicha.controls["tituloFicha"].markAsTouched({
            onlySelf: true
          });
        }

         */
        if (
          this.formFicha.controls["NombreAlumno1"].invalid &&
          this.esExtraordinario
        ) {
          errorGrabar = 1;
          this.formFicha.controls["NombreAlumno1"].markAsTouched({
            onlySelf: true,
          });
        }
        if (
          this.formFicha.controls["codigoAlumno1"].invalid &&
          this.esExtraordinario
        ) {
          errorGrabar = 1;
          this.formFicha.controls["codigoAlumno1"].markAsTouched({
            onlySelf: true,
          });
        }

        if (
          this.formFicha.controls["NombreAlumno2"].invalid &&
          this.esExtraordinario
        ) {
          errorGrabar = 1;
          this.formFicha.controls["NombreAlumno2"].markAsTouched({
            onlySelf: true,
          });
        }
        if (
          this.formFicha.controls["codigoAlumno2"].invalid &&
          this.esExtraordinario
        ) {
          errorGrabar = 1;
          this.formFicha.controls["codigoAlumno2"].markAsTouched({
            onlySelf: true,
          });
        }
        if (this.formFicha.controls["idCampus"].invalid) {
          errorGrabar = 1;
          this.formFicha.controls["idCampus"].markAsTouched({
            onlySelf: true,
          });
        }
        if (this.formFicha.controls["idFacultad"].invalid) {
          errorGrabar = 1;
          this.formFicha.controls["idFacultad"].markAsTouched({
            onlySelf: true,
          });
        }
        if (this.formFicha.controls["idCarrera"].invalid) {
          errorGrabar = 1;
          this.formFicha.controls["idCarrera"].markAsTouched({
            onlySelf: true,
          });
        }

        this.formFicha.controls["idCarrera"].markAsTouched({
          onlySelf: true,
        });


        if (this.formFicha.controls["chkExtraordinaria"].invalid) {
          errorGrabar = 1;
          this.formFicha.controls["chkExtraordinaria"].markAsTouched({
            onlySelf: true,
          });
        }
        if (!this.formFicha.valid) {
          Object.keys(this.formFicha.controls).forEach((key) => {
            if (this.formFicha.controls[key].invalid) {
              this.formFicha.controls[key].markAsTouched({
                onlySelf: true,
              });
            }
          });
        }
        if (errorGrabar == 0 && !error) {
          this.enviarDatos(this.formFicha.value, false, 0);
        }
      } else {
        //---------------------------------------------------------------------------
        //-- Se envia al academico como guardado
        //---------------------------------------------------------------------------
        if (
          this.divCompentencias.find("div input[type='checkbox']:checked")
            .length == 0
        ) {
          this.errorChkCompetencia = true;
        }

        if (this.formFicha.valid && !error) {
          this.enviarDatos(this.formFicha.value, true, 0);
        } else {
          this._utilitarios.validateAllFields(this.formFicha);
        }
      }
    }
  }
  onChangesForm() {
    this.formFicha.valueChanges.subscribe((val) => {

      if (this.tipoCarga != 1 && this.tipoCarga != 2) {
        if (
          this.formFicha.valid &&
          !this.errorChkCompetencia &&
          !this.errorPalabraClave
        ) {
          if (
            val.palabra1 == "" ||
            val.palabra1 == null ||
            val.palabra1_auxiliar == null ||
            val.palabra1_auxiliar == "" ||
            // val.idRepositorio1 == null ||
            // val.idRepositorio1.length == 0 ||
            val.palabra2 == "" ||
            val.palabra2 == null ||
            val.palabra2_auxiliar == null ||
            val.palabra2_auxiliar == "" ||
            // val.idRepositorio2 == null ||
            //  val.idRepositorio2.length == 0 ||
            val.palabra3 == "" ||
            val.palabra3 == null ||
            val.palabra3_auxiliar == null ||
            val.palabra3_auxiliar == "" ||
            // val.idRepositorio3 == null ||
            //  val.idRepositorio3.length == 0 ||
            val.palabra4 == "" ||
            val.palabra4 == null ||
            val.palabra4_auxiliar == null ||
            val.palabra4_auxiliar == ""
            //||
            //  val.idRepositorio4 == null ||
            //  val.idRepositorio4.length == 0
          ) {

            this.errorPalabraClave = true;
            this.esEnviado = false;
          } else {
            this.esEnviado = true;
            this.errorPalabraClave = false;
          }
        } else if (this.formFicha.valid && !this.errorChkCompetencia) {
          if (
            val.palabra1 == "" ||
            val.palabra1 == null ||
            val.palabra1_auxiliar == null ||
            val.palabra1_auxiliar == "" ||
            // val.idRepositorio1 == null ||
            // val.idRepositorio1.length == 0 ||
            val.palabra2 == "" ||
            val.palabra2 == null ||
            val.palabra2_auxiliar == null ||
            val.palabra2_auxiliar == "" ||
            // val.idRepositorio2 == null ||
            // val.idRepositorio2.length == 0 ||
            val.palabra3 == "" ||
            val.palabra3 == null ||
            val.palabra3_auxiliar == null ||
            val.palabra3_auxiliar == "" ||
            //val.idRepositorio3 == null ||
            //val.idRepositorio3.length == 0 ||
            val.palabra4 == "" ||
            val.palabra4 == null ||
            val.palabra4_auxiliar == null ||
            val.palabra4_auxiliar == ""
            // ||
            //val.idRepositorio4 == null ||
            //val.idRepositorio4.length == 0
          ) {
            this.errorPalabraClave = true;
            this.esEnviado = false;
          } else {
            this.esEnviado = true;
            this.errorPalabraClave = false;
          }
        } else {
          this.esEnviado = false;
        }
      }
    });
  }

  onCerrarModal() {
    this.limpiar();
  }

  onChangePalabrasClaves() {


    $("#divPalabrasClaves > [data-row] input").each((index, element) => {
      var idInput = $(element);

      idInput.change((event) => {
        var input = $(event.target).attr("formControlName");
        var index = idInput.parents('div[data-for]').index();
        //  var select = $(event.target)
        //  .parents("div[data-row]")
        //.find("angular2-multiselect")
        //.attr("formControlName");

        var aux = '_auxiliar';
        if ($(event.target).val() != "") {
          //this.asignarValidacion(select, input);


        } else {
          //this.removerValidacionInputSelect(select, input);

        }
      });
    });


  }

  onBlurDocente(event) {
    var target = $(event.target);
    if (target.val() != "") {
      this.dibujarDatosAsesor({
        codigo: target.val(),
        nombreApellidos: "jhoel danie",
        correo: "dd@ggmai.com",
        telefono: "ccv",
      });
    } else {
      //this.formFicha.controls["nombreDocenteAsesor"].setValue("");
      //this.formFicha.controls["correoDocenteAsesor"].setValue("");
      //this.formFicha.controls["telefonoDocenteAsesor"].setValue("");
    }
  }
  //nuevo
  OnItemDeSelect_idRepositorio1(item: any) {
    let idFichaPalabraClave = $(event.target)
      .parents("angular2-multiselect")
      .parent()
      .parent()
      .prev()
      .find("input")
      .data("idFichaPalabraClave");

    this._eliminadosRepositorio.map((ifc) => {
      if (ifc.idFichaPalabraClave == idFichaPalabraClave) {
        ifc.repos.map((ir) => {
          if (ir.id == item.id) {
            ir.esInactivo = 1;
            ir.idRepositorio = item.id;
          }
        });
      }
    });
  }

  public ngVisualizarFicha(item: any) {
    this._codigoEstadoBanco = item.codigoEstado;
    this._totalConfirmadosPorSeccion = item.totalConfirmadosPorSeccion;
    this.habilitaOcultarElementosPorROL();
    $(".radioAprobadoObservado").hide();
    this._BancoArchivoBinarioBE = [];
    this._fichaDocumentosFinales = [];
    this.tipoCarga = 1;
    this._codigoEstadoFicha = item.codigoEstado;
    this._idFicha = item.idFicha;
    this._idCampusFacultadCarrera = item.idCampusFacultadCarrera;

    console.log(item)
    if (this._codigoEstadoFicha != this._utilitarios.enumEstado.Observado
      && this._codigoEstadoFicha != this._utilitarios.enumEstado.Guardado) {

      this.combobox.init(
        {
          id: "formFicha_cboCampus",
          entidad: 'mCampus',
        },
        () => {
          this.editarVerDatosFicha(item);
        }
      );
    } else {
      this.editarVerDatosFicha(item);
    }
  }

  onChangeArchivo(event) {
    var file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      event.target.value = "";
      let archivoBase64 = reader.result.toString().split(",")[1];
      console.log(archivoBase64);
      let nombreArchivo = file.name;
      this._BancoService.AdjuntarDocumentoFicha(
        {
          archivoBase64: archivoBase64,
          idFicha: this._idFicha,
          nombreArchivo: nombreArchivo,
          idDocumentoProceso: 1,
          idPersonaRegistra: this._Session.usuario.persona.idPersona
        },
        (data) => {
          this._BancoArchivoBinarioBE.push(data);
        }
      );
    };
  }
  public ngClickSubirArchivo(event) {
    $("#fileSubirDocumento").trigger("click");
  }
  ngChangeAdjuntarTipoDocumento(event) {
    $("#fldSubirArchivo").trigger("click");
  }

  onChangeArchivoTipoDocumento(event) {

    var file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      event.target.value = "";
      let archivoBase64 = reader.result.toString().split(",")[1];
      let nombreArchivo = file.name;
      this._tipoDocumentoArchivo = nombreArchivo;
      this._tipoDocumentoArchivoBase64 = archivoBase64;
    };
  }
  //#endregion Formulario

  //--------------------------------------------------------------------------------------------------
  //-- Privates
  //--------------------------------------------------------------------------------------------------

  //#region Privates

  private asignarValidacion(idSelect, idInput) {
    /*
     this.formFicha.controls[idSelect].setValidators([Validators.required]);
    this.formFicha.controls[idInput].setValidators([Validators.required]);
    //-->
    if (
      this.formFicha.controls[idSelect].value == null ||
      this.formFicha.controls[idSelect].value.length == 0
    ) {
      this.formFicha.controls[idSelect].setValue([]);
      this.formFicha.controls[idSelect].invalid;
      this.formFicha.controls[idSelect].markAsTouched({
        onlySelf: true,
      });
    }
    if (
      this.formFicha.controls[idInput].value == "" ||
      this.formFicha.controls[idInput].value == null
    ) {
      this.formFicha.controls[idInput].setValue("");
      this.formFicha.controls[idInput].invalid;
      this.formFicha.controls[idInput].markAsTouched({
        onlySelf: true,
      });
    }
    */

  }

  private removerValidacionInputSelect(idSelect, idInput) {
    this.formFicha.controls[idSelect].clearValidators();
    this.formFicha.controls[idSelect].setErrors([]);
    this.formFicha.controls[idSelect].reset();

    this.formFicha.controls[idInput].clearValidators();
    this.formFicha.controls[idInput].setErrors(null);
    this.formFicha.controls[idInput].reset();
  }
  private limpiar() {

    let entidad = this.obtenerEntidadCombobox("cm");
    this.combobox.init(
      {
        id: "formFicha_cboCampus",
        entidad: entidad,
        parametro: {
          "@p_idPersona": this._Session.usuario.persona.idPersona,
        },
      },
      null
    );
    this._totalConfirmadosPorSeccion = 0;
    this.esTitulo = false;
    this.esEnviado = false;
    //-->Error
    this._codigoEstadoBanco = "";
    this._BancoArchivoBinarioBE = [];
    this._fichaDocumentosFinales = [];
    this._codigoEstadoFicha = "";
    this._idFicha = 0;
    this._idCampusFacultadCarrera = 0;
    this._eliminadosRepositorio = [];
    this.errorChkCompetencia = false;

    this.errorPalabraClave = true;

    this.esExtraordinario = false;
    $(".fichaControles .omitir input").val("");
    $(".fichaControles .omitir input").prop("disabled", true);
    $(".informacionEntidad").hide();
    $(".sectionExtraordinaria").hide();
    //$("#chkExtraordinaria").prop("checked", false);
    $("#chkExtraordinaria").val('');
    $("#divCompentencias").html("");
    $(".alumnoDos").attr("data-id-alumno", 0);
    $(".alumnoUno").attr("data-id-alumno", 0);
    this.combobox.Clear("formFicha_cboFacultad");
    this.combobox.Clear("formFicha_cboCarrera");
    this.combobox.Clear("formFicha_lineaInvestigacion");
    this.formFicha.reset();
    //
    this._utilitarios.limpiarTodosLosInput(this.formFicha);
    //this.formFicha.controls["idRepositorio1"].setValue([]);
    //this.formFicha.controls["idRepositorio2"].setValue([]);
    //this.formFicha.controls["idRepositorio3"].setValue([]);
    // this.formFicha.controls["idRepositorio4"].setValue([]);
    // this.formFicha.controls["idRepositorio5"].setValue([]);
    //
    this.esExtraordinario = false;
    this.formFicha.controls["NombreAlumno1"].clearValidators();
    this.formFicha.controls["NombreAlumno1"].setErrors(null);
    this.formFicha.controls["NombreAlumno1"].reset();
    this.formFicha.controls["codigoAlumno1"].clearValidators();
    this.formFicha.controls["codigoAlumno1"].setErrors(null);
    this.formFicha.controls["codigoAlumno1"].reset();
    //
    this.formFicha.controls["NombreAlumno2"].clearValidators();
    this.formFicha.controls["NombreAlumno2"].setErrors(null);
    this.formFicha.controls["NombreAlumno2"].reset();
    this.formFicha.controls["codigoAlumno2"].clearValidators();
    this.formFicha.controls["codigoAlumno2"].setErrors(null);
    this.formFicha.controls["codigoAlumno2"].reset();
    //
    this.formFicha.controls["informacionEntidadObservacion"].clearValidators();
    this.formFicha.controls["informacionEntidadObservacion"].setErrors(null);
    this.formFicha.controls["informacionEntidadObservacion"].reset();
    //
    this._eliminadosRepositorio = [];
    $("#divPalabrasClaves div[data-row]").each((index, element) => {
      var reposiotrios: Array<any> = [];

      var inputPalabraClave = $(element)
        .children("[data-for]:first-child")
        .find("input");
      inputPalabraClave.data("idFichaPalabraClave", 0);
    });
    //
    this.periodoVigente();

    this.dibujarDatosAsesor({
      codigo: this._Session.usuario.persona.codigo,
      nombreApellidos: this._Session.usuario.persona.nombreApellidos,
      correo: this._Session.usuario.persona.correo,
      telefono: this._Session.usuario.persona.telefono,
    });

    // this.formFicha.controls["tipoInvestigacion"].setValue("");
    this.formFicha.controls["lineaInvestigacion"].setValue("");
    // this.formFicha.controls["tallerInvestigacion"].setValue("");
    // this.formFicha.controls["informacionEntidad"].setValue("");
    this.formFicha.controls["eticaInvestigacion"].setValue("");

    this.formFicha.controls["INVINF"].reset();
    this.formFicha.controls["INFPBL"].reset();
    this.formFicha.controls["RQAUT"].reset();
  }

  private removerTodaValidacionPalabraClave() {
    $("[data-row]").each((index, element) => {
      var input = $(element).children("[data-for]:first-child").find("input");

      /*
      var selectMultiple = $(element)
        .children("[data-for]:first-child")
        .next()
        .find(".multipleRepositorio");

      var idSelect = selectMultiple.attr("formcontrolname");
      this.formFicha.controls[idSelect].clearValidators();
      this.formFicha.controls[idSelect].setErrors(null);
      this.formFicha.controls[idSelect].reset();
      this.formFicha.controls[idSelect].setValue([]);
       */


      var idInput = input.attr("formcontrolname");
      this.formFicha.controls[idInput].clearValidators();
      this.formFicha.controls[idInput].setErrors(null);
      this.formFicha.controls[idInput].reset();
      this.formFicha.controls[idInput].setValue([]);
    });
  }

  private cargarCombobox() {

    var entidad = "";
    entidad = entidad = this.obtenerEntidadCombobox("cm");
    this.combobox.init(
      {
        id: "formFicha_cboCampus",
        entidad: entidad,
        parametro: {
          "@p_idPersona": this._Session.usuario.persona.idPersona,
        },
      },
      null
    );

    this.combobox.init(
      {
        id: "cboEstado",
        entidad: "EstadoFichaDocentes",
        html: "todos",
      },
      null
    );
    this.combobox.init(
      {
        id: "chkExtraordinaria",
        entidad: "TipoFicha_Listar",
        html: "seleccionar",
      },
      null
    );
    this.combobox.init(
      {
        id: "cboTipoDocumento",
        entidad: "tipoDocumentoListar",
        html: "seleccionar",
      },
      () => {


      }
    );

  }

  private cargarRepositorios() {
    this.combobox.getJson(
      {
        entidad: "mRepositorio",
      },
      (data) => {
        data.forEach((item) => {
          this.dropdownList.push({
            id: parseInt(item.id),
            itemName: item.nombre,
          });
        });
      }
    );
  }
  //--------------------------------------------------------------------------------
  //-- Carga los datos de la ficha - Academico/Docente
  //--------------------------------------------------------------------------------
  private cargarDatosFichaPorId(idFicha, callbackFicha = null) {
    this._utilitarios.OpenModal("myModal");



    this._FichaService.ObtenerPorId(
      {
        idFicha: idFicha,
      },
      (data) => {
        this._idBanco = data.idBanco;
        this._codigoEstadoFicha = data.codigoEstado;

        if (this._codigoEstadoBanco == "") {

          this._codigoEstadoBanco = data.codigoEstadoBanco;
        }
        if (data.fichaDocumentos != null) {
          this._BancoArchivoBinarioBE = data.fichaDocumentos;
        }
        if (data.fichaDocumentosFinales != null) {
          this._fichaDocumentosFinales = data.fichaDocumentosFinales;
        }

        $("#idNombreCoordinador").val(data.nombreApellidosAprobador);
        $("#idCodigoCoordinador").val(data.codigoAprobador);
        $("#idCargo").val(data.cargoAprobador);
        $("#idFechaAprobacion").val(
          this._utilitarios
            .ConvertFechaServidorToDMYHMS(data.fechaAprobacion)
            .toString()
        );
        $("#idFechaElaboracion").val(
          this._utilitarios
            .ConvertFechaServidorToDMYHMS(data.fechaRegistro)
            .toString()
        );
        this.formFicha.controls["idCampus"].setValue(data.idCampus);
        this.formFicha.controls["idCampus"].setValue(data.idCampus);
        var entidad = "";
        entidad = this.obtenerEntidadCombobox("fa");
        var params =
          this._Session.rolMarcado ==
            this._utilitarios.enumRoles.coordinadorInvestigacion
            ? {
              "@p_idCampus": this.formFicha.controls["idCampus"].value,
            }
            : {
              //   "@p_idPersona": this._Session.usuario.persona.idPersona,
              "@p_idCampus": this.formFicha.controls["idCampus"].value,
            };
        $.when(
          this.combobox.init(
            {
              id: "formFicha_cboFacultad",
              entidad: entidad,
              parametro: params,
            },
            (select) => {
              entidad = this.obtenerEntidadCombobox("ca");
              this.combobox.Clear("formFicha_cboCarrera");
              this.formFicha.controls["idFacultad"].setValue(data.idFacultad);
              var params =
                this._Session.rolMarcado ==
                  this._utilitarios.enumRoles.coordinadorInvestigacion
                  ? {
                    "@p_idCampus": data.idCampus,
                    "@p_idFacultad": data.idFacultad,
                  }
                  : {
                    "@p_idPersona": this._Session.usuario.persona.idPersona,
                    "@p_idCampus": data.idCampus,
                    "@p_idFacultad": data.idFacultad,
                  };

              console.log(params);
              this.combobox.init(
                {
                  id: "formFicha_cboCarrera",
                  entidad: entidad,
                  parametro: params,
                },
                (dc) => {
                  this.formFicha.controls["idCarrera"].setValue(data.idCarrera);
                }
              );
            }
          ),
          this.dibujarLineaInvestigacion(data.idCarrera)
        ).then(
          (status) => {
            this.formFicha.controls["temaInvestigacion"].setValue(
              data.temaInvestigacion
            );
            this.formFicha.controls["problemaInvestigacion"].setValue(
              data.problemaInvestigacion
            );

            this.formFicha.controls["poblacionLugarEstudio"].setValue(
              data.poblacionLugarEstudio
            );
            this.formFicha.controls["lineaInvestigacion"].setValue(
              data.idLineaInvestigacion == null ? "" : data.idLineaInvestigacion
            );
            this.formFicha.controls["actividadesClaves"].setValue(
              data.actividadesClaves
            );
            this.formFicha.controls["incorporaObservaciones"].setValue(
              data.incorporaObservaciones
            );
            this.formFicha.controls["tituloFicha"].setValue(data.tituloFicha);

            //   this.formFicha.controls["tipoInvestigacion"].setValue(
            //     data.idTipoInvestigacion == null ? "" : data.idTipoInvestigacion
            //  );
            //this.formFicha.controls["informacionEntidad"].setValue(
            //            data.esAutorizacionInformacion == null
            //            ? ""
            //          : data.esAutorizacionInformacion
            //    );
            this.formFicha.controls["informacionEntidadObservacion"].setValue(
              data.esAutorizacionInformacion == 1
                ? data.descripcionEntidadesAutorizadas
                : ""
            );
            if (data.esAutorizacionInformacion == 1) {
              $(".informacionEntidad").show();
            }
            this.formFicha.controls["eticaInvestigacion"].setValue(
              data.esEvaluadoPorComiteEtica == null
                ? ""
                : data.esEvaluadoPorComiteEtica
            );

            //cargar ::> fichaPreguntaInformacionEntidades

            data.fichaPreguntaInformacionEntidades.map((item) => {

              this.formFicha.controls[item.codigoPreguntaInformacion].setValue(item.condicional);

            })
            data.fichaPreguntaActividades.map((item) => {

              this.formFicha.controls[item.codigoPreguntaActividad].setValue(item.condicional);

            })
            // this.formFicha.controls["tallerInvestigacion"].setValue(
            //   data.idMetodoInvestigacion == null ? "" : data.idMetodoInvestigacion
            // );
            this.formFicha.controls["observacionAccionesEspecificacion"].setValue(
              data.observacionAccionesEspecificacion
            );
            //this.formFicha.controls["recomendacion"].setValue(data.recomendacion);
            //this.formFicha.controls["codigoDocenteAsesor"].setValue(
            // data.codigoDocenteAsesor
            //);
            //this.formFicha.controls["nombreDocenteAsesor"].setValue(
            // data.nombreDocenteAsesor
            //);
            //this.formFicha.controls["correoDocenteAsesor"].setValue(
            //  data.correoDocenteAsesor
            //);
            // this.formFicha.controls["telefonoDocenteAsesor"].setValue(
            //  data.telefonoDocenteAsesor
            //);
            $.when(this.dibujarCompetencias(data.idCarrera)).then(
              (jqXHR, textStatus, errorThrown) => {

                var datosCompentencia = jqXHR.data;

                var index = 0;
                var fichaPalabraClaves = data.fichaPalabraClaves;

                for (var i = 0; i < datosCompentencia.length; i++) {
                  var rowData = datosCompentencia[i];
                  data.fichaCompetencias.forEach((element) => {
                    if (element.idCompetencia == rowData.id) {
                      $(
                        '#divCompentencias [id="' + element.idCompetencia + '"]'
                      ).attr("checked", "checked");
                    }
                  });
                }

                fichaPalabraClaves.forEach((element) => {
                  index++;
                  $("#divPalabrasClaves [data-row]").each((i, e) => {
                    var arrayEstatic = {
                      idFichaPalabraClave: 0,
                      repos: [],
                    };

                    var rowPalabra = $(e);
                    if (i + 1 == index) {
                      var primerInput = rowPalabra
                        .children("div:first-child")
                        .find("input");

                      primerInput.data(
                        "idFichaPalabraClave",
                        element.idFichaPalabraClave
                      );

                      this.formFicha.controls["palabra" + index].setValue(
                        element.palabraClave
                      );
                      this.formFicha.controls["palabra" + index + '_auxiliar'].setValue(
                        element.palabraClaveAuxiliar
                      );
                      /*
                                          arrayEstatic.idFichaPalabraClave =
                                            element.idFichaPalabraClave;
                                          var repos = [];
                                          element.fichaPalabraClaveRepositorios.forEach(
                                            (elementRepo) => {
                                              let obj = {
                                                idFichaPalabraClaveRepositorio:
                                                  elementRepo.idFichaPalabraClaveRepositorio,
                                                id: parseInt(elementRepo.idRepositorio),
                                                itemName: elementRepo.repositorio,
                                                esInactivo: 0,
                                              };
                                              repos.push(obj);
                                              arrayEstatic.repos.push(obj);
                                            }
                                          );
                      */
                      //Nuevo

                      this._eliminadosRepositorio.push(arrayEstatic);

                      // this.formFicha.controls["idRepositorio" + index].setValue(
                      //repos
                      //);
                      // this.formFicha.controls[
                      //"idRepositorio" + index
                      //                    ].markAsTouched({
                      //                    onlySelf: true,
                      //                });
                    }
                  });
                });

                this.esExtraordinario = false;
                this.formFicha.controls["NombreAlumno1"].clearValidators();
                this.formFicha.controls["NombreAlumno1"].setErrors(null);
                this.formFicha.controls["NombreAlumno1"].reset();
                this.formFicha.controls["codigoAlumno1"].clearValidators();
                this.formFicha.controls["codigoAlumno1"].setErrors(null);
                this.formFicha.controls["codigoAlumno1"].reset();
                //
                this.formFicha.controls["NombreAlumno2"].clearValidators();
                this.formFicha.controls["NombreAlumno2"].setErrors(null);
                this.formFicha.controls["NombreAlumno2"].reset();
                this.formFicha.controls["codigoAlumno2"].clearValidators();
                this.formFicha.controls["codigoAlumno2"].setErrors(null);
                this.formFicha.controls["codigoAlumno2"].reset();
                // }
                //this.formFicha.controls["chkExtraordinaria"].setValue(data.esFichaExtraordinario);
                if (data.idTipoFicha == 2) {
                  this.formFicha.controls["chkExtraordinaria"].enable();

                  this.esExtraordinario = true;
                  $(".sectionExtraordinaria").show();
                  //$("#chkExtraordinaria").prop("checked", true);
                  $("#chkExtraordinaria").val(data.idTipoFicha);
                  this.formFicha.controls["chkExtraordinaria"].setValue(data.idTipoFicha);

                  var alumnoUno = data.fichaAlumnos[0];
                  var alumnoDos = data.fichaAlumnos[1];

                  //  $(".alumnoUno").find("input").prop("disabled", false);
                  // $(".alumnoDos").find("input").prop("disabled", false);
                  //
                  $(".alumnoUno").attr("data-id-alumno", alumnoUno.idFichaAlumnos);
                  this.formFicha.controls["codigoAlumno1"].setValue(
                    alumnoUno.codigo
                  );
                  this.formFicha.controls["NombreAlumno1"].setValue(
                    alumnoUno.nombre
                  );
                  if (data.fichaAlumnos.length == 2) {
                    $(".alumnoDos").attr(
                      "data-id-alumno",
                      alumnoDos.idFichaAlumnos
                    );
                    this.formFicha.controls["codigoAlumno2"].setValue(
                      alumnoDos.codigo
                    );
                    this.formFicha.controls["NombreAlumno2"].setValue(
                      alumnoDos.nombre
                    );
                  }
                  $('#NombreAlumno1').attr('readonly', 'true');
                  $('#NombreAlumno2').attr('readonly', 'true');



                } else {
                  this.formFicha.controls["chkExtraordinaria"].setValue(data.idTipoFicha);
                } // } else {

                $('span.radioAprobadoObservado input[type="radio"]').prop(
                  "checked",
                  false
                );
                //--

                this.dibujarObservaciones(data.fichaMovimientoPreguntas);

                var totalObservacionesPorMarcar = $(
                  ".observacionMovimiento-detalle > div[data-id]"
                ).length;

                var totalMarcado = $(
                  ".observacionMovimiento-detalle > div[data-id] input[type='radio']:checked"
                ).length;

                if (totalObservacionesPorMarcar == totalMarcado) {
                  $("#divObservacion").show();
                } else {
                  $("#divObservacion").hide();
                }

                // 
                callbackFicha();
              }
            );

            if (this._codigoEstadoFicha != this._utilitarios.enumEstado.Guardado) {
              this.idPeriodo = data.idPeriodo;
              this.formFicha.controls["periodo"].setValue(data.periodo);
            } else {
              this.periodoVigente();
            }
          });
      }
    );
  }
  //--------------------------------------------------------------------------------
  //-- habilitar Opciones ficha - Academico/Docente
  //--------------------------------------------------------------------------------
  private habilitaOcultarElementosPorROL() {
    $(".videoInstructivo").hide();
    //$(".fichaControles .omitir input").prop("disabled", true);

    if (
      this._codigoEstadoFicha == this._utilitarios.enumEstado.Aprobadas ||
      this._codigoEstadoFicha == this._utilitarios.enumEstado.Anulado ||
      this.esTitulo == true
    ) {

      $(".videoInstructivo").show();
      $("#btnGuardar").hide();
      $(".observacionesDetalle").hide();
      $(".observacion-nuevo").hide();
      $("#divObservacion").hide();
      $("#divAprobar").hide();
      if (
        this._Session.rolMarcado == this._utilitarios.enumRoles.docente ||
        this._Session.rolMarcado ==
        this._utilitarios.enumRoles.coordinadorInvestigacion ||
        this._Session.rolMarcado ==
        this._utilitarios.enumRoles.coordinadorAcademico ||
        this._Session.rolMarcado ==
        this._utilitarios.enumRoles.revisor
      ) {
        this.esEnviado = false;
        $(".radioAprobadoObservado").hide();


      }
    } else if (
      this._codigoEstadoFicha == this._utilitarios.enumEstado.Inactivo
    ) {


      $("#btnGuardar").hide();
      $(".observacionesDetalle").hide();
      $(".observacion-nuevo").hide();
      $("#divObservacion").hide();
      this.esEnviado = false;
      $(".radioAprobadoObservado").hide();

      if (
        $(".observacionesDetalle .observacionMovimiento div[data-for]").length >
        0
      ) {
        $(".observacionesDetalle").show();
        $(
          '.observacionesDetalle .observacionMovimiento div[data-for] input[type="radio"]'
        ).prop("disabled", true);


      }
    } else if (
      (this._Session.rolMarcado ==
        this._utilitarios.enumRoles.coordinadorAcademico ||
        this._Session.rolMarcado ==
        this._utilitarios.enumRoles.revisor) &&
      this._codigoEstadoFicha == this._utilitarios.enumEstado.Observado
    ) {
      $("#btnGuardar").hide();
      $(".observacionesDetalle").show();
      $(".observacion-nuevo").hide();
      $("#divObservacion").hide();
      this.esEnviado = false;


    } else if (
      (
        this._Session.usuario.roles.filter(rol => rol.codigoRol == this._utilitarios.enumRoles.coordinadorAcademico).length > 0
        || this._Session.usuario.roles.filter(rol => rol.codigoRol == this._utilitarios.enumRoles.revisor).length > 0)
      //== this._utilitarios.enumRoles.coordinadorAcademico ||
      // this._Session.rolMarcado ==  this._utilitarios.enumRoles.revisor)   
      && this._codigoEstadoFicha == this._utilitarios.enumEstado.Enviado
    ) {

      $(".radioAprobadoObservado input[type='radio']").prop("disabled", false);
      this.esEnviado = false;
      $(".radioAprobadoObservado").show();
      $("#divObservacion").hide();
      $("#btnGuardar").show();
      $(".observacionesDetalle").show();
      this.habilitarEnvioObservacion();
      //*aqui 
      if (this._codigoEstadoFicha == this._utilitarios.enumEstado.Enviado) {


        this.tipoCarga = 1;
        $('div[data-id-question]')
          .children("div")
          .find("*")
          .prop("disabled", false);
      }

    } else if (
      this._Session.rolMarcado == this._utilitarios.enumRoles.docente &&
      this._codigoEstadoFicha == this._utilitarios.enumEstado.Observado
    ) {
      this.esEnviado = false;
      this.tipoCarga = 2;


      $(".radioAprobadoObservado").hide();
      //Habilita los controles que tienes observacion
      $(".observacionMovimiento > div[data-for]").each((iObs, elementObs) => {
        var codigoPregunta = $(elementObs).attr("data-for");
        $('div[data-id-question="' + codigoPregunta + '"]')
          .children("div")
          .find("*")
          .prop("disabled", false);
        if (codigoPregunta == "PREG005") {
          this.dropdownSettings = {
            disabled: false,
          };
        }
      });
      $(".observacionesDetalle").show();
      $(".observacion-nuevo").hide();
      $("#divObservacion").hide();
      $(".videoInstructivo").hide();
    } else if (
      this._Session.rolMarcado == this._utilitarios.enumRoles.docente &&
      this._codigoEstadoFicha == this._utilitarios.enumEstado.Guardado
    ) {


      $(".videoInstructivo").show();
      $(".radioAprobadoObservado").hide();
      $(".observacionesDetalle").hide();
      $("#divObservacion").hide();
      $("#btnGuardar").show();
    } else if (
      this._Session.rolMarcado == this._utilitarios.enumRoles.docente &&
      this._codigoEstadoFicha == this._utilitarios.enumEstado.Enviado &&
      $(".observacionMovimiento > div[data-for]").length == 0
    ) {


      this.esEnviado = false;
      $(".videoInstructivo").show();
      $(".radioAprobadoObservado").hide();
      $(".observacionesDetalle").hide();
      $("#btnGuardar").hide();
      $("#divObservacion").hide();
    } else if (
      this._Session.rolMarcado == this._utilitarios.enumRoles.docente &&
      this._codigoEstadoFicha == this._utilitarios.enumEstado.Enviado &&
      $(".observacionMovimiento > div[data-for]").length > 0
    ) {
      this.esEnviado = false;


      $(
        ".observacionMovimiento-detalle > div[data-id] > span.ico input[type='radio']"
      ).prop("disabled", true);
      $(".observacionMovimiento .sustento > textarea").prop("disabled", true);
      $(".radioAprobadoObservado").hide();
      $(".observacionesDetalle").show();
      $(".observacion-nuevo").hide();
      $("#divObservacion").hide();
      $(".videoInstructivo").hide();
    }

    if (this.tipoCarga == 2) {
      $(document).off(
        "change",
        ".observacionMovimiento-detalle input[type='radio']"
      );
      $(document).on(
        "change",
        ".observacionMovimiento-detalle input[type='radio']",
        () => {
          var totalObservacionesPorMarcar = $(
            ".observacionMovimiento-detalle > div[data-id]"
          ).length;

          var totalMarcado = $(
            ".observacionMovimiento-detalle > div[data-id] input[type='radio']:checked"
          ).length;

          if (totalObservacionesPorMarcar == totalMarcado) {
            $("#divObservacion").show();
          } else {
            $("#divObservacion").hide();
          }
        }
      );
    }
  }
  private dibujarObservaciones(fichaMovimientoPreguntas) {
    $(".observacionMovimiento").html("");
    for (var i = 0; i < fichaMovimientoPreguntas.length; i++) {
      var element = fichaMovimientoPreguntas[i];

      $(
        'span.radioAprobadoObservado [name="' +
        element.codigoPreguntaFicha +
        '"][value="' +
        element.fichaUltimoMovimiento.codigoEstado +
        '"]'
      ).prop("checked", true);

      if (
        element.fichaMovimientoPreguntas.length > 0 &&
        (element.fichaUltimoMovimiento.codigoEstado ==
          this._utilitarios.enumEstado.Observado ||
          element.fichaUltimoMovimiento.codigoEstado ==
          this._utilitarios.enumEstado.Aprobadas)
      ) {
        var html = `<div data-for="${element.codigoPreguntaFicha
          }" data-numero="${element.numeroPregunta}" data-id-fichaApertura="${element.idFichaMovimientoPregunta
          }">
        <div class="tituloObservacion">
            <h6>
                <span>Observación en el punto ${element.numeroPregunta} </span>
                <div class="ui-icon-download" data-download>
                    <i class="material-icons" data-toggle="collapse"
                        href="#${element.codigoPreguntaFicha
          }_acordion" role="button"
                        aria-expanded="false" aria-controls="${element.codigoPreguntaFicha
          }_acordion">
                        <a href="javascript:void(0)">expand_more</a>
                    </i>
                </div>
            </h6>

        </div>
        <div class="collapse multi-collapse" id="${element.codigoPreguntaFicha
          }_acordion">
            <div class="observacionMovimiento-detalle">
              ${this.pintarDetalleObservaciones(element)}
            </div>
            <div class="observacion-nuevo">
                <textarea class="form-control"> </textarea>
            </div>
        </div>

    </div>`;

        $(".observacionMovimiento").append(html);
      }
    }
  }
  //--------------------------------------------------------------------------------
  //-- Pintar la lista observaciones - Ficha - Academico/Docente
  //--------------------------------------------------------------------------------
  private pintarDetalleObservaciones(element) {
    var html = "";

    element.fichaMovimientoPreguntas.forEach((item) => {
      var estadoObservacion = item.estadoObservacion;
      var opcionesHTML = "";
      var icono = "";
      var movimientosObservacion = "";
      if (
        this._Session.rolMarcado == this._utilitarios.enumRoles.docente &&
        estadoObservacion.codigoEstado == this._utilitarios.enumEstado.Observado
      ) {
        estadoObservacion = null;
      }
      if (estadoObservacion != null) {
        if (
          (estadoObservacion.codigoEstado ==
            this._utilitarios.enumEstado.ObservacionAprobadaDocente ||
            estadoObservacion.codigoEstado ==
            this._utilitarios.enumEstado.RechazadoDocente ||
            estadoObservacion.codigoEstado ==
            this._utilitarios.enumEstado.Aprobadas) &&
          (this._Session.rolMarcado ==
            this._utilitarios.enumRoles.coordinadorAcademico ||
            this._Session.rolMarcado ==
            this._utilitarios.enumRoles.revisor)


        ) {
          var chkAprobado =
            estadoObservacion.codigoEstado ==
              this._utilitarios.enumEstado.Aprobadas
              ? "checked"
              : "";

          estadoObservacion.codigoEstado ==
            this._utilitarios.enumEstado.Aprobadas
            ? "checked"
            : "";

          icono +=
            estadoObservacion.codigoEstado ==
              this._utilitarios.enumEstado.ObservacionAprobadaDocente
              ? '<span class="ui-icon-done"><i class="material-icons">done</i></span>'
              : '<span class="ui-icon-priority_high"><i class="material-icons">priority_high</i></span>';

          opcionesHTML += `
          
          <span class="radioGroup">
              <input type="radio" id="${item.idFichaMovimientoPreguntaObservacion}_2" ${chkAprobado} name="${item.idFichaMovimientoPreguntaObservacion}_1"  value="APSA">
              <label for="${item.idFichaMovimientoPreguntaObservacion}_2" class="aprobado">
                <i class="material-icons"></i>
              </label>
              <input type="radio" id="${item.idFichaMovimientoPreguntaObservacion}_1" name="${item.idFichaMovimientoPreguntaObservacion}_1" value="ENOD">
              <label for="${item.idFichaMovimientoPreguntaObservacion}_1" class="rechazar">
                <i class="material-icons"></i>
              </label>
          </span> `;
          /*opcionesHTML += `
          <span style="display: block;margin: 0;padding: 0;float: right;"> 
                <input style="display:none" type="radio" id="${item.idFichaMovimientoPreguntaObservacion}_2" ${chkAprobado} name="${item.idFichaMovimientoPreguntaObservacion}_1"  value="APSA">
                <label for="${item.idFichaMovimientoPreguntaObservacion}_2" class="aprobado">
                <div class="ui-icon-check_box_outline_blank" ><i class="material-icons"></i></div>
                </label>
          </span>`;*/
        } else if (
          (estadoObservacion.codigoEstado ==
            this._utilitarios.enumEstado.Enviado ||
            estadoObservacion.codigoEstado ==
            this._utilitarios.enumEstado.ObservacionAprobadaDocente ||
            estadoObservacion.codigoEstado ==
            this._utilitarios.enumEstado.RechazadoDocente) &&
          this._Session.rolMarcado == this._utilitarios.enumRoles.docente
        ) {
          var checkedDocenteAprobado =
            estadoObservacion.codigoEstado ==
              this._utilitarios.enumEstado.ObservacionAprobadaDocente
              ? "checked"
              : "";
          var checkedDocenteRechazado =
            estadoObservacion.codigoEstado ==
              this._utilitarios.enumEstado.RechazadoDocente
              ? "checked"
              : "";

          opcionesHTML += `
          
          <span class="radioGroup">
              <input type="radio" id="${item.idFichaMovimientoPreguntaObservacion}_2" ${checkedDocenteAprobado} name="${item.idFichaMovimientoPreguntaObservacion}_1"  value="OBAPPODO">
              <label for="${item.idFichaMovimientoPreguntaObservacion}_2" class="aprobadoDocente">
                <i class="material-icons"></i>
              </label>
              <input type="radio" id="${item.idFichaMovimientoPreguntaObservacion}_1" ${checkedDocenteRechazado} name="${item.idFichaMovimientoPreguntaObservacion}_1" value="REPODO">
              <label for="${item.idFichaMovimientoPreguntaObservacion}_1" class="rechazarDocente">
                <i class="material-icons"></i>
              </label>
          </span> `;
          /*opcionesHTML += `
          <span style="display: block;margin: 0;padding: 0;float: right;"> 
                <input style="display:none" type="radio" id="${item.idFichaMovimientoPreguntaObservacion}_2" ${checkedDocenteAprobado} name="${item.idFichaMovimientoPreguntaObservacion}_1"  value="OBAPPODO">
                <label for="${item.idFichaMovimientoPreguntaObservacion}_2" class="aprobadoDocente">
                <div class="ui-icon-check_box_outline_blank" ><i class="material-icons"></i></div>
                </label>
          </span>`;*/

          if (
            item.estadoObservacion.observacionRespuesta != null &&
            item.estadoObservacion.observacionRespuesta != ""
          ) {
            movimientosObservacion = `<div class="col-md-12 sustento"><textarea style="width:100%;">${item.estadoObservacion.observacionRespuesta}</textarea></div>`;
          } else {
            movimientosObservacion = `<div class="col-md-12 sustento"><textarea style="width:100%;display:none"></textarea></div>`;
          }
        }

        //--
        if (
          estadoObservacion.codigoEstado ==
          this._utilitarios.enumEstado.Aprobadas
        ) {
          icono =
            '<span class="icon-obs" ><i class="material-icons">done</i></span>';
        }
        //--
        if (
          estadoObservacion.codigoEstado ==
          this._utilitarios.enumEstado.Observado
        ) {
          opcionesHTML += `
              <div class="ui-icon-close" data-event-anular >
                  <i class="material-icons">
                      <a href="javascript:void(0)">close</a>
                  </i>
              </div>`;
        }
      }
      if (
        (this._Session.rolMarcado ==
          this._utilitarios.enumRoles.coordinadorAcademico ||
          this._Session.rolMarcado ==
          this._utilitarios.enumRoles.revisor)
      ) {
        item.movimientosObservacion.forEach((obs) => {
          movimientosObservacion +=
            '<div class="col-md-12 sustento sustentoTag"><h6><i class="material-icons">arrow_upward</i>Sustento:</h6><p>' +
            obs.observacionRespuesta +
            "</p></div>";
        });
      }
      html += `  
      <div class="row mx-0" data-id="${item.idFichaMovimientoPreguntaObservacion}" data-codigoEstado="${item.codigoEstado}">
      <span class="col-md-8 texto">
      ${icono}
      ${item.observacion}
      </span>
      <span class="col-md-4 ico" >${opcionesHTML}</span>
      ${movimientosObservacion}
      </div>`;
    });
    return html;
  }
  //--------------------------------------------------------------------------------
  //-- Preguntas Respondidas - Ficha - Academico/Docente
  //--------------------------------------------------------------------------------
  private cargarDatosPreguntasRespondidad(idFicha) {
    return this._FichaService.ObtenerPorId(
      {
        idFicha: idFicha,
      },
      null
    );
  }
  //--------------------------------------------------------------------------------
  //-- Editar - Ficha - Academico/Docente
  //--------------------------------------------------------------------------------
  private editarVerDatosFicha(rowData) {
    var idFicha = rowData.idFicha;
    var despuesModal = () => {
      this.dropdownSettings = {
        singleSelection: false,
        text: "Seleccionar",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Deseleccionar todos",
        enableSearchFilter: false,
        classes: "multipleRepositorio custom-class",
        disabled: true,
      };

      $(".fichaControles")
        .children("div")
        .find("*:not(option)")
        .prop("disabled", true);
    };

    $(".fichaControles")
      .children("div")
      .find("*:not(option)")
      .prop("disabled", false);



    $(".fichaControles .omitir input").prop("disabled", true);
    //$(".radioAprobadoObservado input[type='radio']").prop("disabled", true);
    switch (this.tipoCarga) {
      case 1:
        this.cargarDatosFichaPorId(idFicha, () => {
          despuesModal();

          $('#divCompentencias div > input[type="checkbox"]').prop(
            "disabled",
            true
          );
          this.dropdownSettings = {
            disabled: false,
          };
          this.habilitaOcultarElementosPorROL();
          this._utilitarios.OpenModal("myModal");

          if (this._Session.rolMarcado ==
            this._utilitarios.enumRoles.coordinadorAcademico) {


          }
        });
        // this.cargarDatosPreguntasRespondidad(idFicha)


        break;
      default:
        if (
          this._codigoEstadoFicha == this._utilitarios.enumEstado.Observado ||
          this._codigoEstadoFicha == this._utilitarios.enumEstado.Enviado ||
          this._codigoEstadoFicha == this._utilitarios.enumEstado.Inactivo ||
          this._codigoEstadoFicha == this._utilitarios.enumEstado.Anulado
        ) {
          this.cargarDatosFichaPorId(idFicha, () => {

            despuesModal();
            $('#divCompentencias div > input[type="checkbox"]').prop(
              "disabled",
              true
            );
            this.dropdownSettings = {
              disabled: false,
            };
            this.habilitaOcultarElementosPorROL();
            this._utilitarios.OpenModal("myModal");
          });
          // this.cargarDatosPreguntasRespondidad(idFicha)
        } else if (
          this._codigoEstadoFicha == this._utilitarios.enumEstado.Guardado
        ) {
          this.cargarDatosFichaPorId(idFicha, () => {

            this.habilitaOcultarElementosPorROL();
            this._utilitarios.OpenModal("myModal");
          });
          // this.cargarDatosPreguntasRespondidad(idFicha)
        } else if (
          this._codigoEstadoFicha == this._utilitarios.enumEstado.Aprobadas
        ) {
          this.cargarDatosFichaPorId(idFicha, () => {

            despuesModal();
            $('#divCompentencias div > input[type="checkbox"]').prop(
              "disabled",
              true
            );
            this.dropdownSettings = {
              disabled: false,
            };
            this.habilitaOcultarElementosPorROL();
            this._utilitarios.OpenModal("myModal");
          });
          // this.cargarDatosPreguntasRespondidad(idFicha)
        }

        break;
    }
  }
  //--------------------------------------------------------------------------------
  //-- Eventos - Academico/Docente
  //--------------------------------------------------------------------------------
  private eventos() {
    var _that: any = this;

    var id =
      this.tipoCarga == 1
        ? "tblListarFichasAsignadas"
        : "frmFichaTblListarFicha";
    var cancelEvent = false;
    $("#formFicha_problemaInvestigacion").keydown(function (event) {
      let tamañoPalabra = 200;
      let element = $(event.target);
      let arraysPalabras = element.val().toString().trim().split(" ");
      var valor = String.fromCharCode(event.keyCode);
      if (valor == " " && arraysPalabras.length - 1 === tamañoPalabra) {
        return false;
      } else {
        if (event.currentTarget == event.target) {
          cancelEvent = arraysPalabras.length - 1 > tamañoPalabra;
          if (cancelEvent) {
            return false;
          }
        }
      }
    });

    $("#formFicha_problemaInvestigacion").keyup(function (event) {
      if (cancelEvent) {
        return false;
      }
    });
    //--------------------------------------------------------------------------------
    //-- Editar Ficha Para Observacion - Academico/Docente(Tabla)
    //--------------------------------------------------------------------------------
    $(document).off(
      "click",
      "#" + "tblListarFichasAsignadas" + " tbody tr   div[data-edit]"
    );
    $(document).off(
      "click",
      "#" + "frmFichaTblListarFicha" + " tbody tr   div[data-edit]"
    );
    $(document).on(
      "click",
      "#" + id + " tbody tr   div[data-edit]",
      (event) => {
        var tr = $(event.target).parents("tr");
        var rowData: any = $("#" + id)
          .DataTable()
          .row(tr)
          .data();
        this._codigoEstadoFicha = rowData.codigoEstado;
        this._idFicha = rowData.idFicha;
        this._idCampusFacultadCarrera = rowData.idCampusFacultadCarrera;
        this.editarVerDatosFicha(rowData);
      }
    );

    //--------------------------------------------------------------------------------
    //-- Ver Ficha Para Observacion - Academico/Docente(Tabla)
    //--------------------------------------------------------------------------------
    $(document).off(
      "click",
      "#" + "tblListarFichasAsignadas" + " tbody tr   div[data-view]"
    );
    $(document).off(
      "click",
      "#" + "frmFichaTblListarFicha" + " tbody tr   div[data-view]"
    );
    $(document).on(
      "click",
      "#" + id + " tbody tr   div[data-view]",
      (event) => {
        var tr = $(event.target).parents("tr");
        var rowData: any = $("#" + id)
          .DataTable()
          .row(tr)
          .data();
        this._codigoEstadoFicha = rowData.codigoEstado;
        this._idFicha = rowData.idFicha;
        this._idCampusFacultadCarrera = rowData.idCampusFacultadCarrera;



        this.combobox.init(
          {
            id: "formFicha_cboCampus",
            entidad: 'mCampus',
          },
          () => {
            this.editarVerDatosFicha(rowData);
          }
        );
        //this.editarVerDatosFicha(rowData);
      }
    );

    //--------------------------------------------------------------------------------
    //-- inactivar Ficha Para Observacion - Academico/Docente(Tabla)
    //--------------------------------------------------------------------------------

    $(document).off(
      "click",
      "#frmFichaTblListarFicha tbody tr   div[data-inactivo]"
    );
    $(document).on(
      "click",
      "#frmFichaTblListarFicha tbody tr   div[data-inactivo]",
      (event) => {
        var tr = $(event.target).parents("tr");
        var rowData: any = $("#" + id)
          .DataTable()
          .row(tr)
          .data();
        Swal.fire({
          title: "¿Está seguro en desactivar la ficha?",
          type: "info",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.value) {
            this._FichaService.DesactivarFicha(
              {
                idFicha: rowData.idFicha,
              },
              (dataEstado) => {
                $("#frmFichaTblListarFicha")
                  .DataTable()
                  .row(tr)
                  .data(dataEstado);
                $("#frmFichaTblListarFicha").DataTable().row(tr).invalidate();

                this.dibujarresumen.emit();
              }
            );
          }
        });
      }
    );

    //--------------------------------------------------------------------------------
    //-- reactivar Ficha Para Observacion - Academico/Docente(Tabla)
    //--------------------------------------------------------------------------------

    $(document).off(
      "click",
      "#frmFichaTblListarFicha tbody tr   div[data-reactivar]"
    );
    $(document).on(
      "click",
      "#frmFichaTblListarFicha tbody tr   div[data-reactivar]",
      (event) => {
        var tr = $(event.target).parents("tr");
        var rowData: any = $("#" + id)
          .DataTable()
          .row(tr)
          .data();
        Swal.fire({
          title: "¿Está seguro en reactivar la ficha?",
          type: "info",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.value) {
            this._FichaService.ReactivarFicha(
              {
                idFicha: rowData.idFicha,
              },
              (dataEstado) => {
                $("#frmFichaTblListarFicha")
                  .DataTable()
                  .row(tr)
                  .data(dataEstado);
                $("#frmFichaTblListarFicha").DataTable().row(tr).invalidate();

                this.dibujarresumen.emit();
              }
            );
          }
        });
      }
    );

    //--------------------------------------------------------------------------------
    //-- busqued titulo VER Ficha  - Academico/Docente(Tabla)
    //--------------------------------------------------------------------------------
    $(document).off("click", "#tblBusquedaFichaInv tbody tr   div[data-view]");
    $(document).off("click", "#tblBusquedaFichaInv tbody tr   div[data-view]");
    $(document).on(
      "click",
      "#tblBusquedaFichaInv tbody tr  div[data-view]",
      (event) => {
        this.esTitulo = true;
        var tr = $(event.target).parents("tr");
        var rowData: any = $("#tblBusquedaFichaInv").DataTable().row(tr).data();
        this._codigoEstadoFicha = rowData.codigoEstado;
        this._idFicha = rowData.idFicha;
        this._idCampusFacultadCarrera = rowData.idCampusFacultadCarrera;
        //this.editarVerDatosFicha(rowData);
        this.combobox.init(
          {
            id: "formFicha_cboCampus",
            entidad: 'mCampus',
          },
          () => {
            this.editarVerDatosFicha(rowData);
          }
        );
      }
    );
    //--------------------------------------------------------------------------------
    //-- busqued Ficha  - Investigacion(Tabla)
    //--------------------------------------------------------------------------------
    $(document).off(
      "click",
      "#tblListaFichaAprobado tbody tr   div[data-view]"
    );

    $(document).on(
      "click",
      "#tblListaFichaAprobado tbody tr   div[data-view]",
      (event) => {
        var tr = $(event.target).parents("tr");
        var rowData: any = $("#tblListaFichaAprobado")
          .DataTable()
          .row(tr)
          .data();
        this.tipoCarga = 1;
        this._codigoEstadoFicha = rowData.codigoEstado;
        this._idFicha = rowData.idFicha;
        this._idCampusFacultadCarrera = rowData.idCampusFacultadCarrera;
        this.combobox.init(
          {
            id: "formFicha_cboCampus",
            entidad: 'mCampus',
          },
          () => {
            this.editarVerDatosFicha(rowData);
          }
        );
        //this.editarVerDatosFicha(rowData);
        this.esEnviado = false;
      }
    );

    $(document).off(
      "click",
      "#tblListaFichaAprobado tbody tr   div[data-print]"
    );
    $(document).on(
      "click",
      "#tblListaFichaAprobado tbody tr   div[data-print]",
      (event) => {
        var tr = $(event.target).parents("tr");
        var rowData: any = $("#tblListaFichaAprobado")
          .DataTable()
          .row(tr)
          .data();
        this._codigoEstadoFicha = rowData.codigoEstado;
        this._idFicha = rowData.idFicha;
        this._idCampusFacultadCarrera = rowData.idCampusFacultadCarrera;
        this._utilitarios.OpenModal("modalImpresion");
      }
    );

    //--------------------------------------------------------------------------------
    //-- Anular Observacion - Academico
    //--------------------------------------------------------------------------------
    $(document).off("click", "[data-event-anular]");
    $(document).on("click", "[data-event-anular]", function () {
      var target = $(this).parent().parent();
      var root = target.parent().parent();
      var id = target.attr("data-id");
      var idFichaMovimientoPregunta = root
        .parents("div[data-numero]")
        .attr("data-id-fichaApertura");
      Swal.fire({
        title: "¿Está seguro en desactivar la observación?",
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          _that._FichaService.DesactivarObservacion(
            {
              idFichaMovimientoPreguntaObservacion: id,
              idPersonaRegistra: _that._Session.usuario.persona.idPersona,
              idFichaMovimientoPregunta: idFichaMovimientoPregunta,
            },
            (rc) => {
              target.remove();
              if (
                root.find("div.observacionMovimiento-detalle > div").length == 0
              ) {
                $(
                  'span[id="' +
                  root.parents("div[data-numero]").attr("data-for") +
                  '"] input[type="radio"]'
                ).prop("checked", false);
                root.parents("div[data-numero]").remove();
                root.remove();
                _that.habilitarEnvioObservacion();
              }
            }
          );
        }
      });
    });
    //--------------------------------------------------------------------------------
    //-- Agregar Ficha - Docente
    //--------------------------------------------------------------------------------
    $(document).off("click", "#fichaListar_agregarFicha");
    $(document).on("click", "#fichaListar_agregarFicha", () => {

      this.limpiar();
      this._idFicha = 0;
      this._idCampusFacultadCarrera = 0;
      this.tipoCarga = 0;
      $("#btnGuardar").show();
      $(".videoInstructivo").show();
      $(".radioAprobadoObservado").hide();
      $(".observacionesDetalle").hide();

      this.dropdownSettings = {
        singleSelection: false,
        text: "Seleccionar",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Deseleccionar todos",
        enableSearchFilter: false,
        classes: "multipleRepositorio custom-class",
        disabled: false,
      };
      $("#formFicha_cboCampus").prop("disabled", false);
      $("#formFicha_cboFacultad").prop("disabled", false);
      $("#formFicha_cboCarrera").prop("disabled", false);
      $("#chkExtraordinaria").prop("disabled", false);
      $(".fichaControles")
        .children("div[data-id-question]")
        .find("*")
        .prop("disabled", false);

      $("#divObservacion").hide();
      this._utilitarios.OpenModal("myModal");
    });
    //--------------------------------------------------------------------------------
    //-- Rechazar/Aprobar - Docente
    //--------------------------------------------------------------------------------

    $(document).off(
      "change",
      ".observacionMovimiento-detalle input[value='REPODO']"
    );
    $(document).on(
      "change",
      ".observacionMovimiento-detalle input[value='REPODO']",
      function () {
        var spanParent = $(this).parents(".ico");
        if ($(this).is(":checked")) {
          spanParent.next().find("textarea").val("");
          spanParent.next().find("textarea").show();
        } else {
          spanParent.next().find("textarea").hide();
        }
      }
    );

    $(document).off(
      "change",
      ".observacionMovimiento-detalle input[value='OBAPPODO']"
    );
    $(document).on(
      "change",
      ".observacionMovimiento-detalle input[value='OBAPPODO']",
      function () {
        var spanParent = $(this).parents(".ico");
        spanParent.next().find("textarea").val("");
        spanParent.next().find("textarea").hide();
      }
    );
    //--------------------------------------------------------------------------------
    //-- Rechazar/Aprobar - Academico
    //--------------------------------------------------------------------------------
    if (this.tipoCarga == 1) {
      $('span.radioAprobadoObservado input[type="radio"]').unbind("change");
      $('span.radioAprobadoObservado input[type="radio"]').change((event) => {
        var valor = $(event.target).val();
        var idPregunta = $(event.target).parents("span").attr("id");
        var numero = $(event.target).parents("span").attr("data-numero");

        if (
          this._utilitarios.enumEstado.Observado == valor &&
          $('div[data-for="' + idPregunta + '"]').length == 0
        ) {
          //-- Agregar observacion si no tiene ninguna observacion
          var html = `<div data-for="${idPregunta}" data-numero="${numero}" data-id-fichaApertura="" >
          <div class="tituloObservacion">
              <h6>
                  <span>Observación en el punto ${numero} </span>
                  <div class="ui-icon-download" data-download>
                      <i class="material-icons" data-toggle="collapse"
                          href="#${idPregunta}_acordion" role="button"
                          aria-expanded="false" aria-controls="${idPregunta}_acordion">
                          <a href="javascript:void(0)">expand_more</a>
                      </i>
                  </div>
              </h6>

          </div>
          <div class="collapse multi-collapse" id="${idPregunta}_acordion">
              <div class="observacionMovimiento-detalle">
               <div class="row mx-0">
              <div class="col-md-12 sustento">
              </div>
             </div>
              </div>
              <div class="observacion-nuevo">
                  <textarea class="form-control"></textarea>
              </div>
          </div>

      </div>`;

          $(".observacionMovimiento").append(html);

          var container = $("<div></div>");
          var elementos = $(".observacionMovimiento");
          var pushTextArea = [];
          while (elementos.find("div[data-for]").length > 0) {
            var auxiliar = 0;
            var div = null;
            elementos.find("div[data-for]").each((i, item) => {
              var valor = $(item).attr("data-numero");
              if (auxiliar == 0) {
                div = $(item);
                auxiliar = parseInt(valor);
              } else {
                if (auxiliar > parseInt(valor)) {
                  div = $(item);
                  auxiliar = parseInt(valor);
                }
              }
            });
            if (div != null) {
              //div[0].outerHTML
              var textArea = div.find("textarea");
              pushTextArea.push({
                valor: textArea.val(),
                id: auxiliar,
              });
              container.append(div.clone());
              div.remove();
            }
          }
          $(".observacionMovimiento").html("");
          $(".observacionMovimiento").append(container.html());
          pushTextArea.forEach((itemArea) => {
            $('div[data-numero="' + itemArea.id + '"] textarea').val(
              itemArea.valor
            );
          });
        } else if (this._utilitarios.enumEstado.Aprobadas == valor) {
          $('div[data-for="' + idPregunta + '"]').hide();
          $('div[data-for="' + idPregunta + '"] textarea').val("");
        } else {
          $('div[data-for="' + idPregunta + '"]').show();
        }

        this.habilitarEnvioObservacion();
      });
      $(document).off(
        "change",
        ".observacionMovimiento .observacionMovimiento-detalle [data-codigoestado] > .ico input[type='radio']"
      );
      $(document).on(
        "change",
        ".observacionMovimiento .observacionMovimiento-detalle [data-codigoestado] > .ico input[type='radio']",
        function (event) {
          var valor = $(this).val();
          var parentPregunta = $(this).parents("div[data-for]");
          var totalDetalle = parentPregunta.find(
            ".observacionMovimiento-detalle [data-codigoestado]"
          ).length;

          if (valor == _that._utilitarios.enumEstado.Aprobadas) {
            var totalMarcados = parentPregunta.find(
              '.observacionMovimiento-detalle [data-codigoestado] input[value="APSA"]:checked'
            ).length;

            if (totalDetalle == totalMarcados) {
              $(
                'span.radioAprobadoObservado[id="' +
                parentPregunta.attr("data-for") +
                '"] input[value="APSA"]'
              ).prop("checked", true);
            }
          } else if (valor == _that._utilitarios.enumEstado.Enviado) {
            $(
              'span.radioAprobadoObservado[id="' +
              parentPregunta.attr("data-for") +
              '"] input[value="OBOD"]'
            ).prop("checked", true);
          }
          _that.habilitarEnvioObservacion();
        }
      );
    }

    //--------------------------------------------------------------------------------
    //-- Evento de mensajes-  help-question
    //--------------------------------------------------------------------------------
    $(".help-question").unbind("click");
    $(".help-question").click((event) => {
      if (event.currentTarget == event.target) {
        var idPregunta = $(event.target).attr("data-for-id-question");
        var itemPregunta = this.mensajeInformativo.find(
          (item) => item.id == idPregunta
        );

        let html = $(
          '<DIV style="text-align:justify !important">' +
          itemPregunta.nombre +
          "</DIV>"
        );

        let linkAyuda = html.find("a");

        if (html.find("a").length > 0) {
          this._ArchivoService
            .descargarArchivo(linkAyuda.attr("href").toString(), null)
            .then((data) => {
              //linkAyuda.attr("href", "data:application/pdf;base64,"+data.data);
              //linkAyuda.attr("target", "_blank");
              linkAyuda.attr("href", "javascript:void(0)");
              linkAyuda.attr("target", "");
              linkAyuda.addClass("descargar");
              linkAyuda.attr("data-url", data.data);
              $(document).off("click", ".descargar");
              $(document).on("click", ".descargar", (event) => {
                /*this._utilitarios.descargarArchivo(
                  $(event.target).attr("data-url"),
                  "fichaGuia",
                  "pdf"
                );*/

                let url_with_name =
                  "data:application/pdf;base64," +
                  $(event.target).attr("data-url");
                let html =
                  "<html>" +
                  "<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>" +
                  "<body>" +
                  '<iframe type="application/pdf" src="' +
                  url_with_name +
                  '"></iframe>' +
                  "</body></html>";
                let a = window.open("about:blank", "Zupfnoter");
                a.document.write(html);
                a.document.close();
              });

              Swal.fire({
                html: html,
                showConfirmButton: true,
                allowOutsideClick: false,
              });
            });
        } else {
          Swal.fire({
            html: html,
            showConfirmButton: true,
            allowOutsideClick: false,
          });
        }
      }
    });
  }
  //--------------------------------------------------------------------------------
  //-- Habilitar Envio Observacion - Academico/Docente
  //--------------------------------------------------------------------------------
  private habilitarEnvioObservacion() {
    var resultado = false;
    $("#divAprobar").hide();
    if (
      $(".radioAprobadoObservado input[type='radio']:first-child").length ==
      $('.radioAprobadoObservado input[type="radio"][value="APSA"]:checked')
        .length
    ) {
      $("#divAprobar").show();
      $("#divObservacion").hide();
    } else if (
      $(".radioAprobadoObservado input[type='radio']:first-child").length ==
      $('.radioAprobadoObservado input[type="radio"]:checked').length
    ) {
      if (
        $(".radioAprobadoObservado input[type='radio'][value='OBOD']:checked")
          .length > 0
      ) {
        resultado = true;
        $("#divObservacion").show();
      } else {
        $("#divObservacion").hide();
      }
    } else {
      $("#divObservacion").hide();
    }

    return resultado;
  }
  //--------------------------------------------------------------------------------
  //-- Construit Controles Formulario - Academico/Docente
  //--------------------------------------------------------------------------------
  private buildFormSolicitud() {
    let controls = new Object();
    controls["chkExtraordinaria"] = ["", Validators.required],
      controls["codigoAlumno1"] = ["", Validators.required],
      controls["NombreAlumno1"] = ["", Validators.required],
      controls["codigoAlumno2"] = ["", Validators.required],
      controls["NombreAlumno2"] = ["", Validators.required],
      controls["idCampus"] = ["", Validators.required],
      controls["periodo"] = ["", null], //Null
      controls["idFacultad"] = ["", Validators.required],
      controls["idCarrera"] = ["", Validators.required],

      controls["temaInvestigacion"] = ["", Validators.required],
      controls["problemaInvestigacion"] = ["", Validators.required],
      controls["poblacionLugarEstudio"] = ["", null],

      controls["lineaInvestigacion"] = ["", Validators.required],
      controls["actividadesClaves"] = ["", Validators.required],
      controls["incorporaObservaciones"] = ["", Validators.required],
      controls["tituloFicha"] = ["", Validators.required],
      //   tipoInvestigacion"]= ["", Validators.required],
      //  controls["informacionEntidad"] = ["", Validators.required],
      controls["eticaInvestigacion"] = ["", Validators.required],
      // tallerInvestigacion"]= ["", Validators.required],
      //  controls["idRepositorio1"] = [[], Validators.required],

      controls["palabra1_auxiliar"] = ["", Validators.required],
      controls["palabra1"] = ["", Validators.required],
      //  controls["idRepositorio2"] = [[], Validators.required],
      controls["palabra2_auxiliar"] = ["", Validators.required],
      controls["palabra2"] = ["", Validators.required],
      // controls["idRepositorio3"] = [[], Validators.required],
      controls["palabra3_auxiliar"] = ["", Validators.required],
      controls["palabra3"] = ["", Validators.required],
      //controls["idRepositorio4"] = [[], Validators.required],
      controls["palabra4_auxiliar"] = ["", Validators.required],
      controls["palabra4"] = ["", Validators.required],
      // controls["idRepositorio5"] = [[], Validators.required],
      controls["palabra5_auxiliar"] = ["", Validators.required],
      controls["palabra5"] = ["", Validators.required],
      controls["observacionAccionesEspecificacion"] = ["", Validators.required],
      controls["informacionEntidadObservacion"] = ["", Validators.required],

      //recomendacion: ["", Validators.required],
      //codigoDocenteAsesor: ["", Validators.required],
      //nombreDocenteAsesor: ["", Validators.required],
      //correoDocenteAsesor: ["", null],
      //telefonoDocenteAsesor: ["", Validators.required]
      controls["INVINF"] = ["", Validators.required];
    controls["INFPBL"] = ["", Validators.required];
    controls["RQAUT"] = ["", Validators.required];

    this.combobox.getJson(
      {
        entidad: "mPreguntasActividadesListar",
      },
      (data) => {
        this._preguntasActividad = data;
        data.map((item) => {
          controls[item.id] = ["", Validators.required];
        })
      }
    );


    this.formFicha = this.formBuilder.group(controls);


  }
  //--------------------------------------------------------------------------------
  //-- Periodo viegente
  //--------------------------------------------------------------------------------
  private periodoVigente() {
    this.combobox.getJson(
      {
        entidad: "mPeriodoVigente",
      },
      (data) => {
        this.idPeriodo = data[0].id;
        this.formFicha.controls["periodo"].setValue(data[0].nombre);
      }
    );
  }
  //--------------------------------------------------------------------------------
  //-- Competencias
  //--------------------------------------------------------------------------------
  private dibujarCompetencias(idCarrera) {
    return this.combobox.getJson(
      {
        entidad: "mCompetencia",
        parametro: {
          "@p_idCarrera": idCarrera,
        },
      },
      (data) => {
        this.divCompentencias.html("");

        data.forEach((element) => {
          var html = `<div class="col-md-6">
                        <div class="form-group">
                            <input type="checkbox" id="${element.id}">
                            <label for="${element.id}">${element.nombre}</label>
                          </div>
                       </div>`;
          var target = $(html);
          this.divCompentencias.append(target);
          target
            .children("div")
            .find('input[type="checkbox"]')
            .change((event) => {
              if (
                $("#divCompentencias").find("div input:checked").length == 0
              ) {
                this.errorChkCompetencia = true;
              } else {
                this.errorChkCompetencia = false;
              }
            });
        });
      }
    );
  }

  //--------------------------------------------------------------------------------
  //-- tipoInvestigacion
  //--------------------------------------------------------------------------------
  private dibujartipoInvestigacion() {
    var promise = new Promise((resolve, reject) => {
      //  this.combobox.init(
      //  {
      //    id: "formFicha_tipoInvestigacion",
      //   entidad: "mTipoInvestigacion",
      //   },
      // (dc) => {
      //   this.model.tipoInvestigacion = "";
      resolve();
      //    }
      //   );
    });

    return promise;
  }

  //--------------------------------------------------------------------------------
  //-- lineaInvestigacion
  //--------------------------------------------------------------------------------
  private dibujarLineaInvestigacion(idCarrera) {
    var promise = new Promise((resolve, reject) => {
      this.combobox.init(
        {
          id: "formFicha_lineaInvestigacion",
          entidad: "mLineaInvestigacion",
          parametro: {
            "@p_idCarrera": idCarrera,
          },
        },
        (dc) => {
          this.model.lineaInvestigacion = "";
          resolve();
        }
      );
    });

    return promise;
  }

  //--------------------------------------------------------------------------------
  //-- tipoInvestigacion
  //--------------------------------------------------------------------------------
  private dibujarinformacionEntidad() {
    //$("#formFicha_informacionEntidad").append('<option value="1">Si</option>');
    //$("#formFicha_informacionEntidad").append('<option value="0">No</option>');
    this.combobox.getJson(
      {
        entidad: "mPreguntaInformacionEntidad_Listar",
      },
      (data) => {
        this._preguntasInformacionEntidad = data;
      }
    );


    this.formFicha.controls["INVINF"].reset();
    this.formFicha.controls["INFPBL"].reset();
    this.formFicha.controls["RQAUT"].reset();

    //this.model.informacionEntidad = "";
  }
  private dibujareticaInvestigacion() {
    $("#formFicha_eticaInvestigacion").append('<option value="1">Si</option>');
    $("#formFicha_eticaInvestigacion").append('<option value="0">No</option>');

    this.model.eticaInvestigacion = "";
  }
  //--------------------------------------------------------------------------------
  //-- dibujartallerInvestigacion
  //--------------------------------------------------------------------------------
  private dibujartallerInvestigacion() {
    var promise = new Promise((resolve, reject) => {
      // this.combobox.init(
      //   {
      //     id: "formFicha_tallerInvestigacion",
      //     entidad: "mMetodoInvestigacion",
      //   },
      //   (dc) => {
      //     this.model.tallerInvestigacion = "";
      //     resolve();
      //   }
      // );
      resolve();
    });

    return promise;
  }

  //--------------------------------------------------------------------------------
  //-- Datos Asesor
  //--------------------------------------------------------------------------------
  private dibujarDatosAsesor(parametro: any) {
    /* this.formFicha.controls["codigoDocenteAsesor"].setValue(parametro.codigo);
    this.formFicha.controls["nombreDocenteAsesor"].setValue(
      parametro.nombreApellidos
    );
    this.formFicha.controls["correoDocenteAsesor"].setValue(parametro.correo);
    this.formFicha.controls["telefonoDocenteAsesor"].setValue(
      parametro.telefono
    );

    */
  }
  //--------------------------------------------------------------------------------
  //-- listar desplegable de mensajes ayuda para pregutnas
  //--------------------------------------------------------------------------------
  private dibujarMensajeInformativo() {
    var promise = new Promise((resolve, reject) => {
      this.combobox.getJson(
        {
          entidad: "mPreguntaMensajeInformativo",
        },
        (data) => {
          $(".help-question").hide();
          this.mensajeInformativo = data;
          this.mensajeInformativo.map((item) => {
            $('.help-question[data-for-id-question="' + item.id + '"]').show();
          });
          resolve(data);
        }
      );
    });
    return promise;
  }
  //--------------------------------------------------------------------------------
  //-- Envia Datos
  //--------------------------------------------------------------------------------
  //*aqui
  private enviarDatos(parametro, esEnviado, esGuardadoPorAcademico) {
    var model = this.modelAsignarEntidadFicha(parametro, esEnviado, esGuardadoPorAcademico);
    this._FichaService.RegistrarActualizar(model, (data) => {
      this.dibujarresumen.emit();
      this._utilitarios.CerrarModal("myModal");
      this.limpiar();
      this.dibujarGrilla.emit();
    });
  }

  private modeloEnvioDatos(parametro) {
    var data = {
      idTipoFicha: parametro.idTipoFicha,
      esGuardadoPorAcademico: parametro.esGuardadoPorAcademico,
      esFichaExtraordinario: parametro.esFichaExtraordinario,
      esEnviado: parametro.esEnviado,
      idFicha: parametro.idFicha,
      idCampus: parametro.idCampus,
      idPeriodo: parametro.idPeriodo,
      idFacultad: parametro.idFacultad,
      idCarrera: parametro.idCarrera,
      idPersona: parametro.idPersona,
      fichaCompetencias: parametro.fichaCompetencias,
      //numeroAlumnos: parametro.numeroAlumnos,
      //esPerspectiva: parametro.esPerspectiva,
      //  idTipoInvestigacion: parametro.tipoInvestigacion,
      // idMetodoInvestigacion: parametro.tallerInvestigacion,
      esAutorizacionInformacion: parametro.informacionEntidad,
      descripcionEntidadesAutorizadas:
        parametro.descripcionEntidadesAutorizadas,
      esEvaluadoPorComiteEtica: parametro.eticaInvestigacion,
      //tallerInvestigacion: parametro.tallerInvestigacion,
      //fichaTrabajoInvestigaciones: parametro.fichaTrabajoInvestigaciones,
      propositoInvestigacion: parametro.propositoInvestigacion,
      observacionAccionesEspecificacion:
        parametro.observacionAccionesEspecificacion,
      //recomendacion: parametro.recomendacion,
      fichaPalabraClaves: parametro.fichaPalabraClaves,
      temaInvestigacion: parametro.temaInvestigacion,
      problemaInvestigacion: parametro.problemaInvestigacion,
      poblacionLugarEstudio: parametro.poblacionLugarEstudio,
      idLineaInvestigacion: parametro.idLineaInvestigacion,
      actividadesClaves: parametro.actividadesClaves,
      incorporaObservaciones: parametro.incorporaObservaciones,
      tituloFicha: parametro.tituloFicha,
      // codigoDocenteAsesor: parametro.codigoDocenteAsesor,
      // nombreDocenteAsesor: parametro.nombreDocenteAsesor,
      //correoDocenteAsesor: parametro.correoDocenteAsesor,
      //telefonoDocenteAsesor: parametro.telefonoDocenteAsesor,
      fichaPreguntaInformacionEntidades: parametro.fichaPreguntaInformacionEntidades,
      fichaPreguntaActividades: parametro.fichaPreguntaActividades,
      fichaAlumnos: parametro.fichaAlumnos,
    };

    return data;
  }
  private modelAsignarEntidadFicha(parametro, esEnviado, esGuardadoPorAcademico) {
    let fichaCompetencias: Array<any> = [];
    let fichaPalabraClaves: Array<any> = [];
    let fichaAlumnos: Array<any> = [];

    let fichaPreguntaInformacionEntidades: Array<any> = [];
    let fichaPreguntaActividades: Array<any> = [];

    this.divCompentencias
      .children("div")
      .children("div")
      .children("input[type='checkbox']:checked")
      .each((index, el) => {
        fichaCompetencias.push({
          idFichaCompetencia: 0,
          idCompetencia: $(el).attr("id"),
          idFicha: 0,
          esInactivo: 0,
        });
      });

    //Nuevo
    $("#divPalabrasClaves div[data-row]").each((index, element) => {
      var reposiotrios: Array<any> = [];

      var numeroIndice = $(element).attr("data-row");
      var inputPalabraClave = $(element)
        .children("[data-for]:first-child")
        .find("input");

      var inputPalabraClaveAuxiliar = $(element)
        .children("[data-for]:last-child")
        .find("input");

      /*
      var selectRepositorio = $(element)
        .children("[data-for]:not(:first-child)")
        .find(".multipleRepositorio");

            var arrays = this.formFicha.controls[
              selectRepositorio.attr("formcontrolname")
            ].value;
      
            let findrootWordKey = this._eliminadosRepositorio.find(
              (ifc) =>
                ifc.idFichaPalabraClave ==
                inputPalabraClave.data("idFichaPalabraClave")
            );
      
            if (arrays != null) {
              arrays.forEach((item) => {
                if (typeof findrootWordKey != "undefined") {
                  let findInactivo = findrootWordKey.repos.find(
                    (ir) => ir.id == item.id
                  );
      
                  if (typeof findInactivo != "undefined") {
                    findInactivo.esInactivo = 0;
                    findInactivo.idRepositorio = item.id;
                    reposiotrios.push(findInactivo);
                  } else {
                    reposiotrios.push({
                      idRepositorio: item.id,
                      idFichaPalabraClaveRepositorio: 0,
                      esInactivo: 0,
                    });
                  }
                } else {
                  reposiotrios.push({
                    idRepositorio: item.id,
                    idFichaPalabraClaveRepositorio:
                      item.idFichaPalabraClaveRepositorio,
                    esInactivo: 0,
                  });
                }
              });
            }
            if (typeof findrootWordKey != "undefined") {
              findrootWordKey.repos.map((inc) => {
                if (inc.esInactivo == 1) {
                  reposiotrios.push(inc);
                }
              });
            }
            */
      var data = {
        idFichaPalabraClave: inputPalabraClave.data("idFichaPalabraClave"),
        numeroIndice: numeroIndice,
        palabraClave: inputPalabraClave.val(),
        palabraClaveAuxiliar: inputPalabraClaveAuxiliar.val(),
        esInactivo: 0,
        //fichaPalabraClaveRepositorios: reposiotrios,
      };
      if (inputPalabraClave.val() != "" || inputPalabraClaveAuxiliar.val() != '') {
        fichaPalabraClaves.push(data);
      }
    });

    if (this.esExtraordinario) {
      fichaAlumnos.push({
        codigo: $("#formFicha_codigoAlumno1").val(),
        idFichaAlumnos: $(".alumnoUno").attr("data-id-alumno"),
        Nombre: $("#formFicha_NombreAlumno1").val(),
      });

      fichaAlumnos.push({
        codigo: $("#formFicha_codigoAlumno2").val(),
        idFichaAlumnos: $(".alumnoDos").attr("data-id-alumno"),
        Nombre: $("#formFicha_NombreAlumno2").val(),
      });
    }


    if (this.formFicha.controls["INVINF"].value != null) {
      fichaPreguntaInformacionEntidades.push({
        codigoPreguntaInformacion: 'INVINF',
        condicional: this.formFicha.controls["INVINF"].value
      });
    }
    if (this.formFicha.controls["INFPBL"].value != null) {
      fichaPreguntaInformacionEntidades.push({
        codigoPreguntaInformacion: 'INFPBL',
        condicional: this.formFicha.controls["INFPBL"].value
      });
    }
    if (this.formFicha.controls["RQAUT"].value != null) {
      fichaPreguntaInformacionEntidades.push({
        codigoPreguntaInformacion: 'RQAUT',
        condicional: this.formFicha.controls["RQAUT"].value
      });
    }

    this._preguntasActividad.map((item) => {
      console.log('_-')

      console.log(this.formFicha.controls[item.id].value)


      let valor = this.formFicha.controls[item.id].value as string;

      if (valor.toString() != "") {

        fichaPreguntaActividades.push({
          codigoPreguntaActividad: item.id,
          condicional: this.formFicha.controls[item.id].value
        })
      }

    })

    var model = {
      esGuardadoPorAcademico: esGuardadoPorAcademico,
      esFichaExtraordinario: this.esExtraordinario ? 1 : 0,
      idTipoFicha: this.formFicha.controls["chkExtraordinaria"].value,
      esEnviado: esEnviado,
      idFicha: this._idFicha,
      idCampus: parametro.idCampus,
      idPeriodo: this.idPeriodo,
      idFacultad: parametro.idFacultad,
      idCarrera: parametro.idCarrera,
      idPersona: this._Session.usuario.persona.idPersona,
      fichaCompetencias: fichaCompetencias,
      // tipoInvestigacion: parametro.tipoInvestigacion,
      informacionEntidad: this.formFicha.controls["INVINF"].value,
      descripcionEntidadesAutorizadas: parametro.informacionEntidadObservacion,
      eticaInvestigacion: parametro.eticaInvestigacion,
      // tallerInvestigacion: parametro.tallerInvestigacion,
      propositoInvestigacion:
        parametro.propositoInvestigacion == ""
          ? null
          : parametro.propositoInvestigacion,
      observacionAccionesEspecificacion:
        parametro.observacionAccionesEspecificacion == ""
          ? null
          : parametro.observacionAccionesEspecificacion,
      //recomendacion:
      //parametro.recomendacion == "" ? null : parametro.recomendacion,
      fichaPalabraClaves: fichaPalabraClaves,

      temaInvestigacion: parametro.temaInvestigacion,
      problemaInvestigacion: parametro.problemaInvestigacion,
      poblacionLugarEstudio: parametro.poblacionLugarEstudio,

      idLineaInvestigacion: parametro.lineaInvestigacion,
      actividadesClaves: parametro.actividadesClaves,
      incorporaObservaciones: parametro.incorporaObservaciones,
      tituloFicha: parametro.tituloFicha,
      fichaPreguntaInformacionEntidades: fichaPreguntaInformacionEntidades,
      fichaPreguntaActividades: fichaPreguntaActividades,
      //codigoDocenteAsesor: parametro.codigoDocenteAsesor,
      //nombreDocenteAsesor: parametro.nombreDocenteAsesor,
      //correoDocenteAsesor: parametro.correoDocenteAsesor,
      //telefonoDocenteAsesor: parametro.telefonoDocenteAsesor,
      fichaAlumnos: fichaAlumnos,
    };

    return model;
  }

  //--------------------------------------------------------------------------------
  //-- Entidad
  //--------------------------------------------------------------------------------

  private obtenerEntidadCombobox(op) {
    var resultado = "";
    switch (op) {
      case "cm":
        if (
          (this._Session.rolMarcado ==
            this._utilitarios.enumRoles.coordinadorAcademico ||
            this._Session.rolMarcado ==
            this._utilitarios.enumRoles.revisor)
        ) {
          resultado = "mCampusCoordinadorAcademico";
        } else if (
          this._Session.rolMarcado == this._utilitarios.enumRoles.docente
        ) {
          resultado = "mCampusDocente";
        } else if (
          this._Session.rolMarcado ==
          this._utilitarios.enumRoles.coordinadorInvestigacion
        ) {
          resultado = "mCampusCoordinadorInvestigacion";
        }


        resultado = "mCampusPorPersona";

        break;
      case "fa":
        if (
          (this._Session.rolMarcado ==
            this._utilitarios.enumRoles.coordinadorAcademico ||
            this._Session.rolMarcado ==
            this._utilitarios.enumRoles.revisor)
        ) {
          resultado = "mFacultad";
        } else if (
          this._Session.rolMarcado == this._utilitarios.enumRoles.docente
        ) {
          resultado = "mFacultadPorDocente";
        } else if (
          this._Session.rolMarcado ==
          this._utilitarios.enumRoles.coordinadorInvestigacion
        ) {
          resultado = "mFacultadCoordinadorInvestigacion";
        }
        resultado = "mFacultad";

        break;
      case "ca":
        if (
          (this._Session.rolMarcado ==
            this._utilitarios.enumRoles.coordinadorAcademico ||
            this._Session.rolMarcado ==
            this._utilitarios.enumRoles.revisor)
        ) {
          resultado = "mCarreraCompletaPorCampusCoordinadorAcademico";
        } else if (
          this._Session.rolMarcado == this._utilitarios.enumRoles.docente
        ) {
          resultado = "mCarreraDocente";
        } else if (
          this._Session.rolMarcado ==
          this._utilitarios.enumRoles.coordinadorInvestigacion
        ) {
          resultado = "mCarreraPorCampus";
        }
        resultado = "mCarreraPorPersonaCampus";

        break;
    }
    return resultado;
  }
  //#endregion Privates
}
