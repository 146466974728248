import {
    FormGroup,
    FormBuilder,
    Validators,
    AbstractControl
  } from "@angular/forms";
  import { Injectable } from "@angular/core";
  
@Injectable({
    providedIn: "root"
  })
  
export class seccionesAsignadasModel {
    constructor() {}  
    _idPeriodo: number = 0;
    _Periodo: number = 0;

    cboCampus:AbstractControl;
    cboCurso:AbstractControl;
    cboSeccion:AbstractControl;
    private _cboPeriodo: AbstractControl;
    get cboPeriodo() {
      return this._cboPeriodo;
    }
    set cboPeriodo(value: AbstractControl) {
      this._cboPeriodo = value;
    }


  }
  