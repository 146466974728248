import { Component, OnInit } from '@angular/core';
import { utilitarios } from 'src/global/utilitarios';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ComboboxService } from '../_servicioComponentes/combobox.service';
import { ReporteService } from '../_servicio/reporte.service';
import { Session } from '../../global/session';
import { ReporteSeccionesFichasBE } from '../model/reporte/ReporteSeccionesFichasBE';

import { ReporteTrabajosInvestigacionTallerFiltroBE } from '../model/reporte/ReporteTrabajosInvestigacionTallerFiltroBE';
import { ReporteTrabajosInvestigacionTallerBE } from '../model/reporte/ReporteTrabajosInvestigacionTallerBE';
import { ArchivoBaseBE } from '../model/reporte/ArchivoBaseBE';
import { FichaService } from '../_servicio/ficha.service';
import { BancoArchivoBinarioBE } from '../model/BancoModel';
import { BancoService } from '../_servicio/banco.service';

@Component({
  selector: 'app-reportefinalproyectosinvestigacion',
  templateUrl: './reportefinalproyectosinvestigacion.component.html',
  styleUrls: ['./reportefinalproyectosinvestigacion.component.scss']
})
export class ReportefinalproyectosinvestigacionComponent implements OnInit {

  _BancoArchivoBinarioBE: any;

  _utilitarios: utilitarios = new utilitarios();
  _dropdownSettings: any = {};
  _dropdownSettingColumna: any = {};
  _dropdownSettingSinBuscar: any = {};

  _dropdownListPeriodo = [];
  _selectedItemsPeriodo = [];

  _dropdownListGrado = [];
  _selectedItemsGrado = [];

  _dropdownListFacultad = [];
  _selectedItemsFacultad = [];

  _dropdownListCarrera = [];
  _selectedItemsCarrera = [];

  _dropdownListCampus = [];
  _selectedItemsCampus = [];

  _dropdownListSeccion = [];
  _selectedItemsSeccion = [];

  _dropdownListCurso = [];
  _selectedItemsCurso = [];



  _dropdownListColumna = [];
  _selectedItemsColumna = [];

  _dropdownListLineainvestigacion = [];
  _selectedItemsLineainvestigacion = [];


  _FORMFICHA: FormGroup;

  public _BUILDER: any = {
    _selectedItemsPeriodo: ["", ""],
    _txtCodigoDocente: ["", ""],
    _txtDocente: ["", ""],
    _txtCodigoAlumno: ["", ""],
    _txtAlumno: ["", ""],
    _selectedItemsGrado: ["", ""],
    _selectedItemsFacultad: ["", ""],
    _selectedItemsCarrera: ["", ""],
    _selectedItemsCampus: ["", ""],
    _selectedItemsSeccion: ["", ""],
    _selectedItemsCurso: ["", ""],
    _selectedItemsLineainvestigacion: ["", ""],

  };
  constructor(
    private _BancoService: BancoService,
    private _FichaService: FichaService,
    private _ReporteService: ReporteService,
    private _clscombobox: ComboboxService,
    private formBuilder: FormBuilder,
    private _Session: Session) {
    this._FORMFICHA = formBuilder.group(this._BUILDER);

  }

  ngOnInit(): void {
    this.onClearSelectAll();
    $('[class="container-fluid pb-5"]').removeAttr('style')

    Promise.all([this.comboPeriodo(),
    this.comboGrado(),
    this.comboFacultad(),
    this.comboCarrera(),
    this.comboboCampus(),
    this.comboSeccion(),
    this.comboCurso(),
    this.comboLineainvestigacion(),
    ]).then(() => {
      this._dropdownSettings = {
        singleSelection: false,
        text: "Todos",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Deseleccionar todos",
        enableSearchFilter: true,
        badgeShowLimit: 1,
        classes: "multipleRepositorio custom-class multi-TIBA",
        searchPlaceholderText: "Buscar",

      };
      this._dropdownSettingColumna = {
        singleSelection: false,
        text: "Seleccione",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Deseleccionar todos",
        enableSearchFilter: false,
        badgeShowLimit: 1,
        classes: "multipleRepositorio custom-class ",

      };
      this._dropdownSettingSinBuscar = {
        singleSelection: false,
        text: "Todos",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Deseleccionar todos",
        enableSearchFilter: false,
        badgeShowLimit: 1,
        classes: "multipleRepositorio custom-class multi-TIBA",

      };
      this.dibujarGrilla();

    })

  }
  ngClick_BuscarDatos(event) {


    this.dibujarGrilla();


  }

  ngClick_DescargarArchivo(event) {
    this._ReporteService.DescargarArchivoExcelReporteTrabajoInvestigacion(this.parametroFiltros(), (data: ArchivoBaseBE) => {
      this._utilitarios.descargarArchivo(data.archivoBase64, data.nombreArchivo, "omitir")
    })
  }
  onClearSelectAll() {
    setInterval((item) => {
      $(".multipleRepositorio span.clear-all").each((index, element) => {
        var parent = $(element).parents(".multipleRepositorio");
        if ($(element).find(".repositorio").length == 0) {
          parent
            .find("span.clear-all")
            .html('<span class="repositorio">x</span>');
          $(element)
            .find(".repositorio")
            .click((event) => {
              if (typeof (this._FORMFICHA.controls[parent.attr("formcontrolname")]) != 'undefined') {
                this._FORMFICHA.controls[parent.attr("formcontrolname")].setValue(
                  []
                );
              }

            });
        }
      });
    }, 1);
  }

  ngClick_DescargarDocumento(parametro: BancoArchivoBinarioBE) {
    this._BancoService.ObtenerBancoFichaDocumento(
      parametro,
      (data: BancoArchivoBinarioBE) => {
        this._utilitarios.descargarArchivo(
          data.archivoBase64,
          data.nombreArchivo,
          "omitir"
        );
      }
    );
  }

  //===============================================================================================
  //-- Privado
  //===============================================================================================

  comboPeriodo() {
    return this._clscombobox.getJson(
      {
        entidad: "mPeriodo"
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListPeriodo.push({
            id: parseInt(item.id),
            itemName: item.nombre,
          });

          //this._selectedItemsPeriodo = this._dropdownListPeriodo;
        });
      }
    );
  }
  comboGrado() {
    return this._clscombobox.getJson(
      {
        entidad: "seccionGrados"
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListGrado.push({
            id: item.id,
            itemName: item.nombre,
          });


        });
      }
    );
  }

  comboFacultad() {
    return this._clscombobox.getJson(
      {
        entidad: "ReporteFacultad"
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListFacultad.push({
            id: parseInt(item.id),
            itemName: item.nombre,
          });


        });
      }
    );
  }
  comboCarrera() {
    return this._clscombobox.getJson(
      {
        entidad: "mCarreraPorPersonaCampus"
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListCarrera.push({
            id: parseInt(item.id),
            itemName: item.nombre,
          });
          // this._selectedItemsCarrera = this._dropdownListCarrera;
        });
      }
    );

  }
  comboboCampus() {
    return this._clscombobox.getJson(
      {
        entidad: "mCampusPorPersona",
        parametro: { "@p_idPersona": this._Session.usuario.persona.idPersona },
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListCampus.push({
            id: parseInt(item.id),
            itemName: item.nombre,
          });

          // this._selectedItemsCampus = this._dropdownListCampus;
        });

      }
    );
  }

  comboSeccion() {

    return this._clscombobox.getJson(
      {
        entidad: "ReporteSeccion"
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListSeccion.push({
            id: item.id,
            itemName: item.nombre,
          });
          // this._selectedItemsCampus = this._dropdownListCampus;
        });

      }
    );
  }

  comboCurso() {

    return this._clscombobox.getJson(
      {
        entidad: "mCurso"
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListCurso.push({
            id: item.id,
            itemName: item.nombre,
          });
          // this._selectedItemsCampus = this._dropdownListCampus;
        });

      }
    );
  }
  comboLineainvestigacion() {

    return this._clscombobox.getJson(
      {
        entidad: "SP_UTP_Lineainvestigacion",
        
      },

      (data) => {
        let i = 0;
        data.forEach((item) => {
          this._dropdownListLineainvestigacion.push({
            id: i,
            itemName: item.nombre,
          });
          i++;
          // this._selectedItemsCarrera = this._dropdownListCarrera;
        });
      }
    );

  }

  comboColumnas() {
    this._clscombobox.getJson(
      {
        entidad: "ListarProduccionFichas_columnas",
      },
      (data) => {
        data.forEach((item) => {
          this._dropdownListColumna.push({
            id: item.id,
            itemName: item.nombre,
          });
          // this._selectedItemsAcademico = this._dropdownListAcademico;
        });
      }
    );
  }
  parametroFiltros() {
    let parametro: ReporteTrabajosInvestigacionTallerFiltroBE = {} as ReporteTrabajosInvestigacionTallerFiltroBE;
    parametro.idTipoCurso = 1;
    parametro.idPeriodoArray = "";
    parametro.codigoDocenteArray = this._FORMFICHA.controls["_txtCodigoDocente"].value ?? "";
    parametro.nombreApellidoDocenteArray = this._FORMFICHA.controls["_txtDocente"].value ?? "";//si es nullo retorna vacio
    parametro.codigoAlumnoArray = this._FORMFICHA.controls["_txtCodigoAlumno"].value ?? "";
    parametro.nombreApellidoAlumnoArray = this._FORMFICHA.controls["_txtAlumno"].value ?? "";
    parametro.gradoArray = "";
    parametro.idFacultadArray = "";
    parametro.idCarreraArray = "";
    parametro.idCampusArray = "";
    parametro.idSeccionArray = "";
    parametro.idCursoArray = "";
    parametro.idLineainvestigacionArray = "";

    if ((this._FORMFICHA.controls["_selectedItemsPeriodo"].value != null
      && this._FORMFICHA.controls["_selectedItemsPeriodo"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsPeriodo"].value.forEach(element => {
        parametro.idPeriodoArray += element.id + ','
      });
    }

    if ((this._FORMFICHA.controls["_selectedItemsGrado"].value != null
      && this._FORMFICHA.controls["_selectedItemsGrado"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsGrado"].value.forEach(element => {
        parametro.gradoArray += element.id + ','
      });
    }

    if ((this._FORMFICHA.controls["_selectedItemsFacultad"].value != null
      && this._FORMFICHA.controls["_selectedItemsFacultad"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsFacultad"].value.forEach(element => {
        parametro.idFacultadArray += element.id + ','
      });
    }

    if ((this._FORMFICHA.controls["_selectedItemsCarrera"].value != null
      && this._FORMFICHA.controls["_selectedItemsCarrera"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsCarrera"].value.forEach(element => {
        parametro.idCarreraArray += element.id + ','
      });
    }
    if ((this._FORMFICHA.controls["_selectedItemsCampus"].value != null
      && this._FORMFICHA.controls["_selectedItemsCampus"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsCampus"].value.forEach(element => {
        parametro.idCampusArray += element.id + ','
      });
    }


    if ((this._FORMFICHA.controls["_selectedItemsSeccion"].value != null
      && this._FORMFICHA.controls["_selectedItemsSeccion"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsSeccion"].value.forEach(element => {
        parametro.idSeccionArray += element.id + ','
      });
    }

    if ((this._FORMFICHA.controls["_selectedItemsCurso"].value != null
      && this._FORMFICHA.controls["_selectedItemsCurso"].value.length > 0)) {
      this._FORMFICHA.controls["_selectedItemsCurso"].value.forEach(element => {
        parametro.idCursoArray += element.id + ','
      });
    }

    if ((this._FORMFICHA.controls["_selectedItemsLineainvestigacion"].value != null
    && this._FORMFICHA.controls["_selectedItemsLineainvestigacion"].value.length > 0)) {
    this._FORMFICHA.controls["_selectedItemsLineainvestigacion"].value.forEach(element => {      
      parametro.idLineainvestigacionArray += element.itemName + ';'
      
    });
  }

   

    return parametro;
  }
  dibujarGrilla() {
    var that = this;


    this._ReporteService.ListarTrabajosInvestigacionTaller(
      this.parametroFiltros(),
      (data: ReporteTrabajosInvestigacionTallerBE[]) => {

        var table = $("#tblReporte");
        this._utilitarios.Datatable(table, {
          responsive: false,
          columns: [

            {
              data: "periodo",
              render: (index, display, rowdata: ReporteTrabajosInvestigacionTallerBE) => {
                return "<span class='pl-3' style='font-size: 0.8rem;'>" + rowdata.periodo + "</span>"
              }
            },
            { data: "codigoUTP" },
            { data: "alumno" },
            { data: "grado", "autoWidth": true },
            { data: "nombreFacultad" },
            { data: "nombreCarrera" },
            { data: "nombreCampus" },
            { data: "classNbr", class: "small_col" },
            { data: "nombreCurso" },
            { data: "nota" },
            { data: "codigoDocente1" },
            { data: "docente1" },
            { data: "codigoDocente2" },
            { data: "docente2" },
            { data: "tituloFicha" },
            {
              data: null,
              render: (valor, type, rowData: ReporteTrabajosInvestigacionTallerBE, meta) => {

                return `<div class="ui-icon-edit documento" ><a href="javascript:void(0)" title="documentos" data-index="${meta.row}"><i class="material-icons " style="font-size:30px !important">chrome_reader_mode</i></a></div>`
              }
            },
            { data: "descripcionLineaInvestigacion" },
            { data: "idFicha", visible: false },
            { data: "idBanco", visible: false },
            
          ],
          data: data,
          fnDrawCallback: () => {
            $(document).off(
              "click",
              "#tblReporte .documento"
            );
            $(document).on("click", "#tblReporte .documento", (element) => {

              var indexRow = $(element.currentTarget).parent();
              let rowData = $('#tblReporte').DataTable().row(indexRow).data() as any;
 
              this._FichaService.ListarDocumentosBanco({
                idBanco: rowData.idBanco,
                idDocumentoProceso: 1
              }, (data) => {
                this._BancoArchivoBinarioBE = data;
                this._utilitarios.OpenModal('modalImpresion');
              })



            })

          }
        });
      }
    );
  }

}
