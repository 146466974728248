import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as $ from "jquery";
import { Session } from "../../../global/session";
import { Alert } from "selenium-webdriver";
import { JsonPipe } from "@angular/common";
@Component({
  selector: "menu-component",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"]
})
export class MenuComponent {
  _Session: Session = new Session();

  html: string = "";
  _opciones: any;
  constructor(private _Router: Router) { }

  ngOnInit() {
    this.dibujarMenu();
  }

  //#region privates
  dibujarMenu() {
    if (this._Session.usuario != null) {
      var divMenuOpciones = $('[data-id="divMenuOpcionesChild"]');
      var opciones = this._Session.usuario.opciones as Array<any>;
      divMenuOpciones.html(``);

      this._opciones = opciones.filter(item => item.idTipoOpcion == 2);
      //console.log( this._opciones)
      this.dibujarElementos( this._opciones, null, divMenuOpciones);
    }
  }

  private dibujarElementos(opciones, valorBuscar, element) {

    opciones.forEach(item => {
      if (item.idOpcionPadre == valorBuscar) {
        console.log('item...', item);
        var a = null;
        var li;
        if (item.url == null) {
          li = $(
            `<li class="menu-utp__item menu-utp__item--has-children">
            <input class="menu-utp__input" type="checkbox" name="group-${item.comando}" id="nivel-${item.comando}">
            <label class="menu-utp__label menu-utp-icon" for="nivel-${item.comando}" style="padding-left: 10px;">
              <span>${item.nombre}</span>
            </label>
            </li>`
          );
          a = $(`<ul class="menu-utp__sub menu-utp-nivel-1"></ul>`);
        } else {
          li = $(`<li class="menu-utp__item"></li>`);
          a = $(
            `<a class="menu-utp__label menu-utp__label--icon-img"  href="javascript:void(0)" routerLink="${item.url}" routerLinkActive="active"><span >${item.nombre}</span> </a>`
          );
          var _that = this;
          a.click(function () {
            var routerLink = $(this).attr("routerLink");

            _that._Router.navigateByUrl("/" + routerLink);
          });
        }
        if (a != null) {
          li.append(a);
          element.append(li);
        }

        this.dibujarElementos( this._opciones, item.idOpcion, a);
      }
    });

  }
  //#endregion privates
}
