import { Injectable } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { MapRoute } from "../../global/maproute";
import { FichaBE } from "../model/fichaModel";
@Injectable({
  providedIn: "root",
})
export class FichaService {
  private _MapRoute: MapRoute = null;
  private url: string = "";
  constructor(private http: HttpClient) {
    this._MapRoute = new MapRoute(http);
    this.url = this._MapRoute.tibaURL + "Ficha";
  }
  AnularFicha(parametro, callback) {
    this._MapRoute.post(`${this.url}/AnularFicha`, parametro, callback);
  }
  ReactivarFicha(parametro, callback) {
    this._MapRoute.post(`${this.url}/ReactivarFicha`, parametro, callback);
  }
  DesactivarFicha(parametro, callback) {
    this._MapRoute.post(`${this.url}/DesactivarFicha`, parametro, callback);
  }
  Listar(parametro, callback) {
    this._MapRoute.post(`${this.url}/Listar`, parametro, callback);
  }
  ListarDocumentosBanco(parametro, callback) {
    this._MapRoute.post(`${this.url}/ListarDocumentosBanco`, parametro, callback);
  }

  ListarFichaResumen(parametro, callback) {
    this._MapRoute.post(`${this.url}/ListarFichaResumen`, parametro, callback);
  }

  RegistrarActualizar(parametro, callback) {
    var data = {
      esGuardadoPorAcademico: parametro.esGuardadoPorAcademico,
      esFichaExtraordinario: parametro.esFichaExtraordinario,
      esEnviado: parametro.esEnviado,
      idFicha: parametro.idFicha,
      idCampus: parametro.idCampus,
      idPeriodo: parametro.idPeriodo,
      idFacultad: parametro.idFacultad,
      idCarrera: parametro.idCarrera,
      idPersona: parametro.idPersona,
      fichaCompetencias: parametro.fichaCompetencias,
      //numeroAlumnos: parametro.numeroAlumnos,
      //esPerspectiva: parametro.esPerspectiva,
      idTipoInvestigacion: parametro.tipoInvestigacion,
      idMetodoInvestigacion: parametro.tallerInvestigacion,
      esAutorizacionInformacion: parametro.informacionEntidad,
      descripcionEntidadesAutorizadas:
        parametro.descripcionEntidadesAutorizadas,
      esEvaluadoPorComiteEtica: parametro.eticaInvestigacion,
      tallerInvestigacion: parametro.tallerInvestigacion,
      //fichaTrabajoInvestigaciones: parametro.fichaTrabajoInvestigaciones,
      propositoInvestigacion: parametro.propositoInvestigacion,
      observacionAccionesEspecificacion:
        parametro.observacionAccionesEspecificacion,
      //recomendacion: parametro.recomendacion,
      fichaPalabraClaves: parametro.fichaPalabraClaves,
      problemaInvestigacion: parametro.problemaInvestigacion,
      idLineaInvestigacion: parametro.idLineaInvestigacion,
      actividadesClaves: parametro.actividadesClaves,
      incorporaObservaciones: parametro.incorporaObservaciones,
      tituloFicha: parametro.tituloFicha,
      poblacionLugarEstudio: parametro.poblacionLugarEstudio,
      temaInvestigacion: parametro.temaInvestigacion,
      // codigoDocenteAsesor: parametro.codigoDocenteAsesor,
      // nombreDocenteAsesor: parametro.nombreDocenteAsesor,
      //correoDocenteAsesor: parametro.correoDocenteAsesor,
      //telefonoDocenteAsesor: parametro.telefonoDocenteAsesor,
      fichaPreguntaInformacionEntidades: parametro.fichaPreguntaInformacionEntidades,
      fichaPreguntaActividades: parametro.fichaPreguntaActividades,
      fichaAlumnos: parametro.fichaAlumnos,
      esProyectoGenerado: parametro.esProyectoGenerado,
      idCampusFacultadCarrera: parametro.idCampusFacultadCarrera,
      idSeccion: parametro.idSeccion,
      idAlumnoProyecto:parametro.idAlumnoProyecto,
      idTipoFicha : parametro.idTipoFicha,
      idFichaPadre:parametro.idFichaPadre,
    };
    this._MapRoute.post(`${this.url}/RegistrarActualizar`, data, callback);
  }

  ObtenerPorId(parametro, callback) {
    return this._MapRoute.post(`${this.url}/ObtenerPorId`, parametro, callback);
  }

  ObtenerObservacionesPorFicha(parametro, callback) {
    return this._MapRoute.post(
      `${this.url}/ObtenerObservacionesPorFicha`,
      parametro,
      callback
    );
  }

  AgregarFichaMovimientoObservacion(parametro, callback) {
    return this._MapRoute.post(
      `${this.url}/AgregarFichaMovimientoObservacion`,
      parametro,
      callback
    );
  }

  DesactivarObservacion(parametro, callback) {
    return this._MapRoute.post(
      `${this.url}/DesactivarObservacion`,
      parametro,
      callback
    );
  }
  ImprimirFichaPDF(parametro: FichaBE, callback) {
    return this._MapRoute.post(`${this.url}/ImprimirFichaPDF`, parametro, callback);
  }
}
