import { JsonPipe } from "@angular/common";
import { ValueTransformer } from "@angular/compiler/src/util";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class Session {
  constructor() { }
  //------------------------------------------------------------------------------------------
  //--> Recordar Usuario
  //------------------------------------------------------------------------------------------
  private _recordar: boolean;
  get recordar() {
    if (localStorage.getItem("recordar") == null) {
      this._recordar = false;
    } else {
      this._recordar =
        localStorage.getItem("recordar").toString() == "false" ? false : true;
    }

    return this._recordar;
  }
  set recordar(value: boolean) {
    localStorage.setItem("recordar", String(value));
  }
  //------------------------------------------------------------------------------------------
  //--> Recordar IniciarSession
  //------------------------------------------------------------------------------------------
  private _Usuario: any;
  get usuario() {
    var resultado: any = null;

    this._Usuario = JSON.parse(String(localStorage.getItem("usuario")));
    resultado = this._Usuario;



    return resultado;
  }
  set usuario(value) {


    localStorage.setItem("usuario", String(JSON.stringify(value)));

  }
  //------------------------------------------------------------------------------------------
  //--> Rol Marcao
  //------------------------------------------------------------------------------------------
  private _rolMarcado: any;
  get rolMarcado() {
    var resultado: any = null;
    if (this.usuario != null) {
      resultado = this.usuario.roles[0].codigoRol;
    }
    return resultado;
  }
  //------------------------------------------------------------------------------------------
  //--> Token
  //------------------------------------------------------------------------------------------
  private _token: string;
  get token() {
    var resultado: string = null;
    if (this.usuario != null) {
      resultado = this.usuario.token;
    }
    return resultado;
  }


  //------------------------------------------------------------------------------------------
  //--> Nombre/Contraseña IniciarSession
  //------------------------------------------------------------------------------------------

  private _NombreUsuario: string = "";

  get NombreUsuario() {
    var resultado: any = null;
    if (this.recordar) {
      this._NombreUsuario = localStorage.getItem("nombreUsuario") as string;
      resultado = this._NombreUsuario;
    } else {
      resultado = null;
    }
    return resultado;
  }

  set NombreUsuario(value) {
    localStorage.removeItem("nombreUsuario");
    localStorage.setItem("nombreUsuario", value);
  }
  //------------------------------------------------------------------------------------------
  //--> Contraseña IniciarSession
  //------------------------------------------------------------------------------------------
  private _Contrasenia: string = "";

  get Contrasenia() {
    if (this.recordar) {
      this._Contrasenia = localStorage.getItem("contrasenia") as string;
      return this._Contrasenia;
    } else {
      return null;
    }
  }

  set Contrasenia(value) {
    localStorage.setItem("contrasenia", value);
  }

  //------------------------------------------------------------------------------------------
  //--> Opciones Menu
  //------------------------------------------------------------------------------------------
  private _Opciones: string = "";

  get Opciones() {
    var resultado: any = null;
    if (this.recordar) {
      if (localStorage.getItem("opciones") != null) {
        this._Opciones = JSON.parse(String(localStorage.getItem("opciones")));
        resultado = this._Opciones;
        sessionStorage.removeItem("opciones");
      }
    } else {
      if (sessionStorage.getItem("opciones") != null) {
        this._Opciones = JSON.parse(String(sessionStorage.getItem("opciones")));
        resultado = this._Opciones;
        localStorage.removeItem("opciones");
      }
    }
    return resultado;
  }

  set Opciones(value) {
    if (value == null) {
      sessionStorage.removeItem("opciones");
      localStorage.removeItem("opciones");
    } else {
      if (this.recordar) {
        localStorage.setItem("opciones", String(JSON.stringify(value)));
      } else {
        sessionStorage.setItem("opciones", String(JSON.stringify(value)));
      }
    }
  }


  //------------------------------------------------------------------------------------------
  //--> Cargo Elegido
  //------------------------------------------------------------------------------------------
  private _CargoElegido: string = "";

  get CargoElegido() {
    var resultado: any = null;

    this._CargoElegido = JSON.parse(String(localStorage.getItem("CargoElegido")));
    resultado = this._CargoElegido;

    return resultado;
  }

  set CargoElegido(value) {

    localStorage.setItem("CargoElegido", String(JSON.stringify(value)));

  }

  //------------------------------------------------------------------------------------------
  //--> Iniciales
  //------------------------------------------------------------------------------------------
  private _Iniciales: string = "";

  get iniciales() {
    var resultado: any = null;
    this._Iniciales = String(localStorage.getItem("Iniciales"));
    resultado = this._Iniciales;
    return resultado;
  }

  set iniciales(value) {

    localStorage.setItem("Iniciales", String(value));

  }



}

//-----------------------------------------------------------------------------------------
//--> Inicializar Session
//-----------------------------------------------------------------------------------------
