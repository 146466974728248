<div class="row justify-content-between">
    <div class="col-md-6">
        <!-- Title section -->
        <h3>Fichas</h3>
        <i class="material-icons"  id="fichaListar_agregarFicha">add_circle</i>
        <!-- End Title section -->
    </div>
</div>
<!-- Content section -->
<div class="card" style="overflow-x: scroll;">
    <!-- Filters -->
    <h6>Filtros</h6>
    <div class="row justify-content-between m-0">
        <div class="col-md-10">
            <div class="row">
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="cboPeriodo">Periodo</label>
                        <select class="form-control" id="cboPeriodo" [(ngModel)]="model.idPeriodo">
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="cboCampus">Campus</label>
                        <select class="form-control" id="cboCampus" (change)="onChangeCampus($event)">
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="cboCarrera">Carrera</label>
                        <select class="form-control" id="cboCarrera" data-html="todos">
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="cboEstado">Estado</label>
                        <select class="form-control" id="cboEstado">
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <button class="btn btn-buscar" type="submit" (click)="onClickBuscar()">Buscar<i
                    class="material-icons">search</i></button>
        </div>
    </div>
    <!-- End Filters -->
    <!-- Table -->
    <div class="mx-3">
        <table class="table" id="frmFichaTblListarFicha">
            <thead>
                <tr>
                    <th scope="col">Título de la ficha</th>
                    <th scope="col">Periódo</th>
                    <th scope="col">Campus</th>
                    <th scope="col">Carrera</th>
                    <th scope="col">Docente</th>
                    <th scope="col">Tipo Ficha</th>
                    <th scope="col">Estado</th>                          
                    <th scope="col">Opciones</th>
                </tr>
            </thead>
            <tbody>


            </tbody>
        </table>
    </div>
    <!-- End Table -->

    <!-- ------------------------------------------------------------------ -->
    <hr class="my-5 mx-3">
    <!-- ------------------------------------------------------------------ -->
    <!-- Resume -->
    <!-- Title section -->
    <h4 class="title-resumen-detail">Resumen</h4>
    <!-- End Title section -->
    <!-- Content section -->
    <div class="card-detail" id="resumen">
        <!-- Resume detail -->
    </div>

    <!-- End resume -->
    <!-- End Content section -->
</div>


<app-ficha-agregar (dibujarGrilla)="dibujarGrilla()" (dibujarresumen)="dibujarresumen()"></app-ficha-agregar>

