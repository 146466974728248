import { Pipe, PipeTransform } from "@angular/core";
import { JsonPipe } from "@angular/common";

@Pipe({
  name: "filter"
})
export class FilterPipe implements PipeTransform {
  transform(items: Array<any>, idBanco: number): any {
    if (!items) {
      alert("sddf");
      return items;
    }

 
    // To search values only of "name" variable of your object(item)
    //return items.filter(item => item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
    
    // To search in values of every variable of your object(item)
    let data = items.filter(item => item.idBanco == 0);
   
    return data;
  }
}
