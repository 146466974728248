import { Injectable } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { MapRoute } from "../../global/maproute";
@Injectable({
  providedIn: "root"
})
export class UsuarioService {
  private _MapRoute: MapRoute = null;
  private urlUsuario: string = "";
  constructor(private http: HttpClient) {
    this._MapRoute = new MapRoute(http);
    this.urlUsuario = this._MapRoute.tibaURL + "usuario";
  }
  validarUsuario(parametro, callback) {
    this._MapRoute.post(
      `${this.urlUsuario}/ValidarUsuario`,
      parametro,
      callback
    );
  }
}
