import {
  Component,
  ViewChild,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  Directive,
  QueryList,
  ViewChildren,
  ɵConsole,
  Input,
} from "@angular/core";
import {
  CdkDragDrop,
  moveItemInArray,
  CdkDrag,
  CdkDropList,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  NgModel,
} from "@angular/forms";
import { ClassField } from "@angular/compiler";
import { JsonPipe, getLocaleDateTimeFormat } from "@angular/common";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
//---------------------------------------------------------------------
//-- Clases Locales
//---------------------------------------------------------------------
import { ComboboxService } from "../_servicioComponentes/combobox.service";
import { utilitarios } from "../../global/utilitarios";
import { SeccionService } from "../_servicio/seccion.service";
import { BancoService } from "../_servicio/banco.service";
import { PersonaService } from "../_servicio/persona.service";
import { Session } from "../../global/session";
import { asignarFichaModel } from "../model/asignarFichaModel";
import { seccionesAsignadasModel } from "../model/seccionesAsignadasModel";
import { FilterPipe } from "../pipes/asignarFicha_FilterBancoPorIdBanco";
import { NgbCheckBox } from "@ng-bootstrap/ng-bootstrap";
import { FichaBE } from "../model/fichaModel";
import { FichaService } from '../_servicio/ficha.service';
import { data } from 'jquery';

//#region ajax

class clsAJAX {
  // _MODEL: asignarFichaModel = new asignarFichaModel();

  constructor(
    private _clscombobox: ComboboxService,
    private _SeccionService: SeccionService,
    private _PersonaService: PersonaService,
    private _BancoService: BancoService,
    private _FichaService: FichaService
  ) { }

  RegistrarProyectoFicha = (parametro) => {
    var promise = new Promise((response, reject) => {
      this._FichaService.RegistrarActualizar({
        "esGuardadoPorAcademico": 0,
        "esFichaExtraordinario": 0,
        "esEnviado": false,
        "idFicha": 0,
        // "idCampus": parametro.idCampus,
        // "idFacultad": parametro.idFacultad,
        // "idCarrera": parametro.idCarrera,
        "idCampusFacultadCarrera": parametro.idCampusFacultadCarrera,
        "idPeriodo": parametro.idPeriodo,
        idSeccion: parametro.idSeccion,
        "idPersona": parametro.idPersona,
        "tituloFicha": parametro.tituloFicha,
        "esProyectoGenerado": true,
        "fichaAlumnos": parametro.fichaAlumnos,
        idAlumnoProyecto: parametro.idAlumnoProyecto,
        "idTipoFicha": 4,
        "idFichaPadre": parametro.idFichaPadre
      }, (data) => {
        response(data);
      })
    })
    return promise;
  }

  RevertirFichaAlumnos = (parametro) => {
    var promise = new Promise((response, reject) => {
      this._BancoService.RevertirFichaAlumnos(
        {
          idSeccion: parametro.idSeccion,
          periodo: parametro.periodo,
          idbanco: parametro.idbanco,
          idFicha: parametro.idFicha,
          idPersonaDocente: parametro.idPersonaDocente,
          tipoCurso: parametro.tipoCurso
        },
        (data) => {
          response(data);
        }
      );
    });
    return promise;
  };
  ListarRetirado = (parametro) =>{
    var promise = new Promise((response, reject) => {
      this._BancoService.ListarRetirado(
        {
          classnbr: parametro,
          
          
        },
        (data) => {
          response(data);
        }
      );
    });
    return promise;

  };
 LimpiarRetirado = (parametro) =>{
    var promise = new Promise((response, reject) => {
      this._BancoService.LimpiarRetirado(
        {
          idAlumno: parametro.idAlumno,
          
          
        },
        (data) => {
          response(data);
          console.log(data);
        }
      );
    });
    return promise;

  };



  AsignarFichaAlumnos = (parametro) => {
    var promise = new Promise((response, reject) => {
      this._BancoService.AsignarFichaAlumnos(
        {
          idSeccion: parametro.idSeccion,
          periodo: parametro.periodo,
          idbanco: parametro.idbanco,
          idFicha: parametro.idFicha,
          idPersonaDocente: parametro.idPersonaDocente,
          tipoCurso: parametro.tipoCurso
        },
        (data) => {
          response(data);
        }
      );
    });
    return promise;
  };

  AsociarAlumnoBanco = (parametro) => {
    var promise = new Promise((response, reject) => {
      this._BancoService.AsociarAlumnoBanco(
        {
          idAlumno: parametro.idAlumno,
          idSeccion: parametro.idSeccion,
          periodo: parametro.periodo,
          idbanco: parametro.idbanco,
          idCarrera: parametro.idCarrera,
          idPersonaDocente: parametro.idPersonaDocente,
          tipoCurso: parametro.tipoCurso
        },
        (data) => {
          response(data);
        }
      );
    });
    return promise;
  };
  DesactivarFicha = (parametro) => {
    var promise = new Promise((response, reject) => {
      this._FichaService.DesactivarFicha(
        parametro,
        (data) => {
          response(data);
        }
      );
    });
    return promise;

  }
  EliminarFichaAsociadaAlumno = (parametro) => {
    var promise = new Promise((response, reject) => {
      this._BancoService.EliminarFichaAsociadaAlumno(
        {
          idAlumno: parametro.idAlumno,
          idSeccion: parametro.idSeccion,
          periodo: parametro.periodo,
          idbanco: parametro.idbanco,
          idPersonaDocente: parametro.idPersonaDocente,
          comentarioDesasociacion: parametro.comentarioDesasociacion,
          tipoCurso: parametro.tipoCurso,
        },
        (data) => {
          response(data);
        }
      );
    });
    return promise;
  };

  RegistrarAlias = (parametro) => {

    var promise = new Promise((response, reject) => {
      this._BancoService.RegistrarActualizarBancoAlias(parametro
        ,
        (data) => {
          response(data);
        }
      );
    });
    return promise;
  }
  obtenerPeriodo = () => {
    var promise = new Promise((response, reject) => {
      this._clscombobox.getJson(
        {
          entidad: "mPeriodoAperturado",
        },
        (data) => {
          response(data);
        }
      );
    });

    return promise;
  };
  ListarMovimientoFichas = (parametro) => {
    var promise = new Promise((response, reject) => {
      this._SeccionService.ListarMovimientoFichas(
        {
          idPeriodo: parametro.idPeriodo,
          idSeccion: parametro.idSeccion,
          idPersonaDocente: parametro.idPersonaDocente,
          codigoRol: parametro.codigoRol
        },
        (data) => {
          response(data);
        }
      );
    });
    return promise;
  };
  ListarAlumnosSinFichas = (parametro) => {
    var promise = new Promise((response, reject) => {
      this._PersonaService.ListarAlumnosSinFichas(
        {
          idPeriodo: parametro.idPeriodo,
          idSeccion: parametro.idSeccion,
          buscar: parametro.buscar == null ? "" : parametro.buscar,
          tipoCurso: parametro.tipoCurso == null ? "" : parametro.tipoCurso,
        },
        (data) => {
          response(data);
        }
      );
    });
    return promise;
  };

  ListarFichaAsociadas = (parametro) => {
    var promise = new Promise((response, reject) => {
      this._SeccionService.ListarFichaAsociadas(
        {
          idPeriodo: parametro.idPeriodo,
          idSeccion: parametro.idSeccion,
          buscar: parametro.buscar == null ? "" : parametro.buscar,
          estado: parametro.estado == null ? 0 : parametro.estado,
          tipoCurso: parametro.tipoCurso == null ? 0 : parametro.tipoCurso,
        },
        (data) => {
          response(data);
        }
      );
    });
    return promise;
  };
}
//#endregion ajax

//#region  metodos

class clsMetodos {
  cdkDropTrackLists: CdkDropList[];


  @ViewChildren(CdkDropList)
  set cdkDropLists(value: QueryList<CdkDropList>) {
    this.cdkDropTrackLists = value.toArray();
  }
  theCheckbox = true;
  _FichasAsociadasModel: any = {};
  _MODEL: asignarFichaModel = new asignarFichaModel();
  _utilitarios: utilitarios = new utilitarios();
  _Session: Session = new Session();
  _BancoSeleccionado: any = {};
  _AlumnoSeleccionado: any = {};
  _AlumnosRetirados: any 
  listaAlumnosRetirados: any[];
  private _clsAJAX: clsAJAX;

  constructor(
    private ComboboxService: ComboboxService,
    SeccionService: SeccionService,
    PersonaService: PersonaService,
    BancoService: BancoService,
    FichaService: FichaService
  ) {
    this._clsAJAX = new clsAJAX(
      ComboboxService,
      SeccionService,
      PersonaService,
      BancoService,
      FichaService
    );
  }

  inicializador() {
    let promiseAll = Promise.all([this._clsAJAX.obtenerPeriodo()]).then(
      (array) => {
        let dataPeriodo: any = array[0];
        this._MODEL._idPeriodo = dataPeriodo[0].id;
        this._MODEL._periodo = dataPeriodo[0].nombre;
        this.dibujarResumenMovimientoFichas();
        this.dibujarComboboxInit();
        Promise.all([
          this.dibujarListaFichasAsociadas(),
          this.dibujarListaAlumnosNoAsignados(),
        ]).then((array: Array<any>) => {
          let itemAsociada = this._MODEL._FichasAsociadasModel;
          let itemSinAsociar = this._MODEL._AlumnosSinAsignarModel;
          this.dibujarDragDrop(itemAsociada, itemSinAsociar);
          //this.expandCollapse();
        });
      }
    );
  }
  RegistrarAlias = (parametro) => {

    var promise = new Promise((response, reject) => {
      this._clsAJAX.RegistrarAlias(parametro).then(() => {
        response();
      });
    });
    return promise;
  }
  RegistrarProyectoFicha(parametro) {
    var promise = new Promise((response, reject) => {
      this._clsAJAX.RegistrarProyectoFicha(parametro).then((data) => {
        Promise.all([
          this.dibujarListaFichasAsociadas(),
          this.dibujarListaAlumnosNoAsignados(),
        ]).then((array: Array<any>) => {
          let itemAsociada = this._MODEL._FichasAsociadasModel;
          let itemSinAsociar = this._MODEL._AlumnosSinAsignarModel;
          this.dibujarDragDrop(itemAsociada, itemSinAsociar);
          //this.expandCollapse();
        });
        response(data);
      });
    })
    return promise;
  }
  DesactivarFicha(indexFicha, idBanco, idFicha) {
    var promise = new Promise((response, reject) => {
      this._clsAJAX
        .DesactivarFicha({
          idFicha: idFicha,
        }).then((data: any) => {

          Promise.all([
            this.dibujarListaFichasAsociadas(),
            this.dibujarListaAlumnosNoAsignados(),
          ]).then((array: Array<any>) => {
            let itemAsociada = this._MODEL._FichasAsociadasModel;
            let itemSinAsociar = this._MODEL._AlumnosSinAsignarModel;
            this.dibujarDragDrop(itemAsociada, itemSinAsociar);
            //this.expandCollapse();
          });
          response(data);

        })
    })
  }
  RevertirFichaAlumnos(indexFicha, idBanco, idFicha) {
    this._clsAJAX
      .RevertirFichaAlumnos({
        idSeccion: this._MODEL._idSeccion,
        periodo: this._MODEL._idPeriodo,
        idbanco: idBanco,
        idFicha: idFicha,
        idPersonaDocente: this._MODEL._idPersonaDocente,
        tipoCurso: this._MODEL._tipoCurso,
      })
      .then((data: any) => {
        let resultEliminar = data.seccionFichasAsociadas as any;
        let resultMovimiento = data.seccionMovimientoFichas[0] as any;

        /*
        
        this._FichasAsociadasModel.column2[indexFicha].codigoEstado =
          resultEliminar.codigoEstado;
        this._FichasAsociadasModel.column2[indexFicha].nombreEstado =
          resultEliminar.nombreEstado;
        this._FichasAsociadasModel.column2[indexFicha].nombreEstadoCSS =
          resultEliminar.nombreEstadoCSS;
 */

        this._MODEL._fichasDisponibles = resultMovimiento.fichasDisponibles;
        this._MODEL._fichasAsignadas = resultMovimiento.fichasAsignadas;
        this._MODEL._AlumnosAsignados = resultMovimiento.alumnosAsignados;
        this._MODEL._AlumnosSinAsignar = resultMovimiento.alumnosSinAsignar;

        //aqui
        Promise.all([
          this.dibujarListaFichasAsociadas(),
          this.dibujarListaAlumnosNoAsignados(),
        ]).then((array: Array<any>) => {
          let itemAsociada = this._MODEL._FichasAsociadasModel;
          let itemSinAsociar = this._MODEL._AlumnosSinAsignarModel;
          this.dibujarDragDrop(itemAsociada, itemSinAsociar);
          //this.expandCollapse();
        });



      });
  }
  AsignarFichaAlumnos(indexFicha, idBanco, idFicha) {
    this._clsAJAX
      .AsignarFichaAlumnos({
        idSeccion: this._MODEL._idSeccion,
        periodo: this._MODEL._idPeriodo,
        idbanco: idBanco,
        idFicha: idFicha,
        idPersonaDocente: this._MODEL._idPersonaDocente,
        tipoCurso: this._MODEL._tipoCurso
      })
      .then((data: any) => {
        let resultEliminar = data.seccionFichasAsociadas as any;
        let resultMovimiento = data.seccionMovimientoFichas[0] as any;

        // this._FichasAsociadasModel.column2[indexFicha].codigoEstado =
        //   resultEliminar.codigoEstado;
        // this._FichasAsociadasModel.column2[indexFicha].nombreEstado =
        //   resultEliminar.nombreEstado;
        // this._FichasAsociadasModel.column2[indexFicha].nombreEstadoCSS =
        //   resultEliminar.nombreEstadoCSS;
        // this._FichasAsociadasModel.column2[indexFicha].cantidadConfirmados = 1;
        // this._FichasAsociadasModel.column2[indexFicha].totalConfirmadosPorSeccion = this._FichasAsociadasModel.column2[indexFicha].alumnos.length;

        // this._FichasAsociadasModel.column2[indexFicha].alumnos.map(item => {

        //   item.esConfirmadoParaTaller = 1;
        // })
        this._MODEL._fichasDisponibles = resultMovimiento.fichasDisponibles;
        this._MODEL._fichasAsignadas = resultMovimiento.fichasAsignadas;
        this._MODEL._AlumnosAsignados = resultMovimiento.alumnosAsignados;
        this._MODEL._AlumnosSinAsignar = resultMovimiento.alumnosSinAsignar;

        //aqui
        Promise.all([
          this.dibujarListaFichasAsociadas(),
          this.dibujarListaAlumnosNoAsignados(),
        ]).then((array: Array<any>) => {
          let itemAsociada = this._MODEL._FichasAsociadasModel;
          let itemSinAsociar = this._MODEL._AlumnosSinAsignarModel;
          this.dibujarDragDrop(itemAsociada, itemSinAsociar);
          //this.expandCollapse();
        });
      });
  }
  AsociarAlumnoBanco(alumno, idbanco) {
    return this._clsAJAX.AsociarAlumnoBanco({
      idAlumno: alumno.idAlumno,
      idSeccion: this._MODEL._idSeccion,
      periodo: this._MODEL._idPeriodo,
      idbanco: idbanco,
      idCarrera: alumno.idCarrera,
      idPersonaDocente: this._MODEL._idPersonaDocente,
      tipoCurso: this._MODEL._tipoCurso
    });
  }
  eliminarAlumnoAsociado(indexFicha, indexAl, idBanco) {
    let promise = new Promise((resolve, reject) => {
      let alumno = this._FichasAsociadasModel.column2[indexFicha].alumnos[
        indexAl
      ];
      Promise.all([
        this._clsAJAX.EliminarFichaAsociadaAlumno({
          idAlumno: alumno.idAlumno,
          idSeccion: this._MODEL._idSeccion,
          periodo: this._MODEL._idPeriodo,
          idbanco: idBanco,
          idPersonaDocente: this._MODEL._idPersonaDocente,
          comentarioDesasociacion: this._MODEL.txtComentarioDesasociacion.value,
          tipoCurso: this._MODEL._tipoCurso
        }),
      ]).then((data: Array<any>) => {
        let resultEliminar = data[0].seccionFichasAsociadas as any;
        let resultMovimiento = data[0].seccionMovimientoFichas[0] as any;

        this._FichasAsociadasModel.column2[indexFicha].codigoEstado =
          resultEliminar.codigoEstado;
        this._FichasAsociadasModel.column2[indexFicha].nombreEstado =
          resultEliminar.nombreEstado;
        this._FichasAsociadasModel.column2[indexFicha].nombreEstadoCSS =
          resultEliminar.nombreEstadoCSS;
        this._FichasAsociadasModel.column2[indexFicha].alumnos.splice(
          indexAl,
          1
        );
        this._FichasAsociadasModel.column1[0].alumnos.push(alumno);

        this._MODEL._fichasDisponibles = resultMovimiento.fichasDisponibles;
        this._MODEL._fichasAsignadas = resultMovimiento.fichasAsignadas;
        this._MODEL._AlumnosAsignados = resultMovimiento.alumnosAsignados;
        this._MODEL._AlumnosSinAsignar = resultMovimiento.alumnosSinAsignar;
        resolve();
      });
    });

    return promise;
  }
  asignarVariablesAntesDesasociar(indexFicha, indexAl, idBanco) {
    this._MODEL._indexFicha = indexFicha;
    this._MODEL._indexAl = indexAl;
    this._MODEL._idBanco = idBanco;
  }

  cargarVariablesInicio() {
    this._MODEL._seccion = sessionStorage.getItem("_seccionNombre").toString();
    this._MODEL._idSeccion = sessionStorage.getItem("_seccionId").toString();
    this._MODEL._tipoCurso = sessionStorage.getItem("_tipoCurso").toString();
    this._MODEL._idPersonaDocente = parseInt(
      sessionStorage.getItem("_idPersonaDocente").toString()
    );
  }

  dibujarAlumnosEncontrados() {
    var promise = new Promise((resolve, reject) => {
      this._clsAJAX
        .ListarAlumnosSinFichas({
          idPeriodo: this._MODEL._idPeriodo,
          idSeccion: this._MODEL._idSeccion,
          buscar: $("#txtBuscarAlumno").val(),
          tipoCurso: this._MODEL._tipoCurso,
        })
        .then((data: any) => {
          this._MODEL._AlumnosSinAsignarModel = data as Array<any>;

          let alumnosSinAsignar = this._FichasAsociadasModel.column1[0];

          alumnosSinAsignar.alumnos = [];

          data.map((item) => {
            alumnosSinAsignar.alumnos.push({
              idAlumno: item.idAlumno,
              codigoUTP: item.codigoUTP,
              alumno: item.alumno,
              nombreCarrera: item.nombreCarrera,
              idBanco: item.idBanco,
              idCampusFacultadCarrera: item.idCampusFacultadCarrera,
              observacion: item.observacion
              
            });
          });
          resolve(data);
        });
    });
    return promise;
  }

  dibujarFichasAsociadasEncontradas() {
    var promise = new Promise((resolve, reject) => {
      this._clsAJAX
        .ListarFichaAsociadas({
          idSeccion: this._MODEL._idSeccion,
          idPeriodo: this._MODEL._idPeriodo,
          buscar: $("#txtBuscarFichaTodos").val(),
          estado:
            $("#cboEstadoBanco").val() == "" ? 0 : $("#cboEstadoBanco").val(),
          tipoCurso: this._MODEL._tipoCurso,
        })
        .then((data: Array<any>) => {
          this._FichasAsociadasModel.column2 = [];

          this._MODEL._FichasAsociadasModel = data;
          this._FichasAsociadasModel.column2 = [];

          data.map((item) => {
            this._FichasAsociadasModel.column2.push({
              // idBanco: item.idBanco,
              // tituloFicha: item.tituloFicha,
              // alias: item.alias,
              // nombreCarrera: item.nombreCarrera,
              // codigoEstado: item.codigoEstado,
              // nombreEstadoCSS: item.nombreEstadoCSS,
              // nombreEstado: item.nombreEstado,
              // cantidadAlumnoAsignado: item.cantidadAlumnoAsignado,
              // observacion: item.observacion,
              // alumnos: item.alumnos,
              // cantidadConfirmados: item.cantidadConfirmados,
              // totalConfirmadosPorSeccion: item.totalConfirmadosPorSeccion,
              // idCampusFacultadCarrera: item.idCampusFacultadCarrera,
              // idTipoFicha: item.idTipoFicha,
              // idFichaPadre: item.idFichaPadre,
              // idFicha: item.idFicha,
              idBanco: item.idBanco,
              idFicha: item.idFicha,
              idCampusFacultadCarrera: item.idCampusFacultadCarrera,
              tituloFicha: item.tituloFicha,
              alias: item.alias,
              nombreCarrera: item.nombreCarrera,
              codigoEstado: item.codigoEstado,
              nombreEstadoCSS: item.nombreEstadoCSS,
              nombreEstado: item.nombreEstado,
              cantidadAlumnoAsignado: item.cantidadAlumnoAsignado,
              observacion: item.observacion,
              esFichaExtraordinaria: item.esFichaExtraordinaria,
              alumnos: item.alumnos,
              cantidadConfirmados: item.cantidadConfirmados,
              totalConfirmadosPorSeccion: item.totalConfirmadosPorSeccion,
              idTipoFicha: item.idTipoFicha,
              idFichaPadre: item.idFichaPadre,


            });
          });

          resolve();
        });
    });
    return promise;
  }

  dibujarDragDrop(itemAsociada, itemSinAsociar) {
    this._FichasAsociadasModel = {
      column1: [],
      column2: [],
    };

    this._FichasAsociadasModel.column1.push({
      idBanco: 0,
      idFicha: 0,
      idCampusFacultadCarrera: 0,
      tituloFicha: "",
      nombreCarrera: "",
      codigoEstado: "0",
      nombreEstadoCSS: "",
      nombreEstado: "",
      cantidadAlumnoAsignado: 0,
      observacion: "",
      alumnos: [],
      esFichaExtraordinaria: 0,
    });

    let firstEntidad = this._FichasAsociadasModel.column1[0];
    itemSinAsociar.map((item) => {
      firstEntidad.alumnos.push({
        codigoUTP: item.codigoUTP,
        idAlumno: item.idAlumno,
        alumno: item.alumno,
        nombreCarrera: item.nombreCarrera,
        idCarrera: item.idCarrera,
        idBanco: item.idBanco,
        idFicha: item.idFicha,
        esPropietario: 0,
        idCampusFacultadCarrera: item.idCampusFacultadCarrera,
        observacion: item.observacion

      });
    });

    itemAsociada.map((item) => {
      this._FichasAsociadasModel.column2.push({
        idBanco: item.idBanco,
        idFicha: item.idFicha,
        idCampusFacultadCarrera: item.idCampusFacultadCarrera,
        tituloFicha: item.tituloFicha,
        alias: item.alias,
        nombreCarrera: item.nombreCarrera,
        codigoEstado: item.codigoEstado,
        nombreEstadoCSS: item.nombreEstadoCSS,
        nombreEstado: item.nombreEstado,
        cantidadAlumnoAsignado: item.cantidadAlumnoAsignado,
        observacion: item.observacion,
        esFichaExtraordinaria: item.esFichaExtraordinaria,
        alumnos: item.alumnos,
        cantidadConfirmados: item.cantidadConfirmados,
        totalConfirmadosPorSeccion: item.totalConfirmadosPorSeccion,
        idTipoFicha: item.idTipoFicha,
        idFichaPadre: item.idFichaPadre,
      });
    });
  }
  dibujarResumenMovimientoFichas() {
    var promise = new Promise((resolve, reject) => {
      this._clsAJAX
        .ListarMovimientoFichas({
          idPeriodo: this._MODEL._idPeriodo,
          idSeccion: this._MODEL._idSeccion,
          idPersonaDocente: this._MODEL._idPersonaDocente,
          codigoRol:''
        })
        .then((data) => {
          let item = data[0] as any;
          this._MODEL._fichasDisponibles = item.fichasDisponibles;
          this._MODEL._fichasAsignadas = item.fichasAsignadas;
          this._MODEL._AlumnosAsignados = item.alumnosAsignados;
          this._MODEL._AlumnosSinAsignar = item.alumnosSinAsignar;
          resolve();
        });
    });
    return promise;
  }

  dibujarListaAlumnosNoAsignados() {
    var promise = new Promise((resolve, reject) => {
      this._clsAJAX
        .ListarAlumnosSinFichas({
          idPeriodo: this._MODEL._idPeriodo,
          idSeccion: this._MODEL._idSeccion,
          tipoCurso: this._MODEL._tipoCurso,
        })
        .then((data) => {

          this._MODEL._AlumnosSinAsignarModel = data as Array<any>;
          console.log(data);
          
          

          resolve();
        });

    });

    return promise;
  }

  dibujarListaFichasAsociadas() {
    var promise = new Promise((resolve, reject) => {
      this._clsAJAX
        .ListarFichaAsociadas({
          idPeriodo: this._MODEL._idPeriodo,
          idSeccion: this._MODEL._idSeccion,
          tipoCurso: this._MODEL._tipoCurso,
        })
        .then((data) => {
          let array = data as Array<any>;
          let panelbody = $(".panel-asig-body");

          this._MODEL._FichasAsociadasModel = array;
          resolve();
        });
    });

    return promise;
  }

  firstFichaAsociada() {
    return this._FichasAsociadasModel[0];
  }

  dibujarComboboxInit() {
    this.ComboboxService.init(
      {
        id: "cboEstadoBanco",
        entidad: "mEstadoBanco",
        parametro: {
          "@p_tipoCurso": this._MODEL._tipoCurso,
        },
      },
      (resl) => { }
    );
  }

  expandCollapse() {
    $(document).ready(() => {


      if (this.theCheckbox) {
        ($(".collapse") as any).collapse("show");
      } else {
        ($(".collapse") as any).collapse("hide");
      }


    });
  }

  //Retirado(){}
  ListarRetirado = (parametro) =>{
    var promise = new Promise((response, reject) => {

      this._clsAJAX.ListarRetirado(parametro).then( (resp) =>
        {
          console.log('respuesta...', resp);
          response(resp);
        }
      );
      
    })

   
    return promise;

  };

  LimpiarRetirado = (parametro) =>{
    var promise = new Promise((response, reject) => {

      console.log('listando retirado...', parametro);
      this._clsAJAX.LimpiarRetirado(parametro);
    });
    return promise;

  };
}
//#endregion metodos

//===============================================================================================================
//-- DOM
//===============================================================================================================
@Component({
  selector: "app-asignarficha",
  templateUrl: "./asignarficha.component.html",
  styleUrls: ["./asignarficha.component.scss"]
})
export class AsignarfichaComponent extends clsMetodos implements OnInit {
  public _FORMFICHA: FormGroup;
  _errorNombreProyecto: Boolean = false;
  _formEditarTrabajoInvestigacionValidar: boolean = false;
  _nombreDelProyecto: string = "";
  _idBancoAuxiliar: number = 0;
  txtNombreProyecto: string = "";

  listaAlumnosRetirados: any;
  @ViewChild(".test") txtEsConfirmado: ElementRef;
  public _BUILDER: any = {
    txtComentarioDesasociacion: ["", Validators.required],
  };

  public _AlumnosSinAsignarModel: any = [
    {
      alumno: "11",
      nombreCarrera: "sdsdsd",
    },
  ];
  public _AlumnosSinAsignarModel2: any = [
    {
      alumno: "cfff11",
      nombreCarrera: "sdsdsd0000",
    },
  ];
  constructor(
    private formBuilder: FormBuilder,
    ComboboxService: ComboboxService,
    SeccionService: SeccionService,
    PersonaService: PersonaService,
    BancoService: BancoService,
    FichaService: FichaService
  ) {
    super(ComboboxService, SeccionService, PersonaService, BancoService, FichaService);
    $(document).ready(() => {

      $('[class="container-fluid pb-5"]').removeAttr('style')
    })

    this._FORMFICHA = this.formBuilder.group(this._BUILDER);
    this._MODEL.txtComentarioDesasociacion = this._FORMFICHA.controls[
      "txtComentarioDesasociacion"
    ];

    this.cargarVariablesInicio();
    $("body").css("overflow-x", "hidden");
    $('[data-toggle="tooltip"]').hover(() => {
      $("body").css("overflow-x", "hidden");
    });
  }


  ngClick_DesactivarFicha(indexFicha, idBanco, idFicha) {
    Swal.fire({
      title: "¿Está seguro en desactivar la ficha?",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.DesactivarFicha(indexFicha, idBanco, idFicha)
      }
    });

  }

  ngClick_btnListarRetirados() {
    console.log('bver data',this._MODEL._seccion);
    this._utilitarios.OpenModal("modalListarRetirados");

    //console.log('lista...', this.ListarRetirado(this._MODEL._seccion));
    
    this.ListarRetirado(this._MODEL._seccion).then( (resp)=>{

      this.listaAlumnosRetirados = resp;
      console.log('Alumnos retirados..', this.listaAlumnosRetirados);

    });

    

  }
  




  ngClick_CerrarModal(event) {

    $('.fondo').removeClass('background-modal');
    this._utilitarios.CerrarModal('modalEditarTrabajoInvestigacion');

  }
  ngBlur_validarombreDelProyecto(event) {
    this._formEditarTrabajoInvestigacionValidar = true;

  }
  ngClick_MostrarNickName(itemFicha, indexFicha) {
    this._BancoSeleccionado = itemFicha;
    this._MODEL._indexFicha = indexFicha;
    this._formEditarTrabajoInvestigacionValidar = false;
    this._nombreDelProyecto = "";
    $('.fondo').addClass('background-modal');
    this._utilitarios.OpenModal('modalEditarTrabajoInvestigacion');

  }
  ngClick_GuardarAlias(event) {
    if (this._nombreDelProyecto == "") {
      this._formEditarTrabajoInvestigacionValidar = true;
    } else {

      this.RegistrarAlias({
        idBanco: this._BancoSeleccionado.idBanco,
        idPersonaRegistro: this._Session.usuario.persona.idPersona,
        alias: this._nombreDelProyecto
      }).then(() => {
        let column2 = this._FichasAsociadasModel.column2[this._MODEL._indexFicha];
        column2.alias = this._nombreDelProyecto
        $('.fondo').removeClass('background-modal');
        this._utilitarios.CerrarModal('modalEditarTrabajoInvestigacion');

      })
    }

  }
  ngOnInit() {
    this.inicializador();
  }

  cdkDragMoved(event) {
    const element = event.target
    let e = window.event as any;
    var posX = e.clientX;
    var posY = e.clientY;
    var el = document.querySelector('body');

    // get scroll position in px
    console.log(el.scrollLeft, el.scrollTop);

    // set scroll position in px

    console.log()
    console.log(`left:${posX}px;top:${posY}px`)

    $('.cdk-drag-preview').attr('style', `position:fixed;left:${posX}px;top:${posY + 15}px !important`)
    // $('[class="cdk-drag row cdk-drag-dragging"]').attr('style', `position:fixed;left:${posX}px;top:${posY}px`)
  }
  //Importante -- para for hijo
  ngTest(id: string) {

    let cantidad = 0;
    console.log('.confirmo_' + id)
    console.log($('.confirmo_' + id).length)
    $('.confirmo_' + id).each((item: any) => {

      cantidad += parseInt(item.html())
    })
    return cantidad;
  }
  ngTest2(item) {

    item.idBanco = 12000
  }



  ngAfterViewChecked() {
    let jquery: any = $('[data-toggle="tooltip"]');
    jquery.tooltip();
  }
  ngOver_SobreFicha(idBanco) {
    this._idBancoAuxiliar = idBanco;

  }
  ngOut_SobreFicha() {
    this._idBancoAuxiliar = 0;
  }
  dropAlumnoSinAsignar(event, idBanco, totalConfirmadosPorSeccion, codigoEstado) {

    if (totalConfirmadosPorSeccion <= 1
      && codigoEstado == this._utilitarios.enumEstado.AsignadoCursoTaller) {

      return false;
    }

    console.log(event.previousContainer)
    console.log(event.previousIndex)
    console.log(':::::::::::::::::::::::::::::::::::.')
    console.log(event.container)

    //if (event.previousContainer === event.container) {
    if (this._idBancoAuxiliar == 0) {

      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    else {
      if (this._idBancoAuxiliar == 0) {

        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      } else {

        //let fichaCurrent=event.container.data;
        let fichaCurrent = this._FichasAsociadasModel.column2.find((x) => {
          return x.idBanco == this._idBancoAuxiliar
        });

        let alumnoCurrent = event.previousContainer.data[event.previousIndex];

        this.AsociarAlumnoBanco(
          alumnoCurrent,
          this._idBancoAuxiliar
        ).then((data: any) => {
          let resultMovimiento = data.seccionMovimientoFichas[0] as any;
          let fichaBanco = this._FichasAsociadasModel.column2.find((x) => {
            return x.idBanco == data.seccionFichasAsociadas.idBanco;
          });
          fichaBanco.codigoEstado = data.seccionFichasAsociadas.codigoEstado;
          fichaBanco.nombreEstado = data.seccionFichasAsociadas.nombreEstado;
          fichaBanco.nombreEstadoCSS =
            data.seccionFichasAsociadas.nombreEstadoCSS;

          this._MODEL._fichasDisponibles = resultMovimiento.fichasDisponibles;
          this._MODEL._fichasAsignadas = resultMovimiento.fichasAsignadas;
          this._MODEL._AlumnosAsignados = resultMovimiento.alumnosAsignados;
          this._MODEL._AlumnosSinAsignar = resultMovimiento.alumnosSinAsignar;

          transferArrayItem(
            event.previousContainer.data,
            fichaCurrent.alumnos,
            event.previousIndex,
            fichaCurrent.alumnos.length
          );
        });
      }

    }


  }


  ngClick_VisualizarFicha(item: FichaBE) {

  }
  ngClick_AsignarProyecto(itemFicha, itemAlumno) {

    this._BancoSeleccionado = itemFicha;
    this._AlumnoSeleccionado = itemAlumno;
    this.txtNombreProyecto = "";
    this._utilitarios.OpenModal('modalProyecto')

  }
  ngClick_btnGuardarProyecto(event) {
    if (this.txtNombreProyecto.trim() != "") {


      this.RegistrarProyectoFicha({
        // "idCampus": parametro.idCampus,
        // "idPeriodo": parametro.idPeriodo,
        // "idFacultad": parametro.idFacultad,
        // "idCarrera": parametro.idCarrera,
        "idPeriodo": this._MODEL._idPeriodo,
        idCampusFacultadCarrera: this._BancoSeleccionado.idCampusFacultadCarrera,
        "idPersona": this._MODEL._idPersonaDocente,
        "tituloFicha": this.txtNombreProyecto,
        "esFichaExtraordinario": 1,
        idSeccion: this._MODEL._idSeccion,
        idAlumnoProyecto: this._AlumnoSeleccionado.idAlumno,
        idFichaPadre: this._BancoSeleccionado.idFicha,
        fichaAlumnos: [{ codigo: this._AlumnoSeleccionado.codigoUTP, idFichaAlumnos: "0", Nombre: this._AlumnoSeleccionado.alumno }]

      }).then(() => {

        this._utilitarios.CerrarModal('modalProyecto')
      });

    } else {
      this._errorNombreProyecto = true;
    }

  }
  ngClick_AsignarFichaAlumnos(indexFicha, idBanco, idFicha) {
    Swal.fire({
      //title
      //text
      html:
        "Al confirmar la ficha, se estará enviando un email con la información de la ficha.</br><b>¿Está seguro de confirmar la ficha?</b>",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#43b12b",
      confirmButtonText: "Si",
      cancelButtonColor: "red",
      cancelButtonText: "No",
    }).then(
      (confirm) => {
        if (confirm.value) {
          this.AsignarFichaAlumnos(indexFicha, idBanco, idFicha);
        }
      },
      function () {
        return false;
      }
    );
  }

  //NUEVO PARA RETIRADOR 
  ngClick_LimpiarRetirado(oAlumno: any){
    console.log('alumno...', oAlumno);
    Swal.fire({
      //title
      //text
      html:
        "Al eliminar al alumno no se mostrara en la lista .</br><b>¿Está seguro de eliminar al Alumno Retirado. </br> "+oAlumno.alumno+"?</b>",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#43b12b",
      confirmButtonText: "Si",
      cancelButtonColor: "red",
      cancelButtonText: "No",
    }).then(
      (confirm) => {
        if (confirm.value) {

        //this.Retirado();
        console.log('alumno...', oAlumno);
        this.LimpiarRetirado(oAlumno);
        this.inicializador();
        
        }
      },
      function () {
        return false;
      }
    );
  }




  ngClick_RevertirFichaAlumnos(indexFicha, idBanco, idFicha) {
    Swal.fire({
      //title
      //text
      html:
        "Al revertir la ficha, se estará cambiando el estado de la ficha a 'Reservado'.</br><b>¿Está seguro de revertir la ficha?</b>",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#43b12b",
      confirmButtonText: "Si",
      cancelButtonColor: "red",
      cancelButtonText: "No",
    }).then(
      (confirm) => {
        if (confirm.value) {
          this.RevertirFichaAlumnos(indexFicha, idBanco, idFicha);
        }
      },
      function () {
        return false;
      }
    );
  }
  ngClick_EliminarAlumnoAsignado(indexFicha, indexAl, idBanco) {
    this.eliminarAlumnoAsociado(indexFicha, indexAl, idBanco);
  }

  ngClick_EliminarAsignacionExtraordinaria(indexFicha, indexAl, idBanco) {
    this._utilitarios.OpenModal("modalDesasociarFichaExtraordinaria");
    this.asignarVariablesAntesDesasociar(indexFicha, indexAl, idBanco);
  }
  ngClick_DesasociarFicha(event) {
    //valida todos los campos
    Object.keys(this._FORMFICHA.controls).forEach((field) => {
      // {1}
      const control = this._FORMFICHA.get(field); // {2}
      control.markAsTouched({ onlySelf: true }); // {3}
    });
    if (this._FORMFICHA.valid) {
      this.eliminarAlumnoAsociado(
        this._MODEL._indexFicha,
        this._MODEL._indexAl,
        this._MODEL._idBanco
      ).then(() => {
        this.onCerrarModel();
      });
    }
  }
  ngkeyup_AlumnoSinAsignar(event) {
    if (event.keyCode == 13) {
      this.dibujarAlumnosEncontrados().then((data: Array<any>) => {
        if (data.length == 0) {
          Swal.fire({
            html: "No se encontraron resultados",
            showConfirmButton: true,
            allowOutsideClick: false,
          });
        }
      });
    }
  }

  ngkeyup_Fichas(event) {
    if (event.keyCode == 13) {
      this.dibujarFichasAsociadasEncontradas();
    }
  }

  ngChange_EstadoBanco(event) {
    this.dibujarFichasAsociadasEncontradas();
  }
  ngChange_Expand(event) {
    //$('checkbox').prop('checked',false);<-- no funciona porque ya se mapeo con angular
    this.expandCollapse();
  }

  ngNumberOnly(event) {
    const pattern = /[0-9]+/;
    let inputChar = String.fromCharCode(event.charCode);
    if (pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onCerrarModel() {
    /*
    this._MODEL.txtComentarioDesasociacion.setValue("");
    this._MODEL.txtComentarioDesasociacion.clearValidators();
    this._MODEL.txtComentarioDesasociacion.setErrors(null);
    this._MODEL.txtComentarioDesasociacion.reset();

    */

    this._FORMFICHA.reset();
    this._MODEL._indexFicha = 0;
    this._MODEL._idBanco = 0;
    this._MODEL._indexAl = 0;
    this._utilitarios.CerrarModal("modalDesasociarFichaExtraordinaria");
  }
}
