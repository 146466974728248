<div class="row justify-content-between">
    <div class="col-md-6">
        <!-- Title section -->
        <h3>Resumen fichas asignadas</h3>
        <!--

        <input type="file" id="fileArchivo" name="fileArchivo" style="display:none"
            (change)="onChangeArchivo($event)" />
        -->
        <!-- End Title section -->
    </div>
    <div class="col-md-6 text-right">
        <button type="submit" class="btn btn-history col-md-5" id="btnHistorialCarga" (click)="onClickHistorialFichas()">Historial de
            fichas<i class="material-icons">assignment</i></button>
    </div>
</div>
<!-- Content section -->
<div class="card">
    <!-- Filters -->
    <h6>Filtros</h6>
    <div class="row justify-content-between m-0">
        <div class="col-md-10">
            <div class="row">
                <div class="col-md-2">
                    <div class="form-group" data-for="cboPeriodo">
                        <label for="cboPeriodo">Periodo</label>
                        <select id="cboPeriodo" class="form-control" data-html="Todos" (change)="onChangePeriodo($event)">
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group" data-for="cboDocentes">
                        <label for="cboDocentes">Docentes</label>
                        <select id="cboDocentes" class="form-control" data-html="Todos">
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group" data-for="cboEstado">
                        <label for="cboEstado">Estado</label>
                        <select id="cboEstado_fichasAsignadas" class="form-control" data-html="Todos">
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <button class="btn btn-buscar" type="submit" (click)="onClickBuscar()">
                Buscar<i class="material-icons">search</i>
            </button>
        </div>
    </div>
    <!-- End Filters -->
    <!-- Table -->
    <div class="mx-3">
        <table class="table" id="tblListarFichasAsignadas">
            <thead>
                <tr>
                    <th scope="col">Título de la ficha</th>
                    <th scope="col">Periodo</th>
                    <th scope="col">Campus</th>
                    <th scope="col">Docente</th>
                    <th scope="col">Fecha de registro</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Opciones</th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
    <!-- End Table -->
</div>
<!-- Modal Historial carga -->
<div class="modal fade" id="modalBusquedaTitulo" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Búsqueda de ficha de investigación por título
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form name="form" class="card p-3">
                <!-- Filters -->
                <h6>Filtros</h6>
                <div class="row justify-content-between m-0">
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="idTitulo">Título</label>
                                    <input type="text" id="idTitulo" class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-buscar" type="submit" (click)="onClickBuscarFichaTitulo()">
                            Buscar<i class="material-icons">search</i>
                        </button>
                    </div>
                </div>
                <div class="mx-3">
                    <table class="table" id="tblBusquedaFichaInv">
                        <thead>
                            <tr>
                                <th scope="col">Periodo</th>
                                <th scope="col">Título de la ficha</th>
                                <th scope="col">Docente</th>
                                <th scope="col">Estado</th>
                                <th scope="col">Opciones</th>
                            </tr>
                        </thead>
                        <tbody>                          
                        </tbody>
                    </table>
                </div>
            </form>
        </div>
    </div>
</div>

<app-ficha-agregar  (dibujarGrilla)="onClickBuscar()" [tipoCarga]="1"></app-ficha-agregar>