import {
  Component,
  OnInit,
  AfterViewInit,
  Injectable,
  Output,
  Input,
  EventEmitter,
  ElementRef,
  ViewChild
} from "@angular/core";
import {
  FormGroup,
  NgForm,
  NgModelGroup,
  FormBuilder,
  Validators
} from "@angular/forms";
import { JsonPipe } from "@angular/common";
import * as $ from "jquery";
import { strictEqual } from "assert";
import { Alert } from "selenium-webdriver";

//---------------------------------------------------------------------
//-- Clases Locales
//---------------------------------------------------------------------

import { SolicitudFichaService } from "../../_servicio/solicitudFicha.service";
import { PersonaService } from "../../_servicio/persona.service";
import { ComboboxService } from "../../_servicioComponentes/combobox.service";
import { Session } from "../../../global/session";
import { utilitarios } from "../../../global/utilitarios";

@Component({
  selector: "app-solicitudficha-agregar",
  templateUrl: "./solicitudficha-agregar.component.html",
  styleUrls: ["./solicitudficha-agregar.component.scss"]
})
export class SolicitudfichaAgregarComponent implements OnInit {
  @Output() onClickListar = new EventEmitter();
  @Output() obtenerResumen = new EventEmitter<any>();
  //@Input() pendiente: number; //parent to child
  solicitarFichas: string;
  @ViewChild("fsol", { static: true }) fsol: NgForm;

  public pendiente: number; //parent to child
  private pendienteAuxiliar: number = 0;
  private _$: any = $;
  private _tr: object = null;
  private edit: boolean = false;
  private _docentesAuxiliar: any;
  private _docentes: any;
  private _maximoFichas: number = 0;
  private _SelectAuxiliar: string = "";
  _idPeriodoVigente: number = 0;
  formSolicitudFicha: FormGroup;
  angForm: FormGroup;
  solicitadasCnt: number;
  model: any = {
    idCampus: "",
    idPeriodo: "",
    idCoordinadorAcademico: this._Session.usuario.persona.idPersona,
    fechaLimite: "",
    cantidad: 0,
    idCarreraSolicitud: 0
  };

  constructor(
    private formBuilder: FormBuilder,
    private formBuilderSolicitudFicha: FormBuilder,
    private combobox: ComboboxService,
    private _SolicitudFicha: SolicitudFichaService,
    private _PersonaService: PersonaService,
    private _Session: Session,
    private _utilitarios: utilitarios
  ) {
    this.buildForm();
  }
  //#region  Inicializador
  ngOnInit() {
    this.solicitadasCnt = 0;
    this.pendienteAuxiliar = 0;
    this.pendienteAuxiliar = 0;

    this.buildFormSolicitud();
    Promise.all([this.obtenerValorMaximoFichas()]).then(() => {
      this.onInitCombobox();
      this.campusPorDefecto();
      this.onEventostblListarSolicitudFicha();
      this.onClickAgregarSolicitud();

      this.solicitarFichas = "Solicitar fichas";
    });
  }
  ngAfterViewInit() {
    this.edit = false;
  }
  onEnviarDatos() {
    if (this.pendiente < this.solicitadasCnt) {
      this._utilitarios.Mensaje("La cantidad asignada es mayor al pendiente.");
      return false;
    }
    if (this.formSolicitudFicha.valid) {
      this.model = $.extend({}, this.model, this.formSolicitudFicha.value);

      var parametro = {
        fechaLimite: this._utilitarios.ConvertJsonDate(this.model.fechaLimite),
        idPeriodo: this.model.idPeriodo,
        periodo: $('#frmAgregarSolicitud_periodo option:selected').html(),
        idCampus: this.model.idCampus,
        idCoordinadorAcademico: this._Session.usuario.persona.idPersona,
        solicitudFichaDocentes: [],
        idCampusFacultadCarrera: $("#frmAgregarSolicitud_carrera").val()
      };
      var idPeriodo=this.model.idPeriodo;
      parametro.solicitudFichaDocentes = [];
      var divDocentesDinamico = $("#divDocentesDinamico");
      divDocentesDinamico.children("[data-row]").each(function () {
        var idSolicitudFicha =
          $(this).attr("data-id") == "" ? 0 : $(this).attr("data-id");
        var container = $(this).children();
        var chk = container.children(".checked").find('input[type="checkbox"]');
        if (chk.is(":checked")) {
          var select = container.children(".cantidad").find("select");
          parametro.solicitudFichaDocentes.push({
            cantidad: select.val(),
            idSolicitudFicha: idSolicitudFicha,
            idPersonaSolicitante: chk.attr("id"),
            docente: chk.next().html(),
            idPeriodo: idPeriodo,
            idCampusFacultadCarrera: $("#frmAgregarSolicitud_carrera").val()
          });
        }
      });
      this._SolicitudFicha.RegistrarActualizar(parametro, data => {
        $("#cboPeriodo").val(parametro.idPeriodo);

        if (this.edit) {
          var divDocentesDinamico = $("#divDocentesDinamico");
          var idSolicitudFicha = divDocentesDinamico
            .children("[data-row]")
            .attr("data-id");

          this._SolicitudFicha.ObtenerSolicitudFichaPorId(
            {
              idSolicitudFicha: idSolicitudFicha
            },
            data => {
              var rowData = $("#tblListarSolicitudFicha")
                .DataTable()
                .row(this._tr)
                .data(data);
              $("#tblListarSolicitudFicha")
                .DataTable()
                .row(this._tr)
                .invalidate();
            }
          );
          this.edit = false;
        } else {
          $("#cboCarrera").val(parametro.idCampusFacultadCarrera);
          this.onClickListar.emit();
          this.edit = false;
        }
        this.pendiente = this.pendienteAuxiliar;
        this.limpiar(true);
        this._utilitarios.CerrarModal("myModal");
        this.obtenerResumen.emit({
          callback: null
        });
      });
    } else {
      this._utilitarios.validateAllFields(this.formSolicitudFicha);
    }
  }
  //#endregion Inicializador

  //#region  combobox
  onInitCombobox() {
    this.cargarMetodos();
  }

  onChangeCarreraSolicitud(event) {
    this.obtenerResumenPorPeriodoAgregar(pend => {
      this.pendiente = parseInt(pend);
    });
  }
  //#endregion combobox

  //#region  checked
  onChangeCampus(event) {
    let valor: string = $(event.target)
      .val()
      .toString();
    $("#chkAll").prop("checked", false);
  }

  onSeleccionarTodos(event) {
    var divDocentesDinamico = $("#divDocentesDinamico");
    if ($(event.target).is(":checked")) {
      if (divDocentesDinamico.find("[data-row]").length > 0) {
        divDocentesDinamico
          .children("div[data-row]")
          .find('input[type="checkbox"]')
          .addClass("dd");
        divDocentesDinamico
          .children("div[data-row]")
          .find('input[type="checkbox"]')
          .prop("checked", true);
        this.cantidadMarcada();
      }
    } else {
      if (divDocentesDinamico.find("[data-row]").length > 0) {
        divDocentesDinamico
          .children("div[data-row]")
          .find('input[type="checkbox"]')
          .prop("checked", false);
        this.cantidadMarcada();
      }
    }
  }
  //#endregion checked

  onEventostblListarSolicitudFicha() {
    //-->edit
    $(document).off("click", "#tblListarSolicitudFicha .data-edit");
    $(document).on("click", "#tblListarSolicitudFicha .data-edit", event => {
      this.solicitarFichas = "Editar solicitud de fichas";
      this.edit = true;
      var tr = $(event.target).parents("tr");
      var rowData = $("#tblListarSolicitudFicha")
        .DataTable()
        .row(tr)
        .data();
      this._tr = tr;

      this.obtenerDatosPorId(rowData, data => {
        this.formSolicitudFicha.clearValidators();
        this.formSolicitudFicha.updateValueAndValidity();
        this.model = data;
        this.formSolicitudFicha.controls["idPeriodo"].setValue(
          this.model.idPeriodo
        );
        this.formSolicitudFicha.controls["fechaLimite"].setValue(
          this.model.fechaLimite
        );
        this.formSolicitudFicha.controls["idCarreraSolicitud"].setValue(
          this.model.idCampusFacultadCarrera
        );
        this.obtenerResumenPorPeriodoAgregar(resultadoPendiente => {
          this.dibujarDocentes(data.solicitudFichaDocentes);
          data.solicitudFichaDocentes.forEach(item => {
            this.pendiente = resultadoPendiente + item.cantidad;
            this.model.cantidad = item.cantidad;
          });
        });
        $("#solicitudficha_btnAgregarSolicitud").trigger("click");
      });
      event.preventDefault();
    });
    //-->delete
    $(document).off("click", "#tblListarSolicitudFicha .data-delete");
    $("#tblListarSolicitudFicha .data-delete").unbind("click");
    $(document).on("click", "#tblListarSolicitudFicha .data-delete", event => {
      this._utilitarios.ConfirmWarning({
        text: "¿Estás seguro en eliminar este registro?",
        yesCallback: res => {
          var tr = $(event.target).parents("tr");
          var rowData = $("#tblListarSolicitudFicha")
            .DataTable()
            .row(tr)
            .data();
          this._tr = tr;
          this._SolicitudFicha.DesactivarSolicitudFicha(rowData, data => {
            /*  this._SolicitudFicha.ObtenerSolicitudFichaPorId(rowData, data => {
          this.obtenerResumen.emit();
          var rowData = $("#tblListarSolicitudFicha")
            .DataTable()
            .row(this._tr)
            .data(data);
          $("#tblListarSolicitudFicha")
            .DataTable()
            .row(this._tr)
            .invalidate();
        });
   */

            $("#tblListarSolicitudFicha")
              .DataTable()
              .rows(this._tr)
              .remove()
              .draw(false);

            this._tr = null;
          });
        }
      });

      event.preventDefault();
    });
  }

  onCerrarModel() {
    this.pendiente = this.pendienteAuxiliar;
    this.limpiar(false);
  }

  onClickAgregarSolicitud() {
    $("#solicitudficha_btnAgregarSolicitud").unbind("click");
    $("#solicitudficha_btnAgregarSolicitud").click(e => {
      if (!this.edit) {
        this.periodoPorDefecto();
        this.cargarDocentes({
          idCampus: this.model.idCampus,
          idPeriodo:
            $("#frmAgregarSolicitud_periodo").val() == ""
              ? 0
              : $("#frmAgregarSolicitud_periodo").val(),
          idPersona: this._Session.usuario.persona.idPersona
        });

        this.combobox.getJson(
          {
            entidad: "fechaLimiteCoordinadorAcademico",
            parametro: {
              "@p_idPersonaCordinadorAcademico": this._Session.usuario.persona
                .idPersona,
              "@p_idPeriodo": $("#frmAgregarSolicitud_periodo").val(),
              "@p_idCampus": this.model.idCampus
            }
          },
          respuesta => {
            if (respuesta.length > 0) {
              var item = respuesta[0];
              this.model.fechaLimite = item.nombre;
              this.formSolicitudFicha.controls["fechaLimite"].setValue(
                this.model.fechaLimite
              );
            }
          }
        );
      }

      this._utilitarios.OpenModal("myModal");
    });
  }
  onChangeBuscarDocente(event) {
    if (event.keyCode == 13) {
      var auxiliar: any = [];

      var data = this._docentes;

      this.cargarDocentes({
        idCampus: this.model.idCampus,
        idPeriodo:
          $("#frmAgregarSolicitud_periodo").val() == ""
            ? 0
            : $("#frmAgregarSolicitud_periodo").val(),
        idPersona: this._Session.usuario.persona.idPersona,
        buscarDocente: $(event.target)
          .val()
          .toString()
          .trim()
      });
    }
    /*data.map(el => {
      var obj = {
        idSolicitudFicha: el.idSolicitudFicha,
        idPersona: el.idPersona,
        idPersonaSolicitante: el.idPersonaSolicitante,
        nombreApellidos: el.nombreApellidos
      };
      auxiliar.push(obj);
    });
*/
    if (
      $(event.target)
        .val()
        .toString()
        .trim() != ""
    ) {
      /*
      for (var i = auxiliar.length - 1; i >= 0; i--) {
        var element = auxiliar[i];
        var encontro = false;
        if (
          element.nombreApellidos
            .toString()
            .toLowerCase()
            .indexOf(
              $(event.target)
                .val()
                .toString()
                .toLowerCase()
            ) < 0
        ) {
          auxiliar.splice(i, 1);
        }
      } this.dibujarDocentes(auxiliar).then((completo: number) => {
        $("#lblBuscar").prop("disabled", false);
        $("#lblBuscar").focus();
      });
    } else {
      $("#previewUTP").css("display", "flex");

      setInterval(() => {
        this.dibujarDocentes(auxiliar).then((completo: number) => {
          $("#previewUTP").css("display", "none");
        });
      }, 1000);
    
*/
    }
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  //-- prueba
  //---------------------------------------------------------------------------------------------------------------------------------------
  disabledes(event) {
    if ($(event.target).is(":checked")) {
      $("#idCampusformst").prop("disabled", true);
      this.angForm.controls["idCampusform"].clearValidators();
      this.angForm.controls["idCampusform"].setErrors(null);
      this.angForm.controls["idCampusform"].reset();
      this.angForm.controls["idCampusform"].setValue("");
    } else {
      this.angForm.controls["idCampusform"].setValidators([
        Validators.required
      ]);
      this.angForm.updateValueAndValidity();
      this.angForm.controls["idCampusform"].setValue("");
      $("#idCampusformst").prop("disabled", false);
    }
  }
  buildForm() {
    this.angForm = this.formBuilder.group({
      idCampusform: ["", Validators.required]
    });
  }

  //----------------------------------------------------------------------------------------------------------------------------------------
  //-- privados
  //---------------------------------------------------------------------------------------------------------------------------------------

  private buildFormSolicitud() {
    this.formSolicitudFicha = this.formBuilderSolicitudFicha.group({
      idCampus: ["", Validators.required],
      idPeriodo: ["", Validators.required],
      fechaLimite: ["", Validators.required],
      idCarreraSolicitud: ["", Validators.required]
    });
  }
  private limpiar(omitirPeriodo) {
    this.solicitadasCnt = 0;
    let idCampus: Int32Array = this.model.idCampus;
    this.edit = false;
    this.model.idPeriodo = "";
    this.model.fechaLimite = "";
    this.model.idCampusFacultadCarrera = "";

    $("#frmAgregarSolicitud_carrera").prop("disabled", true);
    $("#divDocentesDinamico").html("");
    $("#frmAgregarSolicitud_resetear").trigger("click");
    if (!omitirPeriodo) {
      this.periodoPorDefecto();
    }
    this.model.idCampus = idCampus.toString();
    this.campusPorDefecto();
    $("#lblBuscar").val("");
    $("#lblBuscar").prop("disabled", true);
  }
  private cargarMetodos() {
    let idPersona = this._Session.usuario["persona"].idPersona;
    this.combobox.init(
      {
        id: "frmAgregarSolicitud_campus",
        entidad: "mCampusCoordinadorAcademico",
        parametro: { "@p_idPersona": idPersona }
      },
      function (selectElement) { }
    );

    this.combobox.init(
      {
        id: "frmAgregarSolicitud_periodo",
        entidad: "mPeriodoVigenteAsignadoPorCI",
        parametro: {
          p_idPersonaCordinadorAcademico: this._Session.usuario["persona"]
            .idPersona
        }
      },
      (resl) => {

      }
    );
    this.combobox.getJson(
      {
        entidad: "mPeriodoVigente"
      },
      (resl) => {
        this._idPeriodoVigente = resl[0].id;
      

      }
    );
    this.combobox.init(
      {
        id: "frmAgregarSolicitud_carrera",
        entidad: "mCampusFacultadCarreraCoordinadorAcademico",
        parametro: { "@p_idPersona": this._Session.usuario.persona.idPersona }
      },
      function (selectElement) { }
    );
  }
  private obtenerValorMaximoFichas() {
    return this.combobox.getJson(
      {
        entidad: "ParametroObtenerValor",
        parametro: {
          "@p_codigo": "MAXFC"
        }
      },
      respuesta => {
        if (respuesta.length > 0) {
          var item = respuesta[0];

          this._maximoFichas = item.nombre;

          var html = $("<div></div>");
          var select = $(
            '<select  type="text" class="col-md-4 form-group form-control"></select>'
          );
          for (var i = this._maximoFichas; i >= 1; i--) {
            var option = $('<option value="' + i + '">' + i + "</option>");
            select.append(option);
          }

          html.append(select);
          this._SelectAuxiliar = html.html();
        }
      }
    );
  }
  private campusPorDefecto() {
    this.model.idCampus = $("#frmAgregarSolicitud_campus")
      .children("option:nth-child(0n+2)")
      .val()
      .toString();
    this.formSolicitudFicha.controls["idCampus"].clearValidators();
    this.formSolicitudFicha.controls["idCampus"].setErrors(null);
    this.formSolicitudFicha.controls["idCampus"].reset();
    this.formSolicitudFicha.controls["idCampus"].setValue(this.model.idCampus);
    $("#frmAgregarSolicitud_campus").prop("disabled", true);
  }

  private cargarDocentes(parametro) {
    this._PersonaService.ListarDocentesPorCampus(parametro, data => {
      this._docentesAuxiliar = data;
      this._docentes = JSON.parse(JSON.stringify(data));

      this.dibujarDocentes(data);
    });
  }
  private dibujarDocentes(data) {
    var promise = new Promise((resolve, reject) => {
      var divDocentesDinamico = $("#divDocentesDinamico");
      divDocentesDinamico.html("");
      console.log(data.length);
      data.forEach(element => {
        var divparent = $(
          '<div class="row mx-0" data-id="' +
          (element.idSolicitudFicha > 0 ? element.idSolicitudFicha : "") +
          '" data-row="' +
          (element.idSolicitudFicha > 0
            ? element.idPersonaSolicitante
            : element.idPersona) +
          '"></div>'
        );
        divparent.html("");
        var html = `
         <div class="col-md-8" >
         <div class="form-group checked">
             <input type="checkbox" 
             ${element.idSolicitudFicha > 0 ? "disabled" : ""} 
             id="${
          element.idSolicitudFicha > 0
            ? element.idPersonaSolicitante
            : element.idPersona
          }" 
             name="${
          element.idSolicitudFicha > 0
            ? element.idPersonaSolicitante
            : element.idPersona
          }"
             for="${
          element.idSolicitudFicha > 0
            ? element.idPersonaSolicitante
            : element.idPersona
          }">
             <label
             for="${
          element.idSolicitudFicha > 0
            ? element.idPersonaSolicitante
            : element.idPersona
          }">${element.nombreApellidos}</label>
         </div>
         </div>
         <div class="col-md-4">
         <div class="form-group row mx-0 cantidad">
             <label for="cboCantidad" class="col-md-8">Cantidad</label>
        ${this._SelectAuxiliar}
         </div>
        </div>
         `;

        var auxHTML = $(html);
        divparent.append(auxHTML);
        divDocentesDinamico.append(divparent);

        var input = divparent
          .children()
          .children(".checked")
          .find("input");
        var select = divparent
          .children()
          .children(".cantidad")
          .find("Select");

        if (element.idSolicitudFicha > 0) {
          $("#lblBuscar").val("");
          $("#lblBuscar").prop("disabled", true);
          $("#chkAll").prop("checked", true);
          $("#chkAll").prop("disabled", true);
          $("#frmAgregarSolicitud_carrera").prop("disabled", true);
          input.prop("checked", true);
          select.val(element.cantidad);

          this.cantidadMarcada();
        } else {
          $("#lblBuscar").prop("disabled", false);
          $("#chkAll").prop("checked", false);
          $("#chkAll").prop("disabled", false);
          $("#frmAgregarSolicitud_carrera").prop("disabled", false);
        }
        //----------------------------------------------------------------------------------------------------------------------------------------
        //-- Eventos
        //---------------------------------------------------------------------------------------------------------------------------------------

        select.change(event => {
          if (input.is(":checked")) {
            this.cantidadMarcada();
          }
        });
        input.change(event => {
          this.cantidadMarcada();
          if (this.solicitadasCnt > 0) {
            var divDocentesDinamico = $("#divDocentesDinamico");
            if (
              divDocentesDinamico.children("[data-row]").length == 1 ||
              divDocentesDinamico.children("[data-row]").length ==
              divDocentesDinamico.children("[data-row]").find("input:checked")
                .length
            ) {
              $("#chkAll").prop("checked", true);
            } else {
              $("#chkAll").prop("checked", false);
            }
          }
        });
      });
      resolve(1);
    });

    return promise;
  }

  private obtenerDatosPorId(parametro, callback) {
    this._SolicitudFicha.ObtenerPorId(
      {
        idSolicitudFicha: parametro.idSolicitudFicha
      },
      data => {
        data.fechaLimite = this._utilitarios.ConvertFechaServidorToDMYHMS(
          data.fechaLimite
        );
        if (callback != null) {
          callback(data);
        }
      }
    );
  }
  private cantidadMarcada() {
    var _that = this;
    _that.solicitadasCnt = 0;
    var divDocentesDinamico = $("#divDocentesDinamico");
    divDocentesDinamico.children("[data-row]").each(function () {
      var container = $(this).children();
      var chk = container.children(".checked").find('input[type="checkbox"]');
      if (chk.is(":checked")) {
        var select = container.children(".cantidad").find("select");
        _that.solicitadasCnt =
          _that.solicitadasCnt + parseInt(select.val().toString());
      }
    });
  }

  private periodoPorDefecto() {
    $("#frmAgregarSolicitud_periodo")
      .find("option")
      .removeAttr("selected|");


    $("#frmAgregarSolicitud_periodo").attr("disabled", "disabled");

    this.formSolicitudFicha.controls["idPeriodo"].setValue(this._idPeriodoVigente);
    this.model.idPeriodo = this._idPeriodoVigente;
    //-->
    //this.obtenerResumenPorPeriodoAgregar(null);
  }

  private obtenerResumenPorPeriodoAgregar(callback) {
    if ($("#frmAgregarSolicitud_periodo").val() == "") {
      this.pendiente = 0;
    } else {
      this._SolicitudFicha.ResumenSolicitudFicha(
        {
          idPersona: this._Session.usuario.persona.idPersona,
          idPeriodo: $("#frmAgregarSolicitud_periodo").val(),
          idCampusFacultadCarrera: $("#frmAgregarSolicitud_carrera").val()
        },
        data => {
          this.pendiente = data.pendientes;
          if (callback != null) {
            callback(data.pendientes);
          }
        }
      );
    }
  }
}
