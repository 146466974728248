<div id="divMenuContainer">

  <ul class="menu-utp menu-utp--animated">
    <li class="menu-utp__item menu-utp__item--has-children">
      <input class="menu-utp__input" type="checkbox" name="group-1" id="nivel-1">
      <label class="menu-utp__label menu-utp-icon" for="nivel-1">
        <span>TIBA</span>
      </label>

      <ul class="menu-utp__sub menu-utp-nivel-1" data-id="divMenuOpcionesChild">


        <li class="menu-utp__item">
          <a class="menu-utp__label menu-utp__label--icon-img" href="javascript:void(0)">
            <span>hijo</span> </a>
        </li>

        <li class="menu-utp__item menu-utp__item--has-children">
          <input class="menu-utp__input" type="checkbox" name="group-2" id="nivel-2">
          <label class="menu-utp__label menu-utp-icon" for="nivel-2" style="padding-left: 10px;">
            <span>Hijo2</span>
          </label>
          <ul class="menu-utp__sub menu-utp-nivel-1">
            <li class="menu-utp__item"><a class="menu-utp__label menu-utp__label--icon-img" href="javascript:void(0)">
                <span>hijo</span> </a>
            </li>
          </ul>

        </li>


      </ul>
    </li>
  </ul>



</div>
<!--
  <li class="nav-item " id="divMenuOpciones">
  <a class="nav-link" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
    aria-controls="collapseTwo">
    <i class="material-icons">school</i>
    <span>TIBA</span>
  </a>
  <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
    <div class="py-2 collapse-inner">
      <h6 class="collapse-header">Gestores</h6>
      <div  id="divMenuOpcionesChild"  >
         
      </div>
    </div>
  </div>
</li>

-->